import PropTypes from "prop-types";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  gridClasses,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

import AppRegistrationIcon from "@mui/icons-material/AppRegistration";

import {
  fetchAttendance,
  getattendanceDetails,
} from "../../Redux/Slice/Attendance/AttendanceSlice";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { useRef } from "react";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ViewLogWorkModal from "./ViewLogWorkModal";
const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },


  };
});
function AttendanceTable({ setShowForm, setSelectedRow, isEdit }) {
  const dispatch = useDispatch();
  const { header } = useStyles();
  const navigate = useNavigate();
  const EmployeeName = useRef();
  const ByDate = useRef();
  const [ALLattendances, setALLattendances] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const attendancedetails = useSelector(getattendanceDetails);
  const { IsLogin } = useSelector(getattendanceDetails);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [editPermit, seteditpermit] = useState(false);
  const [addPermit, setaddpermit] = useState(false);
  const [showModal, setshowModal] = useState(false);
  // console.log(crop);

  console.log(roles);
  useEffect(() => {
    let string = "Attendance";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Edit") {
        seteditpermit(true);
      } else if (val?.module?.split("_")[1] === "Add") {
        setaddpermit(true);
      }
    });
    if (!roles || roles.length < 1) {
      seteditpermit(true);
      setaddpermit(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  console.log(editPermit);
  console.log(addPermit);

  useEffect(() => {
    dispatch(fetchAttendance({ page: page, pageSize: pageSize }));
  }, [dispatch]);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: attendancedetails?.message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }

  }, [IsLogin]);
  useEffect(() => {
    if (attendancedetails?.attendance) {
      setALLattendances(attendancedetails?.attendance);
      setPage(attendancedetails?.PageNum);
      setPageSize(attendancedetails?.PageSize);
      settotalCount(attendancedetails?.TotalCount);
    }

  }, [attendancedetails])

  const [rows, setrows] = useState([]);
  const [filterRows, setfilterRows] = useState([]);
  const [pageSize, setPageSize] = useState(15);
  const [logWorkRow, setlogWorkRow] = useState(null);
  const [setmessage] = useState("");
  const [setopenToast] = useState(false);
  const [setAlertType] = useState("success");

  const UpdateDetail = (val) => {
    setSelectedRow(val);
    setShowForm(true);
    isEdit(true);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport csvOptions={{ fileName: "Attendance" }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }
  const columns = [
    { field: "sr", headerName: "Sr #", width: 100, headerClassName: header },
    {
      field: "Tabledate",
      headerName: "Date",
      width: 150,
      headerClassName: header,
    },
    {
      field: "employeename",
      headerName: "Employee",
      width: 150,
      headerClassName: header,
    },

    {
      field: "in_time",
      headerName: "In Time",
      width: 150,
      headerClassName: header,
    },
    {
      field: "out_time",
      headerName: "Out Time",
      width: 150,
      headerClassName: header,
    },
    {
      field: "totalhours",
      headerName: "Total Hours",
      width: 150,
      headerClassName: header,
    },
    {
      field: "overtime",
      headerName: "Over Time",
      width: 150,
      headerClassName: header,
    },
    {
      field: "time_zone",
      headerName: "Time Zone",
      width: 250,
      headerClassName: header,
    },
    {
      field: "click",
      headerName: "Actions",
      width: 180,
      headerClassName: header,

      renderCell: (cellValues) => {
        return (
          <>
            <Box sx={{ display: "flex" }}>
              {!cellValues.row.out_time || !cellValues.row.in_time ? (
                editPermit ? (
                  <Tooltip title="Update Out Time">
                    <IconButton>

                      <AppRegistrationIcon
                        sx={{ cursor: "pointer", color: "black !important" }}
                        onClick={() => {
                          UpdateDetail(cellValues.row);
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                ) : null
              ) : null}
              <Tooltip title="view log work">
                <IconButton onClick={() => {
                  setlogWorkRow(cellValues?.row);
                  setshowModal(true)
                }}>
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    let arr = [];

    if (ALLattendances) {
      ALLattendances?.forEach((item, idx) => {
        let timezone = item?.emp_id?.shift_rules?.time_zone
          ? JSON.parse(item?.emp_id?.shift_rules?.time_zone)
          : {};

        return arr.push({
          ...item,
          sr: idx + 1,
          id: item?.attendance_id,
          employeename: item?.emp_id?.name,
          Emp_id: item?.emp_id?.Emp_id,
          Tabledate: item?.date ? moment(item.date).format("DD-MMM-YYYY") : "",
          totalhours: item?.total_hrs
            ? `${item?.total_hrs.split(":")[0]} hrs ${item?.total_hrs.split(":")[1]
            } min `
            : 0,
          // ? moment(item?.total_hrs, "HH:mm:ss").format("hh")
          // : ""
          in_time: moment(item?.in_time, "HH:mm:ss").format("hh:mm A"),

          out_time: item?.out_time
            ? moment(item?.out_time, "HH:mm:ss").format("hh:mm A")
            : "",
          overtime: item?.over_time
            ? `${item?.over_time.split(":")[0]} hrs ${item?.over_time.split(":")[1]
            } min `
            : 0,
          time_zone: timezone?.label,
        });
      });
    }
    setrows(arr);
    setfilterRows(arr);
  }, [ALLattendances]);

  const onTextChange = () => {
    if (EmployeeName.current.value === "" && ByDate.current.value === "") {
      setfilterRows(rows);
    } else {
      let suggestions = [];

      let regex1 = new RegExp(EmployeeName.current.value, "i");
      let regex2 = new RegExp(
        moment(ByDate.current.value).format("DD-MMM-YYYY"),
        "i"
      );

      suggestions = rows?.filter(
        (val) =>
          (EmployeeName.current.value === "" ||
            regex1.test(val.employeename)) &&
          (ByDate.current.value === "" || regex2.test(val?.Tabledate))
      );

      setfilterRows(suggestions);
    }
  };

  useEffect(() => {
    if (
      getattendanceDetails?.status === "succeeded" ||
      getattendanceDetails?.status === "failed"
    ) {
      if (getattendanceDetails?.isSuccess === true) {
        setAlertType("success");
        setmessage(getattendanceDetails.message);
        setopenToast(true);
      }
      if (getattendanceDetails?.isSuccess === false) {
        setAlertType("error");
        setmessage(getattendanceDetails.message || getattendanceDetails.error);
        setopenToast(true);
      }
    }
  }, [getattendanceDetails]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          padding: "20px 10px 10px 10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {addPermit ? (
            <Button
              sx={{ padding: "5px 10px" }}
              onClick={() => {
                setShowForm(true);
              }}
              variant="contained"
              className="tabletopbutton1 text-right"
            >
              New
            </Button>
          ) : null}
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
          }}
        >
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{ color: (theme) => theme.palette.grey.G2 }}
                  />
                </InputAdornment>
              ),
            }}
            variant="standard"
            type="text"
            autoComplete="off"
            placeholder="Employee"
            className="input_border"
            id="empName"
            inputRef={EmployeeName}
            onChange={onTextChange}
          />
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{ color: (theme) => theme.palette.grey.G2 }}
                  />
                </InputAdornment>
              ),
            }}
            variant="standard"
            type="date"
            autoComplete="off"
            placeholder="Search"
            className="input_border"
            id="date"
            inputRef={ByDate}
            onChange={onTextChange}
          />
        </Box>
      </Box>

      <span className="ml-2 float-right">
        <div className="loaderclass"> </div>
      </span>

      {attendancedetails.status !== "succeeded" ? (
        <>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100vh - 237px)",
            }}
          >
            <Box sx={{ width: "5%" }}>
              <CircularProgress color="primary" />
            </Box>
          </Box>
        </>
      ) : (
        <Grid item md={12} sm={12} xs={12} overflow>
          <Box
            sx={{
              height: "calc(100vh - 237px)",
              width: "100%",
              padding: "10px",
            }}
          >
            <DataGrid
              rows={filterRows}
              columns={columns}
              pageSize={pageSize}
              rowCount={totalCount}
              page={Number(page) - 1}
              onPageChange={(newPage) => {
                setPage(newPage + 1);
                if (
                  Number(attendancedetails?.PageNum) < newPage + 1 &&
                  ALLattendances?.length < pageSize * Number(newPage + 1)
                ) {
                  dispatch(
                    fetchAttendance({ page: newPage, pageSize: pageSize })
                  );
                }
              }}
              onPageSizeChange={(newPageSize) => {
                if (ALLattendances?.length >= newPageSize) {
                  setPage(1);
                  setPageSize(newPageSize);
                } else if (newPageSize >= ALLattendances?.length) {
                  setPage(1);

                  dispatch(
                    fetchAttendance({ page: Number(0), pageSize: newPageSize })
                  );
                }
              }}
              rowsPerPageOptions={[15, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
              pagination
              components={{
                Toolbar: CustomToolbar,
              }}
              localeText={{
                toolbarDensity: "Size",
                toolbarDensityLabel: "Size",
                toolbarDensityCompact: "Small",
                toolbarDensityStandard: "Medium",
                toolbarDensityComfortable: "Large",
              }}
            />
          </Box>
        </Grid>
      )}
      <ViewLogWorkModal logWorkRow={logWorkRow} setlogWorkRow={setlogWorkRow} showModal={showModal} setshowModal={setshowModal} />
    </>
  );
}

export default AttendanceTable;
AttendanceTable.propTypes = {
  setShowForm: PropTypes.any,
  setSelectedRow: PropTypes.any,
  SelectedRow: PropTypes.any,
  isEdit: PropTypes.any,
};
