import { Box, Typography, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import StyleIcon from "@mui/icons-material/Style";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import FilterTiltShiftIcon from "@mui/icons-material/FilterTiltShift";
import { makeStyles } from "@mui/styles";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "start",
    padding: theme.spacing(2),
    gap: theme.spacing(2),
  },
  header: {
    fontWeight: "bold",
  },
  description: {
    fontSize: theme.typography.pxToRem(16),
    color: "#53b7c5",
  },
  card: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    padding: theme.spacing(5),
    gap: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    marginTop: "10px",
    marginBottom: "10px",

  },
  cardItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: theme.spacing(1),
    cursor: "pointer",
    height: "90px"
  },
  icon: {
    color: "#53b7c5",
    fontSize: "50px"
  },
}));

const SettingMain = () => {
  const style = useStyles();
  const navigate = useNavigate();

  return (
    <div>
      <Box className={style.root}>
        <Typography variant="h6" className={style.header}>
          Company settings
        </Typography>
        <Typography variant="body1" className={style.description}>
          Manage all your company settings here.
        </Typography>
        <Box className={style.card}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                className={style.cardItem}
                onClick={() => {
                  navigate("/tags");
                }}
              >
                <StyleIcon className={style.icon} />
                <Typography variant="h5" >Tags</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>

              <Box
                className={style.cardItem}
                onClick={() => {
                  navigate("/roles");
                }}
              >
                <SettingsAccessibilityIcon className={style.icon} />
                <Typography variant="h5">Roles</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>

              <Box
                className={style.cardItem}
                onClick={() => {
                  navigate("/rules");
                }}
              >
                <GppMaybeIcon className={style.icon} />
                <Typography variant="h5" >Rules</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className={style.card}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                className={style.cardItem}
                onClick={() => {
                  navigate("/GazettedHolidays");
                }}
              >
                <HolidayVillageIcon className={style.icon} />
                <Typography variant="h5" >Gazetted Holidays</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                className={style.cardItem}
                onClick={() => {
                  navigate("/ShiftRules");
                }}
              >
                <FilterTiltShiftIcon className={style.icon} />
                <Typography variant="h5" >Shift Rules</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div >
  );
};

export default SettingMain;
