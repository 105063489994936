import React from "react";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import { Box } from "@mui/material";
import EmployeePortalSummaryTable from "../../Components/EmployeePortalSummary/EmployeePortalSummaryTable";
const EmployeePortalSummary = () => {
    const [canView] = useState(true);

    return (
        <div>
            {canView === true ? (


                <EmployeePortalSummaryTable
                />

            ) : canView === false ? (
                <Navigate to={"/dashboard"} />
            ) : (
                <Box>Loading ...</Box>
            )}
        </div>
    );
};

export default EmployeePortalSummary;

