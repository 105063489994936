import { Box, IconButton, Modal, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import PropTypes from "prop-types";
import CancelIcon from "@mui/icons-material/Cancel";
const useStyle = makeStyles((theme) => {
  return {
    container: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "10px",
    },
    subContainer: {
      width: "100%",
      maxWidth: "450px",
      padding: "20px",
      position: "relative",
      borderRadius: "10px",
      background: theme.palette.white.main,
      color: theme.palette.grey[700],
    },
  };
});

const ToolTipModal = ({ open, setOpen, tooltipText }) => {
  const { container, subContainer } = useStyle();
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box className={container}>
        <Box className={subContainer}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Support
          </Typography>
          <Box
            sx={{
              height: "100%",
              maxHeight: "550px",
              overflowX: "hidden",
              msOverflowY: "scroll",
            }}
          >
            <Typography sx={{ padding: "10px 0px" }}>{tooltipText}</Typography>
          </Box>
          <IconButton
            sx={{ position: "absolute", top: "0px", right: "0px" }}
            onClick={() => setOpen(false)}
          >
            <CancelIcon color="error" sx={{ fontSize: "25px" }} />
          </IconButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default ToolTipModal;
ToolTipModal.propTypes = {
  open: PropTypes.any,
  setOpen: PropTypes.any,
  tooltipText: PropTypes.any,
};
