import {
  Autocomplete,
  Box,
  Button,
  // CircularProgress,
  gridClasses,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  // MenuItem,
  TextField,
  Typography,
  Badge,
} from "@mui/material";
// import ReportTabs from "../../Components/EmployeeMonthlyAttendance/Reportchangetab";
import axiosInstance from "../../Utils/AxiosInstance";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { getmonthlyreportDetails } from '../../Redux/Slice/MonthlyReport/MonthlyReportSlice';
import { makeStyles } from "@mui/styles";
import {
  // FetchMonthlyAttendanceReport,
  fetchMonthlyReportPopulate,
  // fetchsingleEmpReport,
  getEmpmonthlyreportDetail,
  RemoveEmpMonthlyReport,
} from "../../Redux/Slice/EmployeMonthlyReport/EmployeMonthlyReportSlice";
import moment from "moment/moment";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import Cookies from "universal-cookie";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { Navigate, useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import EmployeeMonthlyAttendancePrint from "./EmployeMonthlyReportPrint";
import { useReactToPrint } from "react-to-print";
import EmployeeDetails from "../../Components/EmployeeMonthlyReport/EmployeeDetails";
import Loader from "../../Components/Loader/Loader";
import BreakadjTable from "../../Components/EmployeeMonthlyAttendance/BreakadjTable";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";
import { useMediaQuery } from "@mui/material";
const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
    },
    Container: {
      width: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    TableContain: { height: "200px", overflowY: "scroll" },
    // countstyle:{
    //   border: '1px solid black',
    //   padding: '4px',
    //   paddingLeft: "6px",
    //   paddingRight: "6px",
    //   borderRadius: '13px',
    //   backgroundColor: (theme) => theme.palette.primary.main,
    // }
  };
});
const cookies = new Cookies();
// console.log(axiosInstance);
function EmployeMonthlyReport() {
  const { tabelStyle } = useContext(TabelStyleContext);
  const user = cookies.get("user");
  const { header, ModalContainer, Container, TableHeader, TableContain } = useStyles();
  const [selectedEmployee, setselectedEmployee] = useState(null);
  const [loading, setloading] = useState(false);
  const [EmpMonthlyAttendance, setEmpMonthlyAttendance] = useState([]);
  const [employees, setemployees] = useState([]);
  const [pageSize, setPageSize] = useState(40);
  const [month, setmonth] = useState(moment().format("YYYY-MM"));
  const [rows, setRows] = useState([]);
  const [selectedRow, setselectedRow] = useState(null);
  const [punchModal, setpunchModal] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const [empmonthlyreport, setempmonthlyreport] = useState([]);
  const {
    Report_Populate,
    // Empmonthlyreport,
    // EmpMonthlyAttendance,
    // status,
    IsLogin,
    message,
  } = useSelector(getEmpmonthlyreportDetail);
  const Date = useRef();
  console.log(selectedRow);
  const dispatch = useDispatch();
  const [canView, setcanView] = useState(null);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  //Breakadj
  const [value, setValue] = useState(0);

  useEffect(() => {
    let string = "EmployeeMonthlyAttendance";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });

    let match = value?.some((val) => {
      if (val?.module?.split("_")[1] === "View") {
        return true;
      }
    });
    setcanView(match);
    if (!roles || roles.length < 1) {
      setcanView(false);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  const componentRef = useRef();
  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  const FetchMonthlyAttendanceReport2 = async (obj) => {
    setloading(true);
    try {
      const response = await axiosInstance.get(
        `/monthattendancesummary?emp_id=${obj?.empid}&month=${obj?.month}`
      );
      if (response?.data?.IsSuccess === true) {
        setloading(false);
        setEmpMonthlyAttendance(response?.data);
      } else {
        setloading(false);
      }
    } catch (error) {
      setloading(false);
      console.error("Error fetching data:", error);
    }
  };
  // FetchMonthlyAttendanceReport2({
  //   empid: user?.Emp_id,
  //   month: moment().format("YYYY-MM"),
  // })
  useEffect(() => {
    if (user?.type === "Employee") {
      // dispatch(
      //   FetchMonthlyAttendanceReport({
      //     empid: user?.Emp_id,
      //     month: moment().format("YYYY-MM"),
      //   })
      // );
      FetchMonthlyAttendanceReport2({
        empid: user?.Emp_id,
        month: month,
      });
    } else {
      dispatch(fetchMonthlyReportPopulate());
    }
  }, [dispatch]);

  useEffect(() => {
    let arr = [];

    if (EmpMonthlyAttendance) {
      EmpMonthlyAttendance?.Data?.forEach((item, idx) => {
        // let timezone = item.timezone ? JSON.parse(item?.timezone) : {};

        return arr.push({
          // ...item,
          sr: idx + 1,
          id: item?.attendance_id,
          Tabledate: item?.Date ? moment(item.Date).format("DD-MMM-YYYY") : "",
          totalhours: item?.Worked_hrs,
          // ? `${item?.Worked_hrs.split(":")[0]} hrs ${
          //     item?.Worked_hrs.split(":")[1]
          //   } min `
          // : 0,
          // item?.total_hrs
          //   ? moment(item?.total_hrs, "HH:mm:ss").format("hh")
          //   : "",
          in_time: moment(item?.CheckIn, "HH:mm:ss").format("hh:mm A"),

          out_time: item?.CheckOut
            ? moment(item?.CheckOut, "HH:mm:ss").format("hh:mm A")
            : "",
          overtime: item?.OverTime_hrs,
          wastetime: item?.waste_time,
          break_time: item?.break_time || "N/A",
          totalPunches: item?.totalPunches || "N/A",
          punches_detail: item?.punches_detail,
          // ? `${item?.OverTime_hrs.split(":")[0]} hrs ${
          //     item?.OverTime_hrs.split(":")[1]
          //   } min `
          // : 0,
          // time_zone: timezone?.label,
          // employeefathername: item?.emp_id?.father_name,
        });
      });
    }
    setRows(arr);
    setempmonthlyreport(arr);
    // setfilterRows(arr);
  }, [EmpMonthlyAttendance]);
  useEffect(() => {
    if (Report_Populate) {
      setemployees(Report_Populate);
    }
  }, [Report_Populate]);
  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport csvOptions={{ fileName: "EmployeeMonthlyAtendance" }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }
  // Tabs for select table
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  //   const breakAdjustmentLabel = (

  //     <span>
  //         Break Adjustment{' '}

  //      {EmpMonthlyAttendance?.breakAdjCount > 0 && (
  //     <span
  //     style={{
  //         border: '1px solid black',
  //         padding: '2px',
  //         paddingLeft: "6px",
  //         paddingRight: "6px",
  //         borderRadius: '13px',
  //         // backgroundColor: "#97C5CE"
  //     }}
  // >
  //         {EmpMonthlyAttendance?.breakAdjCount}
  //     </span>
  // )}
  //     </span>
  // );
  function ReportTabs() {
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    return (
      <Box sx={{ width: "100%" }}>
        <Box>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Attendance" {...a11yProps(0)} sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" } }} />
            <Tab label="Break Adjustment" {...a11yProps(1)} sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" } }} />
            <Badge
              badgeContent={EmpMonthlyAttendance?.breakAdjCount}
              color="primary"
              sx={{ right: 8, top: 23, padding: "0 4px" }}
            />
          </Tabs>
        </Box>
      </Box>
    );
  }

  const columns = [
    { field: "sr", headerName: "Sr #", width: 100, headerClassName: header },
    {
      field: "Tabledate",
      headerName: "Date",
      width: 150,
      headerClassName: header,
    },
    {
      field: "in_time",
      headerName: "In Time",
      width: 150,
      headerClassName: header,
    },
    {
      field: "out_time",
      headerName: "Out Time",
      width: 150,
      headerClassName: header,
    },
    {
      field: "totalhours",
      headerName: "Worked Hours",
      width: 150,
      headerClassName: header,
    },
    {
      field: "overtime",
      headerName: "Extra Hours",
      width: 150,
      headerClassName: header,
    },
    {
      field: "wastetime",
      headerName: "Short Hours",
      width: 150,
      headerClassName: header,
    },
    {
      field: "break_time",
      headerName: "Break Adjustment",
      width: 150,
      headerClassName: header,
    },
    {
      field: "totalPunches",
      headerName: "Total Punches",
      width: 150,
      headerClassName: header,
    },
  ];
  const handleEmployeeChange = (val) => {
    // setcurrenttime(null)
    // setfilterRows([]);
    setselectedEmployee(val);
  };

  const handleGet = () => {
    if (user?.type === "Admin") {
      if (month === "" || selectedEmployee === null) {
        return setsnackBarData({
          type: "error",
          message: "Please add details properly.!",
          openToast: "true",
        });
      }
      FetchMonthlyAttendanceReport2({
        empid: selectedEmployee?.id,
        month: month,
      });
      // dispatch(
      //   FetchMonthlyAttendanceReport({
      //     empid: selectedEmployee?.id,
      //     month: month,
      //   })
      // );
    } else if (user?.type === "Employee") {
      if (month === "") {
        return setsnackBarData({
          type: "error",
          message: "Please add details properly.!",
          openToast: "true",
        });
      }
      // dispatch(
      //   FetchMonthlyAttendanceReport({
      //     empid: user?.Emp_id,
      //     month: month,
      //   })
      // );
      FetchMonthlyAttendanceReport2({
        empid: user?.Emp_id,
        month: month,
      });
    }

    //   if (
    //     user?.type === "Admin"
    //       ? month === "" || selectedEmployee === null
    //       : month === ""
    //   ) {
    //     return setsnackBarData({
    //       type: "error",
    //       message: "Please Add Details Properly.!",
    //       openToast: "true",
    //     });
    //   }
    // const Month = moment(month).format("YYYY-MM-DD");

    // dispatch(
    //   fetchsingleEmpReport({
    //     id: user?.type === "Employee" ? user?.Emp_id : selectedEmployee?.id,
    //     month: Month,
    //   })
    // );
  };

  useEffect(() => {
    dispatch(RemoveEmpMonthlyReport());
  }, []);
  const onTextChange = () => {
    if (Date.current.value === "") {
      setempmonthlyreport(rows);
    } else {
      let suggestions = [];
      let regex2 = new RegExp(moment(Date.current.value).format("DD-MMM-YYYY"), "i");
      suggestions = rows?.filter(
        (val) => Date.current?.value === "" || regex2.test(val?.Tabledate)
      );
      setempmonthlyreport(suggestions);
    }
  };

  useEffect(() => {
    console.log("EmpMonthlyAttendance", EmpMonthlyAttendance);
  }, [EmpMonthlyAttendance]);

  return (
    <>
      {canView === true ? (
        <>
          <Box sx={{ display: "none" }}>
            <EmployeeMonthlyAttendancePrint
              name={selectedEmployee?.name}
              month={moment(month).format("MMM-YYYY")}
              ref={componentRef}
              empmonthlyreport={empmonthlyreport}
              EmpMonthlyAttendance={EmpMonthlyAttendance}
            />
          </Box>
          <Box
            sx={{
              padding: "20px 10px 10px 10px",
              textAlign: "left",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: { xs: 'column', sm: "row" }
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  paddingBottom: "10px",
                  width: "100%",
                }}
              >
                {user?.type === "Admin" ? (
                  <Autocomplete
                    disablePortal
                    options={employees}
                    getOptionLabel={(option) => option.name}
                    sx={{
                      input: { color: "black" },
                      maxWidth: "250px",
                      textAlign: "left",
                    }}
                    fullWidth
                    // value={values.emp_id}
                    size="small"
                    onChange={(e, val) => {
                      handleEmployeeChange(val);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Employee" />
                    )}
                  />
                ) : null}

                <TextField
                  size="small"
                  type="month"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  value={month}
                  sx={{
                    input: { color: "black" },
                    // maxWidth: "250px",
                    maxWidth: {
                      xs: "140px",
                      sm: "200px",
                      md: "250px",
                    },
                    textAlign: "left",
                  }}
                  // value={currenttime}
                  inputProps={{
                    max: moment().format("YYYY-MM"),
                  }}
                  onChange={(e) => {
                    setmonth(e.target.value);
                  }}
                />

                {/* <Button
                  onClick={() => {
                    handleGet();
                  }}
                  // sx={{ padding: "5px 10px" }}
                  sx={{
                    padding: {
                      xs: "3px 6px",  // Smaller padding on mobile
                      sm: "5px 10px", // Default padding for small screens and up
                    },
                  }}
                  variant="contained"
                >
                  Get
                </Button> */}
                <Button
                  onClick={handleGet}
                  sx={{
                    padding: {
                      xs: "2px 5px",  // Smaller padding for mobile
                      sm: "5px 10px", // Normal padding for larger screens
                    },
                    height: {
                      xs: "35px",
                      sm: "39px",
                    },
                    minHeight: "unset",
                  }}
                  variant="contained"
                >
                  Get
                </Button>

                <Button
                  onClick={() => {
                    printPaymentRec();
                  }}
                  // sx={{ padding: "5px 10px" }}
                  sx={{
                    padding: {
                      xs: "3px 6px",
                      sm: "5px 10px",
                    },
                    height: {
                      xs: "35px",
                      sm: "39px",
                    },
                  }}
                  variant="contained"
                >
                  Print
                </Button>
              </Box>
              {!isSmallScreen &&
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{
                            color: (theme) => theme.palette.grey.G2,
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  type="date"
                  autoComplete="off"
                  placeholder="Search"
                  // className="input_border"
                  inputRef={Date}
                  id="date"
                  onChange={onTextChange}
                />
              }
            </Box>

            <Box>
              <EmployeeDetails data={EmpMonthlyAttendance} />
            </Box>
            <Box
              // sx={{padding:"0px 10px"}}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px 10px 0px 10px",
                width: "100%",
                flexDirection: { xs: 'column', sm: "row" }
              }}
            >
              <Box sx={{ display: "flex", gap: { xs: "32px", sm: "10px" } }}>
                <Typography sx={{ fontWeight: "bold", fontSize: { xs: "14px", sm: "17px" }, }}>Total Worked Hours:</Typography>
                <Typography sx={{ fontWeight: "bold", fontSize: { xs: "14px", sm: "17px" }, }}>
                  {
                    EmpMonthlyAttendance?.Total_workedHrs
                    // ? `${parseInt(
                    //     Total_workedHrs.split(":")[0]
                    //   )} hrs ${parseInt(Total_workedHrs.split(":")[1])} min`
                    // : null
                  }
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: { xs: "48px", sm: "10px" } }}>
                <Typography sx={{ fontWeight: "bold", fontSize: { xs: "14px", sm: "17px" }, }}> Total Extra Hours:</Typography>
                <Typography sx={{ fontWeight: "bold", fontSize: { xs: "14px", sm: "17px" }, }}>
                  {EmpMonthlyAttendance?.Total_overtimeHrs}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: { xs: "47px", sm: "10px" } }}>
                <Typography sx={{ fontWeight: "bold", fontSize: { xs: "14px", sm: "17px" }, }}>Total Short Hours:</Typography>
                <Typography sx={{ fontWeight: "bold", fontSize: { xs: "14px", sm: "17px" }, }}>
                  {EmpMonthlyAttendance?.Total_wastedHrs}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontWeight: "bold", fontSize: { xs: "14px", sm: "17px" }, }}>
                  Total Break Adjustment:
                </Typography>
                <Typography sx={{ fontWeight: "bold", fontSize: { xs: "14px", sm: "17px" }, }}>
                  {EmpMonthlyAttendance?.Total_BreakTime}
                </Typography>
              </Box>
            </Box>
            <ReportTabs />
          </Box>
          {/* Tables: aatendance and break adjustment */}
          <CustomTabPanel value={value} index={0}>
            <div style={{ height: tabelStyle.height, padding: "10px", width: "100%" }}>
              {loading ? <Loader /> : null}
              <DataGrid
                rows={empmonthlyreport}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[40, 50, 60, 70, 80, 90, 100]}
                // components={{
                //   Toolbar: CustomToolbar,
                // }}
                components={isSmallScreen ? {} : { Toolbar: CustomToolbar }}
                onRowClick={(e) => {
                  setselectedRow(e.row);
                  setpunchModal(true);
                  console.log(e.row);
                }}
                sx={{
                  overflow: "scroll",
                  cursor: "pointer",
                }}
                scrollbarSize={2}
                localeText={{
                  toolbarDensity: "Size",
                  toolbarDensityLabel: "Size",
                  toolbarDensityCompact: "Small",
                  toolbarDensityStandard: "Medium",
                  toolbarDensityComfortable: "Large",
                }}
                hideFooter={true}
              />
            </div>
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            <BreakadjTable
              Empdata={EmpMonthlyAttendance}
              Loading={loading}
              Empfetch={FetchMonthlyAttendanceReport2}
              selectedemp={selectedEmployee}
              month={month}
            />
          </CustomTabPanel>
        </>
      ) : canView === false ? (
        <Navigate to={"/dashboard"} />
      ) : (
        <Box>Loading ...</Box>
      )}
      {punchModal && (
        <>
          <Modal
            open={punchModal}
            onClose={() => {
              setpunchModal(false);
            }}
          >
            <Box className={ModalContainer}>
              <Box className={Container}>
                <Box className={TableHeader}>
                  <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                    Punches Details
                  </Typography>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setpunchModal(false);
                    }}
                  >
                    <HighlightOffIcon
                      color="primary"
                      sx={{
                        fontSize: "28px",
                      }}
                    />
                  </IconButton>
                </Box>

                <TableContainer component={Paper} className={TableContain}>
                  <Table
                    stickyHeader
                    sx={{
                      minWidth: { md: "auto", xs: "auto" },
                      overflow: "scroll",
                    }}
                    aria-label="simple table"
                    size="small"
                  >
                    <TableHead>
                      <TableRow size="small">
                        <TableCell size="small" sx={{ fontWeight: "bold" }}>
                          #
                        </TableCell>
                        <TableCell size="small" sx={{ fontWeight: "bold" }}>
                          Date
                        </TableCell>
                        <TableCell size="small" sx={{ fontWeight: "bold" }}>
                          Status
                        </TableCell>
                        <TableCell size="small" sx={{ fontWeight: "bold" }}>
                          Time
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedRow?.punches_detail?.map((row, i) => {
                        // const Date=moment(row)
                        return (
                          <TableRow
                            key={i}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell size="small" component="th" scope="row">
                              {i + 1}
                            </TableCell>
                            <TableCell size="small" component="th" scope="row">
                              {row?.date ? moment(row?.date).format("DD-MMM-YYYY") : ""}
                            </TableCell>
                            <TableCell size="small" component="th" scope="row">
                              {row?.status}
                            </TableCell>
                            <TableCell size="small" component="th" scope="row">
                              {moment(row?.time, "HH-mm").format("LT")}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}

export default EmployeMonthlyReport;
