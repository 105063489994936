export const colorPalette = [
  "#E6F2D6",
  "#C7D8C9",
  "#A3D1E3",
  "#F6D6E2",
  "#A5E2F1",
  "#FFDFE6",
  "#D4B5D6",
  "#A7F8D2",
  "#E8A7DB",
  "#F1E4C7",
  "#FFB6C1",
  "#FF8C94",
  "#F3A2A2",
  "#F79C42",
  "#FFC3A0",
  "#FFAC92",
  "#F6B5D1",
  "#F2A9CE",
  "#FFAE88",
  "#F8D5A3",
  "#FF6F91",
  "#FF4F87",
  "#F68C9A",
  "#FF7369",
  "#FFDE8E",
  "#F0E3A1",
  "#F9E8A2",
  "#F6DA87",
  "#F5C2E1",
  "#F3B0D4",
  "#F0C6B5",
  "#F1B2D4",
  "#F7B3BE",
  "#F2C6F2",
  "#F1C6C6",
  "#F7D1B9",
  "#F6D4C0",
  "#F1BBBD",
  "#F9C9E4",
  "#F2A8C8",
  "#F8A9B9",
  "#F7D6D7",
  "#F2A5A6",
  "#F3BCC0",
  "#F5A9C4",
  "#FFEC8C",
  "#F9C7A4",
  "#F2F1C3",
  "#F5C1A1",
  "#F9B4D9",
  "#F2C9B4",
  "#F8A8BE",
  "#F6D1A3",
  "#F9D3D2",
  "#F0B9BB",
  "#F3F2BC",
  "#F8BEFF",
  "#FFB2DB",
  "#FF6DB2",
  "#F7BCCF",
  "#F4A9DF",
  "#F8D0E6",
  "#F6A7E1",
  "#F9A2B6",
  "#F7B7D1",
  "#F1E1DC",
  "#F8C6F4",
  "#F7D1EE",
  "#F6BBEB",
  "#F1C0F2",
  "#F0C5DC",
  "#F7B7E3",
  "#F3A9C9",
  "#F9DBA3",
  "#F4B8F0",
  "#FF8FE1",
  "#F3DAF1",
  "#F7C0F6",
  "#F9A3FB",
  "#F1D4E3",
  "#F2B8E6",
  "#F8A5DF",
  "#F4A6E1",
  "#F6D9F7",
  "#F7E5F2",
  "#FF81D4",
  "#FFABF1",
  "#F4B1F7",
  "#F1C8F8",
  "#F5C4F3",
  "#F3B8F5",
  "#F8B2FF",
  "#F9D4FC",
  "#F4A0F7",
  "#F2A7F6",
];
