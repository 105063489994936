import axiosInstance from "../../Utils/AxiosInstance";

export const AddShiftRulesApi = async (data) => {
  let snackBarData = {};

  try {
    let response = await axiosInstance.post("newshiftrules", data);

    snackBarData = {
      type: response?.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return { ...response, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.Message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const DeleteShiftRuleApi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.delete(`/deleteshiftrule/${data}`);

    snackBarData = {
      type: response.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return { ...response, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response.data.Message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
