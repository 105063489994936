import PropTypes from "prop-types";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import Loader from "../Loader/Loader";
import { GetMonthlyBreakAdjReport } from "../../Api/BreakAdjusmentMonth/BreakAdjReportApi";

const useStyle = makeStyles((theme) => {
  return {
    tablehead: {
      fontWeight: "bold",
      backgroundColor: "#53b7c5 !important",
      color: theme.palette.white.main,
      padding: "15px 20px",
      flex: 1,
    },
  };
});

function MonthlyBreakAdj({ setrowvalue, rowvalue }) {
  const { tablehead } = useStyle();
  const [filterRows, setfilterRows] = useState([]);
  const [loading, setloading] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(rowvalue);
  const { setsnackBarData } = useContext(SnackBarContext);
  const { tabelStyle } = useContext(TabelStyleContext);
  const [search, setSearch] = useState("");

  const handleClick = () => {
    setrowvalue(selectedMonth);
  };
  const GetBreakAdj = async () => {
    setloading(true);
    const response = await GetMonthlyBreakAdjReport({
      month: rowvalue,
    });
    console.log("response", response);
    if (response?.snackBarData?.message) {
      setsnackBarData({
        type: response.data.IsSuccess ? "success" : "error",
        message: response?.snackBarData?.message,
        openToast: true,
      });
    }
    setloading(false);
    if (response.data.IsSuccess) {
      setfilterRows(response?.data);
    } else {
      setloading(false);
    }
    setloading(false);
  };

  useEffect(() => {
    if (rowvalue) {
      GetBreakAdj();
    }
  }, [rowvalue]);
  console.log("filterRows", filterRows);
  const [expandedRow, setExpandedRow] = useState(null);
  const handleExpand = (rowIndex) => {
    setExpandedRow(rowIndex === expandedRow ? null : rowIndex);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          padding: "20px 10px 10px 10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
            width: "35%",
          }}
        >
          <TextField
            size="small"
            type="month"
            variant="outlined"
            color="primary"
            fullWidth
            sx={{
              input: { color: "black" },
              maxWidth: "250px",
              textAlign: "left",
            }}
            value={selectedMonth}
            inputProps={{
              max: moment().format("YYYY-MM"),
            }}
            onChange={(e) => {
              console.log(e.target.value);
              setSelectedMonth(e.target.value);
            }}
          />

          <Button
            onClick={handleClick}
            type="submit"
            sx={{ padding: "5px 10px" }}
            variant="contained"
          >
            Get
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            width: "35%",
          }}
        >
          <TextField
            id="input-with-icon-search"
            label="Search"
            focused
            type="search"
            value={search}
            placeholder="Search by name ..."
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
          />
        </Box>
      </Box>

      {loading ? (
        <>
          <Loader />
        </>
      ) : null}
      <Grid
        item
        md={12}
        sm={12}
        xs={12}
        overflow
        sx={{ height: tabelStyle.height, padding: "10px" }}
      >
        <TableContainer
          component={Paper}
          sx={{
            width: "100%",
            maxWidth: "100%",
            height: "100%",
            overflowX: "scroll",
            margin: "0px auto",
          }}
        >
          <Table size="small" stickyHeader sx={{ overflowX: "scroll" }}>
            <TableHead
              sx={{
                width: "100%",
                padding: "0px 10px",
              }}
            >
              <TableRow sx={{ padding: "0px 20px", width: "100%" }}>
                <TableCell className={tablehead}>Sr #</TableCell>
                <TableCell className={tablehead}>Employee Name</TableCell>
                <TableCell className={tablehead}>Total Break Adjusment</TableCell>
                <TableCell className={tablehead}>Pending Break Adjusment</TableCell>
                <TableCell className={tablehead}>Approved Break Adjusment</TableCell>

                <TableCell className={tablehead}>Rejected Break Adjusment</TableCell>
                <TableCell className={tablehead}>Detail</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ width: "100%", overflowX: "scroll" }}>
              {filterRows?.BreakAdjReport?.length > 0 ? (
                filterRows?.BreakAdjReport?.filter((item) => {
                  console.log("item", item);
                  if (search) {
                    const nameMatch = item.name
                      .toLowerCase()
                      .includes(search.toLowerCase());
                    return nameMatch;
                  } else {
                    return true;
                  }
                }).map((row, rowIndex) => {
                  return (
                    <React.Fragment key={row.id}>
                      <TableRow>
                        <TableCell>{rowIndex + 1}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.TotalBrkAjdCount}</TableCell>
                        <TableCell>{row.PendingBrkAdjCount}</TableCell>
                        <TableCell>{row.ApprovedBrkAdjCount}</TableCell>
                        <TableCell>{row.RejectBrkAdjCount}</TableCell>
                        <TableCell onClick={() => handleExpand(rowIndex)}>
                          <IconButton>
                            <KeyboardArrowDownIcon
                              style={{
                                transform: `rotate(${
                                  rowIndex === expandedRow ? "180deg" : "0deg"
                                })`,
                              }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      {rowIndex === expandedRow && (
                        <>
                          <Typography
                            variant="h8"
                            gutterBottom
                            component="div"
                            sx={{
                              display: "flex",
                              marginTop: "6px",
                              marginLeft: "11%",
                            }}
                          >
                            Break Adjusment Details
                          </Typography>
                          <TableRow>
                            <TableCell colSpan={7}>
                              <TableContainer>
                                <Table size="small" stickyHeader>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Sr#</TableCell>
                                      <TableCell>Date</TableCell>
                                      <TableCell>Status</TableCell>
                                      <TableCell sx={{ width: "25%" }}>Reason</TableCell>
                                      <TableCell>Requested Time</TableCell>
                                      <TableCell>Approved Time</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {row?.BreakAdjustments?.map((item, index) => (
                                      <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>
                                          {moment(item?.requestedDate).format(
                                            "DD-MMM-YYYY"
                                          )}
                                        </TableCell>
                                        <TableCell>{item?.approvalStatus}</TableCell>
                                        <TableCell>{item?.reason}</TableCell>
                                        <TableCell>
                                          {item?.requestedTime * 60 > 0
                                            ? `${item?.requestedTime * 60}M`
                                            : "N/A"}
                                        </TableCell>
                                        <TableCell>
                                          {item?.approvalTime * 60 > 0
                                            ? `${item?.approvalTime * 60}M`
                                            : "N/A"}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </React.Fragment>
                  );
                })
              ) : (
                <TableCell colSpan={7} style={{ textAlign: "center", height: "650px" }}>
                  No rows found
                </TableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
}

export default MonthlyBreakAdj;

MonthlyBreakAdj.propTypes = {
  setrowvalue: PropTypes.any,
  rowvalue: PropTypes.any,
};
