import {
  Box,
  Button,
  FormControl,
  Typography,
  TextField,
  IconButton,
  Paper,
  CircularProgress,
  InputAdornment,
  OutlinedInput,
  MenuItem,
  Tooltip,
  Avatar,
  Switch,
} from "@mui/material";
window.Buffer = window.Buffer || require("buffer").Buffer;
import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import theme from "../../theme";
import { makeStyles } from "@mui/styles";
import RemoveIcon from "@mui/icons-material/Remove";
import UploadIcon from "@mui/icons-material/Upload";
import CollectionsIcon from "@mui/icons-material/Collections";
import { useFormik } from "formik";
import { object, string } from "yup";
// import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import AddIcon from "@mui/icons-material/Add";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import PastExperienceForm from "./StepperForm/PastExperienceForm";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { AddEmployeeApi } from "../../Api/Employee/EmployeAPis";
import PayrollForm from "./StepperForm/PayrollForm";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  employePopulate,
  fetchEmployee,
  FetchSingleEmployee,
  getemployeeDetails,
} from "../../Redux/Slice/Employee/EmplyeeSlice";
import { PatchEmpEduUpdateApi } from "../../Api/Employee/UpdateEmpEduApi";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { HeaderContext } from "../../Context/SnackBarContext/HeaderContext/HeaderContext";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
const cookies = new Cookies();
import { TooltipContext } from "../../Context/TooltipContext/TooltipContent";
import Cookies from "universal-cookie";
import Loader from "../Loader/Loader";
import { createUser } from "aiksol-chat";
const useStyles = makeStyles(() => {
  return {
    inputBox: {
      width: "100%",
    },
    input_typography: {
      textAlign: "left",
      fontSize: `15px !important`,
      marginTop: "10px !important",
    },
    Form_input: {
      borderRadius: theme.borderRadius.All,
      backgroundColor: `${theme.palette.common.white} !important`,
    },
    btns: {
      width: "80px",
    },
    uploadBoxContainer: {
      minWidth: "100%",
      position: "relative",
    },
    IconContainer: {
      paddingTop: "10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    reviewBox: {
      display: "flex",
      alignItems: "center",
      gap: "20px",
      flexWrap: "wrap",
    },
    FieldInRow: {
      display: "flex",
      justifyContent: "space-between",
      gap: "20px",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        gap: "10px",
      },
    },
    imgcontainer: {
      width: "120px",
      height: "120px",
      borderRadius: "50%",
      padding: "10px",
    },
  };
});

const numberAndDashOnly = /^[0-9,-]*$/;

const validationSchema = object({
  name: string().required("This field is required"),
  father_name: string().required("This field is required"),
  cnic: string()
    .matches(numberAndDashOnly, "Only numbers and dashes are allowed")
    .required("This field is required"),
  dob: string().required("This field is required"),
  email: string()
    .email("Please enter a valid email address")
    .required("This field is required"),
  password: string()
    .required("This field is required")
    .min(10, "Please Enter At Least 10 Character!"),
  username: string()
    .matches(
      /^(?=.*[a-z])(?=.*[0-9])[a-z0-9]+$/,
      "Username must contain lowercase letters and numbers, no spaces or special characters"
    )
    .required("This field is required"),
  gender: string("Please Select Gender").required("This field is required"),
});
const EmployeeForm = ({
  pageSize,
  page,
  setShowForm,
  empData,
  setisEdited,
  isEdited,
}) => {
  const {
    Form_input,
    input_typography,
    btns,
    inputBox,
    FieldInRow,
    uploadBoxContainer,
    IconContainer,
    reviewBox,
    imgcontainer,
  } = useStyles();
  const inputRef = useRef();
  const inputRef2 = useRef();
  const inputRef3 = useRef();
  const [stepper, setstepper] = useState(0);
  const [educations, seteducations] = useState([]);
  const [empID, setempID] = useState("");
  const [disableNext, setdisableNext] = useState(true);
  const [cnicfront, setcnicfront] = useState(null);
  const [cnicback, setcnicback] = useState(null);
  const [empimage, setempimage] = useState(null);
  const [showPassword, setshowPassword] = useState(false);
  const [loading, setloading] = useState(false);
  const { settooltipData } = useContext(TooltipContext);
  const [addChatUser, setAddChatUser] = useState(false);
  const [chatUserId, setChatUserId] = useState(null);
  const [portalUserEnable, setPortalUserEnable] = useState(false);
  const [portalUserId, setPortalUserId] = useState(false);
  // const [chatUserCreated, setChatUserCreated] = useState();
  const navigate = useNavigate();
  const { setsnackBarData } = useContext(SnackBarContext);
  const { singleEmployee, IsLogin, message, status } =
    useSelector(getemployeeDetails);
  const { setheader } = useContext(HeaderContext);
  const [value, setValue] = useState();
  const encodeId = (id) => btoa(id);

  const encodedBusinessId = encodeId(process.env.REACT_APP_BUSINESS_ID);
  const dispatch = useDispatch();
  const initialValues = {
    name: "",
    father_name: "",
    cnic: "",
    dob: "",
    email: "",
    password: "",
    username: "",
    gender: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handelAdd(values, resetForm, setSubmitting);
    },
  });
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);

  const handlePortalSwitchChange = (event) => {
    setPortalUserEnable(event.target.checked);
  };

  const handleSwitchChange = async (event) => {
    const isChecked = event.target.checked;

    setAddChatUser(isChecked);
    // 
    // if (isChecked && formik.values.email && formik.values.name) {
    //   setAddChatUser(isChecked);
    //   try {
    //     const response = await createUser({
    //       businessId: encodedBusinessId,
    //       email: formik.values.email,
    //       name: formik.values.name,
    //       type: "businessUser",
    //     });
    //     setChatUserId(response?.user_id);
    //   } catch (error) {
    //     console.error("Error creating user:", error);
    //   }
    // } else if (!formik.values.email) {
    //   setsnackBarData({
    //     type: "error",
    //     message: "Please enter Email Id",
    //     openToast: "true",
    //   });
    // } else if (!formik.values.name) {
    //   setsnackBarData({
    //     type: "error",
    //     message: "Please enter Employee Name",
    //     openToast: "true",
    //   });
    // }
  };
  useEffect(() => {
    if (stepper === 0) {
      setheader("Employee And Education");
      settooltipData(`Welcome to the Employee And Education screen!  
      Employee’s personal information along with its education can be added.Note that password should be of length 10 characters, and username should be unique.
      Click on plus button below education, to add employee’s education history 
      if you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
        "company_email"
      )}.`);
    } else if (stepper === 1) {
      setheader("Experience ");
      settooltipData(`Welcome to the Experience  screen!  
      Select technologies and expertise of the employee. Also add the past experience (if any) by clicking on plus button below experience 
      
      if you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
        "company_email"
      )}.
      `);
    } else if (stepper === 2) {
      setheader("Payroll ");
      settooltipData(`Welcome to the Payroll screen!  
      Add the suitable jobtitle, and relevant department and designation of employee.
      Define the shift rule of the employee and and also mark the sandwich rule checkbox (if required). Please upload a clear pdf file of contract scan.
      If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
        "company_email"
      )}.`);
    }
  }, [stepper]);

  function removeImage2() {
    setcnicfront(null);
  }
  function removeImage3() {
    setcnicback(null);
  }
  const handelAdd = async (values) => {
    const data =
      educations &&
      educations.find((edu) => {
        if (
          edu?.degree_name == "" ||
          edu?.ending_year == "" ||
          edu?.institute_name == "" ||
          edu?.percentage_obtain == "" ||
          edu?.starting_year == ""
        ) {
          return edu;
        }
      });

    if (data) {
      setloading(false);
      return setsnackBarData({
        type: "error",
        message: "Please Fill Education Details Properly.!",
        openToast: "true",
      });
    } else {
      if (!value || !isValidPhoneNumber(value)) {
        return setsnackBarData({
          openToast: true,
          message: "Please Enter Valid Phone Number",
          type: "error",
        });
      }
      setloading(true);
      setdisableNext(true);
      const normalizedChatUserId = chatUserId === "null" ? null : chatUserId;
      let chatUserResponse = null;
      if (normalizedChatUserId === null && addChatUser) {
        try {
          chatUserResponse = await createUser({
            businessId: encodedBusinessId,
            email: values.email,
            name: values.name,
            type: "businessUser",
          });

        } catch (error) {
          console.log("Failed to create user. Please try again.", error);
        }
        setChatUserId(chatUserResponse?.user_id || normalizedChatUserId);
      }

      const formData = new FormData();

      Object.keys(values).map((key) => {
        formData.append(key, values[key]);
      });
      formData.append("phone", value);
      formData.append("cnicF", cnicfront?.file ? cnicfront?.file : "");
      formData.append("cnicB", cnicback?.file ? cnicback?.file : "");
      formData.append("personalimg", empimage?.file ? empimage?.file : "");
      if (chatUserResponse?.user_id || chatUserId) {
        formData.append("chatUserId", chatUserResponse?.user_id ? chatUserResponse?.user_id : chatUserId);

      }
      if (portalUserEnable) {
        formData.append("portalUserEnable", portalUserEnable);
      }
      if (educations != null) {
        educations?.forEach((education, index) => {
          formData.append(`education[${index}]`, JSON.stringify(education));
        });
      }

      if (isEdited) {
        formData.append("joining_date", singleEmployee?.Employee?.joining_date);
        formData.append("Emp_id", empData?.empId);
        formData.delete("password");

        const response = await PatchEmpEduUpdateApi(formData);
        setsnackBarData(response.snackBarData);

        if (response.data.IsSuccess) {
          setempID(response.data.Employee.Emp_id);
          setloading(false);
          setstepper((stepper) => stepper + 1);
          setdisableNext(false);
          setisEdited(false);
          dispatch(fetchEmployee({ page: page - 1, pageSize: pageSize }));
        } else {
          setloading(false);
        }
      } else if (!isEdited) {
        const response = await AddEmployeeApi(formData);
        setsnackBarData(response.snackBarData);
        if (response.data.IsSuccess) {
          setempID(response.data.Employee.Emp_id);
          setloading(false);
          setstepper((stepper) => stepper + 1);
          setdisableNext(false);
          dispatch(fetchEmployee({ page: page - 1, pageSize: pageSize }));
        }

        setloading(false);
      }
      setloading(false);
    }
  };
  const addEducation = () => {
    const obj = {
      degree_name: "",
      ending_year: "",
      institute_name: "",
      percentage_obtain: "",
      starting_year: "",
    };
    seteducations([...educations, obj]);
  };

  useEffect(() => {
    dispatch(employePopulate());

    if (isEdited) {
      dispatch(FetchSingleEmployee(empData?.empId));
    }
  }, [dispatch]);
  useEffect(() => {
    if (isEdited) {
      // setPortalUserId(false);
      formik.setFieldValue("name", singleEmployee?.Employee?.name);
      formik.setFieldValue(
        "father_name",
        singleEmployee?.Employee?.father_name
      );
      formik.setFieldValue("cnic", singleEmployee?.Employee?.cnic);
      formik.setFieldValue("dob", singleEmployee?.Employee?.dob);
      formik.setFieldValue("email", singleEmployee?.Employee?.loginemp?.email);

      setAddChatUser(
        singleEmployee?.Employee?.loginemp?.chatUserId !== null ? true : false
      );
      setPortalUserId(
        singleEmployee?.Employee?.portalid !== null ? true : false
      );
      setPortalUserEnable(
        singleEmployee?.Employee?.portalid !== null ? true : false
      );
      const chatUserIdValue = singleEmployee?.Employee?.loginemp?.chatUserId;
      setChatUserId(chatUserIdValue ?? null);
      formik.setFieldValue(
        "username",
        singleEmployee?.Employee?.loginemp?.username
      );
      formik.setFieldValue("password", "************");
      formik.setFieldValue("gender", singleEmployee?.Employee?.gender);
      setValue(singleEmployee?.Employee?.phone);
      setempimage(
        singleEmployee?.Employee?.emp_image
          ? {
            url: singleEmployee?.Employee?.emp_image,
          }
          : null
      );
      setcnicfront(
        singleEmployee?.Employee?.cnic_photofront
          ? {
            file: {
              name: singleEmployee?.Employee?.cnic_photofront
                .split("?")[0]
                .split("/cnicF-")[1]
                ?.replaceAll("%20", " "),
            },
          }
          : null
      );
      setcnicback(
        singleEmployee?.Employee?.cnic_photoback
          ? {
            file: {
              name: singleEmployee?.Employee?.cnic_photoback
                .split("?")[0]
                .split("/cnicB-")[1]
                .replace("/%20/gi", " "),
            },
          }
          : null
      );

      seteducations(singleEmployee ? singleEmployee?.Education : []);
      setdisableNext(false);
    }
  }, [singleEmployee]);
  const handleImageChange = (e) => {
    const obj = {
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    };
    setempimage(obj);
  };
  const handleRemoveImage = (e) => {
    e.stopPropagation();
    setempimage(null);
  };

  return (
    <>
      {status !== "succeeded" ? <Loader /> : null}
      {stepper === 0 ? (
        <Box
          sx={{
            minHeight: "calc(100vh - 165px)",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          <form onSubmit={formik?.handleSubmit}>
            <Box>
              <Box
                sx={{
                  maxWidth: "900px",
                  width: "100%",
                  display: "flex",
                  gap: "20px",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <Box className={FieldInRow}>
                  <Box
                    className={inputBox}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <Paper
                      elevation={3}
                      className={imgcontainer}
                    >
                      {!empimage ? (
                        <Avatar
                          src="/broken-image.jpg"
                          sx={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                          }}
                        />
                      ) : (
                        <img
                          src={`${empimage?.url}`}
                          alt={
                            empimage
                              ? empimage?.url
                                .split("?")[0]
                                ?.split(".com/")[1]
                                ?.split("-")[1]
                              : "profile"
                          }
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                          }}
                        />
                      )}
                    </Paper>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => {
                          inputRef.current.click();
                        }}
                      >
                        <FileUploadIcon /> Upload
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          border: "1px solid red",
                        }}
                        onClick={handleRemoveImage}
                      >
                        <DoDisturbIcon /> Remove
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <Box className={FieldInRow}>
                  <Box className={inputBox}>
                    <Typography className={input_typography}>Name</Typography>

                    <FormControl
                      fullWidth
                      sx={{ textAlign: "left" }}
                    >
                      <TextField
                        onBlur={formik.handleBlur}
                        onChange={formik?.handleChange}
                        size="small"
                        name="name"
                        value={formik?.values.name}
                        variant="outlined"
                        type="text"
                        color="primary"
                        sx={{ input: { color: "black" } }}
                        className={Form_input}
                        fullWidth
                        error={
                          Boolean(formik?.errors.name) &&
                          Boolean(formik?.touched.name)
                        }
                        helperText={
                          Boolean(formik?.touched.name) && formik?.errors.name
                        }
                      />
                    </FormControl>
                  </Box>

                  <Box className={inputBox}>
                    <Typography className={input_typography}>
                      Father Name
                    </Typography>
                    <FormControl
                      fullWidth
                      sx={{ textAlign: "left" }}
                    >
                      <TextField
                        onBlur={formik.handleBlur}
                        onChange={formik?.handleChange}
                        value={formik?.values.father_name}
                        size="small"
                        name="father_name"
                        variant="outlined"
                        type="text"
                        color="primary"
                        sx={{ input: { color: "black" } }}
                        className={Form_input}
                        fullWidth
                        error={
                          Boolean(formik?.errors.father_name) &&
                          Boolean(formik?.touched.father_name)
                        }
                        helperText={
                          Boolean(formik?.touched.father_name) &&
                          formik?.errors.father_name
                        }
                      />
                    </FormControl>
                  </Box>
                </Box>
                <Box className={FieldInRow}>
                  <Box className={inputBox}>
                    <Typography className={input_typography}>
                      CNIC No
                    </Typography>
                    <FormControl
                      fullWidth
                      sx={{ textAlign: "left" }}
                    >
                      <TextField
                        // onChange={(e) => {
                        //   let val = e.target.value;

                        //   if (val.length === 5 || val.length === 13) {
                        //     val = `${val}-`;
                        //   } else if (val.length > 15) {
                        //     return;
                        //   }

                        //   handleChange({
                        //     target: {
                        //       value: val,
                        //       name: "cnic",
                        //     },
                        //   });
                        // }}
                        // onKeyDown={(e) => {
                        //   if (e.key === "Backspace") {
                        //     let val = e.target.value;
                        //     val = val.slice(0, val.length - 1);
                        //     handleChange({
                        //       target: {
                        //         value: val,
                        //         name: "cnic",
                        //       },
                        //     });
                        //   }
                        // }}
                        onBlur={formik.handleBlur}
                        onChange={formik?.handleChange}
                        size="small"
                        name="cnic"
                        placeholder="xxxxx-xxxxxxx-x"
                        value={formik?.values.cnic}
                        variant="outlined"
                        type="text"
                        color="primary"
                        sx={{ input: { color: "black" } }}
                        className={Form_input}
                        fullWidth
                        error={
                          Boolean(formik?.errors.cnic) &&
                          Boolean(formik?.touched.cnic)
                        }
                        helperText={
                          Boolean(formik?.touched.cnic) && formik?.errors.cnic
                        }
                      />
                    </FormControl>
                    {/* <Input size={'small'} fullWidth placeholder='enter your cnic' /> */}
                  </Box>

                  <Box className={inputBox}>
                    <Typography className={input_typography}>Phone</Typography>
                    <PhoneInput
                      placeholder="Enter phone number"
                      // type="number"
                      value={value}
                      onChange={setValue}
                      limitMaxLength
                      international
                      countryCallingCodeEditable={false}
                      defaultCountry="PK"
                    />
                  </Box>
                </Box>

                <Box className={FieldInRow}>
                  <Box className={inputBox}>
                    <Typography className={input_typography}>
                      User Name
                    </Typography>
                    <FormControl
                      fullWidth
                      sx={{ textAlign: "left" }}
                    >
                      <TextField
                        onChange={formik?.handleChange}
                        onBlur={formik.handleBlur}
                        size="small"
                        name="username"
                        placeholder="user1234"
                        value={formik?.values.username}
                        variant="outlined"
                        type="text"
                        color="primary"
                        sx={{ input: { color: "black" } }}
                        className={Form_input}
                        disabled={
                          isEdited &&
                          /^(?=.*[a-z])(?=.*\d)[a-z0-9]+$/.test(
                            singleEmployee?.Employee?.loginemp?.username
                          )
                        }
                        fullWidth
                        error={
                          Boolean(formik?.errors.username) &&
                          Boolean(formik?.touched.username)
                        }
                        helperText={
                          Boolean(formik?.touched.username) &&
                          formik?.errors.username
                        }
                      />
                    </FormControl>
                  </Box>

                  <Box className={inputBox}>
                    <Typography className={input_typography}>
                      Email Address
                    </Typography>
                    <FormControl
                      fullWidth
                      sx={{ textAlign: "left" }}
                    >
                      <TextField
                        onChange={formik?.handleChange}
                        onBlur={formik.handleBlur}
                        size="small"
                        name="email"
                        value={formik?.values.email}
                        variant="outlined"
                        type="text"
                        color="primary"
                        sx={{ input: { color: "black" } }}
                        className={Form_input}
                        autoComplete="off"
                        inputProps={{ autoComplete: "off" }}
                        disabled={isEdited ? true : false}
                        fullWidth
                        error={
                          Boolean(formik?.errors.email) &&
                          Boolean(formik?.touched.email)
                        }
                        helperText={
                          Boolean(formik?.touched.email) && formik?.errors.email
                        }
                      />
                    </FormControl>
                  </Box>
                </Box>

                <Box className={FieldInRow}>
                  <Box className={inputBox}>
                    <Typography className={input_typography}>
                      Password
                    </Typography>
                    <FormControl
                      fullWidth
                      sx={{ textAlign: "left" }}
                    >
                      <OutlinedInput
                        onChange={formik?.handleChange}
                        onBlur={formik.handleBlur}
                        size="small"
                        name="password"
                        value={formik?.values.password}
                        variant="outlined"
                        type={showPassword ? "text" : "password"}
                        color="primary"
                        disabled={isEdited ? true : false}
                        inputProps={{ autoComplete: "off" }}
                        sx={{ input: { color: "black" } }}
                        className={Form_input}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              disabled={isEdited ? true : false}
                              onClick={() => {
                                setshowPassword(!showPassword);
                              }}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        fullWidth
                        error={
                          Boolean(formik?.errors.password) &&
                          Boolean(formik?.touched.password)
                        }
                        helperText={
                          Boolean(formik?.touched.password) &&
                          formik?.errors.password
                        }
                      />
                      {formik?.errors.password && formik?.touched.password ? (
                        <div
                          style={{
                            color: "#d32f2f",
                            fontfamily: "Roboto!important",
                            fontWeight: "400px",
                            fontSize: "0.75rem",
                            lineHeight: "1.66",
                            letterSpacing: "0.03333em",
                            textAlign: "left",
                            marginTop: "4px",
                            marginRight: "14px",

                            marginLeft: "14px",
                          }}
                        >
                          {formik?.errors.password}
                        </div>
                      ) : null}
                    </FormControl>
                  </Box>

                  <Box className={inputBox}>
                    <Typography className={input_typography}>DOB</Typography>
                    <FormControl
                      fullWidth
                      sx={{ textAlign: "left" }}
                    >
                      <TextField
                        onChange={formik?.handleChange}
                        onBlur={formik.handleBlur}
                        size="small"
                        name="dob"
                        variant="outlined"
                        type="date"
                        value={formik?.values.dob}
                        color="primary"
                        sx={{ input: { color: "black" } }}
                        className={Form_input}
                        fullWidth
                        error={
                          Boolean(formik?.errors.dob) &&
                          Boolean(formik?.touched.dob)
                        }
                        helperText={
                          Boolean(formik?.touched.dob) && formik?.errors.dob
                        }
                      />
                    </FormControl>
                  </Box>
                </Box>
                <Box className={FieldInRow}>
                  <Box className={inputBox}>
                    <Typography
                      fullWidth
                      className={input_typography}
                    >
                      Gender
                    </Typography>

                    <TextField
                      fullWidth
                      sx={{
                        textAlign: "left",
                        input: { color: "black" },
                        paddingTop: "10px",
                      }}
                      size="small"
                      select
                      onChange={(e) => {
                        formik?.handleChange(e);
                      }}
                      name="gender"
                      value={formik?.values.gender}
                      className={Form_input}
                      error={
                        Boolean(formik?.errors.gender) &&
                        Boolean(formik?.touched.gender)
                      }
                      helperText={
                        Boolean(formik?.touched.gender) && formik?.errors.gender
                      }
                    >
                      <MenuItem value="">select</MenuItem>
                      <MenuItem value="male">male</MenuItem>
                      <MenuItem value="female">female</MenuItem>
                      <MenuItem value="others">others</MenuItem>
                    </TextField>
                  </Box>
                  {/* <Box className={inputBox}>
                          <Typography fullWidth className={input_typography}>
                            Employee Image
                          </Typography>
                          <input
                            name="personalimg"
                            id="images"
                            ref={inputRef}
                            type="file"
                            onChange={(e) => {
                              handleChange(e);
                              e.stopPropagation();
                              const obj = {
                                file: e.target.files[0],
                                url: URL.createObjectURL(e.target.files[0]),
                              };
                              setempimage(obj);
                            }}
                            accept="image/*"
                            value={values.personalimg}
                            style={{
                              width: "100%",
                              display: "none",
                              position: "absolute",
                            }}
                          />
                          <Box className={uploadBoxContainer}>
                            <Box className={IconContainer}>
                              <Box className={reviewBox}>
                                {empimage !== null ? (
                                  <>
                                    <Box
                                      sx={{
                                        height: "80px",
                                        width: "80px",
                                        position: "relative",
                                      }}
                                    >
                                      <IconButton
                                        sx={{
                                          transform: "translate(-50%,-50%)",
                                          position: "absolute",
                                          background: (theme) =>
                                            theme.palette.error.main,
                                          padding: "0px",
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          removeImage();
                                        }}
                                      >
                                        <RemoveIcon
                                          sx={{
                                            color: (theme) =>
                                              theme.palette.white.main,
                                            fontWeight: "bold",
                                          }}
                                        />
                                      </IconButton>
                                      <ReactCrop
                                        crop={crop}
                                        onImageLoaded={(x) => {
                                          console.log(x);
                                        }}
                                        onChange={(c) => {
                                          setCrop(c);
                                          console.log(c);
                                        }}
                                      >
                                        <img
                                          src={empimage?.url}
                                          alt={
                                            empimage?.url
                                              .split("?")[0]
                                              ?.split(".com/")[0]
                                            // ?.replaceAll('%20',' ')
                                          }
                                          style={{
                                            height: "100%",
                                            width: "100%",
                                          }}
                                        />
                                      </ReactCrop>
                                    </Box>
                                  </>
                                ) : (
                                  <CollectionsIcon
                                    sx={{
                                      color: (theme) =>
                                        theme.palette.primary.main,
                                    }}
                                  />
                                )}
                              </Box>
                              <Box
                                sx={{ minWidth: "100px", textAlign: "right" }}
                              >
                                <IconButton
                                  onClick={() => inputRef.current.click()}
                                >
                                  <Tooltip title="Upload Image">
                                    <UploadIcon
                                      sx={{
                                        color: (theme) =>
                                          theme.palette.primary.main,
                                      }}
                                    />
                                  </Tooltip>
                                </IconButton>
                              </Box>
                            </Box>
                          </Box>
                        </Box> */}
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  gap={2}
                >
                  <Box>
                    <Typography
                      className={input_typography}
                      sx={{ pl: 1 }}
                    >
                      Chat
                    </Typography>
                    <Switch
                      name="chatUser"
                      size={"large"}
                      disabled={chatUserId !== null ? true : false}
                      fullWidth
                      checked={addChatUser}
                      onChange={handleSwitchChange}
                    />
                  </Box>

                  {/* {portalUserId && ( */}
                  <Box>
                    <Typography
                      className={input_typography}
                      sx={{ pl: 1 }}
                    >
                      Portal
                    </Typography>
                    <Switch
                      name="portalUserEnable"
                      size="large"
                      checked={portalUserEnable}
                      disabled={!!portalUserId}
                      // sx={{
                      //   opacity: portalUserId ? 0.5 : 1, // Reduce opacity when disabled
                      //   pointerEvents: portalUserId ? "none" : "auto", // Prevent interactions
                      // }}
                      onChange={handlePortalSwitchChange}
                    />
                  </Box>
                  {/* )} */}
                </Box>

                <Box className={FieldInRow}>
                  <Box className={inputBox}>
                    <Typography className={input_typography}>
                      CNIC Front
                    </Typography>
                    <input
                      // name="cnicF"
                      id="images"
                      ref={inputRef2}
                      type="file"
                      onChange={(e) => {
                        // handleChange(e);
                        e.stopPropagation();
                        const obj = {
                          file: e.target.files[0],
                          url: URL.createObjectURL(e.target.files[0]),
                        };
                        setcnicfront(obj);
                      }}
                      accept=".pdf"
                      value={formik?.values?.cnicF}
                      style={{
                        width: "100%",
                        display: "none",
                        position: "absolute",
                      }}
                    />
                    <Box className={uploadBoxContainer}>
                      <Box className={IconContainer}>
                        <Box className={reviewBox}>
                          {cnicfront !== null ? (
                            <Box
                              sx={{
                                height: "40px",
                                width: "200px",
                                position: "relative",
                              }}
                            >
                              <IconButton
                                sx={{
                                  transform: "translate(-465%,-50%)",
                                  position: "absolute",
                                  background: (theme) =>
                                    theme.palette.error.main,
                                  padding: "0px",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  removeImage2();
                                }}
                              >
                                <RemoveIcon
                                  sx={{
                                    color: (theme) => theme.palette.white.main,
                                    fontWeight: "bold",
                                  }}
                                />
                              </IconButton>

                              <Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  paddingTop: "5px",
                                  textAlign: "left",
                                }}
                              >
                                {cnicfront.file.name}
                              </Typography>
                            </Box>
                          ) : (
                            <CollectionsIcon
                              sx={{
                                color: (theme) => theme.palette.primary.main,
                              }}
                            />
                          )}
                        </Box>
                        <Box sx={{ minWidth: "100px", textAlign: "right" }}>
                          <IconButton onClick={() => inputRef2.current.click()}>
                            <Tooltip title="Upload Cnic Front">
                              <UploadIcon
                                sx={{
                                  color: (theme) => theme.palette.primary.main,
                                }}
                              />
                            </Tooltip>
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box className={inputBox}>
                    <Typography
                      fullWidth
                      className={input_typography}
                    >
                      CNIC Back
                    </Typography>
                    <input
                      // name="cnicB"
                      id="images"
                      ref={inputRef3}
                      type="file"
                      onChange={(e) => {
                        // handleChange(e);
                        e.stopPropagation();
                        const obj = {
                          file: e.target.files[0],
                          url: URL.createObjectURL(e.target.files[0]),
                        };
                        setcnicback(obj);
                      }}
                      accept=".pdf"
                      value={formik?.values?.cnicB}
                      style={{
                        width: "100%",
                        display: "none",
                        position: "absolute",
                      }}
                    />
                    <Box className={uploadBoxContainer}>
                      <Box className={IconContainer}>
                        <Box className={reviewBox}>
                          {cnicback !== null ? (
                            <Box
                              sx={{
                                height: "40px",
                                width: "200px",
                                position: "relative",
                              }}
                            >
                              <IconButton
                                sx={{
                                  transform: "translate(-465%,-50%)",
                                  position: "absolute",
                                  background: (theme) =>
                                    theme.palette.error.main,
                                  padding: "0px",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  removeImage3();
                                }}
                              >
                                <RemoveIcon
                                  sx={{
                                    color: (theme) => theme.palette.white.main,
                                    fontWeight: "bold",
                                  }}
                                />
                              </IconButton>

                              <Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  paddingTop: "5px",
                                  textAlign: "left",
                                }}
                              >
                                {cnicback.file.name}
                              </Typography>
                            </Box>
                          ) : (
                            <CollectionsIcon
                              sx={{
                                color: (theme) => theme.palette.primary.main,
                              }}
                            />
                          )}
                        </Box>
                        <Box sx={{ minWidth: "100px", textAlign: "right" }}>
                          <IconButton onClick={() => inputRef3.current.click()}>
                            <Tooltip title="Upload Cnic Back">
                              <UploadIcon
                                sx={{
                                  color: (theme) => theme.palette.primary.main,
                                }}
                              />
                            </Tooltip>
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Paper
                    elevation={3}
                    sx={{ padding: "10px" }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      EDUCATIONS
                    </Typography>
                  </Paper>
                  <Box sx={{ textAlign: "right" }}>
                    <Button
                      variant="contained"
                      sx={{ padding: "2px" }}
                      onClick={addEducation}
                    >
                      <AddIcon />
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    {educations &&
                      educations.map((education, index) => {
                        return (
                          <Paper
                            key={index}
                            sx={{ padding: "5px" }}
                            elevation={3}
                          >
                            <Box
                              sx={{
                                transform: "translate(50%,-50%)",
                                width: "fit-content",
                                float: "right",
                              }}
                            >
                              <IconButton
                                sx={{
                                  background: (theme) =>
                                    theme.palette.error.main,
                                  padding: "0px",
                                  color: (theme) => theme.palette.white.main,
                                }}
                                onClick={() => {
                                  let newArr = JSON.parse(
                                    JSON.stringify(educations)
                                  );
                                  newArr.splice(index, 1);
                                  seteducations(newArr);
                                }}
                              >
                                <RemoveIcon />
                              </IconButton>
                            </Box>
                            <Box
                              sx={{
                                padding: "20px",
                                display: { md: "flex", xs: "block" },
                                gap: "20px",
                              }}
                            >
                              <Box sx={{ textAlign: "left" }}>
                                <Typography className={input_typography}>
                                  Degree Name
                                </Typography>
                                <FormControl
                                  fullWidth
                                  sx={{ textAlign: "left" }}
                                >
                                  <TextField
                                    size="small"
                                    name="degree_name"
                                    value={education.degree_name}
                                    variant="outlined"
                                    color="primary"
                                    onChange={(e) => {
                                      let newArr = JSON.parse(
                                        JSON.stringify(educations)
                                      );
                                      newArr[index].degree_name =
                                        e.target.value;
                                      seteducations(newArr);
                                    }}
                                    sx={{
                                      input: {
                                        color: "black",
                                      },
                                    }}
                                    fullWidth
                                  />
                                </FormControl>
                              </Box>
                              <Box>
                                <Typography className={input_typography}>
                                  Institude
                                </Typography>
                                <FormControl
                                  fullWidth
                                  sx={{ textAlign: "left" }}
                                >
                                  <TextField
                                    size="small"
                                    name="institute_name"
                                    variant="outlined"
                                    value={education.institute_name}
                                    color="primary"
                                    onChange={(e) => {
                                      let newArr = JSON.parse(
                                        JSON.stringify(educations)
                                      );
                                      newArr[index].institute_name =
                                        e.target.value;
                                      seteducations(newArr);
                                    }}
                                    sx={{ input: { color: "black" } }}
                                    fullWidth
                                  />
                                </FormControl>
                              </Box>
                              <Box>
                                <Typography className={input_typography}>
                                  Marks %
                                </Typography>
                                <FormControl
                                  fullWidth
                                  sx={{ textAlign: "left" }}
                                >
                                  <TextField
                                    size="small"
                                    name="percentage_obtain"
                                    variant="outlined"
                                    type="number"
                                    color="primary"
                                    value={education.percentage_obtain}
                                    onChange={(e) => {
                                      let newArr = JSON.parse(
                                        JSON.stringify(educations)
                                      );
                                      newArr[index].percentage_obtain =
                                        e.target.value;
                                      seteducations(newArr);
                                    }}
                                    sx={{ input: { color: "black" } }}
                                  />
                                </FormControl>
                              </Box>
                              <Box>
                                <Typography className={input_typography}>
                                  Start Date
                                </Typography>
                                <FormControl
                                  fullWidth
                                  sx={{ textAlign: "left" }}
                                >
                                  <TextField
                                    size="small"
                                    name="starting_year"
                                    type="date"
                                    variant="outlined"
                                    value={education.starting_year}
                                    onChange={(e) => {
                                      let newArr = JSON.parse(
                                        JSON.stringify(educations)
                                      );
                                      newArr[index].starting_year =
                                        e.target.value;
                                      seteducations(newArr);
                                    }}
                                    color="primary"
                                    sx={{ input: { color: "black" } }}
                                    fullWidth
                                  />
                                </FormControl>
                              </Box>
                              <Box>
                                <Typography className={input_typography}>
                                  End Date
                                </Typography>
                                <FormControl
                                  fullWidth
                                  sx={{ textAlign: "left" }}
                                >
                                  <TextField
                                    size="small"
                                    name="ending_year"
                                    variant="outlined"
                                    value={education.ending_year}
                                    type="date"
                                    color="primary"
                                    onChange={(e) => {
                                      let newArr = JSON.parse(
                                        JSON.stringify(educations)
                                      );
                                      newArr[index].ending_year =
                                        e.target.value;
                                      seteducations(newArr);
                                    }}
                                    sx={{ input: { color: "black" } }}
                                    fullWidth
                                  />
                                </FormControl>
                              </Box>
                            </Box>
                          </Paper>
                        );
                      })}
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <Box
                  sx={{
                    float: "right",
                    display: "flex",
                    gap: "10px",
                    marginTop: "20px",
                  }}
                  spacing={2}
                >
                  <>
                    <Button
                      className={btns}
                      variant="contained"
                      color="error"
                      onClick={() => {
                        setShowForm(false);
                        setisEdited(false);
                        setheader(null);
                      }}
                    >
                      Cancel
                    </Button>

                    {loading === false ? (
                      !isEdited ? (
                        <Button
                          className={btns}
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Save
                        </Button>
                      ) : (
                        <Button
                          className={btns}
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Update
                        </Button>
                      )
                    ) : (
                      <CircularProgress />
                    )}
                    {isEdited ? (
                      <Button
                        className={btns}
                        variant="contained"
                        color="success"
                        disabled={disableNext === true ? true : false}
                        onClick={() => {
                          setstepper((stepper) => stepper + 1);
                        }}
                      >
                        Next
                      </Button>
                    ) : null}
                  </>
                </Box>
              </Box>
            </Box>
          </form>
        </Box>
      ) : stepper === 1 ? (
        <PastExperienceForm
          setShowForm={setShowForm}
          setstepper={setstepper}
          empID={empID}
          isEdited={isEdited}
          setisEdited={setisEdited}
          empData={empData}
          singleEmployee={singleEmployee}
          page={page}
          pageSize={pageSize}
        />
      ) : stepper === 2 ? (
        <PayrollForm
          setShowForm={setShowForm}
          empID={empID}
          isEdited={isEdited}
          setisEdited={setisEdited}
          setstepper={setstepper}
          singleEmployee={singleEmployee}
          empData={empData}
          page={page}
          pageSize={pageSize}
        />
      ) : null}
      <input
        type={"file"}
        onChange={handleImageChange}
        ref={inputRef}
        accept="image/*"
        style={{ display: "none" }}
      />
    </>
  );
};

export default EmployeeForm;

EmployeeForm.propTypes = {
  setShowForm: PropTypes.any,
  empData: PropTypes.any,
  setisEdited: PropTypes.any,
  page: PropTypes.any,
  pageSize: PropTypes.any,
  isEdited: PropTypes.any,
};
