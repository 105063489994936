import axiosInstance from "../../Utils/AxiosInstance";

export const AddApplicantApi = async (data) => {


  let snackBarData = {};
  try {
    let response = await axiosInstance.post("/createApplicant", data);


    snackBarData = {
      type: response?.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return { ...response, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response.data.Message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};


export const updateApplicantApi = async (data) => {

  let snackBarData = {};
  try {
    let response = await axiosInstance.put("/updateApplicants", data);


    snackBarData = {
      type: response?.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return { ...response, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response.data.Message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
export const updateApplicantSessionApi = async (data) => {

  let snackBarData = {};
  try {
    let response = await axiosInstance.put("/updateApplicantSession", data);


    snackBarData = {
      type: response?.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return { ...response, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response.data.Message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const posthiringStage = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.post("/createHiringStage", data);

    snackBarData = {
      type: response?.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return { ...response, snackBarData };

  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response.data.Message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

//Post all data of hiring session

export const posthiringSession = async (data) => {

  let snackBarData = {};
  try {
    let response = await axiosInstance.post("/createHiringSession", data);

    snackBarData = {
      type: response?.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return { ...response, snackBarData };

  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.Message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

////Get session update put Api
export const putSession = async (data) => {

  let snackBarData = {};
  try {
    let response = await axiosInstance.put("/updateHiringSession", data);

    snackBarData = {
      type: response?.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return { ...response, snackBarData };

  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.Message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
