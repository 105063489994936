import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import React, { useEffect, useMemo, useRef, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useDispatch, useSelector } from "react-redux";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Loader from "../Loader/Loader";
import * as Yup from "yup";
import {
  fetchEmployeeTaskReportbyPortalId,
  getPortalTaskSummary,
} from "../../Redux/Slice/PortalSummary/PortalSummarySlice";
import { useFormik } from "formik";
import moment from "moment";
import { colorPalette } from "../../Resources/Colors";
const useStyles = makeStyles(() => ({
  ModalContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    padding: "10px",
    width: "100%",
    overflowY: "auto",
  },
  Container2: {
    width: "100%",
    maxWidth: "900px",
    padding: "20px",
    maxHeight: "90vh",
    border: "none",
    position: "relative",
    background: "white",
    borderRadius: "10px",
    display: "flex",
    gap: "20px",
    flexDirection: "column",
  },
  WorkObjectContainer: {
    overflowY: "auto",
    maxHeight: "60vh",
    paddingRight: "10px",
  },
  elipsText: {
    fontSize: "inherit",
    lineHeight: "inherit",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitLineClamp: 2,
    cursor: "pointer",
  },
  TableHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

function PortalSummaryTable({ setShowForm, setrowvalue, rowvalue, dateRange }) {
  const [dateRange1, setdateRange1] = useState(dateRange);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [groupedtask, setgroupedtasks] = useState();
  const { setsnackBarData } = useContext(SnackBarContext);
  const [showMonth, setShowMonth] = useState();
  const [calendarGrid, setCalendarGrid] = useState([]);
  const [selectedDescription, setSelectedDescription] = useState("all");
  const [selectedData, setSelectedData] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { TableHeader, ModalContainer, TableContain, Container2, elipsText } =
    useStyles();
  const [currentMonthDates, setCurrentMonthDates] = useState([]);
  const {
    status1,
    IsLogin,
    message1,
    EmployeeTasksReportbyPortalID,
    estimatedHours,
    spentHours,
  } = useSelector(getPortalTaskSummary);
  const transformRef = useRef(null);
  const handleDescriptionChange = (event) => {
    const selectedDesc = event.target.value;
    setSelectedDescription(selectedDesc);
  };
  console.log("this is employee name", rowvalue?.employee);
  useEffect(() => {
    if (rowvalue) {
      dispatch(
        fetchEmployeeTaskReportbyPortalId({
          startDate: dateRange1[0],
          endDate: dateRange1[1],
          portalId: rowvalue?.portalid,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message1,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);

  const handleSelectEvent = (item) => {
    setSelectedData(item);
    setIsDialogOpen(true);
  };

  function groupByDate(projects) {
    const result = {};

    projects?.forEach((project) => {
      project.workPackages?.forEach((workPackage) => {
        const date = workPackage?.dueDate;

        // Skip if no dueDate exists
        if (!date) return;

        // Ensure consistent date format (e.g., YYYY-MM-DD)
        const formattedDate = moment(date).format("YYYY-MM-DD");

        // Initialize an array if this date doesn't exist yet
        if (!result[formattedDate]) {
          result[formattedDate] = [];
        }

        // Check if the project entry already exists for this date
        let projectEntry = result[formattedDate].find(
          (p) => p.projectName === project.projectName
        );

        // If not, create a new project entry
        if (!projectEntry) {
          projectEntry = {
            projectName: project.projectName,
            projectWorkinghrs: project.projectWorkinghrs,
            todayHours: 0, // Initialize the todayHours
            workobject: [],
          };
          result[formattedDate].push(projectEntry);
        }

        // Calculate the total hours from timeEntries
        const totalHours =
          workPackage.timeEntries?.reduce((sum, entry) => sum + (entry.hours || 0), 0) ||
          0;

        // Add total hours to the todayHours
        projectEntry.todayHours += totalHours;

        // Add workPackage details to the project entry, including totalHours
        projectEntry.workobject.push({
          id: workPackage.id,
          subject: workPackage.subject,
          description: workPackage.description,
          dueDate: workPackage.dueDate,
          doneRatio: workPackage.doneRatio,
          timeEntries: workPackage.timeEntries,
        });
      });
    });

    console.log(result);
    return result;
  }

  useEffect(() => {
    const dates = [];
    const startMoment = dateRange1[0]
      ? moment(dateRange1[0]).startOf("month")
      : moment().startOf("month");
    const endMoment = dateRange1[1]
      ? moment(dateRange1[1]).endOf("month")
      : startMoment.clone().add(3, "months").endOf("month");
    const monthsDiff = Math.ceil(endMoment.diff(startMoment, "months", true));

    let currentDate = startMoment.clone();
    setShowMonth(monthsDiff);
    for (let i = 0; i <= monthsDiff; i++) {
      const monthStart = currentDate.clone().startOf("month");
      const monthEnd = currentDate.clone().endOf("month");

      while (currentDate.isSameOrBefore(monthEnd, "day")) {
        dates.push(currentDate.format("YYYY-MM-DD"));
        currentDate.add(1, "day");
      }
      currentDate = monthStart.add(1, "months");
    }
    setCurrentMonthDates(dates);

    const months = [];
    for (let monthOffset = 0; monthOffset < monthsDiff; monthOffset++) {
      const weeks = [];
      const startOfMonth = dateRange1[0]
        ? moment(dateRange1[0]).add(monthOffset, "months").startOf("month")
        : moment().add(monthOffset, "months").startOf("month");
      const dayOfWeek = startOfMonth.day();
      const daysInMonth = startOfMonth.daysInMonth();
      let currentWeek = [];
      for (let i = 0; i < dayOfWeek; i++) {
        currentWeek.push(null);
      }

      for (let day = 1; day <= daysInMonth; day++) {
        currentWeek.push(
          `${startOfMonth.year()}-${(startOfMonth.month() + 1)
            .toString()
            .padStart(2, "0")}-${day.toString().padStart(2, "0")}`
        );

        if (currentWeek.length === 7) {
          weeks.push(currentWeek);
          currentWeek = [];
        }
      }

      if (currentWeek.length > 0) {
        while (currentWeek.length < 7) {
          currentWeek.push(null);
        }
        weeks.push(currentWeek);
      }
      months.push(weeks);
    }
    setCalendarGrid(months);
  }, [dateRange1[0], dateRange1[1]]);

  const descriptionColorMap = useMemo(() => {
    const colorMap = {};
    EmployeeTasksReportbyPortalID?.forEach((item, index) => {
      colorMap[item.projectName] = colorPalette[index % colorPalette.length];
    });
    return colorMap;
  }, [EmployeeTasksReportbyPortalID]);

  useEffect(() => {
    if (rowvalue) {
      if (!selectedDescription || selectedDescription === "all") {
        const employeeTasksReport = groupByDate(EmployeeTasksReportbyPortalID);
        setgroupedtasks(employeeTasksReport);
      } else {
        const filteredTasks = EmployeeTasksReportbyPortalID.filter(
          (task) => task.projectName === selectedDescription
        );
        const employeeTasksReport = groupByDate(filteredTasks);
        setgroupedtasks(employeeTasksReport);
      }
    }
  }, [EmployeeTasksReportbyPortalID, selectedDescription]);

  const initialValues = {
    from: dateRange1[0],
    to: dateRange1[1],
  };
  const ValidationSchema = Yup.object().shape({
    from: Yup.string().required("Please Enter Start Date"),
    to: Yup.string().required("Please Enter End Date"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      setdateRange1([values.from, values.to]);
      setSelectedDescription("all");
      dispatch(
        fetchEmployeeTaskReportbyPortalId({
          startDate: values.from,
          endDate: values.to,
          portalId: rowvalue?.portalid,
        })
      );
    },
  });
  return (
    <>
      {/* <Box sx={{ padding: { xs: "10px", md: "20px 10px 10px 10px" } }}>
        <Grid container spacing={2} alignItems="center" sx={{ marginBottom: "20px" }}>
          <Grid item>
            <IconButton
              sx={{
                padding: "5px 15px",
                color: (theme) => theme.palette.grey[500],
                borderRadius: 1,
              }}
              onClick={() => {
                setShowForm(false);
                setrowvalue(null);
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              color="primary"
              onClick={() => transformRef.current?.resetTransform()}
            >
              <ZoomOutIcon />
            </IconButton>
          </Grid>
        </Grid>

        <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <FormControl fullWidth>
                <Select
                  labelId="description-select-label"
                  id="description-select"
                  value={selectedDescription}
                  size="small"
                  onChange={handleDescriptionChange}
                  displayEmpty
                  renderValue={(value) => {
                    if (!value || value === "all") {
                      return "All Projects";
                    }
                    if (descriptionColorMap[value]) {
                      const color = descriptionColorMap[value];
                      return (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Box
                            sx={{
                              width: 20,
                              height: 20,
                              backgroundColor: color,
                              borderRadius: "50%",
                              marginRight: 2,
                            }}
                          />
                          {value}
                        </Box>
                      );
                    }
                    return "All Projects";
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: 300,
                        overflowY: "auto",
                        width: "auto",
                      },
                    },
                  }}
                >
                  <MenuItem key="all" value="all">
                    <Box
                      sx={{
                        width: 20,
                        height: 20,
                        backgroundColor: "#ccc",
                        display: "inline-block",
                        marginRight: 2,
                        borderRadius: "50%",
                      }}
                    />
                    All Projects
                  </MenuItem>
                  {Object.entries(descriptionColorMap).map(([description, color]) => (
                    <MenuItem key={description} value={description}>
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          backgroundColor: color,
                          display: "inline-block",
                          marginRight: 2,
                          borderRadius: "50%",
                        }}
                      />
                      {description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <TextField
                type="date"
                name="from"
                size="small"
                label="From"
                InputLabelProps={{
                  shrink: true,
                }}
                value={formik.values.from}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.from) && Boolean(formik.touched.from)}
                helperText={Boolean(formik.errors.from) && formik.errors.from}
                fullWidth
              />
            </Grid>
            <Grid item>
              <TextField
                type="date"
                name="to"
                size="small"
                label="To"
                InputLabelProps={{
                  shrink: true,
                }}
                value={formik.values.to}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.to) && Boolean(formik.touched.to)}
                helperText={Boolean(formik.errors.to) && formik.errors.to}
                fullWidth
              />
            </Grid>
            <Grid item>
              {status1 === "loading" ? (
                <CircularProgress size={24} />
              ) : (
                <Button type="submit" variant="contained" fullWidth>
                  Get
                </Button>
              )}
            </Grid>

            <Grid item>
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                Estimated Hours:{"  "}
                <span style={{ fontWeight: "300" }}>{estimatedHours.toFixed(2)}</span>
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                Spent Hours:{"  "}
                <span style={{ fontWeight: "400" }}>{spentHours.toFixed(2)}</span>
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Box> */}
      <Box sx={{ padding: { xs: "10px", md: "20px 10px 10px 10px" } }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <IconButton
            sx={{
              padding: "5px 15px",
              color: (theme) => theme.palette.grey[500],
              borderRadius: 1,
            }}
            onClick={() => {
              setShowForm(false);
              setrowvalue(null);
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => transformRef.current?.resetTransform()}
          >
            <ZoomOutIcon />
          </IconButton>
        </Box>
        <Box sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "35px",
        }}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Employee Name: {" "}
            <span >{rowvalue?.employee}</span>

          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 2,
            justifyContent: "space-between",
          }}
        >
          {/* Form Section */}
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "100%",
            }}
          >
            <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  gap: 2,
                }}
              >
                <Box sx={{ display: "flex", gap: 2 }}>
                  <TextField
                    type="date"
                    name="from"
                    size="small"
                    label="From"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={formik.values.from}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.from) && Boolean(formik.touched.from)}
                    helperText={Boolean(formik.errors.from) && formik.errors.from}
                    fullWidth
                  />
                  <TextField
                    type="date"
                    name="to"
                    size="small"
                    label="To"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={formik.values.to}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.to) && Boolean(formik.touched.to)}
                    helperText={Boolean(formik.errors.to) && formik.errors.to}
                    fullWidth
                  />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                  {status1 === "loading" ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Button type="submit" variant="contained" fullWidth>
                      Get
                    </Button>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    flex: 1,
                  }}
                >
                  <FormControl sx={{ minWidth: 150 }}>
                    <Select
                      labelId="description-select-label"
                      id="description-select"
                      value={selectedDescription}
                      size="small"
                      onChange={handleDescriptionChange}
                      displayEmpty
                      renderValue={(value) => {
                        if (!value || value === "all") {
                          return "All Projects";
                        }
                        if (descriptionColorMap[value]) {
                          const color = descriptionColorMap[value];
                          return (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Box
                                sx={{
                                  width: 20,
                                  height: 20,
                                  backgroundColor: color,
                                  borderRadius: "50%",
                                  marginRight: 2,
                                }}
                              />
                              {value}
                            </Box>
                          );
                        }
                        return "All Projects";
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            maxHeight: 300,
                            overflowY: "auto",
                            width: "auto",
                          },
                        },
                      }}
                    >
                      <MenuItem key="all" value="all">
                        <Box
                          sx={{
                            width: 20,
                            height: 20,
                            backgroundColor: "#ccc",
                            display: "inline-block",
                            marginRight: 2,
                            borderRadius: "50%",
                          }}
                        />
                        All Projects
                      </MenuItem>
                      {Object.entries(descriptionColorMap).map(([description, color]) => (
                        <MenuItem key={description} value={description}>
                          <Box
                            sx={{
                              width: 20,
                              height: 20,
                              backgroundColor: color,
                              display: "inline-block",
                              marginRight: 2,
                              borderRadius: "50%",
                            }}
                          />
                          {description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </form>
          </Box>

          {/* Hours Section */}
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "flex-end",
              gap: 2,
              marginTop: 2,
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Estimated Hours:{" "}
              <span style={{ fontWeight: "300" }}>{estimatedHours.toFixed(2)}</span>
            </Typography>

            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Spent Hours:{" "}
              <span style={{ fontWeight: "400" }}>{spentHours.toFixed(2)}</span>
            </Typography>
          </Box>
        </Box>
      </Box>

      {status1 !== "succeeded" && <Loader />}
      <TransformWrapper ref={transformRef}>
        <TransformComponent
          wrapperStyle={{
            width: "100%",
            height: "78vh",
            overflow: "auto",
            border: "2px solid #ccc",
            backgroundColor: "#f9f9f9",
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              display: "flex",

              flexWrap: "wrap",
              gap: 2,
              // justifyContent: "center",
            }}
          >
            {Array.from({ length: showMonth }).map((_, monthOffset) => {
              const monthMoment = dateRange1[0]
                ? moment(dateRange1[0]).add(monthOffset, "months")
                : moment().add(monthOffset, "months");
              const monthLabel = monthMoment.format("MMMM YYYY");
              const monthDates = currentMonthDates.filter((date) =>
                moment(date).isSame(monthMoment, "month")
              );

              return (
                <Box
                  key={monthOffset}
                  sx={{
                    flex: {
                      xs: "0 0 100%",
                      sm: "0 0 100%",
                      md: "0 0 100%",
                      lg: "0 0 48%",
                    },
                  }}
                >
                  <Box
                    key={monthOffset}
                    sx={{
                      border: "1px solid #ddd",
                      borderRadius: 1,
                      backgroundColor: "white",
                      height: "100%",
                      p: 1,
                      m: 1,
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "start",
                      gap: 2,
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: {
                          xs: "0.rem", // Smaller for compact layout
                          sm: "0.7rem",
                          md: "1.2rem",
                        },
                      }}
                    >
                      {monthLabel}
                    </Typography>
                    <Grid
                      container
                      spacing={0.5}
                      sx={{
                        width: "100%",
                        display: "grid",
                        gridTemplateColumns: "repeat(7, 1fr)",
                      }}
                    >
                      {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(
                        (day, index) => (
                          <Typography
                            key={index}
                            variant="caption"
                            sx={{
                              fontWeight: "bold",
                              textAlign: "center",
                              fontSize: "1.0rem", // Smaller for compact layout
                            }}
                          >
                            {day}
                          </Typography>
                        )
                      )}
                      {calendarGrid[monthOffset]?.map((week, weekIndex) => (
                        <React.Fragment key={weekIndex}>
                          {week.map((date, dateIndex) => {
                            const ProjectForDate = groupedtask[date];
                            const isDateInMonth = monthDates.includes(date);

                            return isDateInMonth ? (
                              <Paper
                                key={dateIndex}
                                sx={{
                                  m: 0.3,
                                  p: 0.3,
                                  border: "1px solid #ddd",
                                  borderRadius: 1,
                                  textAlign: "center",
                                  minWidth: {
                                    xs: "0.6rem", // Smaller for compact layout
                                    sm: "0.7rem",
                                    md: "0.9rem",
                                  },
                                  minHeight: "44px",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "flex-start",
                                  cursor: "pointer",
                                }}
                              >
                                <Typography
                                  variant="caption"
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "0.6rem",
                                  }}
                                >
                                  {moment(date).format("D")}
                                </Typography>

                                {ProjectForDate?.map((item) => (
                                  <Tooltip key={item?.id} title={item?.projectName} arrow>
                                    <Card
                                      key={item.id}
                                      sx={{
                                        border: "1px solid #ddd",
                                        borderRadius: 1,
                                        m: 0.5,
                                        p: 0.3,
                                        backgroundColor:
                                          descriptionColorMap[item?.projectName],
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        height: "25px",
                                        overflow: "hidden",
                                      }}
                                      onClick={() => handleSelectEvent(item)}
                                    >
                                      <Typography
                                        variant="caption"
                                        sx={{
                                          fontSize: "0.6rem",
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        <span
                                          style={{
                                            flex: "1 1 45%",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            fontSize: "0.6rem",
                                          }}
                                        >
                                          {item.projectName}
                                        </span>

                                        {item.todayHours && (
                                          <Chip
                                            label={`${item.todayHours.toFixed(2)} hrs`}
                                            size="small"
                                            sx={{
                                              marginLeft: 1,
                                              backgroundColor: "#333",
                                              color: "#fff",
                                              fontSize: "8px",
                                              height: "16px",
                                              maxWidth: "60%",
                                              flexShrink: 0,
                                              textOverflow: "ellipsis",
                                              overflow: "hidden",
                                            }}
                                          />
                                        )}
                                      </Typography>
                                    </Card>
                                  </Tooltip>
                                ))}
                              </Paper>
                            ) : (
                              <Paper
                                key={dateIndex}
                                sx={{
                                  m: 0.3,
                                  p: 0.3,
                                  border: "1px solid #ddd",
                                  borderRadius: 1,
                                  textAlign: "center",
                                  minWidth: {
                                    xs: "0.6rem", // Smaller for compact layout
                                    sm: "0.7rem",
                                    md: "0.9rem",
                                  },
                                  minHeight: "44px",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "flex-start",
                                  cursor: "pointer",
                                }}
                              ></Paper>
                            );
                          })}
                        </React.Fragment>
                      ))}
                    </Grid>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </TransformComponent>
      </TransformWrapper>
      {isDialogOpen && (
        <>
          <Modal
            open={isDialogOpen}
            onClose={() => {
              setIsDialogOpen(false);
            }}
          >
            <Box className={ModalContainer}>
              <Box
                className={Container2}
                sx={{
                  overflow: "scroll",
                }}
              >
                <Box className={TableHeader}>
                  <Typography variant="h1" sx={{
                    fontSize: "20px !important",
                    color: (theme) => theme.palette.primary.main,

                  }}>
                    {selectedData.projectName} Task Detail
                  </Typography>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsDialogOpen(false);
                    }}
                  >
                    <HighlightOffIcon
                      color="primary"
                      sx={{
                        fontSize: "28px",
                      }}
                    />
                  </IconButton>
                </Box>

                <Box
                  sx={{
                    maxHeight: "60vh",
                    overflowY: "auto",
                  }}
                >
                  {selectedData?.workobject?.map((row, i) => (
                    <Box key={i} sx={{ marginTop: 2 }}>
                      <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                        <Grid item xs={12} md={5} pr={7}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Work Subject
                          </Typography>
                          <Tooltip title={row?.subject || "N/A"} placement="top" arrow>
                            <Typography
                              sx={{
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                WebkitLineClamp: 1,
                                cursor: "pointer",
                              }}
                            >
                              {row?.subject || "N/A"}
                            </Typography>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Typography sx={{ fontWeight: "bold" }}>Date</Typography>
                          <Typography>{row?.dueDate || "N/A"}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "1.1rem",
                              marginBottom: 0.5,
                            }}
                          >
                            Completion %
                          </Typography>
                          <Box sx={{ position: "relative" }}>
                            <LinearProgress
                              variant="determinate"
                              value={row?.doneRatio || 0}
                              sx={{
                                marginTop: 1,
                                backgroundColor: "#BCCCDC",
                                borderRadius: 10,
                                height: 13,
                              }}
                            />
                            <Typography
                              sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                color: "#fff",
                                fontWeight: "semibold",
                                fontSize: "0.60rem",
                              }}
                            >
                              {Math.round(row?.doneRatio || 0)} %
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Typography
                        variant="h7"
                        sx={{ fontWeight: "bold", marginBottom: 2 }}
                      >
                        Time Entries
                      </Typography>

                      <TableContainer component={Paper} className={TableContain}>
                        <Table
                          stickyHeader
                          sx={{
                            minWidth: { md: "auto", xs: "auto" },
                            overflow: "scroll",
                          }}
                          aria-label="time entries table"
                          size="small"
                        >
                          <TableHead>
                            <TableRow size="small">
                              <TableCell
                                size="small"
                                sx={{ fontWeight: "bold", minWidth: 50 }}
                              >
                                #
                              </TableCell>
                              <TableCell
                                size="small"
                                sx={{ fontWeight: "bold", minWidth: 150 }}
                              >
                                Activity Title
                              </TableCell>
                              <TableCell
                                size="small"
                                sx={{ fontWeight: "bold", minWidth: 150 }}
                              >
                                Comments
                              </TableCell>
                              <TableCell
                                size="small"
                                sx={{ fontWeight: "bold", minWidth: 150 }}
                              >
                                Time Spent
                              </TableCell>{" "}
                              <TableCell
                                size="small"
                                sx={{ fontWeight: "bold", minWidth: 150 }}
                              >
                                Spent Type
                              </TableCell>
                              <TableCell
                                size="small"
                                sx={{ fontWeight: "bold", minWidth: 150 }}
                              >
                                Date
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {row.timeEntries.length > 0 ? (
                              row.timeEntries.map((entry, i) => (
                                <TableRow
                                  key={entry.id}
                                  sx={{
                                    "&:last-child td, &:last-child th": { border: 0 },
                                  }}
                                >
                                  <TableCell size="small" component="th" scope="row">
                                    {i + 1}
                                  </TableCell>
                                  <TableCell size="small" component="th" scope="row">
                                    <Tooltip
                                      title={entry?.activityTitle || "N/A"}
                                      placement="top"
                                      arrow
                                    >
                                      <Typography >
                                        {entry?.activityTitle || "N/A"}
                                      </Typography>
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell size="small" component="th" scope="row">
                                    <Tooltip
                                      title={entry?.comments || "N/A"}
                                      placement="top"
                                      arrow
                                    >
                                      <Typography className={elipsText}>
                                        {entry?.comments || "N/A"}
                                      </Typography>
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell size="small" component="th" scope="row">
                                    {entry.hours || "N/A"}
                                  </TableCell>
                                  <TableCell size="small" component="th" scope="row">
                                    {entry.spentType || "N/A"}
                                  </TableCell>
                                  <TableCell size="small" component="th" scope="row">
                                    {entry.spentOn || "N/A"}
                                  </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={4} align="center">
                                  No time entries available
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Divider sx={{
                        marginBottom: 2, marginTop: 4,
                        backgroundColor: (theme) => theme.palette.primary.main,
                        height: "2px"

                      }} />
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}

export default PortalSummaryTable;
PortalSummaryTable.propTypes = {
  setShowForm: PropTypes.any,
  setrowvalue: PropTypes.any,
  rowvalue: PropTypes.any,
  dateRange: PropTypes.any,
};
