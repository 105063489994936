import {
  Box,
  Button,
  FormControl,
  Typography,
  TextField,
  CircularProgress,
  MenuItem,
} from "@mui/material";
window.Buffer = window.Buffer || require("buffer").Buffer;
import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import { object, string } from "yup";
// import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useContext } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { HeaderContext } from "../../../Context/SnackBarContext/HeaderContext/HeaderContext";
// import { logout } from "../../../Redux/Slice/LogoutSlice/LogoutSlice";
// import { useNavigate } from "react-router-dom";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
// import { TooltipContext } from "../../../Context/TooltipContext/TooltipContent";
// import Loader from "../../Loader/Loader";
import theme from "../../../theme";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import { AddApplicantApi, updateApplicantApi } from "../../../Api/Applicants/ApplicantAPI";
// import { showData } from "../../../Redux/Slice/Applicants/ApplicantSlice";
import SearchIcon from "@mui/icons-material/Search";
import axiosInstance from "../../../Utils/AxiosInstance";
import { showData } from "../../../Redux/Slice/Applicants/ApplicantSlice";
import Loader from "../../Loader/Loader";
const useStyles = makeStyles(() => {
  return {
    inputBox: {
      width: "100%",
      [theme.breakpoints.down("custom")]: {
        minWidth: "0px"
      },
    },
    input_typography: {
      textAlign: "left",
      fontSize: `15px !important`,
      marginTop: "10px !important",
    },
    Form_input: {
      borderRadius: theme.borderRadius.All,
      backgroundColor: `${theme.palette.common.white} !important`,

    },
    btns: {
      width: "80px",
    },
    uploadBoxContainer: {
      minWidth: "100%",
      position: "relative",
    },
    IconContainer: {
      paddingTop: "10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    reviewBox: {
      display: "flex",
      alignItems: "center",
      gap: "20px",
      flexWrap: "wrap",
    },
    FieldInRow: {
      display: "flex",
      justifyContent: "space-between",
      gap: "20px",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        gap: "10px",
      },
    },
    imgcontainer: {
      width: "120px",
      height: "120px",
      borderRadius: "50%",
      padding: "10px",
    },
  };
});

const validationSchema = object({
  name: string().required("This field is required"),
  city: string().required("This field is required"),
  // contactNumber: string()
  // .matches(/^[0-9]{10}$/,"Please enter a valid phone number")  // Use a regex pattern that suits your phone number format
  // .required("This field is required"),
  degree: string().required("This field is required"),
  email: string()
    .email("Please enter a valid email address")
    .required("This field is required"),
  university: string().required("This field is required"),
  cvLink: string().required("This field is required"),
  comment: string().required("This field is required"),
  status: string("Please Select status").required("This field is required"),
  hiringSectionId: string("Please Select Hiring Field").required(
    "This field is required"
  ),
});
const ApplicantsForm = ({ setShowForm, empData, setisEdited, isEdited }) => {

  const { Form_input, input_typography, btns, inputBox, FieldInRow } =
    useStyles();
  const [loading, setloading] = useState(false);

  // const navigate = useNavigate();
  const { setsnackBarData } = useContext(SnackBarContext);
  const { setheader } = useContext(HeaderContext);
  const [value, setValue] = useState();
  const [showFields, setShowFields] = useState(false);
  const [update, setUpdate] = useState(false);

  const dispatch = useDispatch();
  const initialValues = {
    name: "",
    city: "",
    degree: "",
    university: "",
    email: "",
    cvLink: "",
    comment: "",
    status: "",
    contactNumber: "",
    hiringSectionId: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handelAdd(values, resetForm, setSubmitting);
    },
  });
  useEffect(() => {
    if (value) {
      formik.setFieldValue("contactNumber", value);
    }
  }, [value]);
  const handelAdd = async (values, setSubmitting) => {
    if (!values.contactNumber || !isValidPhoneNumber(values.contactNumber)) {

      return setsnackBarData({
        openToast: true,
        message: "Please Enter Valid Phone Number",
        type: "error",
      });
    }
    setloading(true);
    if (isEdited || update) {
      if (isEdited) {

        values.id = empData?.id
      } else {
        values.id = update
      }
      const response = await updateApplicantApi(values);

      setsnackBarData(response.snackBarData);

      if (response.data.IsSuccess) {
        setloading(false);
        setisEdited(false);
        setSubmitting(false)
        setShowForm(false);
      } else {
        setloading(false);
      }
    } else if (!isEdited) {

      const response = await AddApplicantApi(values);
      setsnackBarData(response.snackBarData);
      if (response.data.IsSuccess) {
        setloading(false);
        setSubmitting(false)
        setShowForm(false);
      }

      setloading(false);
    }
    setloading(false);
  };

  const { applicants, status } = useSelector((state) => state.applicants);

  useEffect(() => {
    dispatch(showData());
  }, []);

  useEffect(() => {
    // Update the formik initial values based on the fetched data
    if (isEdited && applicants?.length > 0) {
      const editedApplicant = applicants?.find(
        (applicant) => applicant?.id === empData?.empId
      );

      if (editedApplicant) {
        populateFields(editedApplicant)
        setShowFields(true)
      }
    }
  }, [isEdited]);

  const populateFields = (data) => {

    formik.setFieldValue("city", data.city)
    formik.setFieldValue("email", data.email)
    formik.setFieldValue("comment", data.comment)
    formik.setFieldValue("university", data.university)
    formik.setFieldValue("cvLink", data.cv_link)
    formik.setFieldValue("degree", data.degree)
    formik.setFieldValue("name", data.name)
    formik.setFieldValue("contactNumber", data.contact_number)

    formik.setFieldValue("status", data.status)

    if (data.hiringSection) {

      formik.setFieldValue("hiringSectionId", data.hiringSection.session_id)
    }

  }

  return (
    <>
      {status == "loading" ? <Loader /> : null}
      <Box
        sx={{
          minHeight: "calc(100vh - 165px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: "20px",
        }}
      >
        <form onSubmit={formik?.handleSubmit}>
          <Box>
            <Box
              sx={{
                maxWidth: "900px",
                width: "100%",
                display: "flex",
                gap: "20px",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <Box className={FieldInRow} style={{ alignItems: "center" }}>


                <Box className={inputBox} sx={{ minWidth: "440px" }}>
                  <Typography className={input_typography}>Phone</Typography>
                  <PhoneInput
                    placeholder="Enter phone number"
                    // type="number"
                    value={formik?.values.contactNumber}
                    name="contactNumber"
                    onChange={setValue}
                    limitMaxLength
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="PK"
                    sx={{ width: "732px" }}
                  />
                </Box>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                  <Button

                    type="button"
                    variant="contained"
                    color="primary"
                    style={{ height: "37px", marginTop: "36px", width: "120px" }}
                    onClick={async () => {
                      let resp = await axiosInstance.get(`/getApplicantByPhone/${value}`)

                      if (resp.data.Data) {
                        populateFields(resp.data.Data)
                        // isEdited = true
                        setUpdate(resp.data.Data.applicant_id)
                      }
                      setShowFields(true)
                    }}
                  >
                    <SearchIcon

                    />
                    Search
                  </Button>
                </Box>
              </Box>



              <Box className={FieldInRow}>
                <Box className={inputBox}>
                  <Typography className={input_typography}>Name</Typography>

                  <FormControl fullWidth sx={{ textAlign: "left" }}>
                    <TextField
                      onBlur={formik.handleBlur}
                      onChange={formik?.handleChange}
                      size="small"
                      name="name"
                      value={formik?.values.name}
                      variant="outlined"
                      type="text"
                      color="primary"
                      sx={{ input: { color: "black" } }}
                      className={Form_input}
                      fullWidth
                      error={
                        Boolean(formik?.errors.name) &&
                        Boolean(formik?.touched.name)
                      }
                      helperText={
                        Boolean(formik?.touched.name) && formik?.errors.name
                      }
                    />
                  </FormControl>
                </Box>

                <Box className={inputBox}>
                  <Typography className={input_typography}>
                    Email Address
                  </Typography>
                  <FormControl fullWidth sx={{ textAlign: "left" }}>
                    <TextField
                      onChange={formik?.handleChange}
                      onBlur={formik.handleBlur}
                      size="small"
                      name="email"
                      value={formik?.values.email}
                      variant="outlined"
                      type="text"
                      color="primary"
                      sx={{ input: { color: "black" } }}
                      className={Form_input}
                      autoComplete="off"
                      inputProps={{ autoComplete: "off" }}

                      fullWidth
                      error={
                        Boolean(formik?.errors.email) &&
                        Boolean(formik?.touched.email)
                      }
                      helperText={
                        Boolean(formik?.touched.email) && formik?.errors.email
                      }
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box className={FieldInRow}>
                <Box className={inputBox}>
                  <Typography className={input_typography}>City</Typography>
                  <FormControl fullWidth sx={{ textAlign: "left" }}>
                    <TextField
                      onChange={formik?.handleChange}
                      onBlur={formik.handleBlur}
                      size="small"
                      name="city"
                      value={formik?.values.city}
                      variant="outlined"
                      type="text"
                      color="primary"
                      sx={{ input: { color: "black" } }}
                      className={Form_input}
                      autoComplete="off"
                      inputProps={{ autoComplete: "off" }}
                      fullWidth
                      error={
                        Boolean(formik?.errors.city) &&
                        Boolean(formik?.touched.city)
                      }
                      helperText={
                        Boolean(formik?.touched.city) && formik?.errors.city
                      }
                    />
                  </FormControl>
                </Box>
                <Box className={inputBox}>
                  <Typography fullWidth className={input_typography}>
                    Hiring Section
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{
                      textAlign: "left",
                      input: { color: "black" },
                    }}
                    size="small"
                    select
                    onChange={(e) => {
                      formik?.handleChange(e);
                    }}
                    name="hiringSectionId"
                    value={formik?.values.hiringSectionId}
                    className={Form_input}
                    error={
                      Boolean(formik?.errors.hiringSectionId) &&
                      Boolean(formik?.touched.hiringSectionId)
                    }
                    helperText={
                      Boolean(formik?.touched.hiringSectionId) &&
                      formik?.errors.hiringSectionId
                    }
                  >


                    {applicants &&
                      applicants?.map((ele, i) => (
                        <MenuItem key={i} value={ele.session_id}>{ele.title}</MenuItem>
                      ))}
                  </TextField>
                </Box>
              </Box>

              <Box className={FieldInRow}>
                <Box className={inputBox}>
                  <Typography className={input_typography}>Degree</Typography>
                  <FormControl fullWidth sx={{ textAlign: "left" }}>
                    <TextField
                      onChange={formik?.handleChange}
                      onBlur={formik.handleBlur}
                      size="small"
                      name="degree"
                      value={formik?.values.degree}
                      variant="outlined"
                      type="text"
                      color="primary"
                      sx={{ input: { color: "black" } }}
                      className={Form_input}
                      autoComplete="off"
                      inputProps={{ autoComplete: "off" }}
                      fullWidth
                      error={
                        Boolean(formik?.errors.degree) &&
                        Boolean(formik?.touched.degree)
                      }
                      helperText={
                        Boolean(formik?.touched.degree) && formik?.errors.degree
                      }
                    />
                  </FormControl>
                </Box>
                <Box className={inputBox}>
                  <Typography className={input_typography}>
                    University
                  </Typography>
                  <FormControl fullWidth sx={{ textAlign: "left" }}>
                    <TextField
                      onChange={formik?.handleChange}
                      onBlur={formik.handleBlur}
                      size="small"
                      name="university"
                      value={formik?.values.university}
                      variant="outlined"
                      type="text"
                      color="primary"
                      sx={{ input: { color: "black" } }}
                      className={Form_input}
                      autoComplete="off"
                      inputProps={{ autoComplete: "off" }}
                      fullWidth
                      error={
                        Boolean(formik?.errors.university) &&
                        Boolean(formik?.touched.university)
                      }
                      helperText={
                        Boolean(formik?.touched.university) &&
                        formik?.errors.university
                      }
                    />
                  </FormControl>
                </Box>
              </Box>

              <Box className={FieldInRow}>
                <Box className={inputBox}>
                  <Typography fullWidth className={input_typography}>
                    Status
                  </Typography>

                  <TextField
                    fullWidth
                    sx={{
                      textAlign: "left",
                      input: { color: "black" },
                    }}
                    size="small"
                    select
                    onChange={(e) => {
                      formik?.handleChange(e);
                    }}
                    name="status"
                    value={formik?.values.status}
                    className={Form_input}
                    error={
                      Boolean(formik?.errors.status) &&
                      Boolean(formik?.touched.status)
                    }
                    helperText={
                      Boolean(formik?.touched.status) && formik?.errors.status
                    }
                  >
                    <MenuItem value="">select</MenuItem>
                    <MenuItem value="pending">Pending</MenuItem>
                    <MenuItem value="shortlisted">Shortlisted</MenuItem>
                    <MenuItem value="rejected">Rejected</MenuItem>
                  </TextField>
                </Box>
                <Box className={inputBox}>
                  <Typography className={input_typography}>CV Link</Typography>
                  <FormControl fullWidth sx={{ textAlign: "left" }}>
                    <TextField
                      onChange={formik?.handleChange}
                      onBlur={formik.handleBlur}
                      size="small"
                      name="cvLink"
                      value={formik?.values.cvLink}
                      variant="outlined"
                      type="text"
                      color="primary"
                      sx={{ input: { color: "black" } }}
                      className={Form_input}
                      autoComplete="off"
                      inputProps={{ autoComplete: "off" }}
                      fullWidth
                      error={
                        Boolean(formik?.errors.cvLink) &&
                        Boolean(formik?.touched.cvLink)
                      }
                      helperText={
                        Boolean(formik?.touched.cvLink) && formik?.errors.cvLink
                      }
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box className={FieldInRow}>
                <Box className={inputBox}>
                  <Typography className={input_typography}>Comments</Typography>
                  <FormControl fullWidth sx={{ textAlign: "left" }}>
                    <TextField
                      onChange={formik?.handleChange}
                      onBlur={formik.handleBlur}
                      size="small"
                      name="comment"
                      value={formik?.values.comment}
                      variant="outlined"
                      type="text"
                      color="primary"
                      sx={{ input: { color: "black" } }}
                      className={Form_input}
                      autoComplete="off"
                      inputProps={{ autoComplete: "off" }}
                      fullWidth
                      error={
                        Boolean(formik?.errors.comment) &&
                        Boolean(formik?.touched.comment)
                      }
                      helperText={
                        Boolean(formik?.touched.comment) &&
                        formik?.errors.comment
                      }
                    />
                  </FormControl>
                </Box>
              </Box>

            </Box>

            {showFields ? <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <Box
                sx={{
                  float: "right",
                  display: "flex",
                  gap: "10px",
                  marginTop: "20px",
                }}
                spacing={2}
              >
                <>
                  <Button
                    className={btns}
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setShowForm(false);
                      setisEdited(false);
                      setheader(null);
                    }}
                  >
                    Cancel
                  </Button>

                  {loading === false ? (
                    !isEdited ? (
                      <Button
                        className={btns}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Save
                      </Button>
                    ) : (
                      <Button
                        className={btns}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Update
                      </Button>
                    )
                  ) : (
                    <CircularProgress />
                  )}
                </>
              </Box>
            </Box> : null}
          </Box>
        </form>
      </Box>
    </>
  );
};

export default ApplicantsForm;

ApplicantsForm.propTypes = {
  setShowForm: PropTypes.any,
  empData: PropTypes.any,
  setisEdited: PropTypes.any,
  isEdited: PropTypes.any,
};
