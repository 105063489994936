import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  shiftrules: [],
  Weekoffdays: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};

export const fetchShiftRules = createAsyncThunk("getshiftrules", async () => {
  const response = await axiosInstance.get(`getshiftrules`);
  return response.data;
});
export const fetchShiftRulesPopulate = createAsyncThunk("getoffdays", async () => {
  const response = await axiosInstance.get(`getoffdays`);

  return response.data;
});

const ShiftRuleSlice = createSlice({
  name: "shiftrules",
  initialState,
  reducers: {
    ShiftRulesAdded: {
      reducer(state, action) {
        state.shiftrules.push(action.payload);
      },
    },
    deleteShiftRule: {
      reducer(state, action) {
        const userIndex = state?.shiftrules.findIndex(
          (shiftrule) => shiftrule.rule_id === action.payload
        );
        if (userIndex !== -1) {
          state.shiftrules.splice(userIndex, 1);
        }
      },
    },
    ShiftRuleResetData: (state) => {
      state.shiftrules = [];
      state.Weekoffdays = [];
      state.isSuccess = false;
      state.message = null;
      state.status = "idle"; //'idle' | 'loading' | 'succeeded' | 'failed'
      state.error = null;
      state.IsLogin = true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchShiftRules.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchShiftRules.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.shiftrules = action.payload.Shiftrules;
      })
      .addCase(fetchShiftRules.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })

      .addCase(fetchShiftRulesPopulate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchShiftRulesPopulate.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Weekoffdays = action.payload.Weeklyoffdays;
      })
      .addCase(fetchShiftRulesPopulate.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      });
  },
});

export const getshiftrulesDetails = (state) => state.shiftrules;

export const { ShiftRulesAdded, ShiftRuleResetData, deleteShiftRule } =
  ShiftRuleSlice.actions;

export default ShiftRuleSlice.reducer;
