import PropTypes from "prop-types";
import {
  Box,
  CircularProgress,
  Grid,
  gridClasses,
  InputAdornment,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import {
  fetchAttendanceDetail,
  getAttendancedetailDetails,
} from "../../Redux/Slice/AttendanceDetail/AttendanceDetailSlice";
import { useDispatch, useSelector } from "react-redux";

function AttendanceDetailMain() {
  const dispatch = useDispatch();

  const [rows, setrows] = useState([]);
  const [filterRows, setfilterRows] = useState([]);
  const [pageSize, setPageSize] = useState(15);

  const [loading] = useState(false);
  const attendanceMaindetails = useSelector(getAttendancedetailDetails);

  useEffect(() => {
    dispatch(fetchAttendanceDetail());
  }, [dispatch]);

  // const DyeingQuotationDetails = useSelector(getKierLotCreationDetails);

  // console.log(DyeingQuotationDetails?.dyeingQuotation);

  // useEffect(() => {
  //   setArrDetails(TemparoryArr)
  // //   dispatch(fetchKierLotCreation());
  // }, [dispatch]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }
  const columns = [
    { field: "sr", headerName: "Sr" },
    {
      field: "date",
      headerName: "Date",
      width: 200,
      type: "date",
    },
    {
      field: "Employee",
      headerName: "Employee",
      width: 200,
    },
    { field: "intime", headerName: "In Time", width: 150 },
    { field: "outtime", headerName: "Out Time", width: 150 },

    { field: "Year", headerName: "Year", width: 150 },

    // { field: "Status", headerName: "Status", width: 150 },
  ];

  useEffect(() => {
    // console.log(DyeingQuotationDetails);

    let arr = [];
    if (attendanceMaindetails) {
      console.log(attendanceMaindetails);
      attendanceMaindetails?.attendancedetail.forEach((item, idx) => {
        return arr.push({
          sr: idx + 1,
          id: item?.attendance_id,
          Employee: item?.emp_id.name,
          date: item.date ? moment(item.Date).format("DD MMM, YYYY") : "",
          Year: item?.year,

          intime: item.in_time,
          outtime: item.out_time,
          Status: item.Status,
        });
      });
    }
    console.log(arr);
    setrows(arr);
    setfilterRows(arr);
  }, [attendanceMaindetails]);

  const onTextChange = () => {
    if (document.getElementById("OrderNoFilter").value === "") {
      setfilterRows(rows);
    } else {
      let suggestions = [];

      var regex1 = new RegExp(
        document.getElementById("OrderNoFilter").value,
        "i"
      );

      console.log(rows);
      suggestions = rows?.filter(
        (val) =>
          document.getElementById("OrderNoFilter").value === "" ||
          regex1.test(val.Employee) ||
          document.getElementById("OrderNoFilter").value === "" ||
          regex1.test(val.date)
      );

      setfilterRows(suggestions);
    }
  };

  return (
    <Grid container spacing={2} sx={{ padding: "15px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: (theme) => theme.palette.grey.G2 }} />
              </InputAdornment>
            ),
          }}
          variant="standard"
          type="text"
          autoComplete="off"
          placeholder="Employee"
          className="input_border"
          id="OrderNoFilter"
          onChange={onTextChange}
        />
      </Box>

      <span className="ml-2 float-right">
        <div className="loaderclass"> </div>
      </span>

      {loading ? (
        <>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <Box sx={{ width: "5%" }}>
              <CircularProgress color="primary" />
            </Box>
          </Box>
        </>
      ) : (
        <Grid item md={12} sm={12} xs={12} overflow>
          <div style={{ height: "calc(100vh - 240px)", width: "100%" }}>
            <DataGrid
              rows={filterRows}
              columns={columns}
              onRowClick={() => { }}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[15, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
              pagination
              components={{
                Toolbar: CustomToolbar,
              }}
              localeText={{
                toolbarDensity: "Size",
                toolbarDensityLabel: "Size",
                toolbarDensityCompact: "Small",
                toolbarDensityStandard: "Medium",
                toolbarDensityComfortable: "Large",
              }}
            />
          </div>
        </Grid>
      )}
    </Grid>
  );
}

export default AttendanceDetailMain;

AttendanceDetailMain.propTypes = {
  setShowForm: PropTypes.any,
};
