import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import { Box } from "@mui/material";
// import { useEffect } from "react";
import PortalSummaryTable from "../../Components/PortalSummary/PortalSummaryTable";
import PortalSummaryDetailTable from "../../Components/PortalSummary/PortalSummaryDetailTable";
import moment from "moment";

const PortalSummaryReport = () => {
  const [ShowForm, setShowForm] = useState(false);
  const [canView] = useState(true);
  const [rowvalue, setrowvalue] = useState(null);
  const [dateRange, setDateRange] = useState([
    moment().startOf("week").format("YYYY-MM-DD"),
    moment().endOf("week").format("YYYY-MM-DD"),
  ]);

  return (
    <div>
      {canView === true ? (
        ShowForm ? (
          <PortalSummaryTable
            setShowForm={setShowForm}
            rowvalue={rowvalue}
            setrowvalue={setrowvalue}
            setDateRange={setDateRange}
            dateRange={dateRange}
          />
        ) : (
          <PortalSummaryDetailTable
            setShowForm={setShowForm}
            setrowvalue={setrowvalue}
            setDateRange={setDateRange}
            dateRange={dateRange}
          />
        )
      ) : canView === false ? (
        <Navigate to={"/dashboard"} />
      ) : (
        <Box>Loading ...</Box>
      )}
    </div>
  );
};

export default PortalSummaryReport;
PortalSummaryReport.propTypes = {
  setShowForm: PropTypes.any,
};
