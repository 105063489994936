import PropTypes from "prop-types";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  gridClasses,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";
import Loader from "../Loader/Loader";
import * as Yup from "yup";
import {
  fetchEmployeeTaskReport,
  getPortalTaskSummary,
} from "../../Redux/Slice/PortalSummary/PortalSummarySlice";
import { useFormik } from "formik";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});

function PortalSummaryDetailTable({ setShowForm, setrowvalue, setDateRange, dateRange }) {
  const { tabelStyle } = useContext(TabelStyleContext);
  const [rows, setrows] = useState([]);
  const [pageSize, setPageSize] = useState(15);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setsnackBarData } = useContext(SnackBarContext);
  const { status, IsLogin, message, EmployeeTasksReport } =
    useSelector(getPortalTaskSummary);
  const { header } = useStyles();
  useEffect(() => {
    if (dateRange[1] && dateRange[0]) {
      dispatch(
        fetchEmployeeTaskReport({
          startDate: dateRange[0],
          endDate: dateRange[1],
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    let arr = [];
    if (EmployeeTasksReport) {
      EmployeeTasksReport?.forEach((item, idx) => {
        return arr.push({
          id: idx + 1,
          ...item,
        });
      });
    }
    setrows(arr);
  }, [EmployeeTasksReport]);

  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport csvOptions={{ fileName: "Portal Summary" }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }

  const columns = [
    { field: "id", headerName: "Sr #", width: 100, headerClassName: header },
    {
      field: "employee",
      headerName: "Employee Name",
      width: 300,
      headerClassName: header,
    },
    {
      field: "estimatedHours",
      headerName: "Estimated Hours",
      width: 160,
      headerClassName: header,
    },
    {
      field: "workedHours",
      headerName: "Worked Hours",
      width: 160,
      headerClassName: header,
    },
    {
      field: "projectCount",
      headerName: "Projects Involved",
      width: 160,
      headerClassName: header,
    },
    {
      field: "action",
      headerName: "Action",
      width: 160,
      headerClassName: header,
      renderCell: (cellVal) => {
        return (
          <>
            <Box>
              <IconButton
                onClick={() => {
                  setrowvalue(cellVal?.row);
                  setShowForm(true);
                }}
              >
                <VisibilityIcon />
              </IconButton>
            </Box>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (status === "failed") {
      setsnackBarData({
        type: "error",
        message: "Network Error",
        openToast: true,
      });
    }
  }, [status]);

  const initialValues = {
    from: dateRange[0],
    to: dateRange[1],
  };
  const ValidationSchema = Yup.object().shape({
    from: Yup.string().required("Please Enter Start Date"),
    to: Yup.string().required("Please Enter End Date"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      setDateRange([values.from, values.to]);
      dispatch(fetchEmployeeTaskReport({ startDate: values.from, endDate: values.to }));
    },
  });

  return (
    <>
      <span className="ml-2 float-right">
        <div className="loaderclass"> </div>
      </span>

      {EmployeeTasksReport?.length <= 0 && status === "loading" ? (
        <>
          <Loader />
        </>
      ) : null}
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              paddingBottom: "10px",
              width: "100%",
              padding: "10px 10px 0px 10px",
              alignItems: "center",
            }}
          >
            <TextField
              type={"date"}
              name="from"
              size="small"
              label="From"
              InputLabelProps={{
                shrink: true,
              }}
              value={formik.values.from}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.from) && Boolean(formik.touched.from)}
              helperText={Boolean(formik.errors.from) && formik.errors.from}
            />
            <TextField
              type={"date"}
              name="to"
              label="To"
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              value={formik.values.to}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.to) && Boolean(formik.touched.to)}
              helperText={Boolean(formik.errors.to) && formik.errors.to}
            />
            {status === "loading" ? (
              <CircularProgress />
            ) : (
              <Button type="submit" sx={{ padding: "5px 10px" }} variant="contained">
                Get
              </Button>
            )}
          </Box>
        </Box>
      </form>
      <Grid
        item
        md={12}
        sm={12}
        xs={12}
        overflow
        sx={{ height: tabelStyle.height, padding: "10px" }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[15, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          pagination
          components={{
            Toolbar: CustomToolbar,
          }}
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />
      </Grid>
    </>
  );
}

export default PortalSummaryDetailTable;
PortalSummaryDetailTable.propTypes = {
  setShowForm: PropTypes.any,
  setrowvalue: PropTypes.any,
  setDateRange: PropTypes.any,
  dateRange: PropTypes.any,
};
