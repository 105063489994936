import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  gridClasses,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import {
  fetchMonthalyDetailed,
  getLateArrivalReportDetails,
} from "../../Redux/Slice/LateArrivalReport/LateArrivalReportSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { makeStyles } from "@mui/styles";
import Cookies from "universal-cookie";
// import { ReasonLateArrivalReportApi } from "../../Api/LateArrivalReport/ReasonLateArrivalReportApi";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import SearchIcon from "@mui/icons-material/Search";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
import { AttendanceJustification } from "../../Api/EarlyDepartureReport/ReasonEarlyDepartureReportApi";
import EmployeeEaryDepartureReport from "../EmployeeEarlyDepartureReport/EmployeeEaryDepartureReport";
import EmployeeOvertimeReport from "../EmployeeOvertimeReport/EmployeeOvertimeReport";
import { useMediaQuery } from "@mui/material";
// import AddCardIcon from '@mui/icons-material/AddCard';
function CustomToolbar() {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport csvOptions={{ fileName: "LateArrivalReport" }} />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  );
}
const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});
function EmployeeLateArrivalReport() {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const { header } = useStyles();
  const [pageSize, setPageSize] = useState(15);
  const [openReason, setOpenReason] = useState(false);
  const [reason, setReason] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const dispatch = useDispatch();
  const [months, setmonths] = useState(moment().format("YYYY-MM"));
  const { MonthlyDetailed, status, IsLogin, message } = useSelector(
    getLateArrivalReportDetails
  );
  const [monthlyDetailed, setmonthlyDetailed] = useState([]);
  const [rows, setrows] = useState([]);
  const Date = useRef();
  const [selectedTab, setSelectedTab] = useState(0);
  // const [commentVal, setcommentVal] = useState([])
  // const [isEdit, setisEdit] = useState(true);
  const [loading, setloading] = useState(false);
  const cookies = new Cookies();
  const { setsnackBarData } = useContext(SnackBarContext);
  console.log(rows);
  // console.log(commentVal)
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  useEffect(() => {
    let arr = [];
    MonthlyDetailed &&
      MonthlyDetailed?.forEach((item, idx) => {
        console.log(item);
        return arr.push({
          ...item,
          id: idx + 1,
          sr: idx + 1,
          date: item?.date ? moment(item?.date).format("DD-MMM-YYYY") : "",
          time: item?.time ? moment(item?.time, "HH:mm").format("LT") : "",
          late_time: item?.late_time
            ? parseInt(item?.late_time.split(":")[0]) === 0 &&
              parseInt(item?.late_time.split(":")[1]) === 0
              ? 0
              : `${item?.late_time.split(":")[0]} hrs ${item?.late_time.split(":")[1]
              } min `
            : "",
          attendance_id: item?.attendance_id,
        });
      });

    setrows(arr);
    setmonthlyDetailed(arr);
  }, [MonthlyDetailed]);

  useEffect(() => {
    if (months) {
      if (cookies.get("user")?.type === "Employee") {
        dispatch(
          fetchMonthalyDetailed({
            emp_id: cookies.get("user")?.Emp_id,
            months: months,
          })
        );
      }
    }
  }, [dispatch]);

  // const handleChange = (e, cellval) => {
  //   console.log(e);
  //   console.log(cellval);
  //   monthlyDetailed[cellval.row.id - 1].reason = e.target.value;
  //   monthlyDetailed[cellval.row.id - 1].edit = true;
  //   setisEdit(false);
  // };
  console.log(monthlyDetailed);
  const columns = [
    { field: "sr", headerName: "Sr #", width: 100, headerClassName: header },
    {
      field: "date",
      headerName: "Date",
      width: 200,
      headerClassName: header,
    },
    {
      field: "time",
      headerName: "Check In",
      width: 200,
      headerClassName: header,
    },
    {
      field: "late_time",
      headerName: "Late Time",
      width: 150,
      headerClassName: header,
    },
    {
      field: "comments",
      headerName: "Comment",
      width: 200,
      headerClassName: header,
      renderCell: (cellval) => {
        console.log(cellval?.row);
        return cellval?.row?.reason ? (
          <Tooltip
            title={cellval?.row?.reason?.reason || ""}
            arrow
          >
            <Typography
              sx={{
                maxWidth: 200,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "normal",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
              }}
            >
              Reason: {cellval?.row?.reason?.reason}
            </Typography>
          </Tooltip>
        ) : (
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              width: "100%",
              alignItems: "center",
            }}
          >
            {/* <TextField
              name="coment"
              value={cellval?.row?.comments}
              fullWidth
              variant="outlined"
              size="small"
              onChange={(e) => {
                handleChange(e, cellval);
              }} */}
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                setSelectedDay(cellval.row);
                setOpenReason(true);
              }}
            >
              Add Comment
            </Button>
          </Box>
        );
      },
    },
  ];
  const handleSubmit = async () => {
    if (months === "") {
      return setsnackBarData({
        openToast: true,
        message: "please select date",
        type: "error",
      });
    }
    dispatch(
      fetchMonthalyDetailed({
        emp_id: cookies.get("user")?.Emp_id,
        months: months,
      })
    );
  };
  const handleComment = async () => {
    setloading(true);

    const response = await AttendanceJustification({
      attendance_id: selectedDay?.attendance_id,
      type: "Late Arrival",
      reason: reason,
    });

    setsnackBarData(response.snackBarData);

    if (response.data.IsSuccess) {
      setOpenReason(false);
      setReason(null);
      setSelectedDay(null);
      dispatch(
        fetchMonthalyDetailed({
          emp_id: cookies.get("user")?.Emp_id,
          months: months,
        })
      );
    }

    setloading(false);
  };

  const onTextChange = () => {
    if (Date.current.value === "") {
      setmonthlyDetailed(rows);
    } else {
      let suggestions = [];
      let regex2 = new RegExp(
        moment(Date.current.value).format("DD-MMM-YYYY"),
        "i"
      );

      suggestions = rows?.filter(
        (val) => Date.current?.value === "" || regex2.test(val?.date)
      );
      setmonthlyDetailed(suggestions);
    }
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  return (
    <>
      <Box sx={{ width: "100%", padding: "10px" }}>
        {/* Tabs for switching between reports */}
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            aria-label="report-tabs"
            variant="fullWidth"
          >
            <Tab
              label="Late Arrival Report"
              {...a11yProps(0)}
            // sx={{ fontSize: { xs: "11px", sm: "12px", md: "14px" } }}
            />
            <Tab
              label="Early Departure Report"
              {...a11yProps(1)}
            // sx={{ fontSize: { xs: "11px", sm: "12px", md: "14px" } }}
            />
            <Tab
              label="Overtime Report"
              {...a11yProps(2)}
            // sx={{ fontSize: { xs: "11px", sm: "12px", md: "14px" } }}
            />
          </Tabs>
        </Box>

        {/* Default content is Late Arrival Report, changes on tab click */}
        <Box sx={{ marginTop: 2 }}>
          {selectedTab === 0 && (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    paddingBottom: "10px",
                    width: "100%",
                    padding: "10px 10px 0px 10px",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    type={"month"}
                    name="months"
                    // variant="outlined"
                    size="small"
                    value={months}
                    onChange={(e) => {
                      setmonths(e.target.value);
                    }}
                  />

                  <Button
                    onClick={handleSubmit}
                    sx={{ padding: "5px 10px" }}
                    variant="contained"
                  >
                    Get
                  </Button>

                  {/* )} */}
                </Box>
                <Box sx={{ padding: "10px 10px 0px 0px" }}>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon
                            sx={{ color: (theme) => theme.palette.grey.G2 }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                    type="date"
                    autoComplete="off"
                    placeholder="Search"
                    // className="input_border"
                    inputRef={Date}
                    id="date"
                    onChange={onTextChange}
                  />
                </Box>
              </Box>
              {status === "loading" ? (
                <Box
                  sx={{
                    height: "calc(100vh - 224px)",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <div
                  style={{
                    height: "calc(100vh - 220px)",
                    width: "100%",
                    padding: "10px",
                  }}
                >
                  <DataGrid
                    rows={monthlyDetailed}
                    columns={columns}
                    onRowClick={() => { }}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[
                      15, 20, 30, 40, 50, 60, 70, 80, 90, 100,
                    ]}
                    pagination
                    // components={{
                    //   Toolbar: CustomToolbar,
                    // }}
                    components={isSmallScreen ? {} : { Toolbar: CustomToolbar }}
                    localeText={{
                      toolbarDensity: "Size",
                      toolbarDensityLabel: "Size",
                      toolbarDensityCompact: "Small",
                      toolbarDensityStandard: "Medium",
                      toolbarDensityComfortable: "Large",
                    }}
                  />
                </div>
              )}
            </>
          )}
          {selectedTab === 1 && <EmployeeEaryDepartureReport />}
          {selectedTab === 2 && <EmployeeOvertimeReport />}
        </Box>
      </Box>

      <Dialog
        open={openReason}
        onClose={() => {
          setReason(null);
          setOpenReason(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          Add Comment for the late Arrival
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="dense"
            variant="outlined"
            label="Comment"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              sx={{
                background: "red",
                color: "white !important",
                fontSize: "13px",
                textTransform: "none",
              }}
              onClick={() => {
                setReason(null);
                setOpenReason(false);
              }}
            >
              No
            </Button>

            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                sx={{
                  background: "#3480FA",
                  color: "white !important",
                  fontSize: "13px",
                }}
                onClick={() => {
                  if (reason === null || reason === "") {
                    setsnackBarData({
                      openToast: true,
                      message: "Enter Comment first",
                      type: "error",
                    });
                  } else {
                    handleComment();
                  }
                }}
              >
                Confirm
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EmployeeLateArrivalReport;
