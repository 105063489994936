import Cookies from "universal-cookie";
import axiosInstance from "../../Utils/AxiosInstance";
const cookies = new Cookies();
export const UpdatePassword = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.patch("changeemppass", data);
    localStorage.setItem(
      "roles",
      response.data.Data?.roletype
        ? JSON.stringify(response.data.Data?.roletype)
        : JSON.stringify([])
    );
    cookies.set("access_token", response.data.acces_token, {
      path: "/",
    });
    cookies.set("user", response.data.Data, {
      path: "/",
    });
    cookies.set("chatUserId", response.data.Data.chatUserId, {
      path: "/",
    });
    cookies.set("isChat", response.data.Data.isChat, {
      path: "/",
    });
    snackBarData = {
      type: response?.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return { ...response, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response.data.Message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
