import PropTypes from "prop-types";
import {
  Box,
  // Card,
  // CardContent,
  // Button,
  // CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  gridClasses,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  // Typography,
} from "@mui/material";
import theme from "../../theme";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

import {
  fetchDesignation,
  getDesignationDetails,
  deleteDesignation,
} from "../../Redux/Slice/Designation/DesignationSlice";
import DeleteModal from "../DeleteModal/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
import { DeleteDesignation } from "../../Api/Designation/DesignationAPis";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "../Button/AddNewButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";
import Loader from "../Loader/Loader";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});
function DesignationTable({
  setShowForm,
  EditedData,
  setIsEdit,
  setEditedData,
}) {
  const { tabelStyle } = useContext(TabelStyleContext);
  const { header } = useStyles();
  const dispatch = useDispatch();
  const [rows, setrows] = useState([]);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [filterRows, setfilterRows] = useState([]);
  const [loading, setloading] = useState(false);
  const [pageSize, setPageSize] = useState(15);
  const { setsnackBarData } = useContext(SnackBarContext);
  const navigate = useNavigate();
  const { designation, status } = useSelector(getDesignationDetails);
  const { IsLogin, message } = useSelector(getDesignationDetails);

  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [editPermit, seteditpermit] = useState(false);
  const [addPermit, setaddpermit] = useState(false);
  const [deletePermit, setdeletepermit] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  console.log(selectedItem);

  console.log(roles);
  useEffect(() => {
    let string = "Designations";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Edit") {
        seteditpermit(true);
      } else if (val?.module?.split("_")[1] === "Add") {
        setaddpermit(true);
      } else if (val?.module?.split("_")[1] === "Delete") {
        setdeletepermit(true);
      }
    });
    if (!roles || roles.length < 1) {
      setaddpermit(true);
      seteditpermit(true);
      setdeletepermit(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  console.log(editPermit);
  console.log(addPermit);
  console.log(deletePermit);

  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  useEffect(() => {
    dispatch(fetchDesignation());
  }, [dispatch]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport csvOptions={{ fileName: "Designation" }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }
  const columns = [
    { field: "sr", headerName: "Sr #", width: 100, headerClassName: header },
    {
      field: "designation_name",
      headerName: "Designation",
      width: 200,
      headerClassName: header,
    },
    // {
    //   field: "responsibilties",
    //   headerName: "Responsibilties",
    //   width: 230,
    //   headerClassName: header,
    // },
    {
      field: "resName",
      headerName: "Responsibilties",
      width: 280,
      headerClassName: header,
      renderCell: (celVal) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              gap: "10px",
            }}
          // onClick={() => {
          //   setSelectedItem(celVal.row);
          //   setOpenDialog(true);
          // }}
          >
            <span >
              {
                celVal?.value
                // celVal.row?.resp_id?.toString()
              }
            </span>
          </Box>
        );
      },
    },
    {
      field: "casual_leaves",
      headerName: "Casual Leaves",
      width: 180,
      headerClassName: header,
    },
    {
      field: "annual_leaves",
      headerName: "Annual Leaves",
      width: 180,
      headerClassName: header,
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 180,
      headerClassName: header,
      renderCell: (celVal) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              gap: "10px",
            }}
          >
            {editPermit ? (
              <IconButton
                size={"small"}
                onClick={() => {
                  setEditedData(celVal.row);
                  setIsEdit(true);
                  setShowForm(true);
                }}
              >
                {" "}
                <AppRegistrationIcon
                  sx={{ cursor: "pointer", color: "black !important" }}
                />
              </IconButton>
            ) : null}
            {deletePermit ? (
              <IconButton
                onClick={() => {
                  setEditedData(celVal.row);
                  setshowDeleteModal(true);
                }}
                size={"small"}
              >
                <DeleteOutlineIcon sx={{ color: theme.palette.error.main }} />
              </IconButton>
            ) : null}
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    let arr = [];
    if (designation) {
      designation?.forEach((item, idx) => {
        let respon = item?.resp_id?.map((res) => {
          return res?.responsibilty;
        });
        console.log(item);
        return arr.push({
          ...item,
          sr: idx + 1,
          id: item?.designation_id,
          resName: respon?.toString("."),
          desArr: JSON.stringify(respon),
        });
      });
    }
    setrows(arr);
    setfilterRows(arr);
  }, [designation]);

  const onTextChange = () => {
    if (document.getElementById("OrderNoFilter").value === "") {
      setfilterRows(rows);
    } else {
      let suggestions = [];

      var regex1 = new RegExp(
        document.getElementById("OrderNoFilter").value,
        "i"
      );

      suggestions = rows?.filter(
        (val) =>
          document.getElementById("OrderNoFilter").value === "" ||
          regex1.test(val.designation_name)
      );

      setfilterRows(suggestions);
    }
  };

  const handelDelete = async () => {
    setloading(true);
    const response = await DeleteDesignation(EditedData?.designation_id);
    setsnackBarData(response.snackBarData);
    setloading(false);
    setshowDeleteModal(false);
    if (response.data?.IsSuccess) {
      dispatch(deleteDesignation(EditedData?.designation_id));
    }
  };
  useEffect(() => {
    if (status === "failed") {
      setsnackBarData({
        type: "error",
        message: "Network Error",
        openToast: true,
      });
    }
  }, [status]);
  return (
    <Box spacing={2} sx={{ padding: "10px 0px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            padding: "10px",
          }}
        >
          {addPermit ? (
            <>
              {/* <Button
                sx={{ padding: "5px 10px" }}
                onClick={() => {
                  setShowForm(true);
                }}
                variant="contained"
                className="tabletopbutton1 text-right"
              >
                New
              </Button> */}
              <CustomButton
                Color={"primary"}
                Icon={<AddIcon />}
                onClick={() => {
                  setShowForm(true);
                }}
                Label={"Add"}
              />
            </>
          ) : null}
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{ color: (theme) => theme.palette.grey.G2 }}
                  />
                </InputAdornment>
              ),
            }}
            sx={{
              width: "100%",
              maxWidth: "220px",
            }}
            variant="standard"
            type="text"
            autoComplete="off"
            placeholder="Designation"
            className="input_border"
            id="OrderNoFilter"
            onChange={onTextChange}
          />
        </Box>
      </Box>

      <Box sx={{ height: tabelStyle.height, padding: "10px" }}>
        {designation?.length <= 0 && status === "loading" ? <Loader /> : null}
        <DataGrid
          rows={filterRows}
          columns={columns}
          sx={{ cursor: "pointer" }}
          onRowClick={(e) => {
            setSelectedItem(e.row);
            setOpenDialog(true);
          }}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[15, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          pagination
          components={{
            Toolbar: CustomToolbar,
          }}
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />
      </Box>
      <DeleteModal
        name={`"${EditedData?.designation_name}" Designation`}
        handelDelete={handelDelete}
        showModal={showDeleteModal}
        setshowModal={setshowDeleteModal}
        loading={loading}
      />

      {selectedItem && (
        <Dialog open={openDialog} maxWidth="sm" fullWidth>
          <DialogTitle>Designation Detail</DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                // justifyContent:"space-between",
                gap: "10px",
                overflow: "scroll",
              }}
            >
              <Box sx={{ display: "flex", gap: "20px", width: "100%", maxWidth: "300px" }}>
                <Typography sx={{ fontWeight: "bold" }}>Designation:</Typography>
                <Typography >
                  {selectedItem?.designation_name}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "20px", }}>
                <Typography sx={{ fontWeight: "bold" }}>Casual Leave:</Typography>
                <Typography >
                  {selectedItem?.casual_leaves}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "20px", width: "100%", maxWidth: "300px" }}>
                <Typography sx={{ fontWeight: "bold" }}>Annual Leave:</Typography>
                <Typography >
                  {selectedItem?.annual_leaves}
                </Typography>
              </Box>
            </Box>
            <TableContainer>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bolder" }}>
                      Responsibilities List
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedItem &&
                    JSON.parse(selectedItem?.desArr)?.map(
                      (expertise, index) => (
                        <TableRow key={index}>
                          <TableCell>{expertise.trim()}</TableCell>
                        </TableRow>
                      )
                    )}
                </TableBody>
                {/* <TableBody>
                  {selectedItem && (
                    <TableRow>
                      <TableCell>{selectedItem.resName}</TableCell>
                    </TableRow>
                  )}
                </TableBody> */}
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <IconButton
              sx={{ position: "absolute", top: "0px", right: "0px" }}
              onClick={() => setOpenDialog(false)}
            >
              <CancelIcon color="error" sx={{ fontSize: "25px" }} />
            </IconButton>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}

export default DesignationTable;
DesignationTable.propTypes = {
  setShowForm: PropTypes.any,
  setIsEdit: PropTypes.func,
  setEditedData: PropTypes.func,
  EditedData: PropTypes.any,
};
