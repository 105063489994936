import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@mui/styles";
import axios from "axios";
// import {
//   CircularProgress,
// } from "@mui/material";
import { useLocation } from "react-router-dom";
import { FaFileAlt } from "react-icons/fa";
import { FaRegFilePdf } from "react-icons/fa";
import { RiRadioButtonLine } from "react-icons/ri";
import { AiOutlineUserAdd, AiOutlineUsergroupAdd } from "react-icons/ai";
import { MdOutlineSearch } from "react-icons/md";
import { FaRegFileWord } from "react-icons/fa";
import { FaRegFileExcel } from "react-icons/fa";
import { FiPhoneOutgoing } from "react-icons/fi";
import { FiPhoneIncoming } from "react-icons/fi";
import { FiPhoneMissed } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import { format, addSeconds, parseISO } from "date-fns";
import { IoIosArrowDown } from "react-icons/io";
import Loader from "../../Components/Loader/Loader";
// import theme from "../../../theme";
import { RiCloseLine } from "react-icons/ri";
import MenuIcon from "@mui/icons-material/Menu";

const useStyles = makeStyles(() => {
  return {
    // container: {
    //   marginTop: "0px",
    //   display: "flex",
    //   height: "90vh",
    // },
    // sidebar: {
    //   width: "20%",
    //   minWidth: "300px",
    //   background: "white",
    //   // background: theme?.palette?.primary?.edit,
    //   color: "#383838",
    //   display: "flex",
    //   flexDirection: "column",
    //   // padding: "20px",
    //   borderRight: "1px solid #e1e1e1",
    // },
    container: {
      marginTop: "0px",
      display: "flex",
      height: "90vh",
      "@media (max-width: 425px)": {
        flexDirection: "column",
      },
    },
    sidebar: {
      width: "18%",
      minWidth: "300px",
      background: "white",
      color: "#383838",
      display: "flex",
      flexDirection: "column",
      borderRight: "1px solid #e1e1e1",
      "@media (max-width: 425px)": {
        position: "absolute",
        top: 57,
        // width:"100%",
        left: 0,
        height: "100%",
        width: "100%",
        transform: "translateX(-100%)",
        transition: "transform 0.3s ease-in-out",
      },
    },

    tabs: {
      paddingTop: "15px",
      paddingBottom: "5px",

      display: "flex",
      justifyContent: "space-around",
      marginBottom: "12px",
      cursor: "pointer",
      color: "black",
    },
    search: {
      padding: "10px",
      marginBottom: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center", // Ensures vertical alignment
      border: "2px solid #e1e1e1",
      borderRadius: "5px",
      width: "90%",
      fontSize: "14px",
      color: "black",
      margin: "auto",
    },
    activeTab: {
      borderBottom: "5px solid #53b7c5",
      color: "#53b7c5",
    },
    userList: {
      flex: 1,
      overflowY: "auto",
    },
    userItem: {
      display: "flex",
      alignItems: "center",
      padding: "15px",
      borderBottom: "1px solid #e1e1e1",
      cursor: "pointer",
      "&:hover": {
        background: "#53b7c5",
        color: "white",
      },
      "&.active": {
        background: "#53b7c5",
        color: "white",
      },
    },
    profileImg: {
      width: "40px",
      height: "40px",
      borderRadius: "50%",
      marginRight: "10px",
    },

    sidebarVisible: {
      "@media (max-width: 425px)": {
        transform: "translateX(0)",
      },
    },
    menuButton: {
      display: "none",
      "@media (max-width: 425px)": {
        display: "block",
        zIndex: 1000,
      },
    },
    menuButton2: {
      display: "none",
      "@media (max-width: 425px)": {
        display: "block",
        position: "absolute",
        top: 28,
        right: 20,
        zIndex: 1000,
      },
    },
    chatArea: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      background: "white",
    },
    // chatArea: {
    //   flex: 1,
    //   display: "flex",
    //   flexDirection: "column",
    //   background: "white",
    // },
    chatHeader: {
      display: "flex",
      alignItems: "center",
      padding: "15px",
      justifyContent: "space-between",
      background: "white",
      borderBottom: "1px solid #e1e1e1",
    },
    chatHeaderImg: {
      width: "50px",
      height: "50px",
      borderRadius: "50%",
      marginRight: "15px",
    },
    chatmsgs: {
      //   flex: 1,
      padding: "20px",
      overflowY: "auto",
    },
    msg: {
      marginBottom: "15px",
    },
    msgProfile: {
      display: "flex",
    },
    userName: {
      fontWeight: "bold",
      marginTop: "2px",
      paddingBottom: "5px",
    },
    msgBubble: {
      //   display: "inline-block",
      padding: "10px 15px",
      borderRadius: "15px",
      maxWidth: "70%",
      // background: "#e1e1e1",
      // background: "#f7fafc",
    },
  };
});

const EmployeeChat = () => {
  const classes = useStyles();
  const location = useLocation();
  const userId = location.state;
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [userList, setUserList] = useState("");
  const [groupList, setGroupList] = useState("");
  const [UsersChat, setUsersChat] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [activeTab, setActiveTab] = useState("user");
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const messageRefs = useRef({});
  const [openSearch, setSearchOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [loading, setLoading] = useState(false);
  // Functions to toggle sidebar visibility
  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };
  const formatDuration = (seconds) => {
    const durationDate = addSeconds(new Date(0), seconds);
    return format(durationDate, "mm:ss");
  };
  const encodeId = (id) => (id ? btoa(id) : "");
  const chatUrl = process.env.REACT_APP_CHAT_URL;

  const UserId = encodeId(userId);
  const BusinessId = encodeId(process.env.REACT_APP_BUSINESS_ID);
  const queryString = `businessId=${BusinessId}&_id=${UserId}`;

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);

      try {
        const response = await axios.get(
          `${chatUrl}getAllUserByQuery?${queryString}`
        );
        const response2 = await axios.get(
          `${chatUrl}getUserById?id=${UserId}`
        );

        setGroupList(response2?.data);

        setUserList(response?.data);
        setLoading(false);

      } catch (error) {
        console.error("Error fetching users:", error);
        setLoading(false);

      }
    };

    const fetchgroups = async () => {
      setLoading(true);

      try {

        const response = await axios.get(
          `${chatUrl}getUserById?id=${UserId}`
        );

        setGroupList(response?.data);
        setLoading(false);

      } catch (error) {
        console.error("Error fetching users:", error);
        setLoading(false);

      }
    };
    if (activeTab === "user") {
      fetchUsers();
    } else {
      fetchgroups();
    }
  }, [activeTab]);

  useEffect(() => {
    const fetchUsersChat = async () => {
      setLoading(true);

      try {
        const selectedUserId = encodeId(selectedUser._id);
        const response = await axios.get(
          `${chatUrl}getChatsBetweenUsers?userId=${UserId}&otherUserId=${selectedUserId}`
        );
        setUsersChat(response?.data);
        setLoading(false);

      } catch (error) {
        console.error("Error fetching users:", error);
        setLoading(false);

      }
    };
    const fetchGroupChat = async () => {
      setLoading(true);

      try {
        const selectedGroupId = encodeId(selectedGroup._id);
        const response = await axios.get(
          `${chatUrl}getMessagesByGroupId?groupId=${selectedGroupId}`
        );
        setUsersChat(response?.data);
        setLoading(false);

      } catch (error) {
        console.error("Error fetching users:", error);
        setLoading(false);

      }
    };
    if (activeTab === "user" && selectedUser) {
      fetchUsersChat();
      setSelectedGroup("");
    } else if (activeTab === "group" && selectedGroup) {
      fetchGroupChat();
      setSelectedUser("");
    }
  }, [selectedUser, selectedGroup]);

  useEffect(() => {
    if (activeTab === "user") {
      setSelectedGroup("");
      setSelectedUser(userList?.length > 0 ? userList[0] : "");
    } else {
      setSelectedUser("");
      setSelectedGroup(
        groupList?.user?.groupsIds?.length > 0 ? groupList.user.groupsIds[0]?.groupId : ""
      );
      // setSelectedGroup(groupList?.user?.groupsIds[0].groupId || "");
    }
  }, [activeTab, userList, groupList]);

  let filteredUsers;
  if (activeTab === "user") {
    filteredUsers =
      (userList &&
        userList?.filter((user) =>
          user.name.toLowerCase().includes(searchValue.toLowerCase())
        )) ||
      [];
  } else if (activeTab === "group") {
    filteredUsers =
      (groupList &&
        groupList?.user?.groupsIds.filter((group) =>
          group?.groupId?.name.toLowerCase().includes(searchValue.toLowerCase())
        )) ||
      [];
  }
  const scrollToMessage = (messageId) => {
    if (messageRefs.current[messageId]) {
      messageRefs.current[messageId]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else {
      console.log(`No ref found for message ID: ${messageId}`);
    }
  };
  const highlightMessage = (id) => {
    if (messageRefs.current[id]) {
      const element = messageRefs.current[id];
      element.classList.add("bg-gray-200");

      setTimeout(() => {
        element.classList.remove("bg-gray-200");
      }, 1000);
    }
  };
  const filteredMessages = UsersChat
    ? UsersChat?.filter((message) =>
      message.text.toLowerCase().includes(searchText.toLowerCase())
    )
    : [];
  const handleArrowClick = (direction) => {
    if (searchText !== "") {
      if (filteredMessages.length === 0) return;
      let newIndex = currentIndex;
      if (direction === "down") {
        newIndex = (currentIndex + 1) % filteredMessages.length;
      } else if (direction === "up") {
        newIndex = (currentIndex - 1 + filteredMessages.length) % filteredMessages.length;
      }
      setCurrentIndex(newIndex);
      const nextMessageId = filteredMessages[newIndex]?._id;
      if (nextMessageId) {
        scrollToMessage(nextMessageId);
        highlightMessage(nextMessageId);
      }
    }
  };
  return (
    <div className={classes.container}>
      {/* Sidebar */}
      <div
        className={`${classes.sidebar} ${isSidebarVisible ? classes.sidebarVisible : ""}`}
      >
        {/* <div className={classes.sidebar}> */}
        <div className={classes.chatHeader}>
          <div className="flex items-center gap-3">
            <img
              src={groupList?.user?.profileImg}
              alt={selectedUser.name}
              className={classes.chatHeaderImg}
            />
            <h2>{groupList?.user?.name}</h2>
          </div>
          <button className={classes.menuButton2} onClick={toggleSidebar}>
            {isSidebarVisible && <RiCloseLine size={24} />}
          </button>
        </div>
        <div className={classes.tabs}>
          <h2
            onClick={() => setActiveTab("user")}
            className={activeTab === "user" ? classes.activeTab : ""}
          >
            Members
          </h2>
          <h2
            onClick={() => setActiveTab("group")}
            className={activeTab === "group" ? classes.activeTab : ""}
          >
            Groups
          </h2>
        </div>
        <input
          type="text"
          placeholder="Search here..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          className={classes.search}
        />
        <div className={classes.userList}>
          {activeTab === "user"
            ? filteredUsers &&
            filteredUsers.map((user) => (
              <div
                key={user._id}
                className={`${classes.userItem} ${selectedUser?._id === user._id ? "active" : ""
                  }`}
                onClick={() => setSelectedUser(user)}
              >
                <div className="relative">
                  <img
                    src={user.profileImg}
                    alt={user.name}
                    className={classes.profileImg}
                  />
                  {user?.online && (
                    <div className="absolute bottom-0 right-2 bg-white rounded-full">
                      <RiRadioButtonLine size={12} color="green" />
                    </div>
                  )}
                </div>
                <span>{user.name}</span>
              </div>
            ))
            : filteredUsers &&
            filteredUsers?.map((group) => (
              <div
                key={group._id}
                className={`${classes.userItem} ${selectedGroup?._id === group?.groupId?._id ? "active" : ""
                  }`}
                onClick={() => setSelectedGroup(group?.groupId)}
              >
                <img
                  src={group?.groupId?.profileImg}
                  alt={group?.groupId?.name}
                  className={classes.profileImg}
                />
                <span>{group?.groupId?.name}</span>
              </div>
            ))}
        </div>
      </div>

      {/* Chat Area */}
      {!isSidebarVisible && (
        <div className={classes.chatArea}>
          {selectedUser || selectedGroup ? (
            <>
              {/* Header */}

              <div className={classes.chatHeader}>
                <div className="flex items-center gap-3">
                  <img
                    src={
                      selectedUser ? selectedUser.profileImg : selectedGroup.profileImg
                    }
                    alt={selectedUser ? selectedUser.name : selectedGroup.name}
                    className={classes.chatHeaderImg}
                  />
                  <h2>{selectedUser ? selectedUser.name : selectedGroup.name}</h2>
                </div>
                <div className="flex items-center gap-3">
                  <div className="hover:bg-slate-200 rounded-full p-1">
                    {openSearch ? (
                      <RxCross2 size={20} onClick={() => setSearchOpen(false)} />
                    ) : (
                      <MdOutlineSearch size={20} onClick={() => setSearchOpen(true)} />
                    )}
                  </div>
                  <button className={classes.menuButton} onClick={toggleSidebar}>
                    {!isSidebarVisible && <MenuIcon size={24} />}
                  </button>
                </div>
              </div>
              <div className="relative">
                {openSearch && (
                  <>
                    <input
                      type="text"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      placeholder="Search"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          console.log("Enter Press");
                        }
                      }}
                      className="absolute top-full left-0 w-full pl-2 pr-10 p-2 border rounded bg-white shadow-lg focus:outline-none"
                    />
                    <IoIosArrowDown
                      className="absolute right-16 top-2.5 text-gray-500 hover:text-gray-800 cursor-pointer transition-all duration-300 ease-in-out"
                      size={20}
                      style={{ transform: "rotate(-180deg)" }}
                      onClick={() => handleArrowClick("up")}
                    />
                    <IoIosArrowDown
                      className="absolute right-9 top-2.5 text-gray-500 hover:text-gray-800 cursor-pointer transition-all duration-300 ease-in-out"
                      size={20}
                      onClick={() => handleArrowClick("down")}
                    />
                    {searchText ? (
                      <RxCross2
                        className="absolute right-2 top-2.5 text-gray-500 hover:text-gray-800 cursor-pointer transition-all duration-300 ease-in-out"
                        size={20}
                        onClick={() => setSearchText("")}
                      />
                    ) : (
                      <MdOutlineSearch
                        className="absolute right-2 top-2.5 text-gray-500 hover:text-gray-800 cursor-pointer transition-all duration-300 ease-in-out"
                        size={20}
                      />
                    )}
                  </>
                )}
              </div>
              {/* msgs */}
              {
                loading &&
                <Loader />
              }
              <div className={classes.chatmsgs}>
                {UsersChat &&
                  UsersChat?.map((msg) => (
                    <div
                      ref={(el) => (messageRefs.current[msg._id] = el)}
                      key={msg._id}
                      className={classes.msg}
                    >
                      <div className={classes.msgBubble}>
                        <div className={classes.msgProfile}>
                          <img
                            src={msg?.senderId?.profileImg}
                            alt={msg?.senderId?.name}
                            className={classes.chatHeaderImg}
                          />
                          <div className="">
                            <h2 className={`${classes.userName} text-left`}>
                              {msg?.senderId?.name}
                              <span style={{ fontWeight: "600", marginLeft: "10px" }}>
                                .
                              </span>
                              <span
                                style={{
                                  fontSize: "0.875rem",
                                  color: "rgba(96, 97, 97, 0.61)",
                                  marginLeft: "8px",
                                }}
                              >
                                {format(parseISO(msg.createdAt), "h:mm a")}
                              </span>
                            </h2>
                            {msg.type === "image" ? (
                              <div className="text-left">
                                <a href={msg.url} target="blank">
                                  <img className="w-44 h-42" src={msg.url} alt="Image" />
                                </a>
                              </div>
                            ) : msg.type === "file" ? (
                              <div className="text-left w-fit">
                                <a
                                  href={msg.url}
                                  target="blank"
                                  className="flex items-center"
                                >
                                  {msg.fileType == "pdf" && (
                                    <FaRegFilePdf size={40} color="F40000" />
                                  )}
                                  {msg.fileType == "doc" && (
                                    <FaRegFileWord size={40} color="#0d47a1" />
                                  )}
                                  {msg.fileType == "xls" && (
                                    <FaRegFileExcel size={40} color="#216f44" />
                                  )}
                                  {msg.fileType == "Unknown File Type" && (
                                    <FaFileAlt size={40} color="grey" />
                                  )}
                                  <p className="text-sm text-gray-500 font-semibold text-center mt-1">
                                    {msg.key}
                                  </p>
                                </a>
                              </div>
                            ) : msg.type === "voice" ? (
                              <div>
                                <audio className="" src={msg.url} controls />
                              </div>
                            ) : msg.type == "Call" ? (
                              <div className="flex items-center p-4 bg-gray-100 border border-gray-400 rounded-lg shadow-sm">
                                <div className="flex-shrink-0">
                                  {msg.senderId._id == userId ? (
                                    <FiPhoneOutgoing className="text-gray-500 w-6 h-6" />
                                  ) : (
                                    <FiPhoneIncoming className="text-gray-500 w-6 h-6" />
                                  )}
                                </div>
                                <div className="ml-4">
                                  <p className="font-semibold">Voice Call</p>
                                  <p className="text-gray-700">
                                    {formatDuration(msg.count)}
                                  </p>
                                </div>
                              </div>
                            ) : msg.type == "Miss Call" ? (
                              <div>
                                <div className="flex items-center p-4 bg-red-100 border border-red-400 rounded-lg shadow-sm">
                                  <div className="flex-shrink-0">
                                    <FiPhoneMissed className="text-red-500 w-6 h-6" />
                                  </div>
                                  <div className="ml-4">
                                    <p className="text-red-500 font-semibold">
                                      Missed Call
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="text-sm text-left"
                                style={{
                                  whiteSpace: "pre-wrap",
                                  wordBreak: "break-word",
                                  overflowWrap: "break-word",
                                }}
                              >
                                {msg.text}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          ) : (
            <div className=" text-center ">
              {activeTab === "user" ? (
                <div
                  className="flex flex-col items-center justify-center mt-10"
                  style={{ marginTop: "30px" }}
                >
                  <AiOutlineUserAdd size={32} />

                  <p>No user found</p>
                </div>
              ) : (
                <div
                  className="flex flex-col items-center justify-center mt-10"
                  style={{ marginTop: "30px" }}
                >
                  <AiOutlineUsergroupAdd size={32} />

                  <p>No Group found</p>
                </div>
              )}
            </div>
          )}
        </div>
      )}

    </div>

  );
};

export default EmployeeChat;
