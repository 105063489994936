import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  // CircularProgress,
  Grid,
  gridClasses,
  // MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { Navigate, useNavigate } from "react-router-dom";
import {
  ChangeReqEmpPopulate,
  getChangerequestDetails,
  MonthEmpChangeRequest,
} from "../../Redux/Slice/ChangeRequest/ChangeRequestSlice";
import Cookies from "universal-cookie";
import Loader from "../Loader/Loader";
import { useMediaQuery } from "@mui/material";
const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
    compCircle: {
      width: "100%",
      maxWidth: "20px",
      height: "20px",
      borderRadius: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
});
function MonthlyChangeReq() {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const { tabelStyle } = useContext(TabelStyleContext);
  const { header, compCircle } = useStyles();
  const cookies = new Cookies();
  const [rows, setrows] = useState([]);
  const [empmonthChangeReq, setempmonthChangeReq] = useState([]);
  const [pageSize, setPageSize] = useState(50);
  const [empId, setempId] = useState(null);
  const [month, setmonth] = useState(moment().format("YYYY-MM"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setsnackBarData } = useContext(SnackBarContext);
  const { Employee, EmpMonthlyChangeRequest, status, IsLogin, message } = useSelector(
    getChangerequestDetails
  );
  console.log(cookies.get("user")?.Emp_id);
  console.log(rows);
  const [canView, setcanView] = useState(null);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  useEffect(() => {
    let string = "MonthlyChangeRequest";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    let match = value?.some((val) => {
      if (val?.module?.split("_")[1] === "View") {
        return true;
      }
    });
    setcanView(match);
    console.log(match);
    if (!roles || roles.length < 1) {
      setcanView(false);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  useEffect(() => {
    if (month) {
      if (cookies.get("user")?.type === "Employee") {
        dispatch(
          MonthEmpChangeRequest({
            month: month ? month : "",
            id: cookies.get("user")?.Emp_id,
          })
        );
      }
    }
    dispatch(ChangeReqEmpPopulate());
  }, [dispatch]);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  useEffect(() => {
    let arr = [];
    if (EmpMonthlyChangeRequest) {
      EmpMonthlyChangeRequest?.forEach((item, idx) => {
        return arr.push({
          ...item,
          id: idx + 1,
          sr: idx + 1,
          project: item?.project?.project_name,
          complexity: item?.reqtimeline?.complexity,
          module: item?.module?.module_name,
          submodule: item?.submodule?.submodule_name,
          created_at: item.created_at
            ? moment(item.created_at).format("DD-MMM-YYYY")
            : "",
          completed_at: item.completed_at,
        });
      });
    }
    setrows(arr);
    setempmonthChangeReq(arr);
  }, [EmpMonthlyChangeRequest]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport csvOptions={{ fileName: "MonthlyChangeReqReport" }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }
  const columns = [
    { field: "sr", headerName: "Sr #", width: 100, headerClassName: header },

    {
      field: "project",
      headerName: "Project",
      width: 200,
      headerClassName: header,
    },

    {
      field: "module",
      headerName: "Module",
      width: 170,
      headerClassName: header,
    },
    {
      field: "submodule",
      headerName: "Sub Module",
      width: 170,
      headerClassName: header,
    },
    // {
    //   field: "subject",
    //   headerName: "Subject",
    //   width: 200,
    //   headerClassName: header,
    // },
    // {
    //   field: "description",
    //   headerName: "Description",
    //   width: 250,
    //   headerClassName: header,
    // },
    // {
    //   field: "priority",
    //   headerName: "Priority",
    //   width: 150,
    //   headerClassName: header,
    // },
    {
      field: "complexity",
      headerName: "Complexity",
      width: 150,
      headerClassName: header,
      renderCell: (celVal) => {
        return celVal?.row?.reqtimeline?.complexity ? (
          <Tooltip sx={{ cursor: "pointer" }} title={celVal?.row?.complexity}>
            <Box
              className={compCircle}
              sx={{
                backgroundColor:
                  celVal?.row?.complexity === "easy"
                    ? "green"
                    : celVal?.row?.complexity === "medium"
                      ? "orange"
                      : celVal?.row?.complexity === "hard"
                        ? "blue"
                        : celVal?.row?.complexity === "complex"
                          ? "red"
                          : null,
              }}
            ></Box>
          </Tooltip>
        ) : null;
      },
    },
    {
      field: "company_status",
      headerName: "Company Status",
      width: 170,
      headerClassName: header,
    },
    {
      field: "client_status",
      headerName: "Client Status",
      width: 150,
      headerClassName: header,
    },
    {
      field: "created_at",
      headerName: "Created At",
      width: 150,
      headerClassName: header,
    },
    {
      field: "completed_at",
      headerName: "Completed At",
      width: 150,
      headerClassName: header,
    },
  ];
  console.log("message", message);
  //   const onTextChange = () => {
  //     if (EmployeeName.current.value === "" && ByDate.current.value === "") {
  //       setempmonthChangeReq(rows);
  //     } else {
  //       let suggestions = [];

  //       let regex1 = new RegExp(EmployeeName.current.value, "i");
  //       let regex2 = new RegExp(
  //         moment(ByDate.current.value).format("MMMM-YYYY"),
  //         "i"
  //       );

  //       suggestions = rows?.filter(
  //         (val) =>
  //           (EmployeeName.current.value === "" || regex1.test(val.Employee)) &&
  //           (ByDate.current.value === "" || regex2.test(val?.date))
  //       );

  //       setempmonthChangeReq(suggestions);
  //     }
  //   };
  const handleGet = async () => {
    if (cookies.get("user")?.type === "Admin") {
      if (empId === null || "") {
        return setsnackBarData({
          openToast: true,
          message: "please select Employee",
          type: "error",
        });
      }
    }
    // if (month === null) {
    //   return setsnackBarData({
    //     openToast: true,
    //     message: "please select Month",
    //     type: "error",
    //   });
    // }
    if (cookies.get("user")?.type === "Employee") {
      dispatch(
        MonthEmpChangeRequest({
          month: month ? month : "",
          id: cookies.get("user")?.Emp_id,
        })
      );
    } else if (cookies.get("user")?.type === "Admin") {
      dispatch(MonthEmpChangeRequest({ month: month ? month : "", id: empId }));
    }
  };
  useEffect(() => {
    if (status === "failed") {
      setsnackBarData({
        type: "error",
        message: "Network Error",
        openToast: true,
      });
    }
  }, [status]);
  return (
    <>
      {canView === true ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "10px",
              width: "100%",
              padding: " 10px",
            }}
          >
            {cookies.get("user")?.type === "Employee" ? null : (
              // <TextField
              //   fullWidth
              //   variant="outlined"
              //   type="text"
              //   size="small"
              //   select
              //   autoComplete="off"
              //   placeholder="Employee"
              //   value={empId}
              //   // className="input_border"
              //   sx={{ width: "100%", maxWidth: "200px", textAlign: "left" }}
              //   onChange={(e) => {
              //     console.log(e.target.value);
              //     setempId(e.target.value);
              //   }}
              // >
              //   <MenuItem value="">Select</MenuItem>
              //   {Employee?.map((emp, i) => {
              //     return (
              //       <MenuItem key={i} value={emp?.id}>
              //         {emp?.name}
              //       </MenuItem>
              //     );
              //   })}
              // </TextField>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                name="emp_id"
                options={Employee}
                getOptionLabel={(option) => option.name}
                sx={{ width: "100%", maxWidth: "250px", textAlign: "left" }}
                fullWidth
                // value={values.emp_id}
                size="small"
                onChange={(e, val) => {
                  console.log(e, val);
                  setempId(val?.id);
                }}
                renderInput={(params) => <TextField {...params} placeholder="Employee" />}
              />
            )}
            <TextField
              fullWidth
              variant="outlined"
              type="month"
              size="small"
              autoComplete="off"
              value={month}
              // className="input_border"
              sx={{ width: "100%", maxWidth: "200px", textAlign: "left" }}
              onChange={(e) => {
                console.log(e.target.value);
                setmonth(e.target.value);
              }}
            />
            {status === "loading" ? (
              <CircularProgress />
            ) : (
              <Button
                onClick={() => {
                  handleGet();
                }}
                sx={{ padding: "5px 10px" }}
                variant="contained"
              >
                Get
              </Button>
            )}
          </Box>

          <span className="ml-2 float-right">
            <div className="loaderclass"> </div>
          </span>

          {status === "loading" ? (
            <>
              <Loader />
            </>
          ) : null}
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            overflow
            sx={{ height: tabelStyle.height, padding: "10px" }}
          >
            <DataGrid
              rows={empmonthChangeReq}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
              pagination
              // components={{
              //   Toolbar: CustomToolbar,
              // }}
              components={isSmallScreen ? {} : { Toolbar: CustomToolbar }}
              localeText={{
                toolbarDensity: "Size",
                toolbarDensityLabel: "Size",
                toolbarDensityCompact: "Small",
                toolbarDensityStandard: "Medium",
                toolbarDensityComfortable: "Large",
              }}
            />
          </Grid>
        </>
      ) : canView === false ? (
        <Navigate to={"/dashboard"} />
      ) : (
        <Box>Loading ...</Box>
      )}
    </>
  );
}

export default MonthlyChangeReq;
