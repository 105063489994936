import PropTypes from "prop-types";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  gridClasses,
  // MenuItem,
  TextField,
} from "@mui/material";

import React, { useContext, useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSingleEmployeeAttendanceDetail,
  getAttendancedetailDetails,
  removeAttendanceDetail,
} from "../../Redux/Slice/AttendanceDetail/AttendanceDetailSlice";
import moment from "moment/moment";
import { makeStyles } from "@mui/styles";
import Cookies from "universal-cookie";
import theme from "../../theme";
import {
  fetchMonthlyReportPopulate,
  getmonthlyreportDetails,
} from "../../Redux/Slice/MonthlyReport/MonthlyReportSlice";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
    Form_input: {
      borderRadius: theme.borderRadius.All,
      backgroundColor: `${theme.palette.common.white} !important`,
    },
  };
});
function LogWorkTimeTable({ setShowForm, setlatest_Entry }) {
  const { header, Form_input } = useStyles();
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const [AttendanceDetails, setAttendanceDetails] = useState([]);
  const [useType, setuseType] = useState("null");
  const { singleEmployeeAttendance, status, Latest_Entry
  } = useSelector(
    getAttendancedetailDetails
  );

  const [filterRows, setfilterRows] = useState([]);
  const [pageSize, setPageSize] = useState(15);
  console.log(Latest_Entry, singleEmployeeAttendance)
  const [selectedEmployee, setselectedEmployee] = useState(null);
  const [employees, setemployees] = useState([]);
  const { Report_Populate, IsLogin, message } = useSelector(
    getmonthlyreportDetails
  );
  const [currenttime, setcurrenttime] = useState(null);
  const [maxDate, setMaxDate] = useState("");
  const [timeZone, settimezone] = useState(null);
  const [userid, setuserid] = useState(null);
  const user = cookies.get("user");
  const navigate = useNavigate();
  const { setsnackBarData } = useContext(SnackBarContext);
  useEffect(() => {

    settimezone(user?.timezone);
    setuserid(user?.Emp_id);
    if (user?.type === "Admin") {
      dispatch(fetchMonthlyReportPopulate());
      dispatch(removeAttendanceDetail());
    }
  }, [user?.type]);
  console.log(employees);
  useEffect(() => {
    if (timeZone) {
      let Label = timeZone ? JSON.parse(timeZone).label : "";


      let value = Label?.split("GMT")[1].split(")")[0];

      if (value.split(":")[0].length <= 2) {
        let x = value.split("");
        x.splice(1, 0, "0");
        x = x.join("");
        value = x;
      }

      const time = moment().utcOffset(value).format("YYYY-MM-DD");

      setMaxDate(time);
      setcurrenttime(time);
    }
  }, [timeZone]);

  useEffect(() => {
    if (user?.type === "Employee" || currenttime) {
      fetchuserData();
    }
  }, [dispatch, useType, currenttime]);

  useEffect(() => {
    if (singleEmployeeAttendance) {
      setAttendanceDetails(singleEmployeeAttendance);
      setlatest_Entry(Latest_Entry);
    }
  }, [singleEmployeeAttendance]);

  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
    if (Report_Populate) {
      setemployees(Report_Populate);
    }
  }, [Report_Populate, IsLogin]);

  function fetchuserData() {

    if (userid && currenttime) {
      dispatch(
        fetchSingleEmployeeAttendanceDetail({ id: userid, date: currenttime })
      );
    }
  }

  const handleGet = () => {
    setuserid(selectedEmployee?.id);
    settimezone(selectedEmployee?.time_zone);
    // fetchuserData()
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport
          csvOptions={{ fileName: "Employee Log Work Time" }}
        />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }
  const columns = [
    { field: "id", headerName: "Sr #", headerClassName: header },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      headerClassName: header,
    },
    {
      field: "time",
      headerName: "Time",
      width: 200,
      headerClassName: header,
    },
    { field: "note", headerName: "Note", width: 200, headerClassName: header },
    {
      field: "timeZone",
      headerName: "Time Zone",
      width: 220,
      headerClassName: header,
    },
  ];
  useEffect(() => {
    if (cookies.get("user")) {
      setuseType(cookies.get("user").type);
    }
  }, [cookies.get("user")]);

  useEffect(() => {
    let arr = [];
    if (AttendanceDetails) {
      AttendanceDetails?.forEach((item, idx) => {
        let timezone = item?.emp_id?.shift_rules?.time_zone
          ? JSON.parse(item?.emp_id?.shift_rules?.time_zone)
          : {};

        return arr.push({
          id: idx + 1,
          time: item?.time
            ? moment(item?.time, "HH:mm:ss").format("hh:mm A")
            : "",
          status: item?.status,
          note: item?.note,
          timeZone: timezone?.label,
        });
      });
    }

    setfilterRows(arr);
  }, [AttendanceDetails]);

  const handleEmployeeChange = (val) => {
    console.log(val)
    setcurrenttime(null);
    settimezone(null);
    setfilterRows([]);
    setselectedEmployee(val);
  };
  return (
    <Box
      sx={{
        padding: "20px 10px 10px 10px",
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      {useType === "Admin" ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              paddingBottom: "10px",
              width: "100%",
            }}
          >
            {/* <TextField
              size="small"
              variant="outlined"
              type="text"
              select
              color="primary"
              sx={{ input: { color: "black" }, maxWidth: "250px" }}
              className={Form_input}
              fullWidth
              value={selectedEmployee}
              onChange={handleEmployeeChange}
            >
              <MenuItem value="">Select employee</MenuItem>
              {employees.map((employee) => {
                return (
                  <MenuItem key={employee?.id} value={employee}>
                    {employee?.name}
                  </MenuItem>
                );
              })}
            </TextField> */}
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              name="emp_id"
              options={employees}
              getOptionLabel={(option) => option.name}
              className={Form_input}
              fullWidth
              sx={{ input: { color: "black" }, maxWidth: "250px" }}
              size="small"
              onChange={(e, val) => {
                console.log(e, val);
                handleEmployeeChange(val);
              }}
              renderInput={(params) => <TextField {...params} name="emp_id" placeholder="Employee" />}
            />
            <Button
              onClick={() => {
                handleGet();
              }}
              sx={{ padding: "5px 10px" }}
              variant="contained"
            >
              Get
            </Button>
            {/* )} */}
          </Box>
          <Box>
            <TextField
              type="date"
              variant="standard"
              value={currenttime}
              inputProps={{
                max: `${moment(maxDate).format("YYYY-MM-DD")}`,
              }}
              onChange={(e) => {
                setcurrenttime(e.target.value);
              }}
            />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Button
              onClick={() => setShowForm(true)}
              sx={{ padding: "5px 10px" }}
              variant="contained"
            >
              Log Time
            </Button>
          </Box>
          <Box>
            <TextField
              type="date"
              variant="standard"
              value={currenttime}
              inputProps={{
                max: `${moment(maxDate).format("YYYY-MM-DD")}`,
              }}
              onChange={(e) => {
                setcurrenttime(e.target.value);
              }}
            />
          </Box>
        </Box>
      )}

      <div style={{ height: "calc(100vh - 250px)", width: "100%" }}>
        {singleEmployeeAttendance?.length < 1 && status === "loading" ? (
          <Box
            sx={{
              height: "calc(100vh - 250px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {" "}
            <CircularProgress />
          </Box>
        ) : (
          <DataGrid
            rows={filterRows}
            columns={columns}
            onRowClick={() => { }}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[15, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            pagination
            components={{
              Toolbar: CustomToolbar,
            }}
            sx={{
              overflow: "scroll",
            }}
            scrollbarSize={2}
            autoPageSize={true}
            localeText={{
              toolbarDensity: "Size",
              toolbarDensityLabel: "Size",
              toolbarDensityCompact: "Small",
              toolbarDensityStandard: "Medium",
              toolbarDensityComfortable: "Large",
            }}
          />
        )}
      </div>
      {/* )} */}
    </Box>
  );
}

export default LogWorkTimeTable;

LogWorkTimeTable.propTypes = {
  setShowForm: PropTypes.any,
  setlatest_Entry: PropTypes.any,
};
