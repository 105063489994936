import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import { EmpAllowanceResetData } from "../AllAllowances/AllAllowance";
import { AllowanceResetData } from "../Allowance/AllowanceSlice";
import { AttendanceResetData } from "../Attendance/AttendanceSlice";
import { AttendanceDetailResetData } from "../AttendanceDetail/AttendanceDetailSlice";
import { ChangeReqResetData } from "../ChangeRequest/ChangeRequestSlice";
import { CplResetData } from "../CPL/CPLSlice";
import { DashboardResetData } from "../Dashboard/DashboardSlice";
import { DepartmentResetData } from "../Department/DepartmentSlice";
import { DesignationResetData } from "../Designation/DesignationSlice";
import { EmployeeResetData } from "../Employee/EmplyeeSlice";
import { EmpMonReportResetData } from "../EmployeMonthlyReport/EmployeMonthlyReportSlice";
import { ExpertiesResetData } from "../Experties/ExpertiesSlice";
import { GazzetResetData } from "../GazettedHolidays/GazettedHolidaysSlice";
import { JobTitleResetData } from "../JobTitle/JobTitleSlice";
import { LeaveAppResetData } from "../LeaveApplication/LeaveApplicationSlice";
import { MonthlyReportResetData } from "../MonthlyReport/MonthlyReportSlice";
import { EmpIncreDecreResetData } from "../PerformanceManagement/IncrementDecrementSlice";
import { EmpPromDemResetData } from "../PerformanceManagement/PromotionDemotionSlice";
import { resetData } from "../Project/ProjectSlice";
import { ProjSubHistResetData } from "../ProjectSubscriptionHistory/ProjectSubscriptionHisSlice";
import { ProjSubResetData } from "../ProjectSubscriptionSlice/ProjectSubScriptionSlice";
import { ShiftRuleResetData } from "../ShiftRules/ShiftRuleSlice";
import { TechnologiesResetData } from "../Technologies/TechnologiesSlice";
import { TodayAttendanceResetData } from "../TodayAttendance/TodayAttendanceSlice";
import { BreakAdjResetData } from "../BreakAdjustment/BreakaAjustmentSlice";
const cookies = new Cookies();
// Define your async thunk for logout
export const logout = createAsyncThunk(
  "auth/logout",
  async (params, thunkAPI) => {
    // Clear data from all slices
    console.log("logout");
    cookies.remove("user");
    cookies.remove("access_token");
    cookies.remove("Emp_id");
    cookies.remove("chatUserId");
    cookies.remove("isChat");

    thunkAPI.dispatch(resetData());
    thunkAPI.dispatch(EmployeeResetData());
    thunkAPI.dispatch(AttendanceResetData());
    thunkAPI.dispatch(TodayAttendanceResetData());
    thunkAPI.dispatch(GazzetResetData());
    thunkAPI.dispatch(ShiftRuleResetData());
    thunkAPI.dispatch(MonthlyReportResetData());
    thunkAPI.dispatch(CplResetData());
    thunkAPI.dispatch(EmpMonReportResetData());
    thunkAPI.dispatch(ChangeReqResetData());
    thunkAPI.dispatch(BreakAdjResetData());
    thunkAPI.dispatch(ProjSubHistResetData());
    thunkAPI.dispatch(ProjSubResetData());
    thunkAPI.dispatch(TechnologiesResetData());
    thunkAPI.dispatch(DepartmentResetData());
    thunkAPI.dispatch(DesignationResetData());
    thunkAPI.dispatch(ExpertiesResetData());
    thunkAPI.dispatch(JobTitleResetData());
    thunkAPI.dispatch(LeaveAppResetData());
    thunkAPI.dispatch(AllowanceResetData());
    thunkAPI.dispatch(EmpAllowanceResetData());
    thunkAPI.dispatch(EmpIncreDecreResetData());
    thunkAPI.dispatch(EmpPromDemResetData());
    thunkAPI.dispatch(AttendanceDetailResetData());
    thunkAPI.dispatch(DashboardResetData());
    // thunkAPI.dispatch(slice2.actions.resetData());
    // thunkAPI.dispatch(slice3.actions.resetData());
  }
);
