import React, { useContext, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import theme from "../../theme/index";
import { Box } from "@mui/material";
import { TooltipContext } from "../../Context/TooltipContext/TooltipContent";
const useStyles = makeStyles(() => ({
  deleteIconOpen: {
    "& svg": {
      fontSize: 22,
    },
  },

  deleteIconClosed: {
    "& svg": {
      fontSize: 22,
    },
  },
  SideBarItems: {
    display: "flex",
    alignItems: "start",
    gap: "5px",
  },
  SideBar_link: {
    textDecoration: "none",
    color: theme.palette.grey[50],
    opacity: "80%",
    cursor: "pointer",
    "&:hover": {
      opacity: "100%",
      color: theme.palette.white.main,
    },
    paddingLeft: "20px",
    paddingTop: "5px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  sideBar_title: {
    fontSize: "14px",
  },
  SideBar_link_Selected: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textDecoration: "none",
    cursor: "pointer",

    fontWeight: "bold",
    backgroundColor: theme.palette.white.main,
    color: `${theme.palette.background.submenu}`,
    // borderRadius: "25px",
    paddingLeft: "16px",
    paddingTop: "10px",
    borderLeft: `4px solid ${theme.palette.grey[600]}`,
  },
  SideBar_Sublink_Selected: {
    textDecoration: "underline",
    fontWeight: "bold",
    color: `${theme.palette.background.submenu} `,
    fontSize: "14px",
    paddingLeft: "30px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "10px",
  },
  SideBar_Sublink: {
    textDecoration: "none",
    color: `${theme.palette.white.main} `,

    fontSize: "12px",
    paddingLeft: "30px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "10px",
  },
}));
const SideBarMenu = ({
  open,
  item,
  selectedTab,
  setselectedTab,
  selectedSecondTab,
  setselectedSecondTab,
  idx,
  Roles,
  // setOpen,
  // setOnHover,
}) => {
  const navigate = useNavigate();
  const {
    SideBarItems,
    sideBar_title,
    SideBar_link,
    SideBar_link_Selected,
    SideBar_Sublink_Selected,
    SideBar_Sublink,
  } = useStyles(theme);

  const [selectedSubTab, setselectedSubTab] = useState("");
  const [open1] = useState(true);
  const { settooltipData } = useContext(TooltipContext);

  // console.log(selectedTab);
  //  console.log(item);
  // console.log(setopen1);
  return (
    <>
      <Box
        key={idx}
        onClick={() => {
          if (selectedTab && selectedTab?.name === item.name) {
            setselectedTab("");

          } else {

            setselectedTab(item);
          }
          settooltipData(item?.toolTipText);
          //  setOnHover(false);
          //  setOpen(false);

          // if (item?.subitems.length > 0) {
          //   setselectedSecondTab(item?.subitems[0]);
          // }
          if (item?.subitems?.length < 1) {
            navigate(item.path);

          }
        }}

        className={
          selectedTab?.name === item.name ? SideBar_link_Selected : SideBar_link
        }
      >
        <div className={SideBarItems}>
          <div
            className={
              open ? `classes.deleteIconOpen ` : `classes.deleteIconClosed`
            }
          >
            {item.icon}
          </div>

          {open && <div className={sideBar_title}>{item.name}</div>}
        </div>
        {open && item?.subitems.length > 0 && (
          <div className="left_items">
            {item?.subitems.length > 0 && selectedTab.name === item.name
              ? item.iconOpened
              : item.iconClosed}
          </div>
        )}
      </Box>
      {open &&
        selectedTab.name === item.name &&
        item?.subitems?.map((item1, index) => {
          if (Roles) {
            if (item1?.subitems?.length > 0) {
              return (
                <>
                  <Box
                    key={idx}
                    onClick={() => {
                      if (selectedSubTab && selectedSubTab?.name === item1.name) {
                        setselectedSubTab("")
                      } else {

                        setselectedSubTab(item1);
                      }
                      settooltipData(item1?.toolTipText);

                      if (item?.subitems?.length < 1) {
                        navigate(item.path);
                        // setOpen(false);
                      }
                    }}
                    className={
                      selectedTab?.name === item1.name
                        ? SideBar_link_Selected
                        : SideBar_link
                    }
                  >
                    <Box className={SideBarItems} sx={{ paddingLeft: "20px" }}>
                      <div
                        className={
                          open
                            ? `classes.deleteIconOpen `
                            : `classes.deleteIconClosed`
                        }
                      >
                        {item1.icon}
                      </div>

                      {open && (
                        <div className={sideBar_title}>{item1.name}</div>
                      )}
                    </Box>
                    {open && item?.subitems.length > 0 && (
                      <div className="left_items">
                        {item1?.subitems.length > 0 &&
                          selectedTab.name === item1.name
                          ? item1.iconOpened
                          : item1.iconClosed}
                      </div>
                    )}
                  </Box>
                  {open1 &&
                    selectedSubTab.name === item1.name &&
                    item1?.subitems?.map((item2, index) => {
                      let matches = Roles?.ruleid?.some((role) => {
                        if (
                          role?.module.split("_")[0]?.toLowerCase() ===
                          item2?.title?.split(" ").join("")?.toLowerCase() &&
                          role?.module.split("_")[1].toLowerCase() === "view"
                        ) {
                          return true;
                        }
                      });
                      if (matches) {
                        return (
                          <>
                            <Link
                              to={item2.path}
                              key={index}
                              onClick={() => {
                                settooltipData(item2?.toolTipText);

                                setselectedSecondTab(item2);
                                // setOpen(false);
                              }}
                              className={
                                selectedSecondTab?.name === item2?.name
                                  ? SideBar_Sublink_Selected
                                  : SideBar_Sublink
                              }
                              style={{
                                paddingLeft: "60px",
                              }}
                            >
                              <div className={open ? `` : `icon_class`}>
                                <div
                                  className={
                                    open
                                      ? `classes.deleteIconOpen `
                                      : `classes.deleteIconClosed`
                                  }
                                >
                                  {item2.icon}
                                </div>
                              </div>

                              {item2.name}
                            </Link>
                          </>
                        );
                      }
                    })}
                </>
              );
            } else {
              let matches = Roles?.ruleid?.some((role) => {
                if (
                  role?.module.split("_")[0]?.toLowerCase() ===
                  item1?.title?.split(" ").join("")?.toLowerCase() &&
                  role?.module.split("_")[1].toLowerCase() === "view"
                ) {
                  return true;
                }
              });
              if (matches) {
                return (
                  <>
                    <Link
                      to={item1.path}
                      key={index}
                      onClick={() => {
                        settooltipData(item1?.toolTipText);

                        setselectedSecondTab(item1);
                        // setOpen(false);
                      }}
                      className={
                        selectedSecondTab?.name === item1?.name
                          ? SideBar_Sublink_Selected
                          : SideBar_Sublink
                      }
                    >
                      <div className={open ? `` : `icon_class`}>
                        <div
                          className={
                            open
                              ? `classes.deleteIconOpen `
                              : `classes.deleteIconClosed`
                          }
                        >
                          {item1.icon}
                        </div>
                      </div>

                      {item1.name}
                    </Link>
                  </>
                );
              }
            }
          } else {
            return (
              <>
                <Link
                  to={item1.path}
                  key={index}
                  onClick={() => {
                    settooltipData(item1?.toolTipText);

                    setselectedSecondTab(item1);
                    // setOpen(false);
                  }}
                  className={
                    selectedSecondTab?.name === item1?.name
                      ? SideBar_Sublink_Selected
                      : SideBar_Sublink
                  }
                >
                  <div className={open ? `` : `icon_class`}>
                    <div
                      className={
                        open
                          ? `classes.deleteIconOpen `
                          : `classes.deleteIconClosed`
                      }
                    >
                      {item1.icon}
                    </div>
                  </div>

                  {item1.name}
                </Link>
              </>
            );
          }
        })}
    </>
  );
};

export default SideBarMenu;

SideBarMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  item: PropTypes.any,
  selectedTab: PropTypes.any,
  setselectedTab: PropTypes.func,
  selectedSecondTab: PropTypes.any,
  setselectedSecondTab: PropTypes.func,
  idx: PropTypes.any,
  Roles: PropTypes?.any,
  setOpen: PropTypes?.any,
  setOnHover: PropTypes?.any,
};
