import React, { useContext, useEffect, useRef } from "react";

import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import Cookies from "universal-cookie";
import { makeStyles } from "@mui/styles";
import {
  FetchEmployeesForEngagements,
  GetAllEngagements,
} from "../../../Redux/Slice/Engagements/EngagementSlice";
import { logout } from "../../../Redux/Slice/LogoutSlice/LogoutSlice";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import { useNavigate } from "react-router-dom";
import {
  fetchEmployeeTaskReportbyPortalId,
  getPortalTaskSummary,
} from "../../../Redux/Slice/PortalSummary/PortalSummarySlice";
import PropTypes from "prop-types";
const localizer = momentLocalizer(moment);

const cookies = new Cookies();

const useStyle = makeStyles(() => {
  return {
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
      overflowY: "auto",
    },
    Container2: {
      width: "90%",
      maxWidth: "900px",
      padding: "20px",
      maxHeight: "90vh",
      border: "none",
      position: "relative",
      background: "white",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    WorkObjectContainer: {
      overflowY: "auto",
      maxHeight: "60vh",
      paddingRight: "10px",
    },
    elipsText: {
      fontSize: "inherit",
      lineHeight: "inherit",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      WebkitLineClamp: 2,
      cursor: "pointer",
    },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },

    container: {
      width: "100%",
      height: "700px",
      padding: "22px 0px",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      justifyContent: "start",
      textAlign: "left",
      "@media (max-width: 600px)": {
        padding: "0px 0px",
      },
    },
    loaderContainer: {
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    FieldContainer: {
      display: "flex",
      alignItems: "center",
      gap: "23%",
    },
    Field_input: {
      display: "flex",
      gap: "20px",
      width: "30%",
    },
  };
});

const CalendarChart = () => {
  const navigate = useNavigate();
  const { setsnackBarData } = useContext(SnackBarContext);
  const {
    container,
    loaderContainer,
    FieldContainer,
    Field_input,
    TableHeader,
    ModalContainer,
    TableContain,
    Container2,
    elipsText,
  } = useStyle();
  const [selectedEmployee, setselectedEmployee] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [event, setevent] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const { employees } = useSelector(GetAllEngagements);
  const user = cookies.get("user");
  const dispatch = useDispatch();
  const prevMonthRef = useRef(moment(currentDate).month());

  const { status1, IsLogin, message1, EmployeeTasksReportbyPortalID } =
    useSelector(getPortalTaskSummary);
  console.log("event", event);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message1,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);

  useEffect(() => {
    if (user?.type !== "Admin") {
      fetchTasksForMonth(currentDate);
    }
    dispatch(FetchEmployeesForEngagements());
  }, [dispatch]);

  const fetchTasksForMonth = (date) => {
    const startDate = moment(date).startOf("month").format("YYYY-MM-DD");
    const endDate = moment(date).endOf("month").format("YYYY-MM-DD");
    dispatch(
      fetchEmployeeTaskReportbyPortalId({
        startDate,
        endDate,
        portalId: user?.portalid,
      })
    );
  };
  const handleNavigate = (date) => {
    const currentMonth = moment(currentDate).month();
    const newMonth = moment(date).month();
    const isBackNavigation = newMonth < currentMonth;
    const isNextNavigation = newMonth > currentMonth;

    if (user?.type !== "Admin") {
      if (isBackNavigation || isNextNavigation) {
        console.log(
          isBackNavigation
            ? "Back button pressed, fetching data..."
            : "Next button pressed, fetching data..."
        );
        fetchTasksForMonth(date);
      }
    }

    setCurrentDate(date);
    prevMonthRef.current = newMonth;
  };

  // useEffect(() => {
  //   let values = [];
  //   if (user?.type !== "Admin") {
  //     if (EmployeeTasksReportbyPortalID) {
  //       values = EmployeeTasksReportbyPortalID.flatMap((project) =>
  //         project.workPackages.map((workPackage) => ({
  //           title: `${project.projectName}`,
  //           start: moment(workPackage.dueDate).toDate(),
  //           end: moment(workPackage.dueDate).toDate(),
  //           data: workPackage,
  //           workingHours: project.projectWorkinghrs,
  //         }))
  //       );
  //       setevent(values);
  //     }
  //   }
  // }, [EmployeeTasksReportbyPortalID]);
  useEffect(() => {
    let values = [];

    if (user?.type !== "Admin" && EmployeeTasksReportbyPortalID.length > 0) {
      console.log("EmployeeTasksReportbyPortalID", EmployeeTasksReportbyPortalID);
      values = EmployeeTasksReportbyPortalID.flatMap((project) =>
        project?.workPackages?.map((workPackage) => {
          const totalWorkingHours = workPackage?.timeEntries?.reduce(
            (sum, entry) => sum + parseFloat(entry?.hours || 0),
            0
          );

          return {
            title: project?.projectName,
            start: moment(workPackage?.dueDate).toDate(),
            end: moment(workPackage?.dueDate).toDate(),
            data: workPackage,
            workingHours: totalWorkingHours,
          };
        })
      );

      console.log("values", values);

      const groupedByDate = values.reduce((acc, event) => {
        const eventDate = moment(event.start).format("YYYY-MM-DD");

        if (!acc[eventDate]) {
          acc[eventDate] = [];
        }

        const existingProject = acc[eventDate].find(
          (entry) => entry.projectName === event.title
        );

        if (existingProject) {
          // Add the new task and accumulate the working hours
          existingProject.tasks.push(event.data);
          existingProject.workingHours += event.workingHours; // Add working hours to the existing value
        } else {
          acc[eventDate].push({
            projectName: event.title,
            workingHours: event.workingHours,
            tasks: [event.data],
            start: event.start,
            end: event.end,
          });
        }

        return acc;
      }, {});

      // const calendarEvents = Object.entries(groupedByDate)?.flatMap(([date, projects]) =>
      //   console.log("Processing date:", date);
      // return projects?.map((project) => ({
      //   title: `${project?.projectName}`,
      //   start: project?.start,
      //   end: project?.end,
      //   data: project?.tasks,
      //   workingHours: project?.workingHours,
      // }))
      // );
      const calendarEvents = Object.entries(groupedByDate)?.flatMap(([date, projects]) => {
        console.log("Processing date:", date); // Logging the date correctly
        return projects?.map((project) => ({
          title: `${project?.projectName}`,
          start: project?.start,
          end: project?.end,
          data: project?.tasks,
          workingHours: project?.workingHours,
        }));
      });

      setevent(calendarEvents);
    }
  }, [EmployeeTasksReportbyPortalID]);
  const eventPropGetter = () => ({
    style: {
      backgroundColor: "#53b7c5",
      borderRadius: "5px",
      opacity: 1,
      border: "none",
      color: "white",

    },
  });
  // const Event = ({ event }) => {
  //   return (
  //     <div style={{ display: "flex", alignItems: "center" }}>
  //       <span>{event.title}</span>
  //       {event.workingHours && (
  //         <Chip
  //           label={`${event.workingHours ? event.workingHours.toFixed(2) : "0.00"} hrs`}
  //           size="small"
  //           style={{
  //             marginLeft: 8,
  //             backgroundColor: "#333",
  //             color: "#fff",
  //           }}
  //         />
  //       )}
  //     </div>
  //   );
  // };

  const Event = ({ event }) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          maxWidth: "100%",
          overflow: "hidden",
        }}
      >
        <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
          {event.title}
        </span>
        {event.workingHours && (
          <Chip
            label={`${event.workingHours ? event.workingHours.toFixed(2) : "0.00"} hrs`}
            size="small"
            sx={{ marginLeft: { sx: "0", sm: "8px" }, }}
            style={{

              backgroundColor: "#333",
              color: "#fff",
              maxWidth: "80px",
              fontSize: "12px",
            }}
          />
        )}
      </div>
    );
  };



  return (
    <Box className={container}>
      {user?.type === "Admin" && (
        <Box className={FieldContainer}>
          <Box className={Field_input}>
            <Autocomplete
              disablePortal
              options={employees}
              getOptionLabel={(option) => option?.name}
              sx={{
                input: { color: "black" },
                maxWidth: "350px",
                textAlign: "left",
              }}
              fullWidth
              size="small"
              onChange={(e, val) => setselectedEmployee(val)}
              renderInput={(params) => <TextField {...params} placeholder="Employee" />}
            />
            <Button
              variant="contained"
              sx={{ padding: "5px 10px" }}
              onClick={() => console.log(selectedEmployee)}
            >
              Get
            </Button>
          </Box>
          <Box>
            <Typography color="red">COMING SOON...</Typography>
          </Box>
        </Box>
      )}
      {status1 === "loading" ? (
        <Box className={loaderContainer}>
          <CircularProgress />
        </Box>
      ) : (
        <Calendar
          localizer={localizer}
          startAccessor="start"
          onSelectEvent={(event) => {
            setSelectedData(event);
            setIsDialogOpen(true);
          }}
          defaultView="month"
          events={event}
          endAccessor="end"
          components={{
            event: Event,
          }}
          eventPropGetter={eventPropGetter}
          onNavigate={handleNavigate}
          date={currentDate}
          views={["month", "week", "agenda", "day"]}
          style={{
            height: "100%", maxWidth: "100%", minWidth: 0,
            // overflow: "hidden",
          }}
        />
      )}
      {isDialogOpen && (
        <>
          <Modal
            open={isDialogOpen}
            onClose={() => {
              setIsDialogOpen(false);
            }}
          >
            <Box className={ModalContainer}>
              <Box
                className={Container2}
                sx={{
                  overflow: "scroll",
                }}
              >
                <Box className={TableHeader}>
                  <Typography variant="h1" sx={{ fontSize: "20px !important" }}>
                    {selectedData.title} Task Detail
                  </Typography>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsDialogOpen(false);
                    }}
                  >
                    <HighlightOffIcon
                      color="primary"
                      sx={{
                        fontSize: "28px",
                      }}
                    />
                  </IconButton>
                </Box>

                <Box
                  sx={{
                    maxHeight: "60vh",
                    overflowY: "auto",
                  }}
                >
                  {selectedData?.data?.map((row, i) => (
                    <Box key={i} sx={{ marginTop: 2 }}>
                      <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                        <Grid item xs={12} md={4}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Work Subject
                          </Typography>
                          <Tooltip title={row?.subject || "N/A"} placement="top" arrow>
                            <Typography
                              sx={{
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                WebkitLineClamp: 2,
                                cursor: "pointer",
                              }}
                            >
                              {row?.subject || "N/A"}
                            </Typography>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography sx={{ fontWeight: "bold" }}>Date</Typography>
                          <Typography>{row?.dueDate || "N/A"}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "1.1rem",
                              marginBottom: 0.5,
                            }}
                          >
                            Completion %
                          </Typography>
                          <Box sx={{ position: "relative" }}>
                            <LinearProgress
                              variant="determinate"
                              value={row?.doneRatio || 0}
                              sx={{
                                marginTop: 1,
                                backgroundColor: "#BCCCDC",
                                borderRadius: 10,
                                height: 10,
                              }}
                            />
                            <Typography
                              sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                color: "#fff",
                                fontWeight: "semibold",
                                fontSize: "0.60rem",
                              }}
                            >
                              {Math.round(row?.doneRatio || 0)} %
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Typography
                        variant="h7"
                        sx={{ fontWeight: "bold", marginBottom: 2 }}
                      >
                        Time Entries
                      </Typography>

                      <TableContainer component={Paper} className={TableContain}>
                        <Table
                          stickyHeader
                          sx={{
                            minWidth: { md: "auto", xs: "auto" },
                            overflow: "scroll",
                          }}
                          aria-label="time entries table"
                          size="small"
                        >
                          <TableHead>
                            <TableRow size="small">
                              <TableCell
                                size="small"
                                sx={{ fontWeight: "bold", minWidth: 50 }}
                              >
                                #
                              </TableCell>
                              <TableCell
                                size="small"
                                sx={{ fontWeight: "bold", minWidth: 150 }}
                              >
                                Comments
                              </TableCell>
                              <TableCell
                                size="small"
                                sx={{ fontWeight: "bold", minWidth: 150 }}
                              >
                                Time Spent
                              </TableCell>{" "}
                              <TableCell
                                size="small"
                                sx={{ fontWeight: "bold", minWidth: 150 }}
                              >
                                Spent Type
                              </TableCell>
                              <TableCell
                                size="small"
                                sx={{ fontWeight: "bold", minWidth: 150 }}
                              >
                                Date
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {row?.timeEntries?.length > 0 ? (
                              row?.timeEntries?.map((entry, i) => (
                                <TableRow
                                  key={entry.id}
                                  sx={{
                                    "&:last-child td, &:last-child th": { border: 0 },
                                  }}
                                >
                                  <TableCell size="small" component="th" scope="row">
                                    {i + 1}
                                  </TableCell>
                                  <TableCell size="small" component="th" scope="row">
                                    <Tooltip
                                      title={entry?.comments || "N/A"}
                                      placement="top"
                                      arrow
                                    >
                                      <Typography className={elipsText}>
                                        {entry?.comments || "N/A"}
                                      </Typography>
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell size="small" component="th" scope="row">
                                    {entry.hours || "N/A"}
                                  </TableCell>
                                  <TableCell size="small" component="th" scope="row">
                                    {entry.spentType || "N/A"}
                                  </TableCell>
                                  <TableCell size="small" component="th" scope="row">
                                    {entry.spentOn || "N/A"}
                                  </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={4} align="center">
                                  No time entries available
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Divider sx={{ marginBottom: 2, marginTop: 4 }} />
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </Box>
  );
};

export default CalendarChart;
CalendarChart.propTypes = {
  event: PropTypes.shape({
    title: PropTypes.string,
    workingHours: PropTypes.number,
  }),
};
