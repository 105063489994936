// import { Alert, Snackbar } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import { Navigate } from "react-router-dom";
import { useEffect } from "react";
import { Box } from "@mui/material";
import EmailTable from "../../Components/Email/EmailTable/EmailTable";
import EmailForm from "../../Components/Email/EmailForm/EmailForm";
// import FullWidthTabs from "../EmailTemplateDetail/EmialTemplateDetail";
import Details from "../EmailTemplateDetail/Details";

const EmailMain = () => {


  const [ShowForm, setShowForm] = useState(false);
  const [message, setmessage] = useState("");
  const [openToast, setopenToast] = useState(false);
  const [AlertType, setAlertType] = useState("success");
  const [empData, setempData] = useState(null);
  const [isEdited, setisEdited] = useState(false);
  const [canView, setcanView] = useState(null);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [ShowDetails, setShowDetails] = useState(false);
  useEffect(() => {
    let string = "ActiveEmployees";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    let match = value?.some((val) => {
      if (val?.module?.split("_")[1] === "View") {
        return true;
      }
    });
    setcanView(match);
    console.log(match);
    if (!roles || roles.length < 1) {
      setcanView(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  // console.log(empData);
  return (
    <div>
      {canView === true ? (
        ShowDetails ? (
          <Details setShowDetails={setShowDetails} data={empData} />
        ) : ShowForm ? (
          <EmailForm setShowForm={setShowForm} empData={empData} isEdited={isEdited} setisEdited={setisEdited} />
        ) : (
          <EmailTable
            setopenToast={setopenToast}
            setmessage={setmessage}
            setAlertType={setAlertType}
            setShowForm={setShowForm}
            setShowDetails={setShowDetails}
            setempData={setempData}
            isEdited={isEdited}
            setisEdited={setisEdited}
            setPage={setPage}
            page={page}
            setPageSize={setPageSize}
            pageSize={pageSize}

          />
        )
      ) : canView === false ? (
        <Navigate to={"/dashboard"} />
      ) : (
        <Box>Loading ...</Box>
      )}
      <Snackbar
        openToast={openToast}
        setopenToast={setopenToast}
        AlertType={AlertType}
        message={message}
      />
    </div>
  );
};

export default EmailMain;
EmailMain.propTypes = {
  setShowForm: PropTypes.any,
  setisEdited: PropTypes.any,
  isEdited: PropTypes.any,
};
