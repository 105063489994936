import {
  Box,
  Button,
  CircularProgress,
  Grid,
  gridClasses,
  InputAdornment,
  Modal,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useRef, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchAllInActiveEmployee,
  getemployeeDetails,
} from "../../Redux/Slice/Employee/EmplyeeSlice";
import { UpdateEmpStatApi } from "../../Api/Employee/UpdateEmployeStatus";
import moment from "moment/moment";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import InfoIcon from "@mui/icons-material/Info";
import { Navigate, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";
import Loader from "../../Components/Loader/Loader";

const useStyles = makeStyles(() => {
  return {
    headerClass: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});

function InActiveEmployees() {
  const { headerClass } = useStyles();
  const { tabelStyle } = useContext(TabelStyleContext);

  const [selectedEmployee, setselectedEmployee] = useState(null);

  const [AllEmployees, setAllEmployees] = useState([]);
  const [rows, setrows] = useState([]);
  const [filterRows, setfilterRows] = useState([]);
  const [pageSize, setPageSize] = useState(15);
  const [setmessage] = useState("");
  const [setopenToast] = useState(false);
  const [setAlertType] = useState("success");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const date = new Date();
  const { setsnackBarData } = useContext(SnackBarContext);
  const descRef = useRef();
  const [loader, setloader] = useState(false);
  const navigate = useNavigate();

  const { allInactiveEmployees, status, IsLogin, message } =
    useSelector(getemployeeDetails);
  console.log("status", allInactiveEmployees);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [editPermit, seteditpermit] = useState(false);
  const [viewPermit, setviewPermit] = useState(false);
  const [canView, setcanView] = useState(null);
  useEffect(() => {
    let string = "InActiveEmployees";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    let match = value?.some((val) => {
      if (val?.module?.split("_")[1] === "View") {
        return true;
      }
    });
    setcanView(match);
    console.log(match);
    if (!roles || roles.length < 1) {
      setcanView(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  // console.log(crop);

  console.log(roles);
  useEffect(() => {
    let string = "InActiveEmployees";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Edit") {
        seteditpermit(true);
      } else if (val?.module?.split("_")[1] === "View") {
        setviewPermit(true);
      }
    });
    if (!roles || roles.length < 1) {
      setviewPermit(true);
      seteditpermit(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  console.log(editPermit);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(FetchAllInActiveEmployee());
  }, [dispatch]);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
    setAllEmployees(allInactiveEmployees);
  }, [allInactiveEmployees, IsLogin]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport csvOptions={{ fileName: "InActive Employees" }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }
  const columns = [
    { field: "id", headerName: "Sr #", headerClassName: headerClass },
    {
      field: "name",
      headerName: "Employee",
      width: 150,
      headerClassName: headerClass,
    },

    // {
    //   field: "cnic",
    //   headerName: "CNIC #",
    //   width: 180,
    //   headerClassName: headerClass,
    // },

    {
      field: "phone",
      headerName: "Phone",
      width: 150,
      headerClassName: headerClass,
    },

    // {
    //   field: "dob",
    //   headerName: "DOB",
    //   width: 180,
    //   headerClassName: headerClass,
    // },

    {
      field: "department",
      headerName: "Department",
      width: 150,
      headerClassName: headerClass,
    },
    {
      field: "designation",
      headerName: "Designation",
      width: 150,
      headerClassName: headerClass,
    },
    // {
    //   field: "technology",
    //   headerName: "Technology",
    //   width: 200,
    //   headerClassName: headerClass,
    // },
    // {
    //   field: "expertie",
    //   headerName: "Experties",
    //   width: 200,
    //   headerClassName: headerClass,
    // },
    {
      field: "shiftrules",
      headerName: "Shift",
      width: 150,
      headerClassName: headerClass,
    },
    {
      field: "time_zone",
      headerName: "Time Zone",
      width: 200,
      headerClassName: headerClass,
    },
    {
      field: "InactiveDate",
      headerName: "Inactive Date",
      width: 200,
      headerClassName: headerClass,
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 250,
      headerClassName: headerClass,
      renderCell: (celval) => {
        return (
          <>
            {" "}
            <Box sx={{ display: "flex", gap: "25px", alignItems: "center" }}>
              {viewPermit ? (
                <Tooltip title="Details">
                  <InfoIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate("/employeesDetails", { state: celval.row })
                    }
                  />
                </Tooltip>
              ) : null}
              {editPermit ? (
                <Tooltip title="Active Employe">
                  <AutorenewIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setselectedEmployee(celval.row);

                      handleOpen();
                    }}
                  />
                </Tooltip>
              ) : null}
            </Box>
          </>
        );
      },
    },
  ];

  const hanldeActiveEmp = async (empRow) => {
    if (!descRef.current.value) {
      setsnackBarData({
        type: "error",
        message: "please add Description",
        openToast: "true",
      });
      return;
    }
    const obj = {
      type: "Active",
      emp_id: empRow?.empId,
      date: moment(date).format("YYYY-MM-DD"),
      description: descRef.current.value,
    };
    setloader(true);
    const response = await UpdateEmpStatApi(obj);
    setsnackBarData(response.snackBarData);
    setloader(false);

    if (response.data.IsSuccess) {
      dispatch(FetchAllInActiveEmployee());
      handleClose();
    }
  };
  useEffect(() => {
    let arr = [];
    if (AllEmployees) {
      AllEmployees?.forEach((item, idx) => {
        let Technology = item?.emptech?.map((tech) => {
          return tech?.technology_name;
        });
        let Expertie = item?.emp_expertise?.map((exp) => {
          return exp?.expertise_name;
        });
        let timezone = item?.shift_rules?.time_zone
          ? JSON.parse(item?.shift_rules?.time_zone)
          : {};

        return arr.push({
          id: idx + 1,
          empId: item?.Emp_id,
          name: item?.name,
          fathername: item?.father_name,
          cnic: item?.cnic,
          dob: item?.dob ? moment(item?.dob).format("DD-MMM-YYYY") : "",
          phone: item?.phone,
          shiftrules: item?.shift_rules?.shift_name,
          details: item?.details,
          status: item?.status,
          department: item?.department?.department_name,
          technology: Technology?.toString(),
          expertie: Expertie?.toString(),
          designation: item?.designation?.designation_name,
          time_zone: timezone?.label,
          InactiveDate: item?.inactive_date ? moment(item?.inactive_date).format("DD-MMM-YYYY") : "",
        });
      });
    }

    setrows(arr);
    setfilterRows(arr);
  }, [AllEmployees]);

  const onTextChange = () => {
    let name = document.getElementById("employeeName").value;
    let dept = document.getElementById("employeeDept").value;
    let desig = document.getElementById("employeeDesig").value;
    if (name == "" && dept == "" && desig == "") {
      setfilterRows(rows);
    } else {
      let suggestions = [];
      const nameRegix = new RegExp(name, "i");
      const deptRegix = new RegExp(dept, "i");
      const desigRegix = new RegExp(desig, "i");
      suggestions = rows.filter(
        (val) =>
          (name === "" || nameRegix.test(val?.name)) &&
          (dept === "" || deptRegix.test(val?.department)) &&
          (desig === "" || desigRegix.test(val?.designation))
      );

      setfilterRows(suggestions);
    }
  };

  useEffect(() => {
    if (
      getemployeeDetails?.status === "succeeded" ||
      getemployeeDetails?.status === "failed"
    ) {
      // setloading(false);
      if (getemployeeDetails?.isSuccess === true) {
        setAlertType("success");
        setmessage(getemployeeDetails.message);
        setopenToast(true);
      }
      if (getemployeeDetails?.isSuccess === false) {
        setAlertType("error");
        setmessage(getemployeeDetails.message || getemployeeDetails.error);
        setopenToast(true);
      }
    }
  }, [getemployeeDetails]);
  useEffect(() => {
    if (status === "failed") {
      setsnackBarData({
        type: "error",
        message: "Network Error",
        openToast: true,
      });
    }
  }, [status]);
  return (
    <>
      {canView === true ? (
        <>
          <Grid container spacing={2} sx={{ padding: " 15px 10px 10px 10px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "center",
                padding: "10px",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon
                        sx={{ color: (theme) => theme.palette.grey.G2 }}
                      />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  width: "100%",
                  maxWidth: "180px",
                }}
                variant="standard"
                type="text"
                autoComplete="off"
                placeholder="Employee"
                id="employeeName"
                onChange={onTextChange}
              />
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon
                        sx={{ color: (theme) => theme.palette.grey.G2 }}
                      />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  width: "100%",
                  maxWidth: "200px",
                }}
                variant="standard"
                type="text"
                autoComplete="off"
                placeholder="Department"
                id="employeeDept"
                onChange={onTextChange}
              />
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon
                        sx={{ color: (theme) => theme.palette.grey.G2 }}
                      />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  width: "100%",
                  maxWidth: "200px",
                }}
                variant="standard"
                type="text"
                autoComplete="off"
                placeholder="Designation"
                id="employeeDesig"
                onChange={onTextChange}
              />
            </Box>

            {(allInactiveEmployees?.length <= 0 || allInactiveEmployees === null) && status === "loading" ? (
              <>
                <Loader />
              </>
            ) : null}
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              overflow
              sx={{ height: tabelStyle.height }}
            >
              <DataGrid
                disableColumnMenu
                hideFooterSelectedRowCount={true}
                rows={filterRows}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[15, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                pagination
                components={{
                  Toolbar: CustomToolbar,
                }}
                localeText={{
                  toolbarDensity: "Size",
                  toolbarDensityLabel: "Size",
                  toolbarDensityCompact: "Small",
                  toolbarDensityStandard: "Medium",
                  toolbarDensityComfortable: "Large",
                }}
              />
            </Grid>

            <Modal
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
              }}
              open={open}
              onClose={() => {
                setselectedEmployee(null);
                handleClose();
              }}
            >
              <Paper sx={{ width: "100%", maxWidth: "500px", padding: "10px" }}>
                <Typography sx={{ textAlign: "left" }}>Reason</Typography>
                <TextField
                  placeholder={`Please Enter Reason to Active ${selectedEmployee?.name}`}
                  sx={{ width: "100%", maxWidth: "500px" }}
                  variant="outlined"
                  multiline
                  minRows={4}
                  inputRef={descRef}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    paddingTop: "10px",
                    gap: "10px",
                  }}
                >
                  <Button
                    sx={{
                      background: "red",
                      color: "white !important",
                      fontSize: "13px",
                      textTransform: "none",
                    }}
                    onClick={() => {
                      setselectedEmployee(null);
                      handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                  {loader ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      sx={{
                        background: "#3480FA",
                        color: "white !important",
                        fontSize: "13px",
                        // margin: "0px auto",
                        textTransform: "none",
                      }}
                      onClick={() => {
                        hanldeActiveEmp(selectedEmployee);
                      }}
                    >
                      Save
                    </Button>
                  )}
                </Box>
              </Paper>
            </Modal>
          </Grid>
        </>
      ) : canView === false ? (
        <Navigate to={"/dashboard"} />
      ) : (
        <Box>Loading ...</Box>
      )}
    </>
  );
}

export default InActiveEmployees;
