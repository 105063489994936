import PropTypes from "prop-types";
import { Box, gridClasses, InputAdornment, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
// import DeleteIcon from "@mui/icons-material/Delete";
import { makeStyles } from "@mui/styles";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";

import moment from "moment/moment";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "../Button/AddNewButton";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";

import Loader from "../Loader/Loader";
import {
  // deleteBreakAdjustment,
  fetchBreakAdjustments,
  fetchempBreakAdjustment,
  getBreakAdjustmentDetails,
} from "../../Redux/Slice/BreakAdjustment/BreakaAjustmentSlice";
import Cookies from "universal-cookie";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});

function BreakAdjustmentTable({
  setShowForm,
  setAdjData,
  // ShowForm,
  setisEdited,
}) {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const { tabelStyle } = useContext(TabelStyleContext);
  const { setsnackBarData } = useContext(SnackBarContext);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { BreakAdjustments, status, IsLogin, message } = useSelector(
    getBreakAdjustmentDetails
  );

  const { header } = useStyles();
  const [pageSize, setPageSize] = useState(15);
  const [rows, setrows] = useState([]);
  const [breakreq, setbreakreq] = useState([]);

  const cookies = new Cookies();

  const user = cookies.get("user");

  const Name = useRef();
  const Date = useRef();

  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [editPermit, seteditpermit] = useState(false);

  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  useEffect(() => {
    let string = "BreakAdjustment";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Edit") {
        seteditpermit(true);
      }
    });
    if (!roles || roles.length < 1) {
      seteditpermit(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);

  useEffect(() => {
    if (cookies.get("user")?.type === "Employee") {
      dispatch(fetchempBreakAdjustment(cookies.get("user")?.Emp_id));
    } else if (cookies.get("user")?.type === "admin") {
      dispatch(fetchBreakAdjustments());
    }
  }, [dispatch]);

  useEffect(() => {
    let rowData = [];
    console.log("BreakAdjustments", BreakAdjustments);
    BreakAdjustments?.map((adv, idx) => {
      rowData.push({
        ...adv,
        id: idx + 1,
        requestedDate: adv?.requestedDate,
        requestedTime: adv?.requestedTime * 60,
        approvalStatus: adv?.approvalStatus,
        reason: adv?.reason,
        reasonId: adv?.reasonId,
        emp_id: adv?.emp_id,
      });
    });
    setrows(rowData);
    setbreakreq(rowData);
  }, [BreakAdjustments]);

  useEffect(() => {
    if (status === "failed") {
      setsnackBarData({
        type: "error",
        message: "Network Error",
        openToast: true,
      });
    }
  }, [status]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport csvOptions={{ fileName: "BreakAdjustmentRequest" }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }

  const onTextChange = () => {
    if (Name.current?.value === "" && Date.current.value === "") {
      setbreakreq(rows);
    } else {
      let suggestions = [];
      let regex1 = new RegExp(Name.current?.value, "i");
      let regex2 = new RegExp(moment(Date.current.value).format("MMM-YYYY"), "i");
      suggestions = rows?.filter(
        (val) =>
          (Name.current?.value === "" || regex1.test(val?.name)) &&
          (Date.current?.value === "" ||
            regex2.test(moment(val?.requestedDate).format("MMM-YYYY")))
      );
      setbreakreq(suggestions);
    }
  };

  const columns = [
    { field: "id", headerName: "Sr #", headerClassName: header },
    {
      field: "requestedDate",
      headerName: "Date",
      width: 180,
      headerClassName: header,
    },
    {
      field: "reason",
      headerName: "Reason",
      width: 160,
      headerClassName: header,
    },
    {
      field: "requestedTime",
      headerName: "Requested Time",
      width: 160,
      headerClassName: header,
      renderCell: (params) => {
        return `${params.value}M`;
      },
    },
    {
      field: "approvalTime",
      headerName: "Approval Time",
      width: 160,
      headerClassName: header,
      renderCell: (params) => {
        return <span>{params.value > 0 ? `${params.value}M` : "N/A"}</span>;
      },
    },
    {
      field: "approvalStatus",
      headerName: "Status",
      width: 180,
      headerClassName: header,
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 160,
      headerClassName: header,
      renderCell: (celval) => {
        const isDisabled =
          celval.row.approvalStatus === "Accepted" ||
          celval.row.approvalStatus === "Rejected";
        return (
          <>
            <Box sx={{ display: "flex", gap: "25px", alignItems: "center" }}>
              {editPermit ? (
                <Tooltip title={isDisabled ? "Action not allowed" : "Update Request"}>
                  <AppRegistrationIcon
                    sx={{
                      cursor: isDisabled ? "not-allowed" : "pointer",
                      opacity: isDisabled ? 0.5 : 1,
                    }}
                    onClick={() => {
                      if (!isDisabled) {
                        console.log("row data", celval.row);
                        setAdjData(celval.row);
                        setisEdited(true);
                        setShowForm(true);
                      }
                    }}
                  />
                </Tooltip>
              ) : null}
              {/* {editPermit ? (
                <Tooltip title="Delete">
                  <DeleteIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      try {
                        dispatch(deleteBreakAdjustment(celval?.row?.reasonId));

                        setsnackBarData({
                          type: "success",
                          message:
                            "Break Adjustment Request deleted successfully",
                          openToast: true,
                        });
                      } catch (error) {
                        console.error(
                          "Error deleting break adjustment:",
                          error
                        );

                        setsnackBarData({
                          type: "error",
                          message: "Failed to delete Break Adjustment Request",
                          openToast: true,
                        });
                      }
                    }}
                  />
                </Tooltip>
              ) : null} */}
            </Box>
          </>
        );
      },
    },
  ];

  return (
    <>
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            padding: "10px 10px 0px 10px",
          }}
        >
          {user?.type === "Employee" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <CustomButton
                Color="primary"
                Icon={<AddIcon />}
                onClick={() => {
                  setShowForm(true);
                }}
                Label="Add"
              />
            </Box>
          )}

          <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
            <Box
              sx={{
                padding: "10px 10px 0px 10px",
                display: "flex",
                gap: "10px",
              }}
            >
              {user?.type !== "Employee" && (
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: (theme) => theme.palette.grey.G2 }} />
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  type="text"
                  autoComplete="off"
                  placeholder="Employee"
                  id="empName"
                  inputRef={Name}
                  onChange={onTextChange}
                />
              )}
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: (theme) => theme.palette.grey.G2 }} />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                type="month"
                autoComplete="off"
                placeholder="Search"
                id="date"
                inputRef={Date}
                onChange={onTextChange}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ height: tabelStyle.height, padding: "10px" }}>
          {status === "loading" && BreakAdjustments?.length <= 0 && <Loader />}
          <DataGrid
            rows={breakreq}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[15, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            columnVisibilityModel={
              user?.type === "Employee"
                ? { action: false, name: false }
                : { status: true }
            }
            // components={{
            //   Toolbar: CustomToolbar,
            // }}
            components={isSmallScreen ? {} : { Toolbar: CustomToolbar }}
            localeText={{
              toolbarDensity: "Size",
              toolbarDensityLabel: "Size",
              toolbarDensityCompact: "Small",
              toolbarDensityStandard: "Medium",
              toolbarDensityComfortable: "Large",
            }}
          />
        </Box>
      </>
      ;
    </>
  );
}

export default BreakAdjustmentTable;
BreakAdjustmentTable.propTypes = {
  setShowForm: PropTypes.any,
  setAdjData: PropTypes.any,
  setisEdited: PropTypes.any,
  ShowForm: PropTypes.any,
};
