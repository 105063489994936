import PropTypes from "prop-types";
import {
  Box,
  // Button,
  CircularProgress,
  gridClasses,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import theme from "../../theme";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  fetchExperties,
  getExpertiesDetails,
} from "../../Redux/Slice/Experties/ExpertiesSlice";
import DeleteModal from "../DeleteModal/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
import { DeleteExpertiesApi } from "../../Api/Experties/ExpertiesAPis";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "../Button/AddNewButton";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});
function ExpertiesTable({ setShowForm, EditedData, setIsEdit, setEditedData }) {
  const { header } = useStyles();
  const dispatch = useDispatch();
  const [showDeleteModal, setshowDeleteModal] = useState(false);

  const [loading, setloading] = useState(false);
  const [rows, setrows] = useState([]);
  const [filterRows, setfilterRows] = useState([]);
  const [pageSize, setPageSize] = useState(15);

  const { setsnackBarData } = useContext(SnackBarContext);
  const ExpertiesDetails = useSelector(getExpertiesDetails);
  const { IsLogin, message } = useSelector(getExpertiesDetails);
  const navigate = useNavigate();

  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [editPermit, seteditpermit] = useState(false);
  const [addPermit, setaddpermit] = useState(false);
  const [deletePermit, setdeletepermit] = useState(false);
  // console.log(crop);

  console.log(roles);
  useEffect(() => {
    let string = "Experties";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Edit") {
        seteditpermit(true);
      } else if (val?.module?.split("_")[1] === "Add") {
        setaddpermit(true);
      } else if (val?.module?.split("_")[1] === "Delete") {
        setdeletepermit(true);
      }
    });
    if (!roles || roles.length < 1) {
      setaddpermit(true);
      seteditpermit(true);
      setdeletepermit(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  console.log(editPermit);
  console.log(addPermit);
  console.log(deletePermit);

  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  useEffect(() => {
    dispatch(fetchExperties());
  }, [dispatch]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport csvOptions={{ fileName: "Experties" }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }
  const columns = [
    { field: "sr", headerName: "Sr #", flex: 0, headerClassName: header },
    {
      field: "Soft_Skill",
      headerName: "Soft Skill",
      width: 180,
      headerClassName: header,
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 180,
      headerClassName: header,
      renderCell: (celVal) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              gap: "10px",
            }}
          >
            {editPermit ? (
              <IconButton
                size={"small"}
                onClick={() => {
                  setEditedData(celVal.row);
                  setIsEdit(true);
                  setShowForm(true);
                }}
              >
                <AppRegistrationIcon
                  sx={{ cursor: "pointer", color: "black !important" }}
                />
              </IconButton>
            ) : null}
            {deletePermit ? (
              <IconButton
                onClick={() => {
                  setEditedData(celVal.row);
                  setshowDeleteModal(true);
                }}
                size={"small"}
              >
                <DeleteOutlineIcon sx={{ color: theme.palette.error.main }} />
              </IconButton>
            ) : null}
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    let arr = [];
    if (ExpertiesDetails) {
      ExpertiesDetails?.experties?.forEach((item, idx) => {
        return arr.push({
          ...item,
          sr: idx + 1,
          id: idx + 1,
          Soft_Skill: item?.skill_name

        });
      });
    }
    setrows(arr);
    setfilterRows(arr);
  }, [ExpertiesDetails]);

  const onTextChange = () => {
    if (document.getElementById("OrderNoFilter").value === "") {
      setfilterRows(rows);
    } else {
      let suggestions = [];

      var regex1 = new RegExp(
        document.getElementById("OrderNoFilter").value,
        "i"
      );

      suggestions = rows?.filter(
        (val) =>
          document.getElementById("OrderNoFilter").value === "" ||
          regex1.test(val.skill_name)
      );

      setfilterRows(suggestions);
    }
  };
  const handelDelete = async () => {
    setloading(true);
    const response = await DeleteExpertiesApi(EditedData?.skill_id);
    setsnackBarData(response.snackBarData);
    setloading(false);
    setshowDeleteModal(false);
    if (response.data?.IsSuccess) {
      dispatch(fetchExperties());

    }
  };

  return (
    <Box spacing={2} sx={{ padding: "10px 0px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            padding: "10px",
          }}
        >
          {addPermit ? (
            <>
              {/* <Button
                sx={{ padding: "5px 10px" }}
                onClick={() => {
                  setShowForm(true);
                }}
                variant="contained"
                className="tabletopbutton1 text-right"
              >
                New
              </Button> */}
              <CustomButton
                Color={"primary"}
                Icon={<AddIcon />}
                onClick={() => {
                  setShowForm(true);
                }}
                Label={"Add"}
              />
            </>
          ) : null}
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{ color: (theme) => theme.palette.grey.G2 }}
                  />
                </InputAdornment>
              ),
            }}
            sx={{
              width: "100%",
              maxWidth: "180px",
            }}
            variant="standard"
            type="text"
            autoComplete="off"
            placeholder="Experties"
            className="input_border"
            id="OrderNoFilter"
            onChange={onTextChange}
          />
        </Box>
      </Box>

      <Box
        style={{
          height: "calc(100vh - 245px)",
          width: "100%",
          padding: "10px",
        }}
      >
        {ExpertiesDetails?.status !== "succeeded" ? (
          <Box
            sx={{
              height: "calc(100vh - 245px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <DataGrid
            rows={filterRows}
            columns={columns}
            onRowClick={() => { }}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[15, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            pagination
            components={{
              Toolbar: CustomToolbar,
            }}
            localeText={{
              toolbarDensity: "Size",
              toolbarDensityLabel: "Size",
              toolbarDensityCompact: "Small",
              toolbarDensityStandard: "Medium",
              toolbarDensityComfortable: "Large",
            }}
          />
        )}
      </Box>
      <DeleteModal
        name={`"${EditedData?.skill_name}" Soft Skill`}
        handelDelete={handelDelete}
        showModal={showDeleteModal}
        setshowModal={setshowDeleteModal}
        loading={loading}
      />
    </Box>
  );
}

export default ExpertiesTable;
ExpertiesTable.propTypes = {
  setShowForm: PropTypes.any,
  setIsEdit: PropTypes.func,
  setEditedData: PropTypes.func,
  EditedData: PropTypes.any,
};
