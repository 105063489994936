import "./App.css";
import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import {
  AdminRoutes,
  EmployeeRoutes,
  SuperAdminRoutes,
} from "./ListOfPaths/ListOfPaths";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import CssBaseline from "@mui/material/CssBaseline";
import { Box } from "@mui/material";
import SnackBarContextProvider from "./Context/SnackBarContext/SnackBarContext";
import TabelStyleContextProvider from "./Context/TabelStyleContext/TabelContext";
import SnackBar from "./Components/SnackBar/SnackBar";
import { HeaderContext } from "./Context/SnackBarContext/HeaderContext/HeaderContext";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import Cookies from "universal-cookie";
import LoginContent from "./Pages/Login/LoginContent";
import ProfileContextProvider from "./Context/ProfileContext/ProfileContext";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import ChangeForgotPassword from "./Pages/ChangeForgotPassword/ChangeForgotPassword";
import EmailVarification from "./Pages/EmailVarification/EmailVarification";
import { TooltipContextProvider } from "./Context/TooltipContext/TooltipContent";

const cookies = new Cookies();
function App() {
  const { setheader } = useContext(HeaderContext);
  const [usertype, setusertype] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setheader(null);
  }, [navigate]);
  useEffect(() => {
    const user = cookies.get("user");

    setusertype(user?.type);
  }, [cookies.get("user")]);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          fontFamily: "Poppins !important",
          backgroundColor: (theme) => theme.palette.background.main,
        }}
        className="App"
      >
        <TabelStyleContextProvider>
          <SnackBarContextProvider>
            <ProfileContextProvider>
              <TooltipContextProvider>
                <Routes>
                  <Route
                    exact
                    path="/"
                    element={<LoginContent />}
                  />

                  {usertype === "Admin" ? (
                    AdminRoutes?.map((route) => {
                      return (
                        <Route
                          key={route.path}
                          exact
                          path={route.path}
                          element={route.component}
                        />
                      );
                    })
                  ) : usertype === "Employee" ? (
                    EmployeeRoutes?.map((route) => {
                      return (
                        <Route
                          key={route.path}
                          exact
                          path={route.path}
                          element={route.component}
                        />
                      );
                    })
                  ) : usertype === "Super Admin" ? (
                    SuperAdminRoutes?.map((route) => {
                      return (
                        <Route
                          key={route.path}
                          exact
                          path={route.path}
                          element={route.component}
                        />
                      );
                    })
                  ) : (
                    <>
                      <Route
                        exact
                        path="/"
                        element={<LoginContent />}
                      />
                      <Route
                        exact
                        path="/changeforgotpassword/:userid"
                        element={<ChangeForgotPassword />}
                      />
                      <Route
                        exact
                        path="/forgotpassword"
                        element={<ForgotPassword />}
                      />
                      <Route
                        exact
                        path="/login/emailvarification"
                        element={<EmailVarification />}
                      />
                    </>
                  )}
                </Routes>

                <SnackBar />
              </TooltipContextProvider>
            </ProfileContextProvider>
          </SnackBarContextProvider>
        </TabelStyleContextProvider>
      </Box>
    </ThemeProvider>
  );
}

export default App;
