import * as React from "react";
import {
  Box,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment/moment";
import SkillsViewModal from "./SkillsViewModal";

function DepartmentDetail({ AllSingEmpDetail }) {
  const [allSingEmpDetail, setallSingEmpDetail] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    setallSingEmpDetail(AllSingEmpDetail);
  }, [AllSingEmpDetail]);

  return (
    <>
      <Box sx={{ padding: "10px" }}>
        <Typography
          sx={{ textAlign: "left", fontWeight: "bold", fontSize: { xs: "16px", sm: "18px" }, }}
        >
          Department Details
        </Typography>
        <Divider />
        <Paper elevation={3} sx={{ margin: "20px 0px 0px 0px" }}>
          <Box
            sx={{
              display: "flex",
              // gap: "60px",
              gap: { xs: "10px", sm: "60px" },
              padding: "10px",
              paddingX: "20px",
              flexDirection: { xs: 'column', sm: "row" },

            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "30px",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography sx={{ fontWeight: "bold", fontSize: { xs: "15px", sm: "16px" }, }}>
                Department Name:
              </Typography>
              <Typography sx={{
                fontSize: { xs: "15px", sm: "16px" },

              }}>
                {allSingEmpDetail?.Employee?.department?.department_name}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "30px",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography sx={{ fontWeight: "bold", fontSize: { xs: "15px", sm: "16px" }, }}>Job Title:</Typography>
              <Typography sx={{
                fontSize: { xs: "15px", sm: "16px" },

              }}>
                {allSingEmpDetail?.Employee?.jobtitle?.job_title}
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Box>
      <Box sx={{ width: "100%", padding: "10px", maxWidth: { xs: "300px", sm: "100%" } }}>
        <Typography
          sx={{
            textAlign: "left",
            padding: "20px 0px 0px 0px",
            fontWeight: "bold",
            fontSize: { xs: "16px", sm: "18px" },

          }}
        >
          Technologies
        </Typography>
        <TableContainer sx={{ overflowY: "scroll", paddingTop: "10px" }}>
          <Table
            sx={{
              minWidth: { md: "auto", xs: "auto" },
              overflow: "scroll",
            }}
            aria-label="simple table"
            size="small"
          >
            <TableHead>
              <TableRow size="small">
                <TableCell
                  size="small"
                  sx={{ fontWeight: "bold", width: "100px" }}
                >
                  Id
                </TableCell>
                <TableCell size="small" sx={{ fontWeight: "bold", }}>
                  Technology Name
                </TableCell>
                <TableCell size="small" sx={{ fontWeight: "bold" }}>
                  Experties
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allSingEmpDetail?.Employee?.emptech?.map((tech, ind) => {
                let experties = tech?.empexpertise?.map((expert) => {
                  return expert?.expertise_name;
                });
                return (
                  <TableRow
                    key={tech?.technology_name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell size="small" component="th" scope="row">
                      {ind + 1}
                    </TableCell>
                    <TableCell size="small">{tech?.technology_name}</TableCell>

                    <TableCell
                      onClick={() => {
                        setSelectedItem(tech);
                        setOpenDialog(true);
                      }}
                      sx={{ cursor: "pointer" }}
                      size="small"
                    >
                      {experties?.toString(",")}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {/* <Box sx={{ width: "100%", padding: "10px 10px 10px 10px" }}>
        <Typography
          sx={{
            textAlign: "left",
            padding: "20px 0px 0px 0px",
            fontWeight: "bold",
            fontSize: "18px",
          }}
        >
          Experties
        </Typography>
        <TableContainer sx={{ overflowY: "scroll", paddingTop: "10px" }}>
          <Table
            sx={{
              minWidth: { md: "auto", xs: "auto" },
              overflow: "scroll",
            }}
            aria-label="simple table"
            size="small"
          >
            <TableHead>
              <TableRow size="small">
                <TableCell
                  size="small"
                  sx={{ fontWeight: "bold", width: "100px" }}
                >
                  Id
                </TableCell>
                <TableCell size="small" sx={{ fontWeight: "bold" }}>
                  Expertie Name
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allSingEmpDetail?.Employee?.emp_expertise?.map((exp, ind) => (
                <TableRow
                  key={exp?.expertise_name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell size="small" component="th" scope="row">
                    {ind + 1}
                  </TableCell>
                  <TableCell size="small">{exp?.expertise_name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box> */}
      {allSingEmpDetail?.promotion?.length > 0 ? (
        <Box sx={{ width: "100%", padding: "10px" }}>
          <Typography
            sx={{
              textAlign: "left",
              padding: "20px 0px 0px 0px",
              fontWeight: "bold",
              fontSize: "18px",
            }}
          >
            Promotion/Demotion Details
          </Typography>
          <TableContainer sx={{ overflowY: "scroll", paddingTop: "10px" }}>
            <Table
              sx={{
                minWidth: { md: "auto", xs: "auto" },
                overflow: "scroll",
              }}
              aria-label="simple table"
              size="small"
            >
              <TableHead>
                <TableRow size="small">
                  <TableCell size="small" sx={{ fontWeight: "bold", fontSize: { xs: "12px", sm: "15px" } }}>
                    Id
                  </TableCell>
                  <TableCell
                    size="small"
                    align="center"
                    sx={{ fontWeight: "bold", fontSize: { xs: "12px", sm: "15px" } }}
                  >
                    Type
                  </TableCell>
                  <TableCell
                    size="small"
                    align="center"
                    sx={{ fontWeight: "bold", fontSize: { xs: "12px", sm: "15px" } }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    size="small"
                    align="center"
                    sx={{ fontWeight: "bold", fontSize: { xs: "12px", sm: "15px" } }}
                  >
                    Father Name
                  </TableCell>
                  <TableCell
                    size="small"
                    align="center"
                    sx={{ fontWeight: "bold", fontSize: { xs: "12px", sm: "15px" } }}
                  >
                    Department
                  </TableCell>
                  <TableCell
                    size="small"
                    align="center"
                    sx={{ fontWeight: "bold", fontSize: { xs: "12px", sm: "15px" } }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    size="small"
                    align="center"
                    sx={{ fontWeight: "bold", fontSize: { xs: "12px", sm: "15px" } }}
                  >
                    Previous Designation
                  </TableCell>
                  <TableCell
                    size="small"
                    align="center"
                    sx={{ fontWeight: "bold", fontSize: { xs: "12px", sm: "15px" } }}
                  >
                    New Designation
                  </TableCell>
                  <TableCell
                    size="small"
                    align="center"
                    sx={{ fontWeight: "bold", fontSize: { xs: "12px", sm: "15px" } }}
                  >
                    Description
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allSingEmpDetail?.promotion?.map((prom, ind) => (
                  <TableRow
                    key={prom?.historyid?.emp_id?.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell size="small" component="th" scope="row">
                      {ind + 1}
                    </TableCell>
                    <TableCell size="small" align="center">
                      {prom?.historyid?.type}
                    </TableCell>
                    <TableCell size="small" align="center">
                      {prom?.historyid?.emp_id?.name}
                    </TableCell>
                    <TableCell size="small" align="center">
                      {prom?.historyid?.emp_id?.father_name}
                    </TableCell>
                    <TableCell size="small" align="center">
                      {prom?.historyid?.emp_id?.department?.department_name}
                    </TableCell>
                    <TableCell size="small" align="center">
                      {moment(prom?.historyid?.date).format("DD-MMM-YYYY")}
                    </TableCell>
                    <TableCell size="small" align="center">
                      {prom?.prev_designation?.designation_name}
                    </TableCell>
                    <TableCell size="small" align="center">
                      {prom?.new_designation?.designation_name}
                    </TableCell>
                    <TableCell size="small" align="center">
                      {prom?.historyid?.description}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : null}
      <SkillsViewModal
        open={openDialog}
        setOpen={setOpenDialog}
        selected={selectedItem}
      />
    </>
  );
}

export default DepartmentDetail;
DepartmentDetail.propTypes = {
  AllSingEmpDetail: PropTypes.any,
};
