import PropTypes from "prop-types";
import {
  Box,
  // Button,
  CircularProgress,
  gridClasses,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import React, { useEffect, useState } from "react";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

import { useDispatch, useSelector } from "react-redux";
import {
  getAllRules,
  fetchRules,
} from "../../Redux/Slice/Rules/RulesSlice";
import { makeStyles } from "@mui/styles";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "../Button/AddNewButton";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});
function RulesTable({ setShowForm, setIsEdit,
  setEditedData,
}) {
  const { header } = useStyles();
  const dispatch = useDispatch();
  const [rows, setrows] = useState([]);

  const [filterRows, setfilterRows] = useState([]);
  const [pageSize, setPageSize] = useState(15);
  const navigate = useNavigate();
  const { setsnackBarData } = useContext(SnackBarContext);
  const { IsLogin, message, modules, status } = useSelector(getAllRules);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  useEffect(() => {
    dispatch(fetchRules());
  }, [dispatch]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport csvOptions={{ fileName: "Rules" }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }
  const columns = [
    { field: "sr", headerName: "Sr #", flex: 0, headerClassName: header },
    {
      field: "module",
      headerName: "Modules",
      flex: 1,
      headerClassName: header,
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 200,
      headerClassName: header,
      renderCell: (celVal) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              gap: "10px",
            }}
          >
            <IconButton
              size={"small"}
              onClick={() => {
                setEditedData(celVal.row);
                setIsEdit(true);
                setShowForm(true);
              }}
            >
              <AppRegistrationIcon sx={{ cursor: "pointer", color: "black !important" }} />
            </IconButton>

          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    let arr = [];
    if (modules) {
      modules?.forEach((item, idx) => {
        return arr.push({

          sr: idx + 1,
          id: idx + 1,
          module: item
        });
      });
    }
    setrows(arr);
    setfilterRows(arr);
  }, [modules]);

  const onTextChange = () => {
    if (document.getElementById("OrderNoFilter").value === "") {
      setfilterRows(rows);
    } else {
      let suggestions = [];

      var regex1 = new RegExp(
        document.getElementById("OrderNoFilter").value,
        "i"
      );

      suggestions = rows?.filter(
        (val) =>
          document.getElementById("OrderNoFilter").value === "" ||
          regex1.test(val.Allowancetype)
      );

      setfilterRows(suggestions);
    }
  };


  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          padding: "20px 10px 10px 10px",
        }}
      >
        <Box>
          {/* <Button
            sx={{ padding: "5px 10px" }}
            onClick={() => {
              setShowForm(true);
            }}
            variant="contained"
            className="tabletopbutton1 text-right"
          >
            New
          </Button> */}
          <CustomButton
            Color={"primary"}
            Icon={<AddIcon />}
            onClick={() => {
              setShowForm(true);
            }}
            Label={"Add"}
          />
        </Box>
        <Box>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{ color: (theme) => theme.palette.grey.G2 }}
                  />
                </InputAdornment>
              ),
            }}
            sx={{
              width: "100%",
              maxWidth: "180px",
            }}
            variant="standard"
            type="text"
            autoComplete="off"
            placeholder="Rule Type"
            className="input_border"
            id="OrderNoFilter"
            onChange={onTextChange}
          />
        </Box>
      </Box>

      <Box
        style={{
          height: "calc(100vh - 235px)",
          width: "100%",
          padding: "10px",
        }}
      >
        {status !== "succeeded" ? (
          <Box
            sx={{
              height: "calc(100vh - 235px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <DataGrid
            rows={filterRows}
            columns={columns}
            onRowClick={() => { }}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[15, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            pagination
            components={{
              Toolbar: CustomToolbar,
            }}
            localeText={{
              toolbarDensity: "Size",
              toolbarDensityLabel: "Size",
              toolbarDensityCompact: "Small",
              toolbarDensityStandard: "Medium",
              toolbarDensityComfortable: "Large",
            }}
          />
        )}
      </Box>
    </>
  );
}

export default RulesTable;
RulesTable.propTypes = {
  setShowForm: PropTypes.any,
  setIsEdit: PropTypes.func,
  setEditedData: PropTypes.func,
  EditedData: PropTypes.any,
};
