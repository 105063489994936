import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useEffect } from "react";
import MissingPunchesOfAllEmployees from "../../Components/MissingPunches/MissingPunchesOfAllEmployees";
import MissingPunchesDetails from "../../Components/MissingPunches/MissingPunchesDetail";
import moment from "moment";

const MissingPunchesReport = () => {
  const [ShowForm, setShowForm] = useState(false);
  const [canView, setcanView] = useState(null);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [rowvalue, setrowvalue] = useState(null);
  const [selectedDate, setselectedDate] = useState(moment().format("YYYY-MM"));

  useEffect(() => {
    let string = "MonthlyReport";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    let match = value?.some((val) => {
      if (val?.module?.split("_")[1] === "View") {
        return true;
      }
    });
    setcanView(match);
    console.log(match);
    if (!roles || roles.length < 1) {
      setcanView(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  return (
    <div>
      {canView === true ? (
        ShowForm ? (
          <MissingPunchesDetails
            setShowForm={setShowForm}
            rowvalue={rowvalue}
            setrowvalue={setrowvalue}
            setselectedDate={setselectedDate}
            selectedDate={selectedDate}
          />
        ) : (
          <MissingPunchesOfAllEmployees
            setShowForm={setShowForm}
            setrowvalue={setrowvalue}
            setselectedDate={setselectedDate}
            selectedDate={selectedDate}
            ShowForm={ShowForm}
          />
        )
      ) : canView === false ? (
        <Navigate to={"/dashboard"} />
      ) : (
        <Box>Loading ...</Box>
      )}
    </div>
  );
};

export default MissingPunchesReport;
MissingPunchesReport.propTypes = {
  setShowForm: PropTypes.any,
};
