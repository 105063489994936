import PropTypes from "prop-types";
import {
  Box,
  // CircularProgress,
  gridClasses,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

import {
  deleteDetechnology,
  fetchTechnologies,
  getTechnologiesDetails,
} from "../../Redux/Slice/Technologies/TechnologiesSlice";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useNavigate } from "react-router-dom";
import theme from "../../theme";
import DeleteModal from "../DeleteModal/DeleteModal";
import { DeleteTechnology } from "../../Api/Technologies/TechnologiesAPis";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "../Button/AddNewButton";
// import InfoIcon from "@mui/icons-material/Info";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";
import SkillSetModel from "./SkillSetModel";
import Loader from "../Loader/Loader";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});
function TechnologiesTable({
  setShowForm,
  setIsEdit,
  setEditedData,
  EditedData,
}) {
  const { tabelStyle } = useContext(TabelStyleContext);
  const { header } = useStyles();
  const dispatch = useDispatch();
  const [rows, setrows] = useState([]);
  const [filterRows, setfilterRows] = useState([]);
  const [pageSize, setPageSize] = useState(15);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [loading, setloading] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);
  const TechnologiesDetails = useSelector(getTechnologiesDetails);
  const { IsLogin, message } = useSelector(getTechnologiesDetails);
  const navigate = useNavigate();
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [editPermit, seteditpermit] = useState(false);
  const [addPermit, setaddpermit] = useState(false);
  const [deletePermit, setdeletepermit] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  console.log(TechnologiesDetails);
  useEffect(() => {
    let string = "Technologies";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Edit") {
        seteditpermit(true);
      } else if (val?.module?.split("_")[1] === "Add") {
        setaddpermit(true);
      } else if (val?.module?.split("_")[1] === "Delete") {
        setdeletepermit(true);
      }
    });
    if (!roles || roles.length < 1) {
      setaddpermit(true);
      seteditpermit(true);
      setdeletepermit(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  console.log(editPermit);
  console.log(addPermit);
  console.log(deletePermit);

  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  useEffect(() => {
    dispatch(fetchTechnologies());
  }, [dispatch]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport csvOptions={{ fileName: "Technologies" }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }
  const columns = [
    { field: "id", headerName: "Sr #", flex: 0, headerClassName: header },
    {
      field: "technology_name",
      headerName: "Technology",
      width: 200,
      headerClassName: header,
    },

    {
      field: "expertise_name",
      headerName: "Skill_Set",
      width: 400,
      headerClassName: header,
      renderCell: (celVal) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              gap: "10px",
            }}
          >
            <span
            // style={{ cursor: "pointer" }}
            // onClick={() => {
            //   setSelectedItem(celVal.row);
            //   setOpenDialog(true);
            // }}
            >
              {celVal.value}
            </span>
          </Box>
        );
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 200,
      headerClassName: header,
      renderCell: (celVal) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              gap: "10px",
            }}
          >
            {editPermit ? (
              <IconButton
                size={"small"}
                onClick={() => {
                  setEditedData(celVal.row);
                  setIsEdit(true);
                  setShowForm(true);
                }}
              >
                <AppRegistrationIcon
                  sx={{ cursor: "pointer", color: "black !important" }}
                />
              </IconButton>
            ) : null}
            {deletePermit ? (
              <IconButton
                onClick={() => {
                  setEditedData(celVal.row);
                  setshowDeleteModal(true);
                }}
                size={"small"}
              >
                <DeleteOutlineIcon sx={{ color: theme.palette.error.main }} />
              </IconButton>
            ) : null}
            {/* {
              <IconButton >
                <InfoIcon
                  sx={{ cursor: "pointer", color: "black !important" }}
                  onClick={() => {
                    setSelectedItem(celVal.row);
                    setOpenDialog(true)
                  }}
                  size={"small"}
                />
              </IconButton>
            } */}
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    let arr = [];
    console.log(TechnologiesDetails);
    if (TechnologiesDetails) {
      TechnologiesDetails?.technologies?.forEach((item, idx) => {
        let experties = item?.expertiseid?.map((expert) => {
          return expert?.expertise_name;
        });
        return arr.push({
          ...item,
          id: idx + 1,
          technology_name:
            item?.technology_name[0]?.toUpperCase() +
            item?.technology_name?.substring(1),
          expertise_name: experties?.toString(","),
        });
      });
    }
    setrows(arr);
    setfilterRows(arr);
  }, [TechnologiesDetails]);

  const onTextChange = () => {
    if (document.getElementById("ProjectNameFilter").value === "") {
      setfilterRows(rows);
    } else {
      let suggestions = [];

      var regex1 = new RegExp(
        document.getElementById("ProjectNameFilter").value,
        "i"
      );
      suggestions = rows?.filter(
        (val) =>
          document.getElementById("ProjectNameFilter").value === "" ||
          regex1.test(val.technology_name)
      );

      setfilterRows(suggestions);
    }
  };
  const handelDelete = async () => {
    setloading(true);
    const response = await DeleteTechnology(EditedData?.tech_id);
    setsnackBarData(response.snackBarData);
    setloading(false);
    if (response.data?.IsSuccess) {
      setshowDeleteModal(false);

      dispatch(deleteDetechnology(EditedData?.tech_id));
    }
  };
  useEffect(() => {
    if (TechnologiesDetails?.status === "failed") {
      setsnackBarData({
        type: "error",
        message: TechnologiesDetails?.error,
        openToast: true,
      });
    }
  }, [TechnologiesDetails?.status]);
  return (
    <>
      <Box spacing={2} sx={{ padding: "10px 0px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              padding: "10px",
            }}
          >
            {addPermit ? (
              <>
                {/* <Button
                sx={{ padding: "5px 10px" }}
                onClick={() => {
                  setShowForm(true);
                }}
                variant="contained"
                className="tabletopbutton1 text-right"
              >
                New
              </Button> */}
                <CustomButton
                  Color={"primary"}
                  Icon={<AddIcon />}
                  onClick={() => {
                    setShowForm(true);
                  }}
                  Label={"Add"}
                />
              </>
            ) : null}
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      sx={{ color: (theme) => theme.palette.grey.G2 }}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{
                width: "100%",
                maxWidth: "220px",
              }}
              variant="standard"
              type="text"
              autoComplete="off"
              placeholder="Technology"
              className="input_border"
              id="ProjectNameFilter"
              onChange={onTextChange}
            />
          </Box>
        </Box>

        <Box sx={{ height: tabelStyle.height, padding: "10px" }}>
          {TechnologiesDetails?.technologies?.length <= 0 && TechnologiesDetails?.status === "loading" ? <Loader /> : null}
          <DataGrid
            sx={{ cursor: "pointer" }}

            rows={filterRows}
            columns={columns}
            onRowClick={(e) => {
              setSelectedItem(e.row);
              setOpenDialog(true);
            }}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[15, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            pagination
            components={{
              Toolbar: CustomToolbar,
            }}
            localeText={{
              toolbarDensity: "Size",
              toolbarDensityLabel: "Size",
              toolbarDensityCompact: "Small",
              toolbarDensityStandard: "Medium",
              toolbarDensityComfortable: "Large",
            }}
          />
        </Box>
        <DeleteModal
          name={`"${EditedData?.technology_name}" Technology`}
          handelDelete={handelDelete}
          showModal={showDeleteModal}
          setshowModal={setshowDeleteModal}
          loading={loading}
        />
        {/* {console.log("Tech Name", selectedItem?.technology_name)} */}

        <SkillSetModel
          open={openDialog}
          setOpen={setOpenDialog}
          selected={selectedItem}
        />
      </Box>
    </>
  );
}

export default TechnologiesTable;
TechnologiesTable.propTypes = {
  setShowForm: PropTypes.any,
  setIsEdit: PropTypes.func,
  setEditedData: PropTypes.func,
  EditedData: PropTypes.any,
  loadings: PropTypes.bool,
};
