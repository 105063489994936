import {
  Box,
  Button,
  FormControl,
  Typography,
  TextField,
  CircularProgress,
  MenuItem,
  Input,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Select,
  Paper,
} from "@mui/material";
window.Buffer = window.Buffer || require("buffer").Buffer;
import React, { useState } from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";
import { makeStyles } from "@mui/styles";
import { Field, Formik } from "formik";
import { number, object, string } from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useContext } from "react";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import {
  fetchEmployee,
  // employePopulate,
  // FetchSingleEmployee,
  getemployeeDetails,
} from "../../../Redux/Slice/Employee/EmplyeeSlice";
import { PatchPayRollApi } from "../../../Api/Employee/UpdatePayrollApi";
import { PostPayrollAPI } from "../../../Api/Employee/PostPayrollAPI";
import { HeaderContext } from "../../../Context/SnackBarContext/HeaderContext/HeaderContext";
// import { CheckBox } from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import { logout } from "../../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => {
  return {
    inputBox: {
      width: "100%",
    },
    input_typography: {
      textAlign: "left",

      fontSize: `15px !important`,

      marginTop: "10px !important",
      color: "black",
    },
    Form_input: {
      borderRadius: theme.borderRadius.All,
      backgroundColor: `${theme.palette.common.white} !important`,
      width: "100%",
    },

    btns: {
      width: "80px",
    },
    FieldInRow: {
      display: "flex",
      justifyContent: "space-between",
      gap: "20px",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        gap: "10px",
      },
    },
  };
});

const PayrollForm = ({
  empID,
  setShowForm,
  // empData,
  pageSize,
  page,
  isEdited,
  setisEdited,
  setstepper,
}) => {
  const { Form_input, input_typography, btns, inputBox, FieldInRow } =
    useStyles();
  const { Department, Designation, Jobtitle, ShiftRule, Role } =
    useSelector(getemployeeDetails);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedContract, setselectedContract] = useState(null);
  const [disableNext, setdisableNext] = useState(true);
  const [loading, setloading] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);
  const { singleEmployee, IsLogin, message } = useSelector(getemployeeDetails);
  const { setheader } = useContext(HeaderContext);
  const [checked, setchecked] = useState(true);
  const [verify, setverify] = useState(false);

  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [employeedata] = useState({
    department: {},
    designation: {},
    joining_date: "",
    shift_rules: {},
    roletype: {},
    contract_scan: "",
    payment_method: "",
    bank_name: "",
    bank_account_no: "",
    currency: "",
    jobtitle: {},
    basic_salary: {},
    overtime_ratio: 1,
    fpdevice_id: "",
  });

  useEffect(() => {
    if (isEdited) {
      employeedata.department = singleEmployee?.Employee?.department?.dept_id;
      employeedata.roletype =
        singleEmployee?.Employee?.loginemp?.roletype?.role_id;
      employeedata.designation =
        singleEmployee?.Employee?.designation?.designation_id;
      employeedata.joining_date = singleEmployee?.Employee?.joining_date;
      employeedata.shift_rules = singleEmployee?.Employee?.shift_rules?.rule_id;
      employeedata.jobtitle = singleEmployee?.Employee?.jobtitle?.jobTitle_id;
      employeedata.fpdevice_id =
        singleEmployee?.Employee?.loginemp?.fpdevice_id;
      employeedata.basic_salary = singleEmployee?.Employee?.basic_salary;
      employeedata.bank_account_no =
        singleEmployee?.BankDetails?.bank_account_no;
      employeedata.bank_name = singleEmployee?.BankDetails?.bank_name;
      employeedata.currency = singleEmployee?.BankDetails?.currency;
      employeedata.payment_method = singleEmployee?.Employee?.payment_method;
      setchecked(singleEmployee?.Employee?.sandwichRule_status);
      // setselectedContract(singleEmployee?.Employee?.contract_scan)
      // employeedata.sandwichRule_status = singleEmployee?.Employee?.sandwichRule_status;
    }
  }, [singleEmployee]);

  const handelAdd = async (values) => {
    let formData = new FormData();
    formData.append("emp_id", empID || singleEmployee?.Employee?.Emp_id);
    formData.append("department", values?.department);
    formData.append("roletype", values?.roletype);
    formData.append("designation", values?.designation);
    formData.append("joining_date", values?.joining_date);
    formData.append("overtime_ratio", values?.overtime_ratio);
    formData.append("shift_rules", values?.shift_rules);
    formData.append("payment_method", values?.payment_method);
    formData.append("bank_name", values?.bank_name);
    formData.append("bank_account_no", values?.bank_account_no);
    formData.append("currency", values?.currency);
    formData.append("fpdevice_id", values?.fpdevice_id);
    formData.append(
      "contractscan",
      selectedContract ? selectedContract : values?.contract_scan
    );
    formData.append("jobtitle", values?.jobtitle);
    formData.append("basic_salary", values?.basic_salary);
    formData.append("sandwichRule_status", checked);
    if (checked === false && !verify) {
      return handleClickOpen();
    }
    setloading(true);
    if (isEdited) {
      const response = await PatchPayRollApi(formData);
      setsnackBarData(response.snackBarData);
      if (response.data?.IsSuccess) {
        setheader(null);
        setloading(false);
        setShowForm(false);
        setisEdited(false);
        dispatch(fetchEmployee({ page: page - 1, pageSize: pageSize }));
      } else {
        setloading(false);
      }
    } else if (!isEdited) {
      const response = await PostPayrollAPI(formData);
      setsnackBarData(response.snackBarData);
      if (response.data.IsSuccess) {
        setloading(false);
        setheader(null);
        setdisableNext(false);
        setShowForm(false);
        setisEdited(false);
        dispatch(fetchEmployee({ page: page - 1, pageSize: pageSize }));
      } else {
        setdisableNext(false);
        setloading(false);
      }
      setloading(false);
    }
  };
  useEffect(() => {
    // dispatch(employePopulate());

    if (isEdited) {
      // dispatch(FetchSingleEmployee(empData?.empId));
      setdisableNext(false);
    }
  }, [dispatch]);

  return (
    <>
      {isEdited ? (
        <Box
          sx={{ display: "flex", justifyContent: "center", paddingTop: "20px" }}
        >
          <Box sx={{ width: "100%", maxWidth: "900px" }}>
            <Paper
              elevation={3}
              sx={{
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Employee Name
                </Typography>
                <Typography sx={{ fontWeight: "" }}>
                  {singleEmployee?.Employee?.name}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontWeight: "bold" }}>Designation</Typography>
                <Typography sx={{ fontWeight: "" }}>
                  {singleEmployee?.Employee?.designation?.designation_name}
                </Typography>
              </Box>
            </Paper>
          </Box>
        </Box>
      ) : null}
      <Box
        sx={{
          minHeight: "calc(100vh - 170px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: "20px",
        }}
      >
        <Formik
          initialValues={employeedata}
          validationSchema={object({
            department: string().required("Please Enter Your Department"),
            roletype: string().required("Please Enter Role"),
            designation: string().required("Please Enter Your Designation"),
            joining_date: string().required("Enter Joining Date"),
            shift_rules: string().required("Enter Shift Rules"),
            jobtitle: string().required("Plese Enter Job Title"),
            basic_salary: number("Please Enter Number").required(
              "Salary is Required"
            ),
            overtime_ratio: string().required("Enter Over-Time"),
          })}
          onSubmit={(values, formikHelpers) => {
            handelAdd(values, formikHelpers);
          }}
        >
          {({ errors, touched, values, handleChange, handleSubmit }) => {
            console.log("values", values);
            return (
              <form onSubmit={handleSubmit}>
                <Box>
                  <Box
                    sx={{
                      maxWidth: "900px",
                      display: "flex",
                      gap: "20px",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                      [theme.breakpoints.down("md")]: {
                        gap: "10px",
                      },
                    }}
                  >
                    <Box className={FieldInRow}>
                      <Box className={inputBox}>
                        <Typography className={input_typography}>
                          Job Title
                        </Typography>

                        <FormControl
                          fullWidth
                          sx={{ textAlign: "left" }}
                        >
                          <Field
                            onChange={handleChange}
                            size="small"
                            name="jobtitle"
                            as={TextField}
                            value={values.jobtitle}
                            variant="outlined"
                            type="text"
                            color="primary"
                            sx={{ input: { color: "black" } }}
                            className={Form_input}
                            fullWidth
                            select
                            error={
                              Boolean(errors.jobtitle) &&
                              Boolean(touched.jobtitle)
                            }
                            helperText={
                              Boolean(touched.jobtitle) && errors.jobtitle
                            }
                          >
                            {Jobtitle &&
                              Jobtitle.map((title, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={title.jobTitle_id}
                                  >
                                    {title.job_title}
                                  </MenuItem>
                                );
                              })}
                          </Field>
                        </FormControl>
                      </Box>

                      <Box className={inputBox}>
                        <Typography className={input_typography}>
                          Designation
                        </Typography>
                        <FormControl
                          fullWidth
                          sx={{ textAlign: "left" }}
                        >
                          <Field
                            onChange={handleChange}
                            value={values.designation}
                            size="small"
                            name="designation"
                            as={TextField}
                            variant="outlined"
                            type="text"
                            color="primary"
                            sx={{ input: { color: "black" } }}
                            className={Form_input}
                            fullWidth
                            select
                            error={
                              Boolean(errors.designation) &&
                              Boolean(touched.designation)
                            }
                            helperText={
                              Boolean(touched.designation) && errors.designation
                            }
                          >
                            {Designation &&
                              Designation.map((desi, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={desi.designation_id}
                                  >
                                    {desi.designation_name}
                                  </MenuItem>
                                );
                              })}
                          </Field>
                        </FormControl>
                      </Box>
                    </Box>
                    <Box className={FieldInRow}>
                      <Box className={inputBox}>
                        <Typography className={input_typography}>
                          Department
                        </Typography>
                        <FormControl
                          fullWidth
                          sx={{ textAlign: "left" }}
                        >
                          <Field
                            onChange={handleChange}
                            value={values.department}
                            size="small"
                            name="department"
                            as={TextField}
                            variant="outlined"
                            type="text"
                            color="primary"
                            sx={{ input: { color: "black" } }}
                            className={Form_input}
                            fullWidth
                            select
                            error={
                              Boolean(errors.department) &&
                              Boolean(touched.department)
                            }
                            helperText={
                              Boolean(touched.department) && errors.department
                            }
                          >
                            {Department &&
                              Department.map((desi, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={desi.dept_id}
                                  >
                                    {desi.department_name}
                                  </MenuItem>
                                );
                              })}
                          </Field>
                        </FormControl>
                      </Box>

                      <Box className={inputBox}>
                        <Typography className={input_typography}>
                          Basic Salary
                        </Typography>
                        <FormControl
                          fullWidth
                          sx={{ textAlign: "left" }}
                        >
                          <Field
                            onChange={handleChange}
                            size="small"
                            name="basic_salary"
                            as={TextField}
                            value={values.basic_salary}
                            variant="outlined"
                            type="number"
                            color="primary"
                            sx={{ input: { color: "black" } }}
                            className={Form_input}
                            fullWidth
                            error={
                              Boolean(errors.basic_salary) &&
                              Boolean(touched.basic_salary)
                            }
                            helperText={
                              Boolean(touched.basic_salary) &&
                              errors.basic_salary
                            }
                          />
                        </FormControl>
                      </Box>
                    </Box>
                    <Box className={FieldInRow}>
                      <Box className={inputBox}>
                        <Typography className={input_typography}>
                          Shift Rules
                        </Typography>
                        <FormControl
                          fullWidth
                          sx={{ textAlign: "left" }}
                        >
                          <Field
                            onChange={handleChange}
                            size="small"
                            name="shift_rules"
                            as={TextField}
                            value={values.shift_rules}
                            variant="outlined"
                            type="text"
                            color="primary"
                            sx={{
                              input: { color: "black" },
                              whiteSpace: "nowrap",
                              overflow: "hidder",
                              textOverFlow: "ellipsis",
                            }}
                            className={Form_input}
                            fullWidth
                            select
                            error={
                              Boolean(errors.shift_rules) &&
                              Boolean(touched.shift_rules)
                            }
                            helperText={
                              Boolean(touched.shift_rules) && errors.shift_rules
                            }
                          >
                            {ShiftRule &&
                              ShiftRule?.map((shift, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={shift?.rule_id}
                                  >
                                    {shift?.shift_name}{" "}
                                    {shift?.time_zone
                                      ? JSON.parse(
                                          shift?.time_zone
                                        )?.label?.split(" ")[0]
                                      : ""}
                                  </MenuItem>
                                );
                              })}
                          </Field>
                        </FormControl>
                      </Box>
                      <Box className={inputBox}>
                        <Typography className={input_typography}>
                          Joining Date
                        </Typography>
                        <FormControl
                          fullWidth
                          sx={{ textAlign: "left" }}
                        >
                          <Field
                            onChange={handleChange}
                            size="small"
                            name="joining_date"
                            as={TextField}
                            value={values.joining_date}
                            variant="outlined"
                            type="date"
                            color="primary"
                            sx={{ input: { color: "black" } }}
                            className={Form_input}
                            fullWidth
                            error={
                              Boolean(errors.joining_date) &&
                              Boolean(touched.joining_date)
                            }
                            helperText={
                              Boolean(touched.joining_date) &&
                              errors.joining_date
                            }
                          />
                        </FormControl>
                      </Box>
                    </Box>
                    <Box className={FieldInRow}>
                      <Box className={inputBox}>
                        <Typography className={input_typography}>
                          OverTime Ratio
                        </Typography>
                        <FormControl
                          fullWidth
                          sx={{ textAlign: "left" }}
                        >
                          <Field
                            onChange={handleChange}
                            size="small"
                            name="overtime_ratio"
                            as={TextField}
                            value={values.overtime_ratio}
                            variant="outlined"
                            type="number"
                            color="primary"
                            sx={{
                              input: { color: "black" },
                              whiteSpace: "nowrap",
                              overflow: "hidder",
                              textOverFlow: "ellipsis",
                            }}
                            className={Form_input}
                            fullWidth
                            error={
                              Boolean(errors.overtime_ratio) &&
                              Boolean(touched.overtime_ratio)
                            }
                            helperText={
                              Boolean(touched.overtime_ratio) &&
                              errors.overtime_ratio
                            }
                          ></Field>
                        </FormControl>
                      </Box>

                      <Box className={inputBox}>
                        <Typography className={input_typography}>
                          Payment Method
                        </Typography>
                        <FormControl
                          fullWidth
                          sx={{ textAlign: "left" }}
                        >
                          <Field
                            onChange={handleChange}
                            size="small"
                            name="payment_method"
                            as={Select}
                            value={values.payment_method}
                            variant="outlined"
                            color="primary"
                            sx={{
                              input: { color: "black" },
                              whiteSpace: "nowrap",
                              overflow: "hidder",
                              textOverFlow: "ellipsis",
                            }}
                            className={Form_input}
                            fullWidth
                          >
                            <MenuItem value="">Select</MenuItem>
                            <MenuItem value="Cash">Cash</MenuItem>
                            <MenuItem value="Bank">Bank</MenuItem>
                          </Field>
                        </FormControl>
                      </Box>
                    </Box>
                    {values?.payment_method === "Bank" ? (
                      <>
                        <Box className={FieldInRow}>
                          <Box className={inputBox}>
                            <Typography className={input_typography}>
                              Bank Name
                            </Typography>
                            <FormControl
                              fullWidth
                              sx={{ textAlign: "left" }}
                            >
                              <Field
                                onChange={handleChange}
                                size="small"
                                name="bank_name"
                                as={TextField}
                                value={values?.bank_name}
                                variant="outlined"
                                type="text"
                                color="primary"
                                sx={{
                                  input: { color: "black" },
                                  whiteSpace: "nowrap",
                                  overflow: "hidder",
                                  textOverFlow: "ellipsis",
                                }}
                                className={Form_input}
                                fullWidth
                              ></Field>
                            </FormControl>
                          </Box>

                          <Box className={inputBox}>
                            <Typography className={input_typography}>
                              Currency
                            </Typography>
                            <FormControl
                              fullWidth
                              sx={{ textAlign: "left" }}
                            >
                              <Field
                                onChange={handleChange}
                                size="small"
                                name="currency"
                                as={TextField}
                                value={values.currency}
                                variant="outlined"
                                color="primary"
                                sx={{
                                  input: { color: "black" },
                                  whiteSpace: "nowrap",
                                  overflow: "hidder",
                                  textOverFlow: "ellipsis",
                                }}
                                className={Form_input}
                                fullWidth
                              ></Field>
                            </FormControl>
                          </Box>
                        </Box>
                        <Box className={FieldInRow}>
                          <Box className={inputBox}>
                            <Typography className={input_typography}>
                              Acount Number
                            </Typography>
                            <FormControl
                              fullWidth
                              sx={{ textAlign: "left" }}
                            >
                              <Field
                                onChange={handleChange}
                                size="small"
                                name="bank_account_no"
                                as={TextField}
                                value={values.bank_account_no}
                                variant="outlined"
                                type="text"
                                color="primary"
                                sx={{
                                  input: { color: "black" },
                                  whiteSpace: "nowrap",
                                  overflow: "hidder",
                                  textOverFlow: "ellipsis",
                                }}
                                className={Form_input}
                                fullWidth
                              ></Field>
                            </FormControl>
                          </Box>
                        </Box>
                      </>
                    ) : null}
                    <Box className={FieldInRow}>
                      <Box className={inputBox}>
                        <Typography className={input_typography}>
                          Contract Scan
                        </Typography>

                        <FormControl
                          fullWidth
                          sx={{ textAlign: "left" }}
                        >
                          <Field
                            size="small"
                            name="contract_scan"
                            as={Input}
                            value={values?.contract_scan}
                            onChange={(e) => {
                              handleChange(e);

                              setselectedContract(e.target.files[0]);
                            }}
                            variant="outlined"
                            type="file"
                            color="primary"
                            sx={{ input: { color: "black" } }}
                            className={Form_input}
                            fullWidth
                            inputProps={{
                              accept: ".pdf,",
                            }}
                            error={
                              Boolean(errors.contract_scan) &&
                              Boolean(touched.contract_scan)
                            }
                            helperText={
                              Boolean(touched.contract_scan) &&
                              errors.contract_scan
                            }
                          />
                        </FormControl>
                      </Box>
                      <Box className={inputBox}>
                        <Box>
                          <Typography className={input_typography}>
                            Sandwich Rule
                          </Typography>
                          <Checkbox
                            name="sandwichRule_status"
                            value={checked}
                            checked={checked}
                            onChange={() => {
                              setchecked(!checked);
                            }}
                            sx={{
                              display: "flex",
                              justifyContent: "start",
                              width: "100%",
                            }}
                          />
                          <Typography
                            sx={{
                              textAlign: "left",
                              fontSize: "10px",
                              color: "red",
                            }}
                          >
                            {checked === true
                              ? "SandwichRule Enabled"
                              : "SandwichRule Disabled "}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box className={FieldInRow}>
                      <Box className={inputBox}>
                        <Typography className={input_typography}>
                          Device ID
                        </Typography>
                        <FormControl
                          fullWidth
                          sx={{ textAlign: "left" }}
                        >
                          <Field
                            onChange={handleChange}
                            size="small"
                            name="fpdevice_id"
                            as={TextField}
                            disabled={
                              isEdited &&
                              singleEmployee?.Employee?.loginemp?.fpdevice_id
                            }
                            value={values.fpdevice_id}
                            variant="outlined"
                            type="number"
                            color="primary"
                            sx={{
                              input: { color: "black" },
                              whiteSpace: "nowrap",
                              overflow: "hidder",
                              textOverFlow: "ellipsis",
                            }}
                            className={Form_input}
                            fullWidth
                          ></Field>
                        </FormControl>
                      </Box>

                      <Box className={inputBox}>
                        <Typography className={input_typography}>
                          Role
                        </Typography>
                        <FormControl
                          fullWidth
                          required
                          sx={{ textAlign: "left" }}
                        >
                          <Field
                            onChange={handleChange}
                            size="small"
                            name="roletype"
                            as={TextField}
                            value={values.roletype}
                            variant="outlined"
                            type="text"
                            color="primary"
                            sx={{
                              input: { color: "black" },
                              whiteSpace: "nowrap",
                              overflow: "hidder",
                              textOverFlow: "ellipsis",
                            }}
                            className={Form_input}
                            fullWidth
                            select
                            error={
                              Boolean(errors.roletype) &&
                              Boolean(touched.roletype)
                            }
                            helperText={
                              Boolean(touched.roletype) && errors.roletype
                            }
                          >
                            {Role &&
                              Role?.map((rol, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={rol?.role_id}
                                  >
                                    {rol.role_name}
                                  </MenuItem>
                                );
                              })}
                          </Field>
                        </FormControl>
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        float: "right",
                        display: "flex",
                        gap: "10px",
                        marginTop: "20px",
                      }}
                      spacing={2}
                    >
                      <>
                        <Button
                          className={btns}
                          variant="contained"
                          color="error"
                          onClick={() => {
                            setisEdited(false);
                            setShowForm(false);
                            setheader(null);
                          }}
                        >
                          Cancel
                        </Button>
                        {isEdited ? (
                          <Button
                            className={btns}
                            variant="contained"
                            color="warning"
                            disabled={disableNext === true ? true : false}
                            onClick={() => {
                              setstepper((stepper) => stepper - 1);
                            }}
                          >
                            Back
                          </Button>
                        ) : null}
                        {loading === false ? (
                          !isEdited ? (
                            <Button
                              className={btns}
                              type="submit"
                              variant="contained"
                              color="primary"
                            >
                              Save
                            </Button>
                          ) : (
                            <Button
                              className={btns}
                              type="submit"
                              variant="contained"
                              color="primary"
                            >
                              Update
                            </Button>
                          )
                        ) : (
                          <CircularProgress />
                        )}
                      </>
                    </Box>
                  </Box>
                </Box>
              </form>
            );
          }}
        </Formik>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">
          {/* InActive {activeProjRow?.project_name} */}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to Disabled Sandwich Rule For this Employee{" "}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Button
                onClick={() => {
                  handleClose();
                }}
                sx={{
                  background: "red",
                  color: "white !important",
                  fontSize: "13px",
                  textTransform: "none",
                }}
              >
                No
              </Button>
              {loading ? (
                <CircularProgress />
              ) : (
                <Button
                  onClick={() => {
                    // setchecked(!checked)
                    setverify(true);
                    handleClose();
                  }}
                  sx={{
                    background: "#3480FA",
                    color: "white !important",
                    fontSize: "13px",
                    // margin: "0px auto",
                    textTransform: "none",
                  }}
                >
                  Yes
                </Button>
              )}
            </Box>
          </>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PayrollForm;

PayrollForm.propTypes = {
  setShowForm: PropTypes.any,
  empID: PropTypes.any,
  empData: PropTypes.any,
  setisEdited: PropTypes.any,
  isEdited: PropTypes.any,
  setstepper: PropTypes.any,
  page: PropTypes.any,
  pageSize: PropTypes.any,
};
