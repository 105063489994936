import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  leaveApplication: [],
  EmpleaveApplication: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
  EmpAvlCl: null,
  EmpAvlAl: null,
  totalCount: 1,
  filters: {
    emp_id: "",
    date: "",
    status: "",
    limit: 10,
    page: 1,
  },
};

export const fetchLeaveApplication = createAsyncThunk(
  "empleavestatus",
  async (_, { getState }) => {
    const { filters } = getState().leaveapplication;
    const response = await axiosInstance.get("GetEmployeeLeaveApplications", {
      params: filters,
    });
    return response.data;
  }
);

export const fetchsingleEmpLeave = createAsyncThunk(
  "singlempleaves/",
  async (id) => {
    const response = await axiosInstance.get(`empleaveapplications/${id}`);
    return response.data;
  }
);

const LeaveApplicationSlice = createSlice({
  name: "leaveapplication",
  initialState,
  reducers: {
    LeaveApplicationAdded: {
      reducer(state, action) {
        state.leaveApplication.push(action.payload);
      },
    },
    removeLeaveApplicationDetail: {
      reducer(state) {
        state.leaveApplication = [];
      },
    },
    LeaveAppResetData: (state) => {
      state.leaveApplication = [];
      state.isSuccess = false;
      state.message = null;
      state.status = "idle";
      state.error = null;
      state.IsLogin = true;
    },
    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    clearFilters: (state) => {
      state.filters = {
        emp_id: "",
        date: "",
        status: "",
        limit: 10,
        page: 1,
      };
    },
    setPage: (state, action) => {
      state.filters.page = action.payload;
    },
    setPageSize: (state, action) => {
      state.filters.limit = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLeaveApplication.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLeaveApplication.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.leaveApplication = action.payload.Leave_Applications;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(fetchLeaveApplication.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(fetchsingleEmpLeave.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchsingleEmpLeave.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.EmpleaveApplication = action.payload.Leave_Applications;
        state.EmpAvlCl = action.payload.available_CL;
        state.EmpAvlAl = action.payload.available_AL;
      })
      .addCase(fetchsingleEmpLeave.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      });
  },
});

export const getleaveapplicationDetails = (state) => state.leaveapplication;

export const {
  LeaveApplicationAdded,
  removeLeaveApplicationDetail,
  LeaveAppResetData,
  setFilters,
  clearFilters,
  setPage,
} = LeaveApplicationSlice.actions;

export default LeaveApplicationSlice.reducer;
