import React from "react";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
//import DashboardIcon from "@mui/icons-material/Dashboard";
import NotesIcon from "@mui/icons-material/Notes";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import PeopleIcon from "@mui/icons-material/People";
import SummarizeIcon from "@mui/icons-material/Summarize";
// import InfoIcon from "@mui/icons-material/Info";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import VideoStableIcon from "@mui/icons-material/VideoStable";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import HistoryIcon from "@mui/icons-material/History";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
// import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
// import WorkIcon from "@mui/icons-material/Work";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import SettingsIcon from "@mui/icons-material/Settings";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
// import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
// import WebStoriesIcon from "@mui/icons-material/WebStories";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import PaymentsIcon from "@mui/icons-material/Payments";
// import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
// import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import ReportIcon from "@mui/icons-material/Report";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import DetailsIcon from "@mui/icons-material/Details";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import AlbumIcon from "@mui/icons-material/Album";
import FormatOverlineIcon from "@mui/icons-material/FormatOverline";
// import PaymentIcon from "@mui/icons-material/Payment";
import AddTaskIcon from "@mui/icons-material/AddTask";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import NoTransferIcon from "@mui/icons-material/NoTransfer";
import AirlineSeatLegroomReducedIcon from "@mui/icons-material/AirlineSeatLegroomReduced";
// import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
// import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
// import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import AvTimerIcon from "@mui/icons-material/AvTimer";
// import StyleIcon from "@mui/icons-material/Style";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import Cookies from "universal-cookie";
// import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
// import FilterTiltShiftIcon from "@mui/icons-material/FilterTiltShift";
import BadgeIcon from "@mui/icons-material/Badge";
import DescriptionIcon from "@mui/icons-material/Description";
const cookies = new Cookies();
import ApprovalIcon from "@mui/icons-material/Approval";
import WorkIcon from "@mui/icons-material/Work";
// import HistoryIcon from '@mui/icons-material/History';
import EmailIcon from "@mui/icons-material/Email";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
export const EmployeeSideBarData = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: <StackedBarChartIcon />,
    toolTipText: `Dear User! Welcome to the Dashboard.
    This is your personal portal where you can access important information and tools related to your job.
    It is designed to provide you with a comprehensive overview of your work-related activities and responsibilities. You can view your schedule, track your performance metrics, and access company policies and procedures.
    We hope that you find your dashboard to be a useful and valuable resource. If you have any questions or concerns about the platform, please don't hesitate to reach out to your HR representative. Thank you for using your employee dashboard and we wish you all the best in your role.
    `,
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
    subitems: [],
  },
  {
    name: "Employee",
    icon: <PeopleIcon />,
    path: "/employeesDetails",
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
    subitems: [
      {
        name: " Employee Information",
        toolTipText: `Welcome to the Employee Detail View Screen!
        This screen is designed to provide a comprehensive view of an individual employee's information. Here, you will find details such as their name, job title, department, contact information, and more.
        If you have any questions or concerns about the platform, please don't hesitate to reach out to your HR representative.
        `,
        icon: <PeopleIcon sx={{ fontSize: 20 }} />,
        path: "/employeesDetails",
      },
      // {
      //   name: "Log Time",
      //   toolTipText: `Welcome to the Log Time screen!
      //   This is the place where you can easily check in and check out of work. To use this system, simply click on the “LOG TIME” button when you arrive at work and the Check Out button whenever you leave. It's important that you check in and out on time, as this helps your employer manage their resources effectively and ensures that you receive the correct pay.
      //   Please note that any discrepancies or issues with your attendance should be reported to your supervisor immediately. Your cooperation with this system is greatly appreciated, and helps to ensure a smooth and efficient workplace.  If you have any questions or concerns about the platform, please don't hesitate to reach out to your HR representative. Thank you for logging your time with us today!
      //   `,
      //   icon: <WebStoriesIcon sx={{ fontSize: 20 }} />,
      //   path: "/employee/logworktime",
      // },

      // {
      //   name: "Change Request",
      //   icon: <TrackChangesIcon />,
      //   toolTipText: `Welcome to the Change Requests page!
      //   This page displays all the change requests that have been assigned to you . You can use this page to up-to-date on the current status of each change request.
      //   The page is divided into several columns. These columns provide a quick overview of each change request and its current status.
      //   The subject and description columns provide a brief summary of the change request. The project and module columns indicate the specific project and module that the change request is related to. The complexity column shows the level of difficulty associated with the change request.
      //   If you have any questions or concerns about the platform, please don't hesitate to reach out to your HR representative.
      //   `,
      //   path: "/ChangeRequests",
      //   subitems: [],
      // },
      // {
      //   name: "Engagements",
      //   icon: <TimelapseIcon />,
      //   toolTipText: `Welcome to your Engagements screen!
      //   This is where you can view and manage your tasks and schedules. To add a new task or schedule to your screen, simply follow these steps:
      //   Click on the "Add New" button to open the input field.
      //   Enter the details of your task or schedule into the input field, including the title, date and time it needs to be completed, and a tag.
      //   Once you have entered all the necessary information, save the information.
      //   Your task or schedule should now be visible on your screen, either in a list or a calendar view.
      //   If you have any questions or concerns about the platform, please don't hesitate to reach out to your HR representative. We hope this Engagements screen helps you stay on top of your tasks and schedules!
      //   `,
      //   path: "/employee/engagements",
      //   subitems: [],
      // },
      {
        name: "Break Adjustments",
        icon: <SelfImprovementIcon />,
        toolTipText: `Welcome to the Break Adjustmentspage!
        This page displays all the Break Adjustments that have been assigned by you . You can use this page to up-to-date on the current status of each Break Adjustments.
        The page is divided into several columns. These columns provide a quick overview of each Break Adjustments and its current status.
        The subject and description columns provide a brief summary of the Break Adjustments. 
        If you have any questions or concerns about the platform, please don't hesitate to reach out to your HR representative.
        `,
        path: "/breakAdjustments",
        subitems: [],
      },
    ],
  },
  {
    name: "Reports",
    icon: <ReportIcon />,
    path: "/attendancereports",
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
    subitems: [
      {
        name: "Portal Summary",
        title: "Portal Summary",
        icon: <DashboardIcon />,
        toolTipText: `Welcome to the Portal Summary screen!
            )}
           `,
        path: "/employeeportalsummary",
      },
      {
        name: "Attendance Reports",
        icon: <AssignmentLateIcon />,
        toolTipText: `Welcome to your late arrival report screen!
        We understand that there may be times when circumstances beyond your control cause you to arrive late to work, and we want to provide you with this report to help you keep track of your attendance.
        You can view your any month report of your late arrivals at work.
        As you review this report, please keep in mind that arriving to work on time is important for both you and our company. Timely attendance helps to ensure that all tasks and responsibilities are completed in a timely and efficient manner, and it also helps to promote a positive work culture.
        We encourage you to review this report carefully and take note of any patterns or trends that may be causing you to arrive late. If you have any questions or concerns, please do not hesitate to speak with your supervisor or HR representative.
        `,
        path: "/attendancereports",
      },
      // {
      //   name: "Late Arrival Report",
      //   icon: <AssignmentLateIcon />,
      //   toolTipText: `Welcome to your late arrival report screen!
      //   We understand that there may be times when circumstances beyond your control cause you to arrive late to work, and we want to provide you with this report to help you keep track of your attendance.
      //   You can view your any month report of your late arrivals at work.
      //   As you review this report, please keep in mind that arriving to work on time is important for both you and our company. Timely attendance helps to ensure that all tasks and responsibilities are completed in a timely and efficient manner, and it also helps to promote a positive work culture.
      //   We encourage you to review this report carefully and take note of any patterns or trends that may be causing you to arrive late. If you have any questions or concerns, please do not hesitate to speak with your supervisor or HR representative.
      //   `,
      //   path: "/employelatearrivalreport",
      // },
      // {
      //   name: " Early Departure Report",
      //   icon: <AlbumIcon />,
      //   toolTipText: `Welcome to the Early Departure report screen! This tool has been designed to help you view your early departure reports for any month.
      //   With this report, you can track your early departures and take necessary measures to improve your attendance. It is important to note that early departures can negatively affect your work performance and may result in disciplinary action if not addressed in a timely manner.
      //   To access your early departure report, simply select the month you wish to view from the dropdown menu. The report will display the date, time, and duration of each early departure you have made during that month.
      //   We encourage you to use this report to keep track of your attendance and take steps to improve your punctuality.
      //   If you have any questions or concerns, please do not hesitate to speak with your supervisor or HR representative.
      //   `,
      //   path: "/employeeEarlydeparturereport",
      // },
      // {
      //   name: "Overtime Report",
      //   icon: <FormatOverlineIcon />,
      //   toolTipText: `Welcome to the Overtime Report!
      //   Here, you can easily access your overtime hours for any month by selecting the desired month from the dropdown menu.
      //   By tracking your overtime hours, you can stay on top of your workload and ensure that you are compensated fairly for any extra time you put in. This feature is designed to help you stay organized and informed about your overtime hours, so you can focus on delivering the best results for your team and organization.
      //   We hope this tool helps you stay on top of your overtime hours and manage your workload more effectively. If you have any questions or concerns, please do not hesitate to speak with your supervisor or HR representative.
      //   `,
      //   path: "/employeeOvertimereport",
      // },
      {
        name: "Monthly Attendance",
        icon: <ReportIcon />,
        toolTipText: `Welcome to the Monthly Attendance Report!
        This feature allows you to easily access your attendance records for any month of the year.
       To get started, simply select the month you would like to view from the dropdown menu. The system will then display your attendance record for that particular month, including the number of days you were present, absent, or on leave.
       In addition to viewing your own attendance record, you can also view summary of your worked hours and days
       By regularly checking your attendance record, you can ensure that you are meeting your attendance requirements and identify any areas where improvement may be needed. We hope that this feature will help you stay on top of your attendance and achieve your professional goals.
       If you have any questions or concerns, please do not hesitate to speak with your supervisor or HR representative.
       `,
        path: "/employeMonthlyAttendance",
      },
      {
        name: "Monthly Change Request",
        icon: <EventRepeatIcon />,
        toolTipText: `Welcome to the Monthly Change Request Report!
        This screen is designed to help you easily select any month and retrieve the corresponding change request information.
        To begin, simply select the desired month from the dropdown menu. Once you have made your selection, click the "Get Change Request" button to generate the report.
        The report will provide you with a summary of all change requests submitted for the selected month, including the status of each request and any updates made. Thank you for using our system and we hope this tool will help you streamline your change management processes.
         If you have any questions or concerns, please do not hesitate to speak with your supervisor or HR representative.
        `,
        path: "/monthlyEmployeeChangeRequest",
      },
    ],
  },
  // {
  //   name: "Payroll",
  //   icon: <PaymentIcon />,
  //   path: "/advanced",
  //   iconClosed: <ArrowDropDownIcon />,
  //   iconOpened: <ArrowDropUpIcon />,
  //   subitems: [
  //     {
  //       name: "Advances",
  //       toolTipText: ``,
  //       icon: <MonetizationOnIcon sx={{ fontSize: 20 }} />,
  //       path: "/advanced",
  //     },
  //     {
  //       name: "Loans",
  //       toolTipText: ``,
  //       icon: <CreditScoreIcon sx={{ fontSize: 20 }} />,
  //       path: "/loans",
  //     },
  //     {
  //       name: "Allowances",
  //       title: "Allowances",
  //       toolTipText: `Welcome to the  Employee allowances Screen!
  //       This is where you can add new allowances for employees and view the allowances that have already been given.
  //       To add a new allowance for an employee, follow these steps: 
  //       Click on the "new" button. Select the employee for whom you want to add the allowance. Choose the type of allowance you want to add from the dropdown menu. Enter the amount of the allowance. Click "Save" to add the allowance to the employee's record.
  //       You will see a list of all the allowances that have been given to that employee, along with the date and amount of each allowance.
  //       If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
  //         "company_email"
  //       )}.
  //       `,
  //       icon: <CardGiftcardIcon sx={{ fontSize: 20 }} />,
  //       path: "/allowancesofemployee",
  //     },
  //     {
  //       name: "Payroll Details",
  //       toolTipText: ``,
  //       icon: <DetailsIcon />,
  //       path: "/employeepayrolldetails",
  //     },
  //   ],
  // },
  {
    name: "Leave Management",
    icon: <HolidayVillageIcon />,
    path: "/leaveapplication",
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
    subitems: [
      {
        name: "Leave Application",
        toolTipText: ``,
        icon: <HolidayVillageIcon sx={{ fontSize: 20 }} />,
        path: "/leaveapplication",
      },
      {
        name: "CPL",
        icon: <VideoStableIcon />,
        path: "/cpl",
      },
    ],
  },
];

export const AdminSideBarData = [
  {
    name: "Dashboard",
    title: "Dashboard",
    path: "/dashboard",
    toolTipText: `Welcome to the Dashboard screen!  
      Dashboard is used to track and monitor key performance indicators (KPIs) in real-time. This screen provides a visual display of important metrics and data relevant to the administrator's role, allowing them to quickly identify and respond to any issues or trends. 
      If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
        "company_email"
      )}.
      
      
      `,
    icon: <StackedBarChartIcon />,
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
    subitems: [],
  },
  {
    name: "Employee",
    icon: <PeopleIcon />,
    path: "/activeemployees",
    title: "Employee",
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
    subitems: [
      {
        name: "Active Employees",
        title: "Active Employees",
        toolTipText: `Welcome to the Active Employee screen!  
        The active employee screen is a display that shows currently employed staff members who are actively working. The user can also add a new Employee or update the existing employee information. Employee’s complete details can also be viewed or updated by clicking on the icon at the end of row.
        If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
          "company_email"
        )}.
        `,
        icon: <PeopleIcon sx={{ fontSize: 20 }} />,
        path: "/activeemployees",
      },
      {
        name: "Inactive Employees",
        title: "Inactive Employees",
        toolTipText: `Welcome to the Inactive Employee screen!  
        The inactive employee screen shows staff members who are no longer employed by a particular company or organization. It may include their name, job title, termination date, and other relevant information. This screen is commonly used by HR departments or managers to maintain accurate records of past employees and track turnover rates. Employee’s complete details can also be viewed.
        If you have any issues or questions about screen or any other query , please consult the help documentation or contact at ${cookies.get(
          "company_email"
        )}
        `,
        icon: <PersonOffIcon sx={{ fontSize: 20 }} />,
        path: "/inactiveemployees",
      },

      {
        name: "Engagements",
        title: "Engagements",
        toolTipText: `Welcome to the Engagements screen! To add a task or on your screen, follow these steps:
        Click on the "Add" button to open the input field. Enter the details of your task or schedule into the input field.it will include a title, a tag (tag represents the type of task such as meeting or so on),  Once you have entered all the necessary information, click the "Submit"  button to add the task or schedule to your screen. Your task or schedule will be visible on your screen, either in a list or a calendar view.
        If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
          "company_email"
        )}.
        `,
        icon: <TimelapseIcon sx={{ fontSize: 20 }} />,
        path: "/engagements",
      },
      // {
      //   name: "Log Time",
      //   title: "Log Time",
      //   icon: <WebStoriesIcon sx={{ fontSize: 20 }} />,
      //   path: "/employee/logworktime",
      // },
      // {
      //   name: "Settings",
      //   title: "Settings",
      //   icon: <SettingsApplicationsIcon />,
      //   path: "/Settings",
      //   iconClosed: <ArrowDropDownIcon />,
      //   iconOpened: <ArrowDropUpIcon />,
      //   subitems: [
      //     {
      //       name: "Tags",
      //       title: "Tags",
      //       icon: <StyleIcon />,
      //       toolTipText: `Welcome to the Tag Screen!
      //       Here you can define tags. Simply create a new tag and choose a color that represents it best. You can then apply the tag to relevant items, such as emails, documents, and tasks, and easily filter and search for them later.
      //       This feature can help you stay organized and efficient, as well as improve your productivity and time management skills. So go ahead and start tagging!
      //       If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
      //         "company_email"
      //       )}.`,
      //       path: "/tags",
      //       subitems: [],
      //     },
      //   ],
      // },
    ],
  },
  {
    name: "Time And Attendance",
    title: "Time And Attendance",
    icon: <NotesIcon />,
    path: "/attendance",
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
    subitems: [
      // {
      //   name: "All Attendance",
      //   title: "Attendance",
      //   icon: <PeopleIcon sx={{ fontSize: 20 }} />,
      //   path: "/attendance",
      // },
      // {
      //   name: "Device Attendance",
      //   title: "Device Attendance",
      //   toolTipText: `Welcome to the Device Attendance screen!
      //   Device's attendance is used to add and view the attendance of employees' from the Device. The attendance intime and outtime can be seen.
      //   If you need to mark an attendee intime and out time, simply click the corresponding button next to their name in the attendance list.
      //   If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
      //     "company_email"
      //   )}.
      //   `,
      //   icon: <NotesIcon />,
      //   path: "/deviceattendance",
      // },
      {
        name: "Daily Attendance",
        title: "Daily Attendance",
        icon: <AvTimerIcon />,
        toolTipText: `Welcome to the Daily Attendance screen!
        Daily attendance of employees is used to get the record of employees' presence or absence in the workplace on a particular day. To view attendance information for another date, use the date picker at the top of the screen to select a different date. The clock’in and clock’out of employee can be viewed by clicking on the attendance . This information is used by employers to monitor employee attendance and productivity, ensure compliance with attendance policies, and calculate employee wages and benefits.
        If you have any issues or questions about screen or any other query , please consult the help documentation or contact at ${cookies.get(
          "company_email"
        )}
        `,
        path: "/dailyattendance",
      },
      // {
      //   name: "Today Attendance",
      //   title: "Today Attendance",
      //   toolTipText: `Welcome to the Today Attendance screen!
      //   Today's attendance is used to add and view the attendance of employees' of the current day. The attendance intime and outtime can also be updated.
      //   If you need to mark an attendee intime and out time, simply click the corresponding button next to their name in the attendance list.
      //   If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
      //     "company_email"
      //   )}.
      //   `,
      //   icon: <NotesIcon />,
      //   path: "/TodayAttendance",
      // },

      // {
      //   name: "Monthly Report",
      //   icon: <SummarizeIcon />,
      //   path: "/montly_report",
      // },
      // {
      //   name: "Gazetted Holidays",
      //   title: "Gazetted Holidays",
      //   toolTipText: `Welcome to the Gazetted Holiday Screen!
      //   Here, you can add or edit gazetted holidays for your organization. Follow the steps below to add or edit a gazetted holiday: To add a new gazetted holiday, click the "Add Gazetted Holiday" button. To edit an existing gazetted holiday, click the "Edit" button next to the holiday you want to modify. In the "Add Gazetted Holiday" or "Edit Gazetted Holiday" form, fill in the holiday name, date, and any other relevant details.
      //   Please note that only authorized personnel are able to add or edit gazetted holidays. If you do not have permission to do so, please contact your organization's administrator.
      //   If you have any questions or issues with the gazetted holiday adding process, please contact at ${cookies.get(
      //     "company_email"
      //   )}
      //   `,
      //   icon: <HolidayVillageIcon />,
      //   path: "/GazettedHolidays",
      // },
      // {
      //   name: "Shift Rules",
      //   title: "Shift Rules",
      //   toolTipText: `Welcome to the Shift Rules screen!
      //   This screen allows you to create and manage rules for shift scheduling.

      //   To add a new rule, click the "New" button at the top of the screen. You will be prompted to enter a name for the rule and to specify the conditions under which it applies. You have to select the correct timezone according to your country. Also define off days of your office
      //   Once you've created a rule, you can edit it. You can then modify the name or conditions of the rule, and save your changes.
      //   If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
      //     "company_email"
      //   )}.
      //    `,
      //   icon: <FilterTiltShiftIcon />,
      //   path: "/ShiftRules",
      // },
      {
        name: "Missing Punches",
        title: "Attendance Details Report",
        icon: <SummarizeIcon />,
        toolTipText: `Welcome to the Monthly Report screen!
        This screen allows you to generate and view monthly reports.A monthly report of an employee is a summary of an individual employee's performance and accomplishments over the course of a given month.  Here are some helpful tips to get you started: 
       To generate a monthly report, click date picker. You will be prompted to select the month for which you want to generate the report. Once you have made your selection, click on the "Generate" button to create the report.
       You can choose to save the report to your computer or print it out for your records.
       To view a previous month generated report, click on the eye icon. You will be presented with a list of all the reports that have been generated.
       If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
         "company_email"
       )}.
       
       `,
        path: "/missing_punches",
      },

      {
        name: "Monthly Attendance",
        title: "Employee Monthly Attendance",
        icon: <ReportIcon />,
        toolTipText: `Welcome to the Employee monthly attendance screen!
            Employee monthly attendance screen shows the record of each employee's presence or absence from work during a given month. Employee’s total working hours, working days and other attendance relevant information will be viewed on the screen.
           To get employee’s monthly attendance on your screen, follow these steps:
           Select the employee and the month and click on “Get button”. The employee’s monthly detail will be generated
           If you have any issues or questions about screen or any other query , please consult the help documentation or contact at ${cookies.get(
             "company_email"
           )}
           `,
        path: "/employeMonthlyAttendance",
      },
    ],
  },

  {
    name: "Ticket Management",
    title: "Ticket Management",
    icon: <ChangeCircleIcon />,
    path: "/ChangeRequests",
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
    subitems: [
      {
        name: "Change Request",
        title: "ChangeRequest",
        toolTipText: `Welcome to the Change Requests page!
        This screen displays all the change requests. To add new change request click on “Add” button, select the priority (note that each project can have only one change request on high or critical priority). 
        The page is divided into several columns. These columns provide a quick overview of each change request and its current status.
        The complexity column shows the level of difficulty associated with the change request. The company status shows the progress made by our team in implementing the change request, while the client status shows the client's feedback and approval status.
        If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
          "company_email"
        )}.
        
        `,
        icon: <TrackChangesIcon />,
        path: "/ChangeRequests",
        subitems: [],
      },
      {
        name: "Service Request",
        title: "Service Request Reports",
        icon: <SupportAgentIcon />,
        path: "/service_request_reports",
        iconClosed: <ArrowDropDownIcon />,
        iconOpened: <ArrowDropUpIcon />,
        subitems: [
          {
            name: "Completed Change",
            title: "Completed Change Request",
            icon: <AddTaskIcon />,

            toolTipText: `Welcome to the Completed Change Request page!
            Here you will find a list of all the change requests or tickets that have been completed. Each entry in the list contains the following information:
            A brief description of the change request or ticket. The project to which the change request or ticket was related. The module of the project that was affected by the change request or ticket. The date on which the change request or ticket was completed. The level of complexity of the change request or ticket, as determined by the development team.
            If you have any questions or concerns about a completed change request or ticket, please contact us at ${cookies.get(
              "company_email"
            )}. Thank you for using our service!
            `,
            path: "/completedChangeRequest",
          },
          {
            name: "Pending Change",
            title: "Pending Change Request",
            icon: <PendingActionsIcon />,
            toolTipText: `Welcome to the Pending Change Request page. This page displays all change requests that are currently awaiting approval or action. Each row represents a single change request and contains the following columns:
            A brief description of the change request. The name of the project associated with the change request. The specific module or component that will be affected by the change. The date on which the change request was submitted for approval. An indication of the level of priority involved in implementing the change request
           Please note that all pending change requests should be reviewed and acted upon promptly to ensure that projects can proceed as planned. If you have any questions or concerns about a specific change request, please reach out to the appropriate team member or project manager for assistance.
           `,
            path: "/pendingChangeRequest",
          },
          {
            name: "Unallocated Change",
            title: "Unallocated Change Request",
            icon: <NoTransferIcon />,
            toolTipText: `Welcome to the Unallocated Change Request page! This is where you can find all the change requests or tickets that have not been processed by our employees yet.
            On this page, you will see a list of change requests or tickets.These details will help you identify the specific change request or ticket that you need to follow up on.
            If you have submitted a change request or ticket and it has not been processed yet, please check this page regularly to see if it has been allocated to an employee.
             If you have any query, please do not hesitate to reach out to our support team for assistance or contact us at . Thank you for your patience and understanding!
            `,
            path: "/unallocatedChangeRequest",
          },
          {
            name: "Monthly Change",
            title: "Monthly Change Request",
            icon: <EventRepeatIcon />,
            toolTipText: `Welcome to the Monthly Change Requests page!
            This screen displays monthly change requests assigned to any employee. Select the employee and the month then click on “Get” button. 
            The page is divided into several columns. These columns provide a quick overview of each change request and its current status.
            The complexity column shows the level of difficulty associated with the change request. The company status and client status columns show the current status of each change request. The company status shows the progress made by our team in implementing the change request, while the client status shows the client's feedback and approval status.
            If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
              "company_email"
            )}.
            `,
            path: "/monthlyEmployeeChangeRequest",
          },
        ],
      },
    ],
  },

  {
    name: "Project Management",
    title: "Project Management",
    icon: <AutoFixHighIcon />,

    path: "/Project",
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
    subitems: [
      {
        name: "Projects",
        title: "Projects",
        icon: <AccountTreeIcon />,
        toolTipText: `Welcome to the project screen!. It shows all active projects in the company. In order to add a new project, you will need to follow the steps outlined below:


        Click on the "Add Project" button to create a new project. Enter a unique project name and identifier. This identifier will be used to reference the project in other parts of the system. Add at least one module for the project. You may add additional sub-modules to further categorize the project. Click "Save" to create the new project.
        Once you have created a project, you can edit or deactivate it at any time. The table below shows the list of all projects and their actions:To edit a project, simply click on the "Edit" button next to the project name. You can then make changes to the project's name, identifier, modules, and sub-modules.
        To deactivate a project, click on the "Inactive" button next to the project name. This will prevent users from accessing the project's features and functionality.
        If you have any questions or need further assistance with managing your company's projects, please contact us at ${cookies.get(
          "company_email"
        )}.
        `,
        path: "/Project",
      },
      {
        name: "InActive Projects",
        title: "Inactive Projects",
        icon: <LinkOffIcon />,
        toolTipText: `Welcome to the Inactive project screen!
        Inactive projects of company refer to those initiatives that have been put on hold or stopped due to various reasons such as lack of resources, changes in business priorities, or external factors. These projects may have been in different stages of development, ranging from initial planning and design to implementation and testing.While inactive projects do not contribute to the company's current operations, they may still hold some value and could potentially be revived or repurposed in the future.
        If you have any questions or need further assistance with managing your company's projects, please contact us at ${cookies.get(
          "company_email"
        )}.
        `,
        path: "/InactiveProjects",
      },

      {
        name: "Project Subscription",
        title: "Project Subscription",
        icon: <SubscriptionsIcon />,
        toolTipText: `Welcome to the Projects Subscriptions screen! This page allows you to manage your project subscriptions and track the progress of your ongoing projects.
        To add a new project subscription, simply click on the "New" button and follow the prompts to select the project you wish to subscribe to. Once subscribed, you can track the project's status and receive updates on any changes made to the project.
        You can view all your existing project subscriptions and their respective statuses. You can also update any existing subscriptions by clicking on the button next to the project.
        If you have any questions or need further assistance with managing your company's projects, please contact us at ${cookies.get(
          "company_email"
        )}.
        `,
        path: "/ProjectSubscription",
      },
      {
        name: "Subscription History",
        title: "Subscription History",
        icon: <HistoryIcon />,
        toolTipText: `Welcome to the Projects Subscription History screen!
        A project subscription history of a company refers to a record of the projects that the company has subscribed to over time, including the duration of each subscription and the associated costs.
        To add a new subscription to your history, please click on the “New” button and provide the required details, including the month and project name. Please note that once you edit any subscription history, any previous subscription will be automatically marked as "null" in the received date column.
        We hope this feature will make it easier for you to manage your subscription history and keep track of your payments. If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
          "company_email"
        )}. `,
        path: "/SubscriptionHistory",
      },
      // {
      //   name: "Active Projects",
      //   icon: <HistoryIcon />,
      //   path: "",
      // },
    ],
  },

  {
    name: "Talent Management",
    title: "Talent Management",
    icon: <SettingsIcon />,
    path: "/Technologies",
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
    subitems: [
      {
        name: "Technologies",
        title: "Technologies",
        icon: <AutoFixHighIcon />,
        toolTipText: `Welcome to the Technologies page! 
        Here you can view, add, edit or delete information about the different technologies or programming languages used by our company.
       If you would like to add new technologies to the list, please click on the "New" button and fill in the required information, such as the technology name, description, and any relevant links or resources.
       If you notice any outdated or incorrect information about a technology, you can edit it by clicking on the "Edit" icon next to the technology's name. 
       If you no longer use a technology or programming language and would like to remove it from the list, simply click on the "Delete" icon next to the technology's name. Please note if the technology is associated with any user it will not let you delete it.
       We hope that this Technologies page will provide you with the information you need to stay up-to-date on the different technologies used by our company.  If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
         "company_email"
       )}.
        `,
        path: "/Technologies",
      },
      {
        name: "Departments",
        title: "Departments",
        icon: <DomainAddIcon />,
        toolTipText: `Welcome to the Departments page! 
        Here you can view, add, edit or delete information about the different Departments of our company.
       If you would like to add new Departments to the list, please click on the "New" button and fill in the required information, such as the Departments name. If you notice any outdated or incorrect information about a Departments , you can edit it by clicking on the "Edit" icon next to the Department’s name. 
       If you no longer use a Departments  and would like to remove it from the list, simply click on the "Delete" icon next to the Department’s name.  Please note if the department is associated with any user it will not let you delete it.
       We hope that this Departments page will provide you with the information you need to stay up-to-date on the different Departments used by our company.  If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
         "company_email"
       )}.
       `,
        path: "/Department",
      },
      {
        name: "Designations",
        title: "Designations",
        icon: <EventSeatIcon />,
        toolTipText: `Welcome to the Designation page! 
        Here you can view, add, edit or delete information about the different Designation of our company.
       If you would like to add new Designation to the list, please click on the "New" button and fill in the required information, such as the Designation name, responsibilities, annual and casual leaves count. If you notice any outdated or incorrect information about a Designation , you can edit it by clicking on the "Edit" icon next to the Designation name. 
       If you no longer use a Designation  and would like to remove it from the list, simply click on the "Delete" icon next to the Designation name.  Please note if the Designation is associated with any user it will not let you delete it
       We hope that this Departments page will provide you with the information you need to stay up-to-date on the different Departments used by our company.  If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
         "company_email"
       )}.
       `,
        path: "/Designation",
      },
      {
        name: "Job Titles",
        title: "Job Titles",
        icon: <BadgeIcon />,
        toolTipText: `Welcome to the Job Titles page! 
        Job titles of a company refer to the official designations given to employees based on their roles and responsibilities. Here you can view, add, edit or delete information about the different Job Titles of our company.
       If you would like to add new Job Titles to the list, please click on the "New" button and fill in the required information, such as the  Job Titles name. If you notice any outdated or incorrect information about a Job Titles , you can edit it by clicking on the "Edit" icon next to the Job Titles name. 
       If you no longer use a Job Titles and would like to remove it from the list, simply click on the "Delete" icon next to the Job Titles name.  Please note if the Job Titles is associated with any user it will not let you delete it
       We hope that this Job Titles page will provide you with the information you need to stay up-to-date on the different Job Titles used by our company.  If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
         "company_email"
       )}.
       `,
        path: "/JobTitle",
      },
      {
        name: "Job Applications",
        title: "Job Titles",
        icon: <DescriptionIcon />,
        toolTipText: `Welcome to the Job Titles page! 
        Job titles of a company refer to the official designations given to employees based on their roles and responsibilities. Here you can view, add, edit or delete information about the different Job Titles of our company.
       If you would like to add new Job Titles to the list, please click on the "New" button and fill in the required information, such as the  Job Titles name. If you notice any outdated or incorrect information about a Job Titles , you can edit it by clicking on the "Edit" icon next to the Job Titles name. 
       If you no longer use a Job Titles and would like to remove it from the list, simply click on the "Delete" icon next to the Job Titles name.  Please note if the Job Titles is associated with any user it will not let you delete it
       We hope that this Job Titles page will provide you with the information you need to stay up-to-date on the different Job Titles used by our company.  If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
         "company_email"
       )}.
       `,
        path: "/JobApplications",
      },
      // {
      //   name: "Soft Skills",
      //   title: "Soft Skills",
      //   toolTipText: `Welcome to the Soft Skills page!
      //   The Soft Skills of a company refers to its specialized knowledge, skills, and experience in a particular field or industry.Here you can view, add, edit or delete information about the different Soft Skills of our company.
      //  If you would like to add new Soft Skills to the list, please click on the "New" button and fill in the required information, such as the  Soft Skills name. If you notice any outdated or incorrect information about a Soft Skills, you can edit it by clicking on the "Edit" icon next to the Soft Skills name.
      //  If you no longer use a Soft Skills  and would like to remove it from the list, simply click on the "Delete" icon next to the Soft Skills name.  Please note if the Soft Skills is associated with any user it will not let you delete it
      //  We hope that this Soft Skills page will provide you with the information you need to stay up-to-date on the different Soft Skills used by our company.  If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
      //    "company_email"
      //  )}.`,
      //   icon: <HomeRepairServiceIcon />,
      //   path: "/SoftSkills",
      // },
    ],
  },

  {
    name: "Leave Management",
    title: "Leave Management",
    icon: <HolidayVillageIcon />,
    path: "/leaveapplication",
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
    subitems: [
      {
        name: "Leave Applications",
        title: "Leave Applications",
        toolTipText: `Welcome to the Leave Application Help Center!
        A leave application is a formal request made by an employee to take time off from work for a specific period. In this screen all the requested applications will be viewed, also the user can update the status.
         If you approve an application, enter the Approval Count along with Approved Start Date. Please note that the number of days approved will be calculated based on your Leave Type and the dates you have requested.
        If you have any questions or concerns about the Leave Application system, please reach out to your manager or HR representative for assistance.If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
          "company_email"
        )}.
        `,
        icon: <HolidayVillageIcon sx={{ fontSize: 20 }} />,
        path: "/leaveapplication",
      },
      {
        name: "CPL",
        title: "CPL",
        toolTipText: `Welcome to the CPL screen!
            CPL is compensated leaves. This screen allows you to view cpl’s of all employees. It shows the casual and annual leaves of employees. It is generated yearly, depending on the designation of employees
           If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
             "company_email"
           )}.
         
           `,
        icon: <VideoStableIcon />,
        path: "/cpl",
      },
    ],
  },
  {
    name: "PayRoll Management",
    title: "PayRoll Management",
    icon: <PaymentsIcon />,
    path: "/allowances",
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
    subitems: [
      {
        name: "Allowances",
        title: "Allowances",
        toolTipText: `Welcome to the Allowance Screen Help Center!
To add a new allowance type, please follow these steps:
Click on the " New" button located at the top of the screen. Enter the name of the new allowance type. 
To delete or edit an existing allowance type, please follow these steps: Locate the allowance type that you wish to delete or edit on the list. To delete the allowance type, click on the Delete icon located next to the allowance type. Please note that if the allowance is not attach to any employee it will be deleted. 
To edit the allowance type, click on the edit icon located next to the allowance type. If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
          "company_email"
        )}.
        `,
        icon: <CardGiftcardIcon />,
        path: "/allowances",
      },
      {
        name: "Employee Allowances",
        title: "Employee Allowances",
        toolTipText: `Welcome to the  Employee allowances Screen!
        This is where you can add new allowances for employees and view the allowances that have already been given.
        To add a new allowance for an employee, follow these steps: 
        Click on the "new" button. Select the employee for whom you want to add the allowance. Choose the type of allowance you want to add from the dropdown menu. Enter the amount of the allowance. Click "Save" to add the allowance to the employee's record.
        You will see a list of all the allowances that have been given to that employee, along with the date and amount of each allowance.
        
        
        
        If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
          "company_email"
        )}.
        `,
        icon: <CardGiftcardIcon sx={{ fontSize: 20 }} />,
        path: "/employeeallowances",
      },
      {
        name: " Advances",
        title: "Advances",
        toolTipText: `Welcome to the Advance Screen!
        An advance is a type of financial assistance where an employer provides an employee with a portion of their expected earnings in advance of their regular payday. In this screen you can view all the  advance’s requests and approved advances of employee
        If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
          "company_email"
        )}.`,
        icon: <MonetizationOnIcon sx={{ fontSize: 20 }} />,
        path: "/advanced",
      },
      {
        name: " Loans",
        title: " Loans",
        icon: <CreditScoreIcon sx={{ fontSize: 20 }} />,
        path: "/loans",
        toolTipText: `Welcome to the Loan Screen!
        A loan to an employee is a type of financial assistance provided by an employer to an employee, that must be repaid over time. In this screen you can view all the  loan’s requests and approved loans of employee
        If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
          "company_email"
        )}.`,
      },
      {
        name: "Payroll Details",
        title: "Payroll Details",
        icon: <DetailsIcon />,
        toolTipText: `Welcome to the  Payroll Detail Screen!
        Payroll details of an employee refer to the information related to their compensation, including their base pay, overtime pay, bonuses, and deductions such as less worked hours, etc. These details are used to generate the employee’s monthly payroll. This information is generated with monthly report.
        If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
          "company_email"
        )}.`,
        path: "/payrolldetails",
      },
      {
        name: "Manual Loan",
        title: "Manual Loan",
        toolTipText: ``,
        icon: <AirlineSeatLegroomReducedIcon />,
        path: "/manualloan",
      },
      {
        name: "Loan Deduction Request",
        title: "LoanDeductionRequest",

        toolTipText: ``,
        icon: <AirlineSeatLegroomReducedIcon />,
        path: "/loanDeductionRequest",
      },
    ],
  },
  // {
  //   name: "Performance Management",
  //   title: "Performance Management",
  //   icon: <ManageAccountsIcon />,
  //   path: "/employeeallowances",
  //   iconClosed: <ArrowDropDownIcon />,
  //   iconOpened: <ArrowDropUpIcon />,
  //   subitems: [
  //     {
  //       name: "Employee Allowances",
  //       title: "Employee Allowances",
  //       toolTipText: `Welcome to the  Employee allowances Screen!
  //       This is where you can add new allowances for employees and view the allowances that have already been given.
  //       To add a new allowance for an employee, follow these steps:
  //       Click on the "new" button. Select the employee for whom you want to add the allowance. Choose the type of allowance you want to add from the dropdown menu. Enter the amount of the allowance. Click "Save" to add the allowance to the employee's record.
  //       You will see a list of all the allowances that have been given to that employee, along with the date and amount of each allowance.

  //       If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
  //         "company_email"
  //       )}.
  //       `,
  //       icon: <CardGiftcardIcon sx={{ fontSize: 20 }} />,
  //       path: "/employeeallowances",
  //     },
  //     {
  //       name: "Increments/Decrements",
  //       title: "Employee Increments Decrements",
  //       toolTipText: `Welcome to the Increment/Decrement Screen!
  //       An increment or decrement in an employee's salary refers to an increase or decrease in their base pay or total compensation package.
  //       To add a new increment or decrement, simply click on the “new”  button on the screen. Then select the employee, the designation, basic salary and other relevant information of the employee will be viewed. Then you can add new increment or decrement in salary.
  //       If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
  //         "company_email"
  //       )}.`,
  //       icon: <CurrencyExchangeIcon sx={{ fontSize: 20 }} />,
  //       path: "/employee/increment/decrement",
  //     },
  //     {
  //       name: "Promotions/Demotions",
  //       title: "Employee Promotions Demotions",
  //       icon: <TrendingUpIcon />,
  //       toolTipText: `Welcome to the Promotion /Demotion Screen!
  //       The Promotion and Demotion Screen allows you to manage promotions and demotions for your team.The promotion or demotion details will be viewed on the screen.
  //       Adding a New Promotion or Demotion: Click on the "Add New" button on the top right corner of the screen. Fill in the details of the promotion or demotion, including the employee's name, new role, effective date, and reason for the change.
  //       We hope this information helps you manage promotions and demotions effectively. If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
  //         "company_email"
  //       )}.`,
  //       path: "/employee/promotion/demotion",
  //     },
  //   ],
  // },

  // {
  //   name: "Roles Management",
  //   title: "Roles Management",
  //   icon: <SupervisedUserCircleIcon />,
  //   path: "/roles",
  //   iconClosed: <ArrowDropDownIcon />,
  //   iconOpened: <ArrowDropUpIcon />,
  //   subitems: [
  //     {
  //       name: "Roles",
  //       title: "Roles",
  //       toolTipText: `Welcome to the Roles Screen!
  //       Role management refers to the process of assigning and managing different levels of access or permissions to users within a system or application. These roles are typically defined based on the user's responsibilities and the level of access they require to perform their tasks.
  //       It is an essential security measure to ensure that users have access to only the features and data that they need to perform their duties. It helps to prevent unauthorized access, data breaches, and other security incidents.

  //       If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
  //         "company_email"
  //       )}.`,

  //       icon: <SettingsAccessibilityIcon sx={{ fontSize: 20 }} />,
  //       path: "/roles",
  //     },
  //     {
  //       name: "Rules",
  //       title: "Rules",
  //       toolTipText: `Welcome to the Rules Screen!
  //       Rules refers to the process of assigning access and managing screens. User can Edit, Delete, Add or View the screens. All Functions are added here.
  //       If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
  //         "company_email"
  //       )}.`,
  //       icon: <GppMaybeIcon sx={{ fontSize: 20 }} />,
  //       path: "/rules",
  //     },
  //   ],
  // },
  {
    name: "Recruitment",
    title: "Roles Management",
    icon: <WorkIcon />,
    path: "/roles",
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
    subitems: [
      {
        name: "Applicants",
        title: "Roles",
        toolTipText: ``,
        icon: <ApprovalIcon sx={{ fontSize: 20 }} />,
        path: "/applicants",
      },
      {
        name: "HiringSession",
        title: "Roles",
        toolTipText: ``,
        icon: <HistoryIcon sx={{ fontSize: 20 }} />,
        path: "/hiringSession",
      },
      {
        name: "ApplicantSession",
        title: "Roles",
        toolTipText: ``,
        icon: <HistoryIcon sx={{ fontSize: 20 }} />,
        path: "/applicantSession",
      },
    ],
  },
  {
    name: "Portal Summary",
    title: "Dashboard",
    path: "/portalsummary",
    toolTipText: `Welcome to the Portal Summary screen!
            )}
           `,

    icon: <DashboardIcon />,
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
    subitems: [],
  },
  {
    name: "Reports",
    title: "Shift Rules",
    icon: <SummarizeIcon />,
    path: "/montly_report",
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
    subitems: [
      {
        name: "Missing Punches Record",
        title: "Missing Punches",
        icon: <AvTimerIcon />,
        toolTipText: `Welcome to the Employee Missing Punches screen!
            )}
           `,
        path: "/EmployeeMissingPunches",
      },
      // {
      //   name: "Monthly Missing Punches",
      //   title: "Missing Punches",
      //   icon: <AvTimerIcon />,
      //   toolTipText: `Welcome to the Employee Missing Punches screen!
      //       )}
      //      `,
      //   path: "/MonthlyMissingPunches",
      // },
      {
        name: "Monthly Break Adjustments",
        title: "Missing Punches",
        icon: <AvTimerIcon />,
        toolTipText: `Welcome to the Employee Break Adjusments screen!
            )}
           `,
        path: "/MonhtlyBreakAdjReport",
      },
      // {
      //   name: "Employe Monthly Puches",
      //   title: "Missing Punches",
      //   icon: <AvTimerIcon />,
      //   toolTipText: `Welcome to the Employee Monthly Missing Punches screen!
      //   )}
      //  `,
      //   path: "/EmployeeMonthlyMissingPunches",
      // },
      {
        name: "Monthly Summary",
        title: "Monthly Report",
        icon: <EventRepeatIcon />,
        toolTipText: `Welcome to the Monthly Report screen!
        This screen allows you to generate and view monthly reports.A monthly report of an employee is a summary of an individual employee's performance and accomplishments over the course of a given month.  Here are some helpful tips to get you started: 
       To generate a monthly report, click date picker. You will be prompted to select the month for which you want to generate the report. Once you have made your selection, click on the "Generate" button to create the report.
       You can choose to save the report to your computer or print it out for your records.
       To view a previous month generated report, click on the eye icon. You will be presented with a list of all the reports that have been generated.
       If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
         "company_email"
       )}.
       
       `,
        path: "/montly_report",
      },
      {
        name: "Attendance Details",
        title: "Attendance Details Report",
        icon: <HistoryIcon />,
        toolTipText: `Welcome to the Attendance Detail Report screen!
            This report provides a comprehensive view of the attendance details of a single employee from the selected start and end date. Here are some tips to help you get started:
            Select the appropriate date range, then filter by employee by selecting their name from the employee filter. Then click on “Get Button”, the report will be generated. Review the report. You can see the date, time, and type of attendance (e.g., clock-in, clock-out, break) for each record. If you need to share this information with others, you can export the report by clicking on “print” button. 
            If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
              "company_email"
            )}.
            `,
        path: "/attendancedetailsreport",
      },

      // {
      //   name: "Daily Attendence",
      //   title: "Shift Rules",
      //   icon: <SummarizeIcon />,
      //   path: "/dailyattendance",
      // },

      {
        name: "Employee Off Days",
        title: "Monthly Report",
        toolTipText: ``,
        icon: <PeopleIcon />,
        path: "/emp_offdays",
      },

      {
        name: "Late Arrival",
        title: "Late Arrival Report",
        toolTipText: `Welcome to the Late Arrival Reports help center!
            Late Arrival Screen provides a comprehensive overview of late arrivals of employees, with the ability to view data at a monthly and yearly level. The screen is divided into three tabs: Monthly Detail, Monthly Summary, and Yearly Summary.
            The Monthly Detail tab displays the complete list of late arrivals for a selected month. Users can filter the data by selecting a specific date or date range. 
            The Monthly Summary tab provides a summary of late arrivals for each employee within a selected month. Users can also filter the data by selecting a specific date or date range.
            The Yearly Summary tab displays the total number of late arrivals for a selected employee for the entire year. Users can select a specific year to view the data. 
            We hope this information is helpful in navigating the Late Arrival Screen. If you have any further questions or concerns, please do not hesitate to contact us at ${cookies.get(
              "company_email"
            )}.
            `,
        icon: <AssignmentLateIcon />,
        path: "/latearrivalreport",
      },
      {
        name: "Early Departure",
        title: "Early Departure Report",
        icon: <AlbumIcon />,
        toolTipText: `Welcome to the Early Departure Reports help center!
            In this section, you can learn about the Early Departure screen, which displays three tabs: Monthly Detail, Monthly Summary, and Yearly Summary. An early departure report of employee is a summary of the number of times an employee leaves work before their scheduled shift end time over a specified period of time.
            To use the Early Departure screen, first select a date in the date selector at the top of the screen. This will display information about employee departures for that day.
            The Monthly Detail tab shows a detailed breakdown of employee departures for each day of the selected month. You can click on each day to see more information about individual employee departures, including the time of departure and the reason for leaving early.
            The Monthly Summary tab provides a summary of employee departures for the selected month, including the total number of early departures and the percentage of employees who left early.
            The Yearly Summary tab displays a summary of employee departures for the selected year, but only for one employee at a time. Simply select an employee from the dropdown list to view their yearly summary.
            We hope this helps you navigate the Early Departure screen. If you have any further questions or concerns, please don't hesitate to contact us at ${cookies.get(
              "company_email"
            )}.
            
            `,
        path: "/earlydeparturereport",
      },
      {
        name: "Overtime",
        title: "Overtime Report",
        icon: <FormatOverlineIcon />,
        toolTipText: `Welcome to the Overtime Help Center!
            Overtime screen offers three tabs to help you manage your overtime data: Monthly Detail, Monthly Summary, and Yearly Summary.
            Monthly Detail displays a detailed breakdown of an employee's overtime hours and pay for each day of the selected month. You can select a specific date to view the overtime details for that day.
            Monthly Summary provides an overview of an employee's total overtime hours and pay for the selected month. You can also view the total number of overtime hours and pay for all employees for the selected month.
            Yearly Summary displays a summary of an employee's total overtime hours and pay for the entire year. You can select a specific year and employee to view their yearly summary.
            To get started, simply select the tab you wish to view and choose the date or employee you want to see overtime data for. If you need further assistance, please don't hesitate to reach out us at ${cookies.get(
              "company_email"
            )}.
            `,
        path: "/overtimereport",
      },
    ],
  },
  {
    name: "Email",
    title: "Settings",
    icon: <EmailIcon />,
    path: "/tags",
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
    subitems: [
      {
        name: "Email Template",
        title: "Tags",
        icon: <AttachEmailIcon />,
        toolTipText: `Welcome to the Tag Screen!
        Here you can define tags. Simply create a new tag and choose a color that represents it best. You can then apply the tag to relevant items, such as emails, documents, and tasks, and easily filter and search for them later.
        This feature can help you stay organized and efficient, as well as improve your productivity and time management skills. So go ahead and start tagging!
        If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
          "company_email"
        )}.`,
        path: "/email",
        subitems: [],
      },
    ],
  },
  {
    name: "Settings",
    title: "Tags",
    icon: <SettingsApplicationsIcon />,
    path: "/allsettings",
    toolTipText: `Welcome to the Setting Screen!
        Here you can Change Setting of for All .${cookies.get("company_email")}.`,
    subitems: [],
  },
];

export const SuperAdminSideBarData = [
  {
    name: "Dashboard",
    title: "Dashboard",
    path: "/dashboard",
    toolTipText: `Welcome to the Dashboard screen!  
      Dashboard is used to track and monitor key performance indicators (KPIs) in real-time. This screen provides a visual display of important metrics and data relevant to the administrator's role, allowing them to quickly identify and respond to any issues or trends. 
      If you have any questions or concerns, please don't hesitate to contact us at ${cookies.get(
        "company_email"
      )}.`,
    icon: <StackedBarChartIcon />,
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
    subitems: [],
  },
  {
    name: "Organizations",
    title: "Organizations",
    path: "/organizations",
    toolTipText: `Welcome to the Organizations screen!  
      Organizations is used to add Organizations and view their details}.`,
    icon: <AddBusinessIcon />,
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
    subitems: [],
  },
];
