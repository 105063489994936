import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useEffect } from "react";
import DeviceAttendanceTable from "../../Components/deviceattendance/DeviceAttendancetable";
const DeviceAttendance = () => {
    const [canView, setcanView] = useState(null);
    const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
    useEffect(() => {
        let string = "TodayAttendance";
        let value = roles?.ruleid?.filter((role) => {
            if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
                return role;
            }
        });
        console.log(value);
        let match = value?.some((val) => {
            if (val?.module?.split("_")[1] === "View") {
                return true;
            }
        });
        setcanView(match);
        console.log(match);
        if (!roles || roles.length < 1) {
            setcanView(true);
        }
        setroles(JSON.parse(localStorage.getItem("roles")));
    }, []);
    return (
        <div>
            {canView === true ? (


                <DeviceAttendanceTable
                />

            ) : canView === false ? (
                <Navigate to={"/dashboard"} />
            ) : (
                <Box>Loading ...</Box>
            )}
        </div>
    );
};

export default DeviceAttendance;
DeviceAttendance.propTypes = {
    setShowForm: PropTypes.any,
};
