import {
  Avatar,
  Box,
  IconButton,
  Link,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import moment from "moment";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      border: `1px solid ${theme.palette.grey[300]}`,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "10px 20px",
      borderRadius: "2px",
      color: theme.palette.grey[700],
      [theme.breakpoints.down("md")]: {
        flexWrap: "wrap",
      },
    },
    imageContainer: {
      display: "flex",
      gap: "20px",
      alignItems: "center",
      width: "100%",
      padding: "10px 20px",
      borderRadius: "10px",
    },
    contentContainer: {
      display: "flex",
      alignItems: "center",
      gap: "20px",
      padding: "10px 20px",
      [theme.breakpoints.down("md")]: {
        gap: "15px",
        flexDirection: "column",
      },
    },
    image: {
      width: "100%",
      maxWidth: "100px",
      height: "100px",
      [theme.breakpoints.down("md")]: {
        maxWidth: "70px",
        height: "70px",
      },
    },
    Card: {
      display: "flex",
      alignItems: "center",
      gap: "20px",
      padding: "10px 20px",
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: "10px",
      [theme.breakpoints.down("md")]: {
        padding: "8px 16px",
        gap: "17px",
        width: "200px"

      },
    },
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
    },
    Container: {
      width: "100%",
      maxWidth: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",

    },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    TableContain: { height: "300px", overflowY: "scroll" },
  };
});

const EmployeeDetails = ({ data }) => {
  console.log("data", data);
  const {
    container,
    imageContainer,
    contentContainer,
    image,
    Card,
    ModalContainer,
    Container,
    TableHeader,
    TableContain,
  } = useStyles();
  const [showModal, setshowModal] = useState(false);
  const [showModal2, setshowModal2] = useState(false);

  return (
    <>
      <Box className={container}>
        <Box className={imageContainer}>
          <Box className={image}>
            {data?.picture ? (
              <img
                src={data?.picture}
                alt={"employee"}
                style={{ width: "100%", height: "100%", borderRadius: "50%" }}
              />
            ) : (
              <Avatar
                src="/broken-image.jpg"
                sx={{ width: "100%", height: "100%" }}
              />
            )}
          </Box>
          <Box>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold", textTransform: "capitalize",

              }}
            >
              {data?.Name}
            </Typography>
            <Typography sx={{
              fontWeight: "bold",
              fontSize: { xs: "12px", sm: "16px" },

            }}>
              {data?.designation}
            </Typography>
            <Typography sx={{ paddingTop: "10px", fontSize: "12px" }}>
              {data?.timezone ? JSON.parse(data?.timezone)?.value : ""}
            </Typography>
          </Box>
        </Box>
        <Box className={contentContainer}>
          <Box
            onClick={() => {
              setshowModal(true);
            }}
            sx={{ cursor: "pointer" }}
            className={Card}
          >
            <Box sx={{
              height: {
                xs: "45px",
                sm: "80px",
              },
              width: {
                xs: "45px",
                sm: "80px",
              },
            }}>
              <CircularProgressbar
                value={
                  data
                    ? ((data?.absents / data?.TotalMonthDays) * 100).toFixed(2)
                    : 0
                }
                styles={buildStyles({
                  pathColor: "#d93f3f",
                })}
              />
            </Box>
            <Box>
              <Typography sx={{
                whiteSpace: "nowrap",
                fontSize: { xs: "14px", sm: "18px" },
              }}>
                Total Absents
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold", whiteSpace: "nowrap",
                  fontSize: { xs: "14px", sm: "18px" },

                }}
              >
                {data?.absents}
              </Typography>
            </Box>
          </Box>
          <Box className={Card}>
            <Box sx={{
              height: {
                xs: "45px",
                sm: "80px",
              },
              width: {
                xs: "45px",
                sm: "80px",
              },
            }}>
              <CircularProgressbar
                value={
                  data?.workedDays_percentage
                    ? parseFloat(data?.workedDays_percentage).toFixed(2)
                    : 0
                }
                styles={buildStyles({
                  pathColor: "#edb932",
                })}
              />
            </Box>
            <Box>
              <Typography sx={{
                whiteSpace: "nowrap", fontSize: { xs: "14px", sm: "18px" },
              }}>Total Days</Typography>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold", whiteSpace: "nowrap", fontSize: { xs: "14px", sm: "18px" },
                }}
              >
                {data?.workedDays}
              </Typography>
            </Box>
          </Box>
          <Box className={Card}>
            <Box sx={{
              height: {
                xs: "45px",
                sm: "80px",
              },
              width: {
                xs: "45px",
                sm: "80px",
              },
            }}>
              <CircularProgressbar
                value={
                  data?.workedHrs_percentage
                    ? parseFloat(data?.workedHrs_percentage).toFixed(2)
                    : 0
                }
              />
            </Box>
            <Box>
              <Typography sx={{ whiteSpace: "nowrap", fontSize: { xs: "14px", sm: "18px" }, }}>Work Hours</Typography>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", whiteSpace: "nowrap", fontSize: { xs: "14px", sm: "18px" }, }}
              >
                {data?.workedHrs_percentage
                  ? parseFloat(data?.workedHrs_percentage).toFixed(2)
                  : "0"}{" "}
                %
              </Typography>
            </Box>
          </Box>
          <Box
            onClick={() => {
              setshowModal2(true);
            }}
            sx={{ cursor: "pointer" }} className={Card}>
            <Box sx={{
              height: {
                xs: "45px",
                sm: "80px",
              },
              width: {
                xs: "45px",
                sm: "80px",
              },
            }}>
              <CircularProgressbar
                value={
                  data?.leaves_percentage
                    ? parseFloat(data?.leaves_percentage).toFixed(2)
                    : 0
                }
                styles={buildStyles({
                  pathColor: "#53b7c5",
                })}
              />
            </Box>
            <Box>
              <Typography sx={{ whiteSpace: "nowrap", fontSize: { xs: "14px", sm: "18px" }, }}>Approved Leaves</Typography>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", whiteSpace: "nowrap", fontSize: { xs: "14px", sm: "18px" }, }}
              >
                {data?.aproveleaves
                  ? data?.aproveleaves
                  : "0"}

              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {showModal && (
        <>
          <Modal
            open={showModal}
            onClose={() => {
              setshowModal(false);
            }}
          >
            <Box className={ModalContainer}>
              <Box className={Container}>
                <Box className={TableHeader}>
                  <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                    Absents Details
                  </Typography>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setshowModal(false);
                    }}
                  >
                    <HighlightOffIcon
                      color="primary"
                      sx={{
                        fontSize: "28px",
                      }}
                    />
                  </IconButton>
                </Box>

                <TableContainer component={Paper} className={TableContain}>
                  <Table
                    stickyHeader
                    sx={{
                      minWidth: { md: "auto", xs: "auto" },
                      overflow: "scroll",
                    }}
                    aria-label="simple table"
                    size="small"
                  >
                    <TableHead>
                      <TableRow size="small">
                        <TableCell size="small" sx={{ fontWeight: "bold" }}>
                          #
                        </TableCell>
                        <TableCell size="small" sx={{ fontWeight: "bold" }}>
                          Day
                        </TableCell>
                        <TableCell size="small" sx={{ fontWeight: "bold" }}>
                          Date
                        </TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data &&
                        data?.absent_dates?.map((row, i) => {
                          // const Date=moment(row)
                          return (
                            <TableRow
                              key={i}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                size="small"
                                component="th"
                                scope="row"
                              >
                                {i + 1}
                              </TableCell>
                              <TableCell
                                size="small"
                                component="th"
                                scope="row"
                              >
                                {moment(row).format("dddd")}
                              </TableCell>
                              <TableCell
                                size="small"
                                component="th"
                                scope="row"
                              >
                                {moment(row).format("DD-MMM-YYYY")}
                              </TableCell>

                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Typography variant="h1" sx={{ fontSize: "18px !important" }}>Alternate Days</Typography>
                <TableContainer component={Paper} className={TableContain}>
                  <Table
                    stickyHeader
                    sx={{
                      minWidth: { md: "auto", xs: "auto" },
                      overflow: "scroll",
                    }}
                    aria-label="simple table"
                    size="small"
                  >
                    <TableHead>
                      <TableRow size="small">
                        <TableCell size="small" sx={{ fontWeight: "bold" }}>
                          #
                        </TableCell>
                        <TableCell size="small" sx={{ fontWeight: "bold" }}>
                          Month
                        </TableCell>
                        <TableCell size="small" sx={{ fontWeight: "bold" }}>
                          Off Day
                        </TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data &&
                        data?.alternatedays?.map((row, i) => {
                          // const Date=moment(row)
                          return (
                            <TableRow
                              key={i}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                size="small"
                                component="th"
                                scope="row"
                              >
                                {i + 1}
                              </TableCell>
                              <TableCell
                                size="small"
                                component="th"
                                scope="row"
                              >
                                {moment(row?.month).format("MMM-YYYY")}

                              </TableCell>
                              <TableCell
                                size="small"
                                component="th"
                                scope="row"
                              >
                                {moment(row?.alternate_offdate).format("DD-MMM-YYYY")}

                              </TableCell>

                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Modal>
        </>
      )}
      {showModal2 && (
        <>
          <Modal
            open={showModal2}
            onClose={() => {
              setshowModal2(false);
            }}
          >
            <Box className={ModalContainer}>
              <Box sx={{
                width: "100%",
                maxWidth: "900px !important",
              }} className={Container}>
                <Box className={TableHeader}>
                  <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                    Approved Leaves Details
                  </Typography>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setshowModal2(false);
                    }}
                  >
                    <HighlightOffIcon
                      color="primary"
                      sx={{
                        fontSize: "28px",
                      }}
                    />
                  </IconButton>
                </Box>

                <TableContainer component={Paper} className={TableContain}>
                  <Table
                    stickyHeader
                    sx={{
                      minWidth: { md: "auto", xs: "auto" },
                      overflow: "scroll",
                    }}
                    aria-label="simple table"
                    size="small"
                  >
                    <TableHead>
                      <TableRow size="small">
                        <TableCell size="small" sx={{ fontWeight: "bold" }}>
                          #
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Leave Type
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Type
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Start Date
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>End Date</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Attachment
                        </TableCell>

                        <TableCell sx={{ fontWeight: "bold" }} align="right">
                          Reason
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }} align="right">
                          Approval Count
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }} align="right">
                          Approval Status
                        </TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data &&
                        data?.leaves?.map((row, i) => {
                          // const Date=moment(row)
                          return (
                            <TableRow
                              key={i}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                size="small"
                                component="th"
                                scope="row"
                              >
                                {i + 1}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {row?.leave_type}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {row?.type}
                              </TableCell>
                              <TableCell>
                                {row?.start_date
                                  ? moment(row?.start_date).format("DD-MMM-YYYY")
                                  : ""}
                              </TableCell>
                              <TableCell>
                                {row?.end_date
                                  ? moment(row?.end_date).format("DD-MMM-YYYY")
                                  : ""}
                              </TableCell>

                              <TableCell sx={{ width: "200px" }} align="right">
                                {/* {row?.attachment} */}
                                <Link href={row?.attachment} target={"_blank"}>
                                  <Typography
                                    sx={{
                                      width: "200px",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {row?.attachment}
                                  </Typography>
                                </Link>
                              </TableCell>
                              <TableCell align="right">{row?.reason}</TableCell>
                              <TableCell align="right">
                                {row?.approval_count ? row?.approval_count : ""}
                              </TableCell>
                              <TableCell align="right">
                                {row?.approval_status ? row?.approval_status : ""}
                              </TableCell>

                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};

export default EmployeeDetails;
EmployeeDetails.propTypes = {
  data: PropTypes.any,
  //   setlatest_Entry: PropTypes.any,
};
