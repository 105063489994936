import {
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import moment from "moment/moment";

const useStyles = makeStyles(() => {
  return {
    // ModalContainer: {
    //   display: "flex",
    //   justifyContent: "center",
    //   alignItems: "center",
    //   height: "100vh",
    //   padding: "10px",
    //   width: "100%",
    // },
    // Container: {
    //   // width: "800px",
    //   width: "auto",
    //   padding: "20px",
    //   border: "none",
    //   position: "relative",
    //   background: "white",
    //   borderRadius: "10px",
    //   display: "flex",
    //   gap: "20px",
    //   flexDirection: "column",
    // },
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100vh",
      padding: "10px",
      width: "100%",
    },
    Container: {
      width: "85%",
      maxWidth: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
      "@media (max-width: 600px)": {
        padding: "15px",
        gap: "10px",
      },
    },
    TableContain: { height: "450px", overflowY: "scroll" },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  };
});

function EmpPrevTaskModal({ ShowModal, setShowModal, Tasksdata }) {
  const { TableContain, Container, ModalContainer, TableHeader } = useStyles();

  return (
    <Modal open={ShowModal}>
      <Box className={ModalContainer}>
        <Box className={Container}>
          <Box className={TableHeader}>
            <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
              Task Assigned
            </Typography>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setShowModal(!ShowModal);
              }}
            >
              <HighlightOffIcon
                color="primary"
                sx={{
                  fontSize: "28px",
                }}
              />
            </IconButton>
          </Box>
          <TableContainer component={Paper} className={TableContain}>
            <Table
              stickyHeader
              sx={{
                minWidth: { md: "auto", xs: "auto" },
                overflow: "scroll",
              }}
              aria-label="simple table"
              size="small"
            >
              <TableHead>
                <TableRow size="small">
                  <TableCell size="small" sx={{ fontWeight: "bold", fontSize: { xs: "12px", sm: "15px" } }}>
                    Start Date
                  </TableCell>
                  <TableCell size="small" sx={{ fontWeight: "bold", fontSize: { xs: "12px", sm: "15px" } }}>
                    Subject
                  </TableCell>
                  <TableCell size="small" sx={{ fontWeight: "bold", fontSize: { xs: "12px", sm: "15px" } }}>
                    Estimated Hours
                  </TableCell>
                  <TableCell size="small" sx={{ fontWeight: "bold", fontSize: { xs: "12px", sm: "15px" } }}>
                    Completion
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Tasksdata?.length > 0 ? (
                  Tasksdata.map((row, i) => (
                    <TableRow
                      key={i}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell size="small" component="th" scope="row">
                        {moment(row?.startDate).format("DD-MMM-YYYY")}
                      </TableCell>
                      <TableCell size="small">{row?.subject}</TableCell>
                      <TableCell size="small">{row?.estimatedHours}</TableCell>
                      <TableCell size="small">{row?.doneRatio}</TableCell>
                    </TableRow>
                  ))) : (

                  <TableRow sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}>
                    <TableCell size="small" colSpan={4} align="center">
                      No task assigned
                    </TableCell>
                  </TableRow>
                )}

              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Modal>
  );
}

export default EmpPrevTaskModal;
EmpPrevTaskModal.propTypes = {
  ShowModal: PropTypes.any,
  setShowModal: PropTypes.any,
  Tasksdata: PropTypes.any,
};
