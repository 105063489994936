import React, { useContext, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
// import MenuItem from "@mui/material/MenuItem";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
// import ExitToAppIcon from "@mui/icons-material/ExitToApp";
// import bellIcon from "../../../Assets/icons/bell.svg";
import logo from "../../../Assets/logo_round.png";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useScrollTrigger } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import theme from "../../../theme";
// import SearchIcon from "@mui/icons-material/Search";
// import Cookies from "universal-cookie";
// import { useDispatch } from "react-redux";
// import { logout } from "../../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useEffect } from "react";
import { ProfileContext } from "../../../Context/ProfileContext/ProfileContext";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
// import VolumeUpIcon from "@mui/icons-material/VolumeUp";
// import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import HelpIcon from "@mui/icons-material/Help";
import ToolTipModal from "../../TooltipModal/ToolTipModal";

// const cookies = new Cookies();
const useStyles = makeStyles(() => {
  return {
    appbar_main: {
      padding: "0px 30px",
      display: "flex",
      justifyContent: "space-between",
    },
    topbar_title: {
      height: "100%",
      fontWeight: 700,
      color: theme.palette.secondary.main,
      textDecoration: "none",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    topbar_hamburger: {
      display: "none !important",
      [theme.breakpoints.down("sm")]: {
        display: "block !important",
      },
    },
    topbar_username: {
      color: theme.palette.secondary.main,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    head_title: {
      // fontSize: "22px !important",
      color: "black",
      // background:"transparent"
    },
    searchBar: {
      backgroundColor: theme.palette.background.main,
      borderRadius: "5px",
      width: "100% !important",
      "&::placeholder": {
        color: theme.palette.grey.G2,
      },
    },
  };
});

const ScrollHandler = (props) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: props.window ? window() : undefined,
  });

  return React.cloneElement(props.children, {
    style: {
      backgroundColor: trigger ? "white" : "transparent",
    },
  });
};
// const settings = [
//   {
//     name: "Profile",
//     icon: <ManageAccountsIcon />,
//     path: "/setting/profile",
//   },
//   // { name: "Logout", icon: <ExitToAppIcon />, path: "/logout" },
// ];

const TopBar = ({ isSettings, setOpen, setOnHover, headerTitle, toolTipText }) => {
  const { appbar_main, topbar_hamburger, topbar_username, head_title } =
    useStyles();
  // const [anchorElUser, setAnchorElUser] = useState(null);
  const { profileData } = useContext(ProfileContext);
  const [isplay] = useState(true);
  console.log(isplay);
  const [openToolTipModal, setopenToolTipModal] = useState(false);

  const navigate = useNavigate();
  // const dispatch=useDispatch();
  const [avatarImage, setavatarImage] = useState("");
  useEffect(() => {
    if (profileData) {
      setavatarImage(
        profileData?.profile_image ? profileData?.profile_image : ""
      );
    }
  }, [profileData]);
  // const handleOpenUserMenu = (event) => {
  //   setAnchorElUser(event.currentTarget);
  // };

  // const handleCloseUserMenu = (setting) => {
  //   // if (setting.path === "/logout") {
  //   //   // cookies.remove("user");
  //   //   // cookies.remove("access_token");
  //   //   dispatch(logout())
  //   //   return  navigate("/");
  //   // }
  //   navigate(setting.path);
  //   setAnchorElUser(null);
  // };

  return (
    <ScrollHandler threshold={0}>
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: (theme) => theme.palette.white.main,
        }}
      // className={App_Bar}
      >
        <Toolbar disableGutters className={appbar_main}>
          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h1" className={head_title}
              sx={{
                fontSize: { xs: "17px", sm: "19px", md: "22px", lg: "25px" },
                fontWeight: "bold",
              }}>
              {headerTitle}
            </Typography>
            <IconButton onClick={() => setopenToolTipModal(true)}>
              <HelpIcon color="primary" sx={{ cursor: "pointer" }} />
            </IconButton>
            {/* {isplay ? (
              <VolumeUpIcon
                color="primary"
                sx={{ cursor: "pointer" }}

                text="Speak"
              />
            ) : (
              <VolumeOffIcon color="primary" sx={{ cursor: "pointer" }} />
            )} */}
          </Box>

          {isSettings ? (
            <img src={logo} alt="" height="40px !important" />
          ) : (
            <>
              <MenuIcon
                onClick={() => {
                  setOpen(true);
                  setOnHover(true);
                }}
                className={topbar_hamburger}
                sx={{ color: (theme) => theme.palette.secondary.main }}
              />
              <Box sx={{ width: "30%" }}>
                {/*   <TextField
                  size="small"
                  fullWidth
                  className={searchBar}
                  id="outlined-basic"
                  placeholder="Search"
                  variant="standard"
                  // variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{ color: (theme) => theme.palette.grey.G2 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: {
                      border: "none !important",
                      backgroundColor: (theme) => theme.palette.background.main,
                      color: (theme) => theme.palette.secondary.main,
                      fontWeight: "500",
                    },
                  }}
                />*/}
              </Box>
            </>
          )}
          <Box sx={{ display: "flex", alignItems: "center", gap: "30px" }}>
            {/* <Box
              sx={{
                backgroundColor: (theme) => theme.palette.greenShades.g98,
                borderRadius: (theme) => theme.borderRadius.All,
                padding: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <img src={bellIcon} alt="" />
            </Box> */}
            <Box>
              <Tooltip title="Profile">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    // border: (theme) =>
                    //   `1px solid ${theme.palette.greenShades.g92}`,
                    alignItems: "center",
                    position: "relative",
                    gap: "5px",
                    padding: "5px",
                    borderRadius: (theme) => theme.borderRadius.All,
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/setting/profile")}
                // onClick={handleOpenUserMenu}
                >
                  <IconButton
                    sx={{
                      p: 0,
                      position: "relative",
                    }}
                  >
                    <Avatar
                      alt={profileData?.username?.slice(0, 2).toUpperCase()}
                      src={avatarImage}
                      sx={{
                        p: 0,
                        backgroundColor: (theme) => theme.palette.primary.main,
                      }}
                    />
                    <Box
                      sx={{
                        padding: "1px",
                        position: "absolute",
                        top: "-3px",
                        right: "-3px",
                        height: "16px",
                        width: "16px",
                        borderRadius: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: (theme) => theme.palette.white.main,
                      }}
                    >
                      <FiberManualRecordIcon
                        sx={{
                          fontSize: "15px",
                          color: "#61b33b !important",
                        }}
                      />
                    </Box>
                  </IconButton>
                  <Typography
                    className={topbar_username}
                    sx={{
                      color: (theme) => theme.palette.secondary.main,
                      [(theme) => theme.breakpoints.down("sm")]: {
                        display: "none",
                      },
                    }}
                  >
                    {profileData?.username}
                  </Typography>
                  {/* <KeyboardArrowDownIcon
                    sx={{ color: (theme) => theme.palette.secondary.main }}
                  /> */}
                </Box>
              </Tooltip>
              {/* <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem
                    key={setting?.name}
                    onClick={() => handleCloseUserMenu(setting)}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "10px",
                      padding: "10px",
                      cursor: "pointer",
                      width: "inherit",

                      "&:hover": {
                        color: (theme) => theme.palette.primary.main,
                      },
                    }}
                  >
                    {setting?.icon}
                    <Typography textAlign="center">{setting?.name}</Typography>
                  </MenuItem>
                ))}
              </Menu> */}
            </Box>
          </Box>
        </Toolbar>
        {openToolTipModal ? (
          <ToolTipModal
            open={openToolTipModal}
            setOpen={setopenToolTipModal}
            tooltipText={toolTipText}
          />
        ) : null}
      </AppBar>
    </ScrollHandler>
  );
};
export default TopBar;

TopBar.propTypes = {
  isSettings: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setOnHover: PropTypes.func.isRequired,
  headerTitle: PropTypes.any,
  toolTipText: PropTypes.string,

};
