import * as React from "react";

import { Box, Divider, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment/moment";

function ShiftRuleDetail({ AllSingEmpDetail }) {
  const [allSingEmpDetail, setallSingEmpDetail] = useState([]);

  useEffect(() => {
    setallSingEmpDetail(AllSingEmpDetail);
  }, [AllSingEmpDetail]);

  return (
    <>
      <Box sx={{ padding: "10px" }}>
        <Typography
          sx={{ textAlign: "left", fontWeight: "bold", fontSize: "18px" }}
        >
          ShiftRule Details
        </Typography>
        <Divider />
        <Box>
          <Box
            sx={{
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              padding: "20px",
              paddingTop: "10px",
              paddingBottom: "10px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              marginTop: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                // gap: "80px ",
                flexDirection: { xs: 'column', sm: "row" },
                gap: { xs: '10px', sm: "80px" },


              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "30px",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography sx={{ fontWeight: "bold", fontSize: { xs: "15px", sm: "16px" }, }}>Shift Name:</Typography>
                <Typography sx={{
                  fontSize: { xs: "15px", sm: "16px" },

                }}>
                  {allSingEmpDetail?.Employee?.shift_rules?.shift_name}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "30px",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography sx={{ textAlign: "left", fontWeight: "bold", fontSize: { xs: "15px", sm: "16px" }, }}>
                  Duty Hours:
                </Typography>
                <Typography sx={{
                  fontSize: { xs: "15px", sm: "16px" },

                }}>
                  {allSingEmpDetail?.Employee?.shift_rules?.duty_hrs}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                // gap: "80px ",
                flexDirection: { xs: 'column', sm: "row" },
                gap: { xs: '10px', sm: "80px" },
                textAlign: "right",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "40px",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography sx={{ textAlign: "left", fontWeight: "bold", fontSize: { xs: "15px", sm: "16px" }, }}>
                  Working Hours:
                </Typography>
                <Typography sx={{
                  fontSize: { xs: "15px", sm: "16px" },

                }}>
                  {allSingEmpDetail?.Employee?.shift_rules?.working_hrs}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "40px",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography sx={{ fontWeight: "bold", fontSize: { xs: "15px", sm: "16px" }, }}>
                  CheckIn Time:
                </Typography>
                <Typography sx={{
                  fontSize: { xs: "15px", sm: "16px" },

                }}>
                  {allSingEmpDetail?.Employee?.shift_rules
                    ? moment(
                      allSingEmpDetail?.Employee?.shift_rules?.checkin_time,
                      "HH-mm"
                    ).format("LT")
                    : null}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                // gap: "80px ",
                flexDirection: { xs: 'column', sm: "row" },
                gap: { xs: '10px', sm: "80px" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "40px",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography sx={{ fontWeight: "bold", fontSize: { xs: "15px", sm: "16px" }, }}>
                  Checkout Time
                </Typography>
                <Typography sx={{
                  fontSize: { xs: "15px", sm: "16px" },

                }}>
                  {allSingEmpDetail?.Employee?.shift_rules
                    ? moment(
                      allSingEmpDetail?.Employee?.shift_rules?.checkout_time,
                      "HH-mm"
                    ).format("LT")
                    : null}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "40px",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography sx={{ fontWeight: "bold", fontSize: { xs: "15px", sm: "16px" }, }}>Break Time</Typography>
                <Typography sx={{
                  fontSize: { xs: "15px", sm: "16px" },

                }}>
                  {allSingEmpDetail?.Employee?.shift_rules
                    ? `${moment(
                      allSingEmpDetail?.Employee?.shift_rules?.breakin_time,
                      "HH-mm"
                    ).format("LT")} - ${moment(
                      allSingEmpDetail?.Employee?.shift_rules?.breakout_time,
                      "HH-mm"
                    ).format("LT")}`
                    : null}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                // gap: "80px ",
                flexDirection: { xs: 'column', sm: "row" },
                gap: { xs: '10px', sm: "80px" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "40px",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography sx={{ fontWeight: "bold", fontSize: { xs: "15px", sm: "16px" }, }}>Off Days</Typography>
                <Box sx={{ display: "flex", gap: "5px" }}>
                  {allSingEmpDetail?.Employee?.shift_rules?.offdays?.map(
                    (ofday, ind) => (
                      <Typography key={ind} sx={{
                        fontSize: { xs: "15px", sm: "16px" },

                      }}>{ofday.offday}</Typography>
                    )
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "40px",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography sx={{ fontWeight: "bold", fontSize: { xs: "15px", sm: "16px" }, }}>
                  Yearly Leaves
                </Typography>
                <Typography sx={{
                  fontSize: { xs: "15px", sm: "16px" },

                }}>
                  {allSingEmpDetail?.Employee?.shift_rules?.yearly_leaves}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                // gap: "80px ",
                flexDirection: { xs: 'column', sm: "row" },
                gap: { xs: '10px', sm: "80px" },
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "40px",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography sx={{ fontWeight: "bold", fontSize: { xs: "15px", sm: "16px" }, }}>Margin Min</Typography>
                <Typography sx={{
                  fontSize: { xs: "15px", sm: "16px" },

                }}>
                  {allSingEmpDetail?.Employee?.shift_rules?.margin_min}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "40px",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography sx={{ fontWeight: "bold", fontSize: { xs: "15px", sm: "16px" }, }}>Role</Typography>
                <Typography sx={{
                  fontSize: { xs: "15px", sm: "16px" },

                }}>
                  {allSingEmpDetail?.Employee?.loginemp?.type}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ShiftRuleDetail;
ShiftRuleDetail.propTypes = {
  AllSingEmpDetail: PropTypes.any,
};
