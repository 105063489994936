import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  gridClasses,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import {
  fetchMonthalyDetailed,
  getEarlyDepartureReportDetails,
} from "../../Redux/Slice/EarlyDepartureReport/EarlyDepartureReportSlice";
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// import { ReasonEarlyArrivalReportApi } from "../../Api/EarlyDepartureReport/ReasonEarlyDepartureReportApi";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { AttendanceJustification } from "../../Api/EarlyDepartureReport/ReasonEarlyDepartureReportApi";
import { useMediaQuery } from "@mui/material";
const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});
function CustomToolbar() {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport csvOptions={{ fileName: "EarlyDepartureReport" }} />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  );
}
function EmployeeEaryDepartureReport() {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const { header } = useStyles();
  const [pageSize, setPageSize] = useState(15);
  const dispatch = useDispatch();
  const [rows, setrows] = useState([]);
  const [months, setmonths] = useState(moment().format("YYYY-MM"));
  const cookies = new Cookies();
  const [openReason, setOpenReason] = useState(false);
  const [reason, setReason] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const { MonthlyDetailed, status, IsLogin, message } = useSelector(
    getEarlyDepartureReportDetails
  );
  // const [isEdit, setisEdit] = useState(true);
  const [loading, setloading] = useState(false);
  const [monthlyDetailed, setmonthlyDetailed] = useState([]);
  const { setsnackBarData } = useContext(SnackBarContext);
  const Date = useRef();
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  useEffect(() => {
    if (months) {
      if (cookies.get("user")?.type === "Employee") {
        dispatch(
          fetchMonthalyDetailed({
            emp_id: cookies.get("user")?.Emp_id,
            months: months,
          })
        );
      }
    }
  }, [dispatch]);
  console.log(rows);
  useEffect(() => {
    let arr = [];

    MonthlyDetailed &&
      MonthlyDetailed?.forEach((item, idx) => {
        return arr.push({
          ...item,
          id: idx + 1,
          sr: idx + 1,
          date: item?.date ? moment(item?.date).format("DD-MMM-YYYY") : "",
          time: item?.time ? moment(item?.time, "HH:mm").format("LT") : "",
          early_time: item?.early_time
            ? parseInt(item?.early_time.split(":")[0]) === 0 &&
              parseInt(item?.early_time.split(":")[1]) === 0
              ? 0
              : `${item?.early_time.split(":")[0]} hrs ${item?.early_time.split(":")[1]
              } min`
            : "",
          attendance_id: item?.attendance_id,
        });
      });

    setrows(arr);
    setmonthlyDetailed(arr);
  }, [MonthlyDetailed]);
  // const handleChange = (e, cellval) => {
  //   console.log(e);
  //   console.log(cellval);
  //   monthlyDetailed[cellval.row.id - 1].reason = e.target.value;
  //   monthlyDetailed[cellval.row.id - 1].edit = true;
  //   setisEdit(false);
  // };
  const columns = [
    { field: "sr", headerName: "Sr #", width: 100, headerClassName: header },
    {
      field: "date",
      headerName: "Date",
      width: 200,
      headerClassName: header,
    },
    {
      field: "time",
      headerName: "Check Out",
      width: 200,
      headerClassName: header,
    },
    {
      field: "early_time",
      headerName: "Early Time",
      width: 150,
      headerClassName: header,
    },
    {
      field: "comments",
      headerName: "Comment",
      width: 200,
      headerClassName: header,
      renderCell: (cellval) => {
        console.log(cellval?.row);
        return cellval?.row?.reason ? (
          <Tooltip title={cellval?.row?.reason?.reason || ""} arrow>
            <Typography
              sx={{
                maxWidth: 200,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "normal",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
              }}
            >
              Reason: {cellval?.row?.reason?.reason}
            </Typography>
          </Tooltip>
        ) : (
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                setSelectedDay(cellval.row);
                setOpenReason(true);
              }}
            >
              Add Comment
            </Button>
          </Box>
        );
      },
    },
  ];
  const handleSubmit = async () => {
    if (months === "") {
      return setsnackBarData({
        openToast: true,
        message: "please select date",
        type: "error",
      });
    }
    // const obj = { ...values, country: value?.label };
    dispatch(
      fetchMonthalyDetailed({
        emp_id: cookies.get("user")?.Emp_id,
        months: months,
      })
    );
  };
  const handleComment = async () => {
    setloading(true);
    const response = await AttendanceJustification({
      attendance_id: selectedDay?.attendance_id,
      type: "Early Departure",
      reason: reason,
    });
    setsnackBarData(response.snackBarData);
    console.log(response);
    if (response.data.IsSuccess) {
      setOpenReason(false);
      setReason(null);
      setSelectedDay(null);
      dispatch(
        fetchMonthalyDetailed({
          emp_id: cookies.get("user")?.Emp_id,
          months: months,
        })
      );
    }
    setloading(false);
  };
  const onTextChange = () => {
    if (Date.current.value === "") {
      setmonthlyDetailed(rows);
    } else {
      let suggestions = [];
      let regex2 = new RegExp(moment(Date.current.value).format("DD-MMM-YYYY"), "i");

      suggestions = rows?.filter(
        (val) => Date.current?.value === "" || regex2.test(val?.date)
      );
      setmonthlyDetailed(suggestions);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            paddingBottom: "10px",
            width: "100%",
            padding: "10px 10px 0px 10px",
            alignItems: "center",
          }}
        >
          <TextField
            type={"month"}
            name="months"
            // variant="outlined"
            size="small"
            value={months}
            onChange={(e) => {
              setmonths(e.target.value);
            }}
          />

          <Button onClick={handleSubmit} sx={{ padding: "5px 10px" }} variant="contained">
            Get
          </Button>
          {/* {loading ? (
            <CircularProgress />
          ) : (
            <Button
              disabled={isEdit}
              onClick={handleComment}
              variant="contained"
              sx={{ padding: "5px 10px", textAlign: "center" }}
            >
              Update
            </Button>
          )} */}
        </Box>
        <Box sx={{ padding: "10px 10px 0px 0px" }}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: (theme) => theme.palette.grey.G2 }} />
                </InputAdornment>
              ),
            }}
            variant="standard"
            type="date"
            autoComplete="off"
            placeholder="Search"
            // className="input_border"
            inputRef={Date}
            id="date"
            onChange={onTextChange}
          />
        </Box>
      </Box>
      {status === "loading" ? (
        <Box
          sx={{
            height: "calc(100vh - 224px)",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div
          style={{
            height: "calc(100vh - 220px)",
            width: "100%",
            padding: "10px",
          }}
        >
          <DataGrid
            rows={monthlyDetailed}
            columns={columns}
            onRowClick={() => { }}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[15, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            pagination
            // components={{
            //   Toolbar: CustomToolbar,
            // }}
            components={isSmallScreen ? {} : { Toolbar: CustomToolbar }}
            localeText={{
              toolbarDensity: "Size",
              toolbarDensityLabel: "Size",
              toolbarDensityCompact: "Small",
              toolbarDensityStandard: "Medium",
              toolbarDensityComfortable: "Large",
            }}
          />
        </div>
      )}
      <Dialog
        open={openReason}
        onClose={() => {
          setReason(null);
          setOpenReason(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          Add Comment for the Early Departure
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="dense"
            variant="outlined"
            label="Comment"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              sx={{
                background: "red",
                color: "white !important",
                fontSize: "13px",
                textTransform: "none",
              }}
              onClick={() => {
                setReason(null);
                setOpenReason(false);
              }}
            >
              No
            </Button>

            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                sx={{
                  background: "#3480FA",
                  color: "white !important",
                  fontSize: "13px",
                }}
                onClick={() => {
                  if (reason === null || reason === "") {
                    setsnackBarData({
                      openToast: true,
                      message: "Enter Comment first",
                      type: "error",
                    });
                  } else {
                    handleComment();
                  }
                }}
              >
                Confirm
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EmployeeEaryDepartureReport;
