import PropTypes from "prop-types";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  gridClasses,
  InputAdornment,
  Modal,
  Paper,
  TextField,
  Tooltip,
  Typography,
  MenuItem,
} from "@mui/material";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";

import { useContext } from "react";
// import AutorenewIcon from "@mui/icons-material/Autorenew";
import InfoIcon from "@mui/icons-material/Info";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { makeStyles } from "@mui/styles";
import { useRef } from "react";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "../../Button/AddNewButton";
import Loader from "../../Loader/Loader";
import { TabelStyleContext } from "../../../Context/TabelStyleContext/TabelContext";
import { HeaderContext } from "../../../Context/SnackBarContext/HeaderContext/HeaderContext";
import { TooltipContext } from "../../../Context/TooltipContext/TooltipContent";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import { UpdateEmpStatApi } from "../../../Api/Employee/UpdateEmployeStatus";
import { getallData } from "../../../Redux/Slice/Applicants/ApplicantSlice";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});

function ApplicantsTable({
  setShowForm,
  setempData,
  setisEdited,
  pageSize,
  page,
  setPage,
  setShowDetails,
}) {
  const { tabelStyle } = useContext(TabelStyleContext);

  const { header } = useStyles();
  const dispatch = useDispatch();
  const { setheader } = useContext(HeaderContext);
  const [selectedEmployee, setselectedEmployee] = useState(null);
  const [rows, setrows] = useState([]);
  const [filterRows, setfilterRows] = useState([]);
  const [open, setOpen] = useState(false);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [loader, setloader] = useState(false);
  const date = new Date();
  const descRef = useRef();
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [editPermit, seteditpermit] = useState(false);
  const [addPermit, setaddpermit] = useState(false);
  const { settooltipData } = useContext(TooltipContext);
  const [session, setSession] = useState([]);
  let [statee, setstatee] = useState("Hiring Section");

  const { applicants, status } = useSelector((state) => state.applicants);
  console.log(filterRows);

  useEffect(() => {
    dispatch(getallData());
  }, [dispatch]);

  useEffect(() => {
    if (applicants) {
      const formattedData = applicants?.map((applicant, index) => ({
        index: index + 1,
        id: applicant.applicant_id,
        name: applicant.name,
        email: applicant.email,
        city: applicant.city,
        phone: applicant.contact_number,
        degree: applicant.degree,
        university: applicant.university,
        comment: applicant.comment,
        cvLink: applicant.cv_link,
        hiringSession_title: applicant?.hiringSection?.title,
        // Provide a default value if shift_rules or shift_name is undefined
        // Map other fields as needed based on your data structure
      }));
      setrows(formattedData);
      setfilterRows(formattedData);
      let data = [];
      formattedData.forEach((element) => {
        data.push(element.hiringSession_title);
      });
      setSession([...new Set(data)]);
    }

    console.log(editPermit);
  }, [applicants]);
  useEffect(() => {
    let string = "ActiveEmployees";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Edit") {
        seteditpermit(true);
      } else if (val?.module?.split("_")[1] === "Add") {
        setaddpermit(true);
      }
    });
    if (!roles || roles.length < 1) {
      setaddpermit(true);
      seteditpermit(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport csvOptions={{ fileName: "Active Applicants" }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }

  const columns = [
    {
      field: "index",
      headerName: "Sr #",
      headerClassName: header,
    },
    {
      field: "name",
      headerName: "Name",
      width: 180,
      headerClassName: header,
    },

    // {
    //   field: "cnic",
    //   headerName: "CNIC #",
    //   width: 200,
    //   headerClassName: header,
    // },

    {
      field: "phone",
      headerName: "Phone",
      width: 200,
      headerClassName: header,
    },

    // { field: "dob", headerName: "DOB", width: 180, headerClassName: header },

    {
      field: "degree",
      headerName: "Degree",
      width: 200,
      headerClassName: header,
    },
    {
      field: "university",
      headerName: "University",
      width: 200,
      headerClassName: header,
    },
    {
      field: "city",
      headerName: "City",
      width: 200,
      headerClassName: header,
    },
    {
      field: "hiringSession_title",
      headerName: "Hiring Session",
      width: 200,
      headerClassName: header,
    },

    // {
    //   field: "time_zone",
    //   headerName: "Time Zone",
    //   width: 250,
    //   headerClassName: header,
    // },

    {
      field: "Actions",
      headerName: "Actions",
      width: 160,
      headerClassName: header,
      renderCell: (celval) => {
        return (
          <>
            {" "}
            <Box sx={{ display: "flex", gap: "25px", alignItems: "center" }}>
              {addPermit ? (
                <Tooltip title="Update Employe">
                  <AppRegistrationIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setempData(celval.row);
                      setisEdited(true);
                      setShowForm(true);
                    }}
                  />
                </Tooltip>
              ) : null}
              <Tooltip title="Details">
                <InfoIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    settooltipData(`Welcome to the Employee Detail View Screen!
This screen is designed to provide a comprehensive view of an individual employee's information. Here, you will find details such as their name, job title, department, contact information, and more.
If you have any questions or concerns about the platform, please don't hesitate to reach out to your HR representative`);
                    // navigate("/employeesDetails", { state: celval.row });
                    setShowDetails(true);
                    setempData(celval.row);
                  }}
                />
              </Tooltip>

              {/* {editPermit ? (
                <Tooltip title="Deactivate">
                  <AutorenewIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setselectedEmployee(celval.row);
                      handleOpen();
                    }}
                  />
                </Tooltip>
              ) : null} */}
            </Box>
          </>
        );
      },
    },
  ];
  const hanldeInactiveEmp = async (empRow) => {
    if (!descRef.current.value) {
      setsnackBarData({
        type: "error",
        message: "please add Reason",
        openToast: "true",
      });
      return;
    }
    setloader(true);
    const obj = {
      type: "Inactive",
      emp_id: empRow?.empId,
      date: moment(date).format("YYYY-MM-DD"),
      description: descRef.current.value,
    };
    const response = await UpdateEmpStatApi(obj);
    setsnackBarData(response.snackBarData);
    setloader(false);
    if (response.data.IsSuccess) {
      handleClose();
    }
  };

  const onTextChange = () => {
    let name = document.getElementById("employeeName").value;
    let dept = document.getElementById("employeeDept").value;
    let desig = document.getElementById("employeeDesig").value;
    let session = statee;
    if (name == "" && dept == "" && desig == "" && session == "") {
      setfilterRows(rows);
    } else {
      if (session == "Hiring Section") {
        session = "";
      }
      let suggestions = [];
      const nameRegix = new RegExp(name, "i");
      const deptRegix = new RegExp(dept, "i");
      const desigRegix = new RegExp(desig, "i");
      const sessionRegix = new RegExp(session, "i");
      suggestions = rows.filter(
        (val) =>
          (name === "" || nameRegix.test(val?.name)) &&
          (dept === "" || deptRegix.test(val?.degree)) &&
          (desig === "" || desigRegix.test(val?.university)) &&
          (session === "" || sessionRegix.test(val?.hiringSession_title))
      );

      setfilterRows(suggestions);
    }
  };
  return (
    <Grid
      container
      spacing={2}
      sx={{ padding: "15px 10px 6px 10px", backgroundColor: "white" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          flexWrap: "wrap",
          alignItems: "center",
          padding: "8px 7px 7px 17px",
        }}
      >
        {addPermit ? (
          <>

            <CustomButton
              Color={"primary"}
              Icon={<AddIcon />}
              onClick={() => {
                setheader("Employee And Education Information");
                settooltipData("fdsfsdfsdfsdf");
                setShowForm(true);
              }}
              Label={"Add"}
            />
          </>
        ) : null}

        <Box
          sx={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "flex-end",
            width: "70%",
          }}
        >
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{ color: (theme) => theme.palette.grey.G2 }}
                  />
                </InputAdornment>
              ),
            }}
            sx={{
              width: "100%",
              maxWidth: "180px",
            }}
            variant="standard"
            type="text"
            autoComplete="off"
            placeholder="Name"
            id="employeeName"
            onChange={onTextChange}
          />
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{ color: (theme) => theme.palette.grey.G2 }}
                  />
                </InputAdornment>
              ),
            }}
            sx={{
              width: "100%",
              maxWidth: "200px",
            }}
            variant="standard"
            type="text"
            autoComplete="off"
            placeholder="Degree"
            id="employeeDept"
            onChange={onTextChange}
          />
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{ color: (theme) => theme.palette.grey.G2 }}
                  />
                </InputAdornment>
              ),
            }}
            sx={{
              width: "100%",
              maxWidth: "200px",
            }}
            variant="standard"
            type="text"
            autoComplete="off"
            placeholder="University"
            id="employeeDesig"
            onChange={onTextChange}
          />

          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{
                      color: (theme) =>
                        statee != "Hiring Section"
                          ? theme.palette.grey.G2
                          : null,
                    }}
                  />
                </InputAdornment>
              ),
            }}
            sx={{
              width: "100%",
              maxWidth: "200px",
              opacity: statee === "Hiring Section" ? 0.5 : 1,
            }}
            variant="standard"
            type="text"
            select
            autoComplete="off"
            value={statee}
            defaultValue={"Hiring Section"}
            id="HiringSession"
            onChange={(e) => {
              statee = e.target.value;
              setstatee(statee);
              onTextChange(e);
            }}
          >
            <MenuItem
              disabled
              style={{ display: "none" }}
              value={"Hiring Section"}
            >
              Hiring Section
            </MenuItem>
            {session?.map((item, index) => {
              return (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </TextField>
          {/* <Box >


          </Box> */}
        </Box>
      </Box>
      {status === "loading" ? (
        <>
          <Loader />
        </>
      ) : null}
      <Grid
        item
        md={12}
        sm={12}
        xs={12}
        overflow
        sx={{ height: tabelStyle.height, overflowX: "visible" }}
      >
        <DataGrid
          style={{ overflow: "visible" }}
          disableColumnMenu
          scrollbarSize={50}
          // hideFooterSelectedRowCount={true}
          rows={filterRows}
          columns={columns}
          getRowId={(row) => row.index}
          // rowCount={totalCount}
          pageSize={pageSize}
          page={page}
          onPageChange={(newPage) => {
            setPage(newPage);
            //             if (
            //               Number(employeeDetails?.PageNum) < newPage + 1 &&
            //               AllEmployees?.length < pageSize * Number(newPage + 1)
            //             ) {
            // console.log("abc");
            //             }
            // else if(allChangeData?.length < pageSize*(Number(newPage+1))){
            //   dispatch(fetchChangeRequest({page:newPage,pageSize:pageSize}))

            // }
          }}
          // onPageSizeChange={(newPageSize) => {
          //   if (AllEmployees?.length >= newPageSize) {
          //     setPage(1);

          //     setPageSize(newPageSize);
          //   } else if (newPageSize >= AllEmployees?.length) {
          //     // dispatch(RemoveChangeRequest())
          //     setPage(1);

          //   }
          // }}
          rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          pagination
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{
            toolbar: { csvOptions: { fileName: "test" } },
          }}
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />
      </Grid>

      <Modal
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
        }}
        open={open}
        onClose={() => {
          setselectedEmployee(null);
          handleClose();
        }}
      >
        <Paper sx={{ width: "100%", maxWidth: "500px", padding: "10px" }}>
          <Typography sx={{ textAlign: "left" }}>Reason</Typography>
          <TextField
            placeholder={`Please Enter Reason to InActive ${selectedEmployee?.name}`}
            sx={{ width: "100%", maxWidth: "500px" }}
            variant="outlined"
            multiline
            minRows={4}
            inputRef={descRef}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              paddingTop: "10px",
              gap: "10px",
            }}
          >
            <Button
              sx={{
                background: "red",
                color: "white !important",
                fontSize: "13px",
                textTransform: "none",
              }}
              onClick={() => {
                setselectedEmployee(null);
                handleClose();
              }}
            >
              Cancel
            </Button>
            {loader ? (
              <CircularProgress />
            ) : (
              <Button
                sx={{
                  background: "#3480FA",
                  color: "white !important",
                  fontSize: "13px",

                  textTransform: "none",
                }}
                onClick={() => {
                  hanldeInactiveEmp(selectedEmployee);
                }}
              >
                Save
              </Button>
            )}
          </Box>
        </Paper>
      </Modal>
    </Grid>
  );
}

export default ApplicantsTable;

ApplicantsTable.propTypes = {
  setShowForm: PropTypes.any,
  setempData: PropTypes.any,
  setisEdited: PropTypes.any,
  employeeDetails: PropTypes.any,
  IsLogin: PropTypes.any,
  pageSize: PropTypes.any,
  setPageSize: PropTypes.any,
  page: PropTypes.any,
  setPage: PropTypes.any,
  setShowDetails: PropTypes.any,
};
