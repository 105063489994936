import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  gridClasses,
  IconButton,
  TextField,
  FormControl,
  Typography,
  Modal,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";

import { makeStyles } from "@mui/styles";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";
import Loader from "../Loader/Loader";
import Cookies from "universal-cookie";
import { BreakAdjustmentTimeApi } from "../../Api/BreakAdjustment/AdjustBreakTime";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useDispatch } from "react-redux";
import moment from "moment/moment";
import { useMediaQuery } from "@mui/material";
const useStyles = makeStyles(() => ({
  header: {
    fontWeight: "bold",
    fontSize: "1rem",
  },
  ModalContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    padding: "10px",
    width: "100%",
  },
  Container: {
    width: "fit-content",
    maxWidth: "800px",
    padding: "20px",
    border: "none",
    position: "relative",
    background: "white",
    borderRadius: "10px",
    display: "flex",
    gap: "20px",
    flexDirection: "column",
  },
  infomodal: {
    position: "relative",
    top: "60%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "white",
    borderRadius: "10px",
    padding: "20px",
    border: "none",
    maxheight: "40vh",
    maxWidth: "400px",
  },
}));

function BreakadjTable({ Empdata, Loading, selectedemp, month, Empfetch }) {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const { tabelStyle } = useContext(TabelStyleContext);
  const [adjData, setAdjData] = useState(null);
  const { header, Container, ModalContainer, infomodal } = useStyles();
  const [pageSize, setPageSize] = useState(15);
  const [rows, setRows] = useState([]);
  const [breakreq, setBreakReq] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const cookies = new Cookies();
  const user = cookies.get("user");
  const { setsnackBarData } = useContext(SnackBarContext);
  const [showmodal, setshowmodal] = useState(false);
  const [addreason, setaddreason] = useState(null);
  const [actioncount, setactioncount] = useState(0);
  console.log(rows);
  const dispatch = useDispatch();
  const clickinfo = (reason) => {
    setshowmodal(true);
    setaddreason(reason);
  };
  const strdata =
    "Badge generates a small badge to the top-right of its child generates a small badge to the top-right of its child";

  useEffect(() => {
    let rowData = [];
    Empdata?.breakAdj?.map((adv, idx) => {
      rowData.push({
        ...adv,
        id: idx + 1,
        requestedDate: adv?.requestedDate
          ? moment(adv.requestedDate).format("DD-MMM-YYYY")
          : "",
        requestedTime: adv?.requestedTime * 60,
        approvalTime: adv?.approvalTime * 60,
        approvalStatus: adv?.approvalStatus,
        reason: strdata,
        reasonId: adv?.reasonId,
        emp_id: adv?.emp_id,
      });
    });
    setRows(rowData);
    setBreakReq(rowData);
  }, [Empdata]);
  // Formik
  const formik = useFormik({
    initialValues: {
      requestedTime: adjData ? adjData.requestedTime : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      requestedTime: Yup.number()
        .required("Time is required")
        .min(0, "Time must be a positive number")
        .max(60, "Time must be atmost 60 minutes"),
    }),
    onSubmit: async (values, formikHelpers) => {
      try {
        const updatedValues = {
          reasonId: adjData.reasonId,
          status: "Approved",
          approvalTime: parseFloat((values.requestedTime / 60).toFixed(2)),
          user_id: user.user_id,
        };
        const response = await BreakAdjustmentTimeApi(updatedValues);
        setsnackBarData(response.snackBarData);
        if (response.data.IsSuccess) {
          setactioncount(1);
          formikHelpers.setSubmitting(true);
          if (user?.type === "Admin") {
            dispatch(Empfetch({ empid: selectedemp?.id, month: month }));
          } else if (user?.type === "Employee") {
            dispatch(Empfetch({ empid: user?.id, month: month }));
          }
          formikHelpers.resetForm();
        }
      } finally {
        formikHelpers.setSubmitting(false);
      }
    },
  });
  //function for rejection button
  const handleReject = async () => {
    try {
      const updateValues = {
        reasonId: adjData.reasonId,
        status: "Rejected",
        approvalTime: parseFloat((adjData.requestedTime / 60).toFixed(2)),
        user_id: user.user_id,
      };
      const response = await BreakAdjustmentTimeApi(updateValues);
      setsnackBarData(response.snackBarData);
      if (response.data.IsSuccess) {
        setactioncount(1);
        if (user?.type === "Admin") {
          dispatch(Empfetch({ empid: selectedemp?.id, month: month }));
        } else if (user?.type === "Employee") {
          dispatch(Empfetch({ empid: user?.id, month: month }));
        }
        setOpen(false);
      }
    } catch (error) {
      console.error("Error rejecting request:", error);
    }
    setOpen(false);
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport
          csvOptions={{ fileName: "BreakAdjustmentRequest" }}
        />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }
  const columns = [
    { field: "id", headerName: "Sr #", headerClassName: header },
    {
      field: "requestedDate",
      headerName: "Date",
      width: 180,
      headerClassName: header,
    },
    {
      field: "reason",
      headerName: "Reason",
      width: 250,
      headerClassName: header,
      cursor: "pointer",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div>
              {/* For showing specific length of string in reason col */}
              <span>
                {params.value.length > 25
                  ? params.value.slice(0, 25)
                  : params.value}
              </span>
              {params.value.length > 25 && <span>...</span>}
            </div>
            {/* show icon if reason value string is more than limit */}
            {params.value.length >= 30 && (
              <IconButton
                size="small"
                onClick={() => clickinfo(params.value)}
                sx={{ color: (theme) => theme.palette.primary.main }}
              >
                <InfoIcon fontSize="small" />
              </IconButton>
            )}
          </div>
        );
      },
    },
    {
      field: "requestedTime",
      headerName: "Requested Time",
      width: 160,
      headerClassName: header,
      renderCell: (params) => {
        return `${params.value}M`;
      },
    },
    {
      field: "approvalTime",
      headerName: "Approval Time",
      width: 160,
      headerClassName: header,
      renderCell: (params) => {
        return <span>{params.value > 0 ? `${params.value}M` : "N/A"}</span>;
      },
    },
    {
      field: "approvalStatus",
      headerName: "Status",
      width: 180,
      headerClassName: header,
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 190,
      headerClassName: header,
      renderCell: (cellVal) => {
        if (user?.type === "Admin") {
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* Approve  Button in action col */}
              {cellVal.row.approvalStatus == "Pending" && actioncount < 1 && (
                <Button
                  sx={{
                    backgroundColor: (theme) => theme.palette.primary.main,
                    color: (theme) => theme.palette.white.main,
                    padding: "4px 8px",
                    fontSize: "12px",
                    minWidth: "auto",
                  }}
                  onClick={() => {
                    setOpenDialog(true);
                    setAdjData(cellVal.row);
                  }}
                >
                  Approve
                </Button>
              )}
              {/* Reject button in action col */}
              {cellVal.row.approvalStatus == "Pending" && actioncount < 1 && (
                <Button
                  sx={{
                    backgroundColor: (theme) => theme.palette.error.main,
                    color: (theme) => theme.palette.white.main,
                    marginLeft: "10px",
                    paddingLeft: "10px",
                    padding: "4px 8px",
                    fontSize: "12px",
                    minWidth: "auto",
                  }}
                  onClick={() => {
                    setOpen(true);
                    setAdjData(cellVal.row);
                  }}
                >
                  Reject
                </Button>
              )}
              {cellVal.row.approvalStatus === "Rejected" ||
                cellVal.row.approvalStatus === "Accepted" ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>N/A</Box>
              ) : null}
            </Box>
          );
        } else if (user?.type === "Employee") {
          return <Box sx={{ display: "flex", alignItems: "center" }}>N/A</Box>;
        }
      },
    },
  ];

  return (
    <>
      <Box sx={{ height: tabelStyle.height, padding: "10px" }}>
        {Loading ? <Loader /> : null}
        {status === "loading" && Empdata?.length <= 0 && <Loader />}
        <DataGrid
          rows={breakreq}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[15, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          columnVisibilityModel={
            user?.type === "Employee"
              ? { action: false, name: false }
              : { status: true }
          }
          // components={{
          //   Toolbar: CustomToolbar,
          // }}
          components={isSmallScreen ? {} : { Toolbar: CustomToolbar }}
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />
      </Box>
      {/* Approve Modal*/}
      <Dialog open={openDialog} maxWidth="sm" fullWidth>
        <DialogContent>
          <DialogActions>
            <IconButton
              sx={{ position: "absolute", top: "0px", right: "12px" }}
              onClick={() => setOpenDialog(false)}
            >
              <CancelIcon color="error" sx={{ fontSize: "25px" }} />
            </IconButton>
          </DialogActions>
          <FormControl fullWidth sx={{ bottom: "0px" }}>
            <Box sx={{ width: "100%" }}>
              <Typography>Time in minutes</Typography>
              <form onSubmit={formik.handleSubmit}>
                <TextField
                  fullWidth
                  size="small"
                  name="requestedTime"
                  type="number"
                  variant="outlined"
                  value={formik.values.requestedTime}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.requestedTime &&
                    Boolean(formik.errors.requestedTime)
                  }
                  helperText={
                    formik.touched.requestedTime && formik.errors.requestedTime
                  }
                />
                <Box sx={{ marginTop: "19px" }}>
                  <Button
                    sx={{
                      backgroundColor: (theme) => theme.palette.primary.main,
                      color: (theme) => theme.palette.white.main,
                      float: "right",
                      marginLeft: "16px",
                    }}
                    type="submit"
                    variant="contained"
                  >
                    Submit
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: (theme) => theme.palette.error.main,
                      color: (theme) => theme.palette.white.main,
                      float: "right",
                    }}
                    color="error"
                    variant="contained"
                    onClick={() => setOpenDialog(false)}
                  >
                    Cancel
                  </Button>
                </Box>
              </form>
            </Box>
          </FormControl>
        </DialogContent>
      </Dialog>
      {/* Reject Modal*/}
      <Modal open={open}>
        <Box className={ModalContainer}>
          <Box className={Container}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                overflow: "scroll",
              }}
            >
              <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                Are you sure to Reject Break Adjustment
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                <Button
                  sx={{
                    color: (theme) => theme.palette.white.main,
                    backgroundColor: (theme) => theme.palette.error.main,
                  }}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  No
                </Button>
                <Button
                  sx={{
                    backgroundColor: (theme) => theme.palette.primary.main,
                    color: (theme) => theme.palette.white.main,
                  }}
                  onClick={handleReject}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* Snackbar toaster: if attendace data is not found for specific brkadj */}
      {/* {!dateExists && (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={opensnack}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <SnackbarContent
            message="Attendance not found"
            style={{
              backgroundColor: "white",
              color: "black",
            }}
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="error"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
        </Snackbar>
      )} */}
      {/* Reason col: moreinfo modal */}
      <Box>
        {showmodal && (
          <Modal open={showmodal} onClose={() => setshowmodal(false)}>
            <Box className={infomodal}>
              <Typography sx={{ paddingTop: "10px", fontSize: "13px" }}>
                {addreason}
              </Typography>
              <IconButton
                sx={{ position: "absolute", top: "0px", right: "4px" }}
                onClick={() => setshowmodal(false)}
              >
                <CancelIcon color="error" sx={{ fontSize: "18px" }} />
              </IconButton>
            </Box>
          </Modal>
        )}
      </Box>
    </>
  );
}

export default BreakadjTable;

BreakadjTable.propTypes = {
  setAdjData: PropTypes.any,
  Empdata: PropTypes.any,
  Loading: PropTypes.any,
  selectedemp: PropTypes.any,
  month: PropTypes.any,
  Empfetch: PropTypes.any,
};
