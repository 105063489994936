import { Button, Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useState } from "react";
// import employedetail from "../../Assets/download.jpg";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PersonalInfo from "../../Components/EmployeDetails/PersonalInfo";
import DepartmentDetail from "../../Components/EmployeDetails/DepartmentDetail";
import SalaryDetail from "../../Components/EmployeDetails/SalaryDetail";
import ShiftRuleDetail from "../../Components/EmployeDetails/ShiftRuleDetail";
// import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchSingleEmployeDetail,
  getemployeeDetails,
} from "../../Redux/Slice/Employee/EmplyeeSlice";
import Cookies from "universal-cookie";
// var voice = require('responsiveVoice');
import { Navigate, useLocation, useNavigate } from "react-router-dom";
// import { useLocation } from 'react-router';
// import theme from '../../theme';
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import Loader from "../../Components/Loader/Loader";
import person from "../../Assets/person.png";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function FullWidthTabs({ data, setShowDetails }) {
  const { setsnackBarData } = useContext(SnackBarContext);
  const cookies = new Cookies();
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const { SingleEmployeDetail, IsLogin, message, status } =
    useSelector(getemployeeDetails);
  const [AllSingEmpDetail, setAllSingEmpDetail] = useState([]);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [canView, setcanView] = useState(null);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  useEffect(() => {
    let string = "EmployeeDetails";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    let match = value?.some((val) => {
      if (val?.module?.split("_")[1] === "View") {
        return true;
      }
    });
    setcanView(match);
    if (!roles || roles.length < 1) {
      setcanView(false);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (state) {
      dispatch(FetchSingleEmployeDetail(state?.empId));
    } else if (data) {
      dispatch(FetchSingleEmployeDetail(data?.empId));
    } else {
      let user = cookies.get("user");
      dispatch(FetchSingleEmployeDetail(user?.Emp_id));
    }
  }, [dispatch]);

  useEffect(() => {
    setAllSingEmpDetail(SingleEmployeDetail);
  }, [SingleEmployeDetail]);

  const handlebackButScreen = () => {
    navigate(-1);
  };

  return (
    <>
      {status !== "succeeded" && <Loader />}
      {canView === true ? (
        // <Box
        //   sx={{
        //     width: "100%",
        //     display: "flex",
        //     gap: "10px",
        //     height: "calc(100vh - 170px)",
        //     overflow: "hidden",
        //     // flexDirection: { xs: 'column', sm: "row" }
        //   }}
        // >
        //   <Box sx={{ width: "20%" }}>
        //     <Box
        //       sx={{
        //         display: "flex",
        //         justifyContent: "left",
        //         padding: "10px 0px 0px 10px",
        //       }}
        //     >
        //       {cookies.get("user")?.type === "Admin" ? (
        //         <Button
        //           sx={{ width: "30px", height: "30px" }}
        //           variant="outlined"
        //           onClick={() => {
        //             if (data) {
        //               setShowDetails(false);
        //             } else handlebackButScreen();
        //           }}
        //         >
        //           <ArrowBackIcon sx={{ color: "grey !important" }} />
        //         </Button>
        //       ) : null}
        //     </Box>

        //     <Box sx={{ width: "100%", height: "170px", paddingTop: "20px" }}>
        //       <img
        //         style={{
        //           width: "100%",
        //           maxWidth: "140px",
        //           height: "140px",
        //           borderRadius: "100%",
        //         }}
        //         alt="EmployeImage"
        //         // src={employedetail}
        //         src={
        //           AllSingEmpDetail?.Employee?.emp_image
        //             ? AllSingEmpDetail?.Employee?.emp_image
        //             : person
        //         }
        //       />
        //     </Box>
        //     <Typography
        //       sx={{
        //         // paddingTop: "15px",
        //         // paddingLeft: "10px",
        //         fontWeight: "bold",
        //         fontSize: "21px",
        //       }}
        //     >
        //       {" "}
        //       {AllSingEmpDetail?.Employee?.name}
        //     </Typography>
        //     <Divider sx={{ marginLeft: "10px" }} />
        //     <Box sx={{ width: "100%", textAlign: "left" }}>
        //       <Tabs
        //         orientation="vertical"
        //         sx={{ paddingTop: "10px" }}
        //         value={value}
        //         onChange={handleChange}
        //       >
        //         <Tab label="Personal Details" {...a11yProps(0)} />
        //         <Tab label="Department Details" {...a11yProps(1)} />
        //         <Tab label="Salary Details " {...a11yProps(2)} />
        //         <Tab label="ShiftRule Details" {...a11yProps(3)} />
        //       </Tabs>
        //     </Box>
        //   </Box>
        //   <Box>
        //     <Divider sx={{ height: "100vh" }} orientation="vertical" />
        //   </Box>
        //   <Box
        //     sx={{
        //       width: "80%",
        //       height: "calc(100vh - 170px)",
        //       overflow: "scroll",
        //     }}
        //   >
        //     {value === 0 ? (
        //       <PersonalInfo AllSingEmpDetail={AllSingEmpDetail} />
        //     ) : value === 1 ? (
        //       <DepartmentDetail AllSingEmpDetail={AllSingEmpDetail} />
        //     ) : value === 2 ? (
        //       <SalaryDetail AllSingEmpDetail={AllSingEmpDetail} />
        //     ) : value === 3 ? (
        //       <ShiftRuleDetail AllSingEmpDetail={AllSingEmpDetail} />
        //     ) : null}
        //   </Box>
        // </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "10px",
            height: "calc(100vh - 170px)",
            overflow: "hidden",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "20%" } }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
                padding: "10px 0px 0px 10px",
              }}
            >
              {cookies.get("user")?.type === "Admin" ? (
                <Button
                  sx={{ width: "30px", height: "30px" }}
                  variant="outlined"
                  onClick={() => {
                    if (data) {
                      setShowDetails(false);
                    } else handlebackButScreen();
                  }}
                >
                  <ArrowBackIcon sx={{ color: "grey !important" }} />
                </Button>
              ) : null}
            </Box>

            {/* <Box sx={{ width: "100%", height: "170px", paddingTop: "20px", textAlign: "center" }}>
              <img
                style={{
                  width: "100%",
                  maxWidth: "140px",
                  height: "140px",
                  borderRadius: "100%",
                }}
                alt="EmployeImage"
                src={
                  AllSingEmpDetail?.Employee?.emp_image
                    ? AllSingEmpDetail?.Employee?.emp_image
                    : person
                }
              />
            </Box> */}
            <Box
              sx={{
                width: "100%",
                height: "170px",
                // height: {
                //   xs: "100px", // Shorter height on small screens
                //   sm: "170px",
                // },
                paddingTop: "15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  width: "100%",
                  maxWidth: "140px",
                  height: "140px",
                  borderRadius: "100%",
                }}
                alt="Employee Image"
                src={
                  AllSingEmpDetail?.Employee?.emp_image
                    ? AllSingEmpDetail?.Employee?.emp_image
                    : person
                }
              />
            </Box>

            <Typography
              sx={{
                fontWeight: "bold",
                // fontSize: "21px",
                fontSize: { xs: "18px", sm: "21px" },
                textAlign: "center",
              }}
            >
              {AllSingEmpDetail?.Employee?.name}
            </Typography>
            <Divider sx={{ margin: "10px" }} />
            <Box sx={{ width: "100%", textAlign: "center" }}>
              <Tabs
                orientation="vertical" // Adjusts tab orientation
                sx={{
                  paddingTop: "10px",

                }}

                value={value}
                onChange={handleChange}

              // variant="scrollable"
              >
                <Tab label="Personal Details" {...a11yProps(0)} />
                <Tab label="Department Details" {...a11yProps(1)} />
                <Tab label="Salary Details" {...a11yProps(2)} />
                <Tab label="ShiftRule Details" {...a11yProps(3)} />
              </Tabs>
            </Box>
          </Box>

          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Divider sx={{ height: "100vh" }} orientation="vertical" />
          </Box>

          <Box
            sx={{
              width: { xs: "100%", sm: "80%" },
              height: "calc(100vh - 170px)",
              overflow: "scroll",
            }}
          >
            {value === 0 ? (
              <PersonalInfo AllSingEmpDetail={AllSingEmpDetail} />
            ) : value === 1 ? (
              <DepartmentDetail AllSingEmpDetail={AllSingEmpDetail} />
            ) : value === 2 ? (
              <SalaryDetail AllSingEmpDetail={AllSingEmpDetail} />
            ) : value === 3 ? (
              <ShiftRuleDetail AllSingEmpDetail={AllSingEmpDetail} />
            ) : null}
          </Box>
        </Box>

      ) : canView === false ? (
        <Navigate to={"/dashboard"} />
      ) : (
        <Box>Loading ...</Box>
      )}
    </>
  );
}
FullWidthTabs.propTypes = {
  AllSingEmpDetail: PropTypes.any,
  data: PropTypes.any,
  setShowDetails: PropTypes.any,
};
