import {
  Grid,
  gridClasses,
  IconButton,
  Modal,
  Button,
  CircularProgress,
  TextField,
  Typography,
  // Chip,
  // Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  // LinearProgress,
  Paper,
  Divider,
} from "@mui/material";

import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// import moment from "moment/moment";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useNavigate } from "react-router-dom";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import theme from "../../theme";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";
import Loader from "../Loader/Loader";
import {
  FetchEmployeeTaskSummary,
  getemployeeDetails,
} from "../../Redux/Slice/Employee/EmplyeeSlice";
const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
    },
    Container: {
      width: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    Container2: {
      width: "100%",
      maxWidth: "900px",
      padding: "20px",
      maxHeight: "90vh",
      border: "none",
      position: "relative",
      background: "white",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    elipsText: {
      fontSize: "inherit",
      lineHeight: "inherit",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      WebkitLineClamp: 2,
      cursor: "pointer",
    },
    container: {
      width: "100%",
      padding: "0px 20px 20px 20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      textAlign: "left",
    },
  };
});

function EmployeePortalSummaryTable() {
  const { tabelStyle } = useContext(TabelStyleContext);
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const dispatch = useDispatch();
  const { header, ModalContainer, TableHeader, Container2, elipsText } = useStyles();
  const [rowvalue, setrowvalue] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [rows, setrows] = useState([]);
  const [filterRows, setfilterRows] = useState([]);
  const [pageSize, setPageSize] = useState(15);
  const { setsnackBarData } = useContext(SnackBarContext);
  const navigate = useNavigate();
  const employeeTaskSummary = useSelector(getemployeeDetails);
  const { status } = useSelector(getemployeeDetails);
  const { IsLogin } = useSelector(getemployeeDetails);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [editPermit, seteditpermit] = useState(false);
  const [addPermit, setaddpermit] = useState(false);
  // const [dateRange, setDateRange] = useState([
  //     moment(employeeTaskSummary?.EmployeeTaskSummary?.startof).format("YYYY-MM-DD"),
  //     moment(employeeTaskSummary?.EmployeeTaskSummary?.endof).format("YYYY-MM-DD"),
  // ]);
  useEffect(() => {
    let string = "TodayAttendance";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Edit") {
        seteditpermit(true);
      } else if (val?.module?.split("_")[1] === "Add") {
        setaddpermit(true);
      }
    });
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  console.log(editPermit);
  console.log(addPermit);

  useEffect(() => {
    dispatch(
      FetchEmployeeTaskSummary({
        startDate: null,
        endDate: null,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: employeeTaskSummary?.EmployeeTaskSummary?.message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport csvOptions={{ fileName: "Employee Portal Summary" }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }
  const columns = [
    { field: "sr", headerName: "Sr #", width: 100, headerClassName: header },

    {
      field: "date",
      headerName: "Date",
      width: 150,
      headerClassName: header,
    },
    // {
    //     field: "projectTitles",
    //     headerName: "Projects Involved",
    //     width: 300,
    //     headerClassName: header,
    //     renderCell: (params) => (
    //         <Stack direction="row" spacing={1} sx={{ flexWrap: "wrap" }}>
    //             {params.value?.map((title, index) => (
    //                 <Chip key={index} label={title} variant="contained" />
    //             ))}
    //         </Stack>
    //     ),
    // },
    {
      field: "logTime",
      headerName: "Log Hours",
      width: 150,
      headerClassName: header,
      renderCell: (params) => (
        <span style={{ color: params.value < 7.5 ? "red" : "inherit" }}>
          {params.value}
        </span>
      ),
    },

    {
      field: "tasksCount",
      headerName: "No. of Tasks",
      width: 250,
      headerClassName: header,
    },
    {
      field: "action",
      headerName: "Action",
      width: 160,
      headerClassName: header,
      renderCell: (cellVal) => {
        return (
          <>
            <Box>
              <IconButton
                onClick={() => {
                  setrowvalue(cellVal?.row);
                  setIsDialogOpen(true);
                }}
              >
                <VisibilityIcon />
              </IconButton>
            </Box>
          </>
        );
      },
    },
  ];
  useEffect(() => {
    let arr = [];
    if (employeeTaskSummary?.EmployeeTaskSummary) {
      employeeTaskSummary?.EmployeeTaskSummary?.summary?.forEach((item, idx) => {
        const uniqueTitles = new Set(item.tasks.map((task) => task.projectTitle));
        return arr.push({
          sr: idx + 1,
          id: idx + 1,
          date: item?.date,
          projectTitles: Array.from(uniqueTitles),
          projects: item.projects || "N/A",
          logTime: item?.totalLoggedHours,
          tasksCount: item?.tasks.length,
          tasks: item.tasks,
        });
      });
    }
    setrows(arr);

    setfilterRows(arr);
  }, [employeeTaskSummary?.EmployeeTaskSummary]);
  console.log("row data", rows);
  const ValidationSchema = Yup.object().shape({
    from: Yup.string().required("Please Enter Start Date"),
    to: Yup.string().required("Please Enter End Date"),
  });
  const formik = useFormik({
    initialValues: {
      from: employeeTaskSummary?.EmployeeTaskSummary?.startof
        ? moment(employeeTaskSummary.EmployeeTaskSummary.startof).format("YYYY-MM-DD")
        : "",
      to: employeeTaskSummary?.EmployeeTaskSummary?.endof
        ? moment(employeeTaskSummary.EmployeeTaskSummary.endof).format("YYYY-MM-DD")
        : "",
    },
    validationSchema: ValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(FetchEmployeeTaskSummary({ startDate: values.from, endDate: values.to }));
    },
  });

  useEffect(() => {
    if (employeeTaskSummary.status === "failed") {
      setsnackBarData({
        type: "error",
        message: employeeTaskSummary?.error,
        openToast: true,
      });
    }
  }, [employeeTaskSummary.status]);
  return (
    <>
      <Grid container spacing={2} sx={{ padding: "10px" }}>
        <span className="ml-2 float-right">
          <div className="loaderclass"> </div>
        </span>

        {status === "loading" ? <Loader /> : null}
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ display: "flex", mt: "20px", mb: "5px", ml: "8px" }}>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                paddingBottom: "10px",
                width: "100%",
                padding: "10px 10px 0px 10px",
                alignItems: "center",
              }}
            >
              <TextField
                type={"date"}
                name="from"
                size="small"
                label="From"
                InputLabelProps={{
                  shrink: true,
                }}
                value={formik.values.from}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.from) && Boolean(formik.touched.from)}
                helperText={Boolean(formik.errors.from) && formik.errors.from}
              />
              <TextField
                type={"date"}
                name="to"
                label="To"
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                value={formik.values.to}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.to) && Boolean(formik.touched.to)}
                helperText={Boolean(formik.errors.to) && formik.errors.to}
              />
              {status === "loading" ? (
                <CircularProgress />
              ) : (
                <Button type="submit" sx={{ padding: "5px 10px" }} variant="contained">
                  Get
                </Button>
              )}
            </Box>
          </Box>
        </form>

        <Grid item md={12} sm={12} xs={12} overflow sx={{ height: tabelStyle.height }}>
          <DataGrid
            rows={filterRows}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[15, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            pagination
            // components={{
            //   Toolbar: CustomToolbar,
            // }}
            components={isSmallScreen ? {} : { Toolbar: CustomToolbar }}
            localeText={{
              toolbarDensity: "Size",
              toolbarDensityLabel: "Size",
              toolbarDensityCompact: "Small",
              toolbarDensityStandard: "Medium",
              toolbarDensityComfortable: "Large",
            }}
          />
        </Grid>
      </Grid>
      {isDialogOpen && (
        <>
          <Modal
            open={isDialogOpen}
            onClose={() => {
              setIsDialogOpen(false);
            }}
          >
            <Box className={ModalContainer}>
              <Box
                className={Container2}
                sx={{
                  overflow: "scroll",
                }}
              >
                <Box className={TableHeader}>
                  <Typography
                    variant="h1"
                    sx={{
                      fontSize: "20px !important",
                      color: (theme) => theme.palette.primary.main,
                    }}
                  >
                    {rowvalue.date} Task Details
                  </Typography>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsDialogOpen(false);
                    }}
                  >
                    <HighlightOffIcon
                      color="primary"
                      sx={{
                        fontSize: "28px",
                      }}
                    />
                  </IconButton>
                </Box>

                <Box
                  sx={{
                    maxHeight: "60vh",
                    overflowY: "auto",
                  }}
                >
                  {rowvalue?.tasks?.map((row, i) => (
                    <Box key={i} sx={{ marginTop: 2 }}>
                      <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                        <Grid item xs={12} md={5} pr={7}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Work Subject
                          </Typography>
                          <Tooltip
                            title={row?.workPackageTitle || "N/A"}
                            placement="top"
                            arrow
                          >
                            <Typography
                              sx={{
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                WebkitLineClamp: 1,
                                cursor: "pointer",
                              }}
                            >
                              {row?.workPackageTitle || "N/A"}
                            </Typography>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Project Name
                          </Typography>
                          <Typography>{row?.projectTitle || "N/A"}</Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Total Time spent
                          </Typography>
                          <Typography>{row?.totalHoursSpent || "N/A"}</Typography>
                        </Grid>
                      </Grid>
                      <Typography
                        variant="h7"
                        sx={{ fontWeight: "bold", marginBottom: 2 }}
                      >
                        Time Entries
                      </Typography>

                      <TableContainer component={Paper}>
                        <Table
                          stickyHeader
                          sx={{
                            minWidth: { md: "auto", xs: "auto" },
                            overflow: "scroll",
                          }}
                          aria-label="time entries table"
                          size="small"
                        >
                          <TableHead>
                            <TableRow size="small">
                              <TableCell
                                size="small"
                                sx={{ fontWeight: "bold", minWidth: 50 }}
                              >
                                #
                              </TableCell>
                              <TableCell
                                size="small"
                                sx={{ fontWeight: "bold", minWidth: 150 }}
                              >
                                Activity Title
                              </TableCell>
                              <TableCell
                                size="small"
                                sx={{ fontWeight: "bold", minWidth: 150 }}
                              >
                                Comments
                              </TableCell>
                              <TableCell
                                size="small"
                                sx={{ fontWeight: "bold", minWidth: 150 }}
                              >
                                Time Spent
                              </TableCell>{" "}
                              <TableCell
                                size="small"
                                sx={{ fontWeight: "bold", minWidth: 150 }}
                              >
                                Date
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {row.timeEntries.length > 0 ? (
                              row.timeEntries.map((entry, i) => (
                                <TableRow
                                  key={entry.id}
                                  sx={{
                                    "&:last-child td, &:last-child th": { border: 0 },
                                  }}
                                >
                                  <TableCell size="small" component="th" scope="row">
                                    {i + 1}
                                  </TableCell>
                                  <TableCell size="small" component="th" scope="row">
                                    <Tooltip
                                      title={entry?.activityTitle || "N/A"}
                                      placement="top"
                                      arrow
                                    >
                                      <Typography>
                                        {entry?.activityTitle || "N/A"}
                                      </Typography>
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell size="small" component="th" scope="row">
                                    <Tooltip
                                      title={entry?.comments || "N/A"}
                                      placement="top"
                                      arrow
                                    >
                                      <Typography className={elipsText}>
                                        {entry?.comments || "N/A"}
                                      </Typography>
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell size="small" component="th" scope="row">
                                    {entry.hours || "N/A"}
                                  </TableCell>

                                  <TableCell size="small" component="th" scope="row">
                                    {entry.spentOn || "N/A"}
                                  </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={4} align="center">
                                  No time entries available
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Divider
                        sx={{
                          marginBottom: 2,
                          marginTop: 4,
                          backgroundColor: (theme) => theme.palette.primary.main,
                          height: "2px",
                        }}
                      />
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}

export default EmployeePortalSummaryTable;
// EmployeePortalSummaryTable.propTypes = {
//     setShowForm: PropTypes.any,
//     seteditedRow: PropTypes.any,
//     setisEdited: PropTypes.any,
// };
