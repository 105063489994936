import PropTypes from "prop-types";
import {
  Box,
  Button,
  CircularProgress,
  // CircularProgress,
  Grid,
  gridClasses,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchReportsByMonths,
  FetchReportsShiftRuleByMonths,
  getmonthlyreportDetails,
} from "../../Redux/Slice/MonthlyReport/MonthlyReportSlice";
import { makeStyles } from "@mui/styles";
import { useRef } from "react";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { PostMonthlyReport } from "../../Api/MonthlyReport/PostMonthlyReport";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";
import Loader from "../Loader/Loader";
import Loader2 from "../Loader/Loader2";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import axiosInstance from "../../Utils/AxiosInstance";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
    },
    Container: {
      width: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    Container2: {
      width: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    TableContain: { height: "450px", overflowY: "scroll" },
  };
});

function MonthlyReportDetailTable({ setShowForm, setrowvalue }) {
  const { tabelStyle } = useContext(TabelStyleContext);
  // const dispatch = useDispatch();
  const { header, TableHeader, Container, ModalContainer, TableContain, Container2 } =
    useStyles();
  const [rows, setrows] = useState([]);
  const [filterRows, setfilterRows] = useState([]);
  const [allShiftRule, setallShiftRule] = useState([]);
  const [pageSize, setPageSize] = useState(15);
  const [loading, setloading] = useState(false);
  const [loading2, setloading2] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [showModal2, setshowModal2] = useState(false);
  const [Edit, setEdit] = useState(false);
  const [Save, setSave] = useState(false);
  const [Idx, setIdx] = useState(null);
  const [selectedEmp, setselectedEmp] = useState(null);
  const [selectedEmpList, setselectedEmpList] = useState([]);
  const [alterDates, setalterDates] = useState([]);
  const [loadingMessage, setloadingMessage] = useState("Fetching Absents..");
  const loaderMessage = [
    "Fetching Absents..",
    "Fetching Leaves..",
    "Fetching Portal Data..",
    "Calculating Hours...",
  ];
  const [selectedDate, setselectedDate] = useState(null);
  const navigate = useNavigate();
  const ByDate = useRef();
  const dispatch = useDispatch();
  const { setsnackBarData } = useContext(SnackBarContext);
  const {
    status,
    IsLogin,
    message,
    ReportByMonths,
    ReportShiftRuleByMonths,
    message2,
    status2,
  } = useSelector(getmonthlyreportDetails);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [addPermit, setaddpermit] = useState(false);
  console.log(allShiftRule);
  console.log(alterDates);
  console.log(selectedEmpList);
  console.log(selectedEmp);
  console.log(ReportShiftRuleByMonths);
  useEffect(() => {
    let string = "MonthlyReport";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Add") {
        setaddpermit(true);
      }
    });
    if (!roles || roles.length < 1) {
      setaddpermit(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  console.log(ReportByMonths);

  useEffect(() => {
    dispatch(FetchReportsByMonths());
  }, [dispatch]);

  console.log(addPermit);
  useEffect(() => {
    let arr = [];
    if (ReportByMonths) {
      ReportByMonths?.forEach((item, idx) => {
        return arr.push({
          id: idx + 1,
          month: item.month ? moment(item.month).format("MMMM-YYYY") : "",
          date: item.date ? moment(item?.date).format("DD-MMM-YYYY") : "",
        });
      });
    }
    setrows(arr);
    setfilterRows(arr);
  }, [ReportByMonths]);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport csvOptions={{ fileName: "Monthly Report" }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }
  const columns = [
    { field: "id", headerName: "Sr #", width: 100, headerClassName: header },
    {
      field: "month",
      headerName: "Month",
      width: 160,
      // type: "date",
      headerClassName: header,
    },
    {
      field: "date",
      headerName: "Created On",
      width: 160,
      headerClassName: header,
    },
    {
      field: "action",
      headerName: "Action",
      width: 160,
      headerClassName: header,
      renderCell: (cellVal) => {
        return (
          <>
            <Box>
              <IconButton
                onClick={() => {
                  console.log(cellVal?.row);
                  setrowvalue(cellVal?.row);
                  setShowForm(true);
                }}
              >
                <VisibilityIcon />
              </IconButton>
            </Box>
          </>
        );
      },
    },
  ];

  const onTextChange = () => {
    if (ByDate.current.value === "") {
      setfilterRows(rows);
    } else {
      let suggestions = [];
      let regex2 = new RegExp(moment(ByDate.current.value).format("MMMM-YYYY"), "i");
      suggestions = rows?.filter(
        (val) => ByDate.current.value === "" || regex2.test(val?.month)
      );
      setfilterRows(suggestions);
    }
  };
  console.log(alterDates);
  const handleSave = (data) => {
    console.log(data);
    let arr2 = [];
    data?.offday_id?.map((date) => {
      arr2.push(date?.alternate_offdate);
    });
    let found = false;
    console.log(found);
    let updatedData = alterDates?.map((item) => {
      console.log(item);
      console.log(item?.offday_id[0]);
      console.log(moment(item?.offday_id[0]?.month).format("YYYY-MM"));
      if (
        item?.rule_id == data?.rule_id &&
        moment(item?.offday_id[0]).format("YYYY-MM") ==
        moment(data?.offday_id[0]?.month).format("YYYY-MM")
      ) {
        found = true;
        return { ...item, rule_id: data?.rule_id, offday_id: arr2 };
      } else {
        return item;
      }
    });
    console.log(alterDates);

    if (!found) {
      const newStateArray = [
        ...alterDates,
        {
          ...data,
          rule_id: data?.rule_id,
          offday_id: arr2,
        },
      ];
      setalterDates(newStateArray);
    } else {
      setalterDates(updatedData);
    }
    setsnackBarData({
      openToast: true,
      message: "Off Days Date Change",
      type: "success",
    });
    // console.log(newStateArray);
  };
  const hanleDateChange = (e, ind, offday) => {
    // const newStateArray = [...alterDates, e.target.value];
    // setalterDates(newStateArray);
    let newArr = [];
    allShiftRule?.map((item, i) => {
      if (i == Idx) {
        let arr = [];
        item?.offday_id?.map((ofday, index) => {
          if (index == ind) {
            arr.push({
              ...ofday,
              alternate_offdate: e.target.value,
            });
            console.log(offday);
          } else {
            arr.push(ofday);
          }
        });
        newArr.push({ ...item, offday_id: arr });
      } else {
        newArr.push(item);
      }
    });
    setallShiftRule(newArr);
    console.log(newArr);
  };
  console.log(PostMonthlyReport);
  console.log(message2);
  console.log(status2);
  console.log(FetchReportsShiftRuleByMonths);
  const generateShiftRule = async () => {
    // setloading(true);
    if (!selectedDate) {
      // setloading(false);
      return setsnackBarData({
        openToast: true,
        message: "please select date",
        type: "error",
      });
    }
    setloading2(true);

    try {
      let response = await axiosInstance.get(
        `getshiftalternatedays?month=${selectedDate}`
      );

      console.log(response);
      if (response.data.IsSuccess == true) {
        setshowModal(true);
        setloading2(false);
        setallShiftRule(response.data.Data);
        // dispatch(FetchReportsByMonths());
      } else {
        setloading2(false);
      }
    } catch (error) {
      setloading2(false);

      setsnackBarData({
        type: "error",
        message: error?.response.data.Message,
        openToast: true,
      });
      // setloading(false);
    }
  };
  console.log(allShiftRule);
  const handleSubmit = async () => {
    setloading(true);
    setshowModal(false);
    setshowModal2(false);
    // if (!selectedDate) {
    //   setloading(false);
    //   return setsnackBarData({
    //     openToast: true,
    //     message: "please select date",
    //     type: "error",
    //   });
    // }
    // dispatch(FetchReportsShiftRuleByMonths(selectedDate));
    // setshowModal(true);
    let arr = [];
    allShiftRule?.map((item) => {
      let arr2 = [];
      item?.offday_id?.map((date) => {
        if (date?.alternate_offdate != "") {
          arr2.push(date?.alternate_offdate);
        }
      });
      arr.push({
        rule_id: item?.rule_id,
        month: item?.offday_id[0]?.month,
        alternate_offdate: arr2,
      });
    });
    console.log(arr);
    const response = await PostMonthlyReport({
      month: selectedDate,
      offdays: arr,
    });

    setsnackBarData(response?.snackBarData);
    if (response.data.IsSuccess) {
      setshowModal(false);
      setshowModal2(false);
      setloading(false);
      setselectedEmpList([]);
      setalterDates([]);
      dispatch(FetchReportsByMonths());
    } else {
      setloading(false);
    }
    setloading(false);
    console.log(response);
  };
  useEffect(() => {
    // if (loading) {
    //   setTimeout(() => {
    //     loaderMessage?.map((item) => {
    //       return setloadingMessage(item);
    //     });
    //   }, 2000);
    // }

    // const loopArrayWithTimeout = () => {
    //   // Use setTimeout to set a delay between iterations
    //   const timeoutId = setTimeout(() => {
    //     // Update the current index
    //     setloadingMessage(
    //       (prevIndex) => (prevIndex + 1) % loaderMessage.length
    //     );
    //   }, 1000); // Set your desired delay in milliseconds (e.g., 1000ms for 1 second)

    //   // Clean up the setTimeout when the component unmounts
    //   return () => clearTimeout(timeoutId);
    // };

    // Start the loop when the component mounts
    // loopArrayWithTimeout();
    //   setTimeout(() => {
    //     setloadingMessage("Fetching Absents..");
    //   }, 2000);
    //   setTimeout(() => {
    //     setloadingMessage("Fetching Leaves..");
    //   }, 4000);
    //   setTimeout(() => {
    //     setloadingMessage("Fetching Portal Data..");
    //   }, 6000);
    // } else {
    //   setloadingMessage("Calculating Hours...");
    // }
    const intervalId = setInterval(() => {
      // Check if otherState is false, if so, stop changing currentState
      if (!loading) {
        clearInterval(intervalId);
        return;
      }
      console.log();

      // Change currentState every 4 seconds
      setloadingMessage((prevIndex) => {
        console.log(prevIndex);
        let index = loaderMessage.findIndex((data) => data === prevIndex);

        console.log(index);
        console.log((prevIndex + 1) % loaderMessage.length);
        return loaderMessage[(index + 1) % loaderMessage.length];
      });
    }, 2000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [loading]);
  useEffect(() => {
    if (status === "failed") {
      setsnackBarData({
        type: "error",
        message: "Network Error",
        openToast: true,
      });
    }
  }, [status]);
  // const currentDate = new Date();
  const currentYear =
    selectedEmp && moment(selectedEmp?.offday_id[0]?.month)?._d.getFullYear();
  const currentMonth =
    selectedEmp && moment(selectedEmp?.offday_id[0]?.month)?._d.getMonth() + 1; // January is 0, so we add 1

  // Calculate the first and last day of the month
  const firstDayOfMonth = new Date(currentYear, currentMonth - 1, 1);
  const lastDayOfMonth = new Date(currentYear, currentMonth, 0);
  console.log(moment(firstDayOfMonth).format("DD-MMM-YYYY"));
  console.log(lastDayOfMonth);
  console.log(moment(selectedEmp)?._d);
  return (
    <>
      {loading ? (
        <Loader2
          showloadingModal={loading}
          loadingMessage={loadingMessage}
          setshowloadingModal={setloading}
          setloadingMessage={setloadingMessage}
        />
      ) : null}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          padding: "20px 10px 10px 10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "10px",
            width: "100%",
          }}
        >
          {addPermit ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                width: "100%",
              }}
            >
              <TextField
                size="small"
                type="month"
                variant="outlined"
                color="primary"
                fullWidth
                sx={{
                  input: { color: "black" },
                  maxWidth: "250px",
                  textAlign: "left",
                }}
                // value={currenttime}
                inputProps={{
                  max: moment().format("YYYY-MM"),
                }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setselectedDate(e.target.value);
                }}
              />
              {loading2 ? (
                <CircularProgress />
              ) : (
                <Button
                  sx={{ padding: "5px 10px" }}
                  onClick={() => {
                    generateShiftRule();
                    // handleSubmit();
                  }}
                  variant="contained"
                  className="tabletopbutton1 text-right"
                >
                  Generate
                </Button>
              )}
            </Box>
          ) : null}
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "end",
            width: "100%",
          }}
        >
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: (theme) => theme.palette.grey.G2 }} />
                </InputAdornment>
              ),
            }}
            variant="standard"
            type="month"
            autoComplete="off"
            placeholder="Search"
            // className="input_border"
            inputRef={ByDate}
            id="date"
            onChange={onTextChange}
          />
        </Box>
      </Box>

      <span className="ml-2 float-right">
        <div className="loaderclass"> </div>
      </span>

      {ReportByMonths?.length <= 0 && status === "loading" ? (
        <>
          <Loader />
        </>
      ) : null}
      <Grid
        item
        md={12}
        sm={12}
        xs={12}
        overflow
        sx={{ height: tabelStyle.height, padding: "10px" }}
      >
        <DataGrid
          rows={filterRows}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[15, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          pagination
          components={{
            Toolbar: CustomToolbar,
          }}
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />
      </Grid>
      {showModal && (
        <>
          <Modal
            open={showModal}
            onClose={() => {
              setshowModal(false);
              setselectedEmpList([]);
              setalterDates([]);
            }}
          >
            <Box className={ModalContainer}>
              <Box className={Container}>
                <Box className={TableHeader}>
                  <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                    Shift Rule Details
                  </Typography>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setshowModal(false);
                    }}
                  >
                    <HighlightOffIcon
                      color="primary"
                      sx={{
                        fontSize: "28px",
                      }}
                    />
                  </IconButton>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    sx={{ padding: "5px 10px" }}
                    onClick={() => {
                      // setshowModal2(true);

                      handleSubmit();
                    }}
                    variant="contained"
                    className="tabletopbutton1 text-right"
                  >
                    Submit
                  </Button>
                </Box>
                <TableContainer component={Paper} className={TableContain}>
                  <Table
                    stickyHeader
                    sx={{
                      minWidth: { md: "auto", xs: "auto" },
                      overflow: "scroll",
                    }}
                    aria-label="simple table"
                    size="small"
                  >
                    <TableHead>
                      <TableRow size="small">
                        <TableCell size="small" sx={{ fontWeight: "bold" }}>
                          #
                        </TableCell>
                        <TableCell size="small" sx={{ fontWeight: "bold" }}>
                          Shift Name
                        </TableCell>
                        <TableCell size="small" sx={{ fontWeight: "bold" }}>
                          Off Days Month
                        </TableCell>
                        <TableCell size="small" sx={{ fontWeight: "bold" }}>
                          Off Days Date
                        </TableCell>
                        <TableCell size="small" sx={{ fontWeight: "bold" }}>
                          Off Days Date
                        </TableCell>
                        <TableCell size="small" sx={{ fontWeight: "bold" }}>
                          Off Days Date
                        </TableCell>
                        <TableCell size="small" sx={{ fontWeight: "bold" }}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {allShiftRule &&
                        allShiftRule?.map((row, i) => {
                          return (
                            <TableRow
                              key={i}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell size="small" component="th" scope="row">
                                {i + 1}
                              </TableCell>
                              <TableCell size="small" component="th" scope="row">
                                {row?.shift_name}
                              </TableCell>
                              <TableCell size="small">
                                {moment(row?.offday_id[0]?.month).format("MMMM")}
                              </TableCell>

                              {row?.offday_id?.length == 2 ? (
                                <>
                                  {row?.offday_id?.map((offday, ind) => {
                                    return (
                                      <>
                                        {Edit && i == Idx ? (
                                          <TableCell
                                            key={offday?.alternate_offdate}
                                            size="small"
                                          >
                                            <TextField
                                              variant="standard"
                                              type="date"
                                              value={offday?.alternate_offdate}
                                              inputProps={{
                                                min: moment(firstDayOfMonth).format(
                                                  "YYYY-MM-DD"
                                                ), // Set minimum selectable date to the first day of the month
                                                max: moment(lastDayOfMonth).format(
                                                  "YYYY-MM-DD"
                                                ), // Set maximum selectable date to the last day of the month
                                              }}
                                              id="date"
                                              onChange={(e) => {
                                                console.log(e.target.value);
                                                hanleDateChange(e, ind, offday);
                                              }}
                                            />
                                          </TableCell>
                                        ) : (
                                          <TableCell
                                            key={offday?.alternate_offdate}
                                            size="small"
                                          >
                                            {offday?.alternate_offdate}
                                          </TableCell>
                                        )}
                                      </>
                                    );
                                  })}
                                  {Edit && i == Idx ? (
                                    <TableCell size="small">
                                      {/* <TextField
                                        variant="standard"
                                        type="date"
                                        // inputRef={ByDate}
                                        id="date"
                                        onChange={hanleDateChange}
                                      /> */}
                                    </TableCell>
                                  ) : (
                                    <TableCell size="small"></TableCell>
                                  )}
                                </>
                              ) : row?.offday_id?.length >= 3 ? (
                                <>
                                  {row?.offday_id?.map((offday, ind) => {
                                    console.log(row?.offday_id?.length);
                                    return (
                                      <>
                                        {Edit && i == Idx ? (
                                          <TableCell
                                            key={offday?.alternate_offdate}
                                            size="small"
                                          >
                                            <TextField
                                              variant="standard"
                                              type="date"
                                              value={offday?.alternate_offdate}
                                              inputProps={{
                                                min: moment(firstDayOfMonth).format(
                                                  "YYYY-MM-DD"
                                                ), // Set minimum selectable date to the first day of the month
                                                max: moment(lastDayOfMonth).format(
                                                  "YYYY-MM-DD"
                                                ), // Set maximum selectable date to the last day of the month
                                              }}
                                              id="date"
                                              onChange={(e) => {
                                                console.log(e.target.value);
                                                hanleDateChange(e, ind, offday);
                                              }}
                                            />
                                          </TableCell>
                                        ) : (
                                          <TableCell
                                            key={offday?.alternate_offdate}
                                            size="small"
                                          >
                                            {offday?.alternate_offdate}
                                          </TableCell>
                                        )}
                                      </>
                                    );
                                  })}
                                  {/* {Edit && i == Idx ? (
                                    <TableCell size="small">
                                
                                    </TableCell>
                                  ) : (
                                    <TableCell size="small"></TableCell>
                                  )} */}
                                </>
                              ) : (
                                <>
                                  <TableCell size="small"></TableCell>
                                  <TableCell size="small"></TableCell>
                                  <TableCell size="small"></TableCell>
                                </>
                              )}

                              <TableCell size="small">
                                {Save && Idx == i ? (
                                  <Button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleSave(row);
                                      setEdit(false);
                                      setSave(false);
                                    }}
                                  >
                                    Save
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      console.log(row?.rule_id);
                                      let found2 = false;
                                      let updatedData = selectedEmpList?.map((item) => {
                                        console.log(item?.rule_id);
                                        if (
                                          item?.rule_id == row?.rule_id &&
                                          row?.offday_id[0]?.month ==
                                          item?.offday_id[0]?.month
                                        ) {
                                          found2 = true;
                                          return { ...item };
                                        } else {
                                          return item;
                                        }
                                      });

                                      if (!found2) {
                                        const newStateArray = [...selectedEmpList, row];
                                        setselectedEmpList(newStateArray);
                                      } else {
                                        setselectedEmpList(updatedData);
                                      }
                                      setselectedEmp(row);
                                      setEdit(true);
                                      setSave(true);
                                      setIdx(i);
                                    }}
                                  >
                                    Edit
                                  </Button>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Modal>
        </>
      )}
      {showModal2 && (
        <>
          <Modal
            open={showModal2}
            onClose={() => {
              // setalterDates([]);
              setshowModal2(false);
            }}
          >
            <Box className={ModalContainer}>
              <Box className={Container2}>
                <Box className={TableHeader}>
                  <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                    Off Days Change Details
                  </Typography>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      // setalterDates([]);

                      setshowModal2(false);
                    }}
                  >
                    <HighlightOffIcon
                      color="primary"
                      sx={{
                        fontSize: "28px",
                      }}
                    />
                  </IconButton>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    sx={{ padding: "5px 10px" }}
                    onClick={() => {
                      handleSubmit();
                    }}
                    variant="contained"
                    className="tabletopbutton1 text-right"
                  >
                    Continue
                  </Button>
                </Box>
                <TableContainer component={Paper} className={TableContain}>
                  <Table
                    stickyHeader
                    sx={{
                      minWidth: { md: "auto", xs: "auto" },
                      overflow: "scroll",
                    }}
                    aria-label="simple table"
                    size="small"
                  >
                    <TableHead>
                      <TableRow size="small">
                        <TableCell size="small" sx={{ fontWeight: "bold" }}>
                          #
                        </TableCell>
                        <TableCell size="small" sx={{ fontWeight: "bold" }}>
                          Shift Name
                        </TableCell>
                        <TableCell size="small" sx={{ fontWeight: "bold" }}>
                          Off Days Month
                        </TableCell>
                        <TableCell size="small" sx={{ fontWeight: "bold" }}>
                          Off Days Date
                        </TableCell>
                        <TableCell size="small" sx={{ fontWeight: "bold" }}>
                          Off Days Date
                        </TableCell>
                        <TableCell size="small" sx={{ fontWeight: "bold" }}>
                          Off Days Date
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {alterDates &&
                        selectedEmpList &&
                        alterDates?.map((row, i) => {
                          let abc = selectedEmpList?.find(
                            (item2) => item2?.rule_id == row?.rule_id
                          );
                          console.log(row);

                          console.log(abc);
                          return (
                            <TableRow
                              key={i}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell size="small" component="th" scope="row">
                                {i + 1}
                              </TableCell>
                              <TableCell size="small" component="th" scope="row">
                                {row?.shift_name}
                              </TableCell>
                              <TableCell size="small">
                                {moment(selectedDate).format("MMMM")}
                              </TableCell>
                              {row?.offday_id?.length == 2 ? (
                                <>
                                  {row?.offday_id?.map((offday, ik) => {
                                    return (
                                      <>
                                        <TableCell
                                          key={ik}
                                          // key={offday?.alternate_offdate}
                                          size="small"
                                        >
                                          {abc?.offday_id[ik]?.alternate_offdate !=
                                            offday ? (
                                            <>
                                              {abc?.offday_id[ik]?.alternate_offdate
                                                ? moment(
                                                  abc?.offday_id[ik]?.alternate_offdate
                                                ).format("DD-MMM-YYYY")
                                                : null}{" "}
                                              <Typography sx={{ fontWeight: "bold" }}>
                                                {"To"}
                                              </Typography>{" "}
                                              {offday
                                                ? moment(offday).format("DD-MMM-YYYY")
                                                : null}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </TableCell>
                                      </>
                                    );
                                  })}
                                  <TableCell size="small"></TableCell>
                                </>
                              ) : row?.offday_id?.length >= 3 ? (
                                <>
                                  {row?.offday_id?.map((offday, ik) => {
                                    return (
                                      <>
                                        <TableCell
                                          key={ik}
                                          // key={offday?.alternate_offdate}
                                          size="small"
                                        >
                                          {abc?.offday_id[ik]?.alternate_offdate !=
                                            offday ? (
                                            <>
                                              {abc?.offday_id[ik]?.alternate_offdate
                                                ? moment(
                                                  abc?.offday_id[ik]?.alternate_offdate
                                                ).format("DD-MMM-YYYY")
                                                : null}{" "}
                                              <Typography sx={{ fontWeight: "bold" }}>
                                                {"To"}
                                              </Typography>{" "}
                                              {offday
                                                ? moment(offday).format("DD-MMM-YYYY")
                                                : null}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </TableCell>
                                      </>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  <TableCell size="small"></TableCell>
                                  <TableCell size="small"></TableCell>
                                  <TableCell size="small"></TableCell>
                                </>
                              )}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}

export default MonthlyReportDetailTable;
MonthlyReportDetailTable.propTypes = {
  setShowForm: PropTypes.any,
  setrowvalue: PropTypes.any,
};
