import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import theme from "../../theme";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  ChangeinRequestPopulate,
  ChangeRequestAdded,
  fetchChangeRequest,
  getChangerequestDetails,
} from "../../Redux/Slice/ChangeRequest/ChangeRequestSlice";
import { useState } from "react";
import { ChangeinReqApi } from "../../Api/ChangeinReqApi/ChangeinRequestApi";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { Autocomplete, CircularProgress, Typography } from "@mui/material";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
// import Cookies from "universal-cookie";
const useStyles = makeStyles(() => {
  return {
    inputBox: {
      width: "100%",
    },
    btns: {
      width: "80px",
      color: "#ffff",
    },
    input_typography: {
      textAlign: "left",
      fontSize: `15px !important`,

      marginTop: "10px !important",
    },
    Form_input: {
      borderRadius: theme.borderRadius.All,
      backgroundColor: `${theme.palette.common.white} !important`,
    },
    FieldInRow: {
      display: "flex",
      justifyContent: "space-between",
      gap: "25px",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        gap: "10px",
      },
    },
  };
});

const ChangeinForm = ({ setShowForm }) => {
  const { setsnackBarData } = useContext(SnackBarContext);

  const { btns, inputBox, FieldInRow, input_typography, Form_input } =
    useStyles();
  const dispatch = useDispatch();
  const { Projects, IsLogin, message } = useSelector(getChangerequestDetails);
  const [allProjects, setAllProjects] = useState([]);
  const navigate = useNavigate();
  const [module, setModule] = useState([]);
  const [projectSubModule, setProjectSubModule] = useState([]);
  console.log(allProjects);
  useEffect(() => {
    dispatch(ChangeinRequestPopulate());
  }, [dispatch]);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
    setAllProjects(Projects);
  }, [Projects, IsLogin]);

  const handleChangeReqAdd = async (values, formikHelpers) => {
    const response = await ChangeinReqApi({
      ...values,
      project: values?.project?.project_id,
    });

    setsnackBarData(response.snackBarData);

    if (response.data.IsSuccess) {
      dispatch(ChangeRequestAdded(response.data.chngreq));
      dispatch(fetchChangeRequest({ page: 1, pageSize: 10 }));
      // cookies.remove("user");
      // cookies.remove("access_token");
      setShowForm(false);
      formikHelpers.resetForm();
    }

    formikHelpers.setSubmitting(false);
  };

  const ChangeReqForm = Yup.object({
    description: Yup.string().required("Required"),
    priority: Yup.string().required("Required"),
    google_docs: Yup.string().required("Required"),
    project: Yup.object().required("Required"),
    module: Yup.string().required("Required"),
    subject: Yup.string().required("Required"),
    // submodule: Yup.string().required("Required"),
  });
  console.log(allProjects);
  return (
    <Box
      sx={{
        minHeight: "calc(100vh - 173px)",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        padding: "20px",
      }}
    >
      <Formik
        initialValues={{
          description: "",
          priority: "",
          google_docs: "",
          project: null,
          module: "",
          submodule: null,
          subject: "",
        }}
        onSubmit={(values, formikHelpers) => {
          handleChangeReqAdd(values, formikHelpers);
        }}
        validationSchema={ChangeReqForm}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box>
              <Box
                sx={{
                  maxWidth: "900px",
                  width: "100%",
                  display: "flex",
                  gap: "20px",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <Box className={FieldInRow}>
                  <Box className={inputBox}>
                    <Typography className={input_typography}>
                      Subject
                    </Typography>
                    <TextField
                      sx={{ textAlign: "left", input: { color: "black" } }}
                      className={Form_input}
                      fullWidth
                      size="small"
                      name="subject"
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.subject}
                      variant="outlined"
                      error={
                        Boolean(touched.subject) && Boolean(errors.subject)
                      }
                      helperText={Boolean(touched.subject) && errors.subject}
                    />
                  </Box>

                  <Box className={inputBox}>
                    <Typography className={input_typography}>
                      Priority Cell
                    </Typography>
                    <TextField
                      sx={{ textAlign: "left" }}
                      name="priority"
                      fullWidth
                      size="small"
                      select
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.priority}
                      error={
                        Boolean(touched.priority) && Boolean(errors.priority)
                      }
                      helperText={Boolean(touched.priority) && errors.priority}
                    >
                      <MenuItem value="Medium">Medium</MenuItem>
                      <MenuItem value="Critical">Critical</MenuItem>
                      <MenuItem value="High">High</MenuItem>
                      <MenuItem value="Low">Low</MenuItem>
                    </TextField>
                  </Box>
                </Box>
                <Box className={FieldInRow}>
                  <Box className={inputBox}>
                    <Typography className={input_typography}>
                      Google Docs
                    </Typography>
                    <TextField
                      sx={{ textAlign: "left" }}
                      fullWidth
                      size="small"
                      id="standard-basic"
                      name="google_docs"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.google_docs}
                      error={
                        Boolean(touched.google_docs) &&
                        Boolean(errors.google_docs)
                      }
                      helperText={
                        Boolean(touched.google_docs) && errors.google_docs
                      }
                    />
                  </Box>
                  <Box className={inputBox}>
                    <Typography className={input_typography}>
                      Project Name
                    </Typography>
                    {/* <TextField
                      sx={{ textAlign: "left" }}
                      fullWidth
                      size="small"
                      id="standard-basic"
                      name="project"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        console.log( e.target.value);
                        const filterModule = allProjects?.find((projctss) => {
                          return projctss.project_id === e.target.value;
                        });
                        setModule(filterModule.module);
                        setFieldValue("project", e.target.value);
                      }}
                      value={values.project}
                      select
                      error={
                        Boolean(touched.project) && Boolean(errors.project)
                      }
                      helperText={Boolean(touched.project) && errors.project}
                    >
                      <MenuItem value="">Select</MenuItem>
                      {allProjects?.map((projct) => (
                        <MenuItem
                          key={projct?.project_id}
                          value={projct?.project_id}
                        >
                          {projct?.project_name}
                        </MenuItem>
                      ))}
                    </TextField> */}
                    <Autocomplete
                      disablePortal
                      name={'project'}
                      options={allProjects ? allProjects : []}
                      getOptionLabel={(option) => option.project_name}
                      sx={{
                        input: { color: "black" },
                        textAlign: "left",
                      }}
                      className={Form_input}
                      fullWidth
                      value={values?.project}
                      size="small"
                      onChange={(e, val) => {
                        console.log(e, val);
                        console.log(e.target.value);
                        const filterModule = allProjects?.find((projctss) => {

                          return projctss.project_id === val?.project_id;
                        });
                        console.log(filterModule)
                        setModule(filterModule?.module);
                        setFieldValue("project", val);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="project"
                          error={
                            Boolean(errors.project) && Boolean(touched.project)
                          }
                          helperText={
                            Boolean(touched.project) && errors.project
                          }
                        />
                      )}
                    />
                  </Box>
                </Box>
                <Box className={FieldInRow}>
                  <Box className={inputBox}>
                    <Typography className={input_typography}>Module</Typography>
                    <TextField
                      sx={{ textAlign: "left" }}
                      fullWidth
                      size="small"
                      id="standard-basic"
                      name="module"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={(evt) => {
                        const SubModuleFilter = module?.find((mode) => {
                          return mode.module_id === evt.target.value;
                        });

                        setProjectSubModule(SubModuleFilter.submodules);
                        setFieldValue("module", evt.target.value);
                      }}
                      value={values.module}
                      select
                      error={Boolean(touched.module) && Boolean(errors.module)}
                      helperText={Boolean(touched.module) && errors.module}
                    >
                      <MenuItem value="">Select</MenuItem>
                      {module?.map((mod) => (
                        <MenuItem key={mod?.module_id} value={mod?.module_id}>
                          {mod?.module_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                  <Box className={inputBox}>
                    <Typography className={input_typography}>
                      Sub Module
                    </Typography>
                    <TextField
                      sx={{ textAlign: "left" }}
                      fullWidth
                      size="small"
                      id="standard-basic"
                      name="submodule"
                      variant="outlined"
                      onBlur={handleBlur}
                      select
                      value={values.submodule}
                      onChange={handleChange}
                    // error={
                    //   Boolean(touched.submodule) && Boolean(errors.submodule)
                    // }
                    // helperText={
                    //   Boolean(touched.submodule) && errors.submodule
                    // }
                    >
                      <MenuItem value={null}>Select</MenuItem>
                      {/* <MenuItem value="SelectA">SelectA</MenuItem>
                    <MenuItem value="SelectB">SelectB</MenuItem> */}

                      {projectSubModule?.map((submod) => (
                        <MenuItem
                          key={submod?.submodule_id}
                          value={submod?.submodule_id}
                        >
                          {submod?.submodule_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                </Box>
                <Box
                  //           sx={{width:"100%",maxWidth:"48.5%",      [theme.breakpoints.down("md")]: {
                  //   maxWidth: "100%",
                  // },}}
                  className={FieldInRow}
                >
                  <Box className={inputBox}>
                    <Typography className={input_typography}>
                      Description
                    </Typography>
                    <TextField
                      sx={{ textAlign: "left", input: { color: "black" } }}
                      className={Form_input}
                      fullWidth
                      size="small"
                      multiline
                      rows={4}
                      name="description"
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      variant="outlined"
                      error={
                        Boolean(touched.description) &&
                        Boolean(errors.description)
                      }
                      helperText={
                        Boolean(touched.description) && errors.description
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",

                  gap: "10px",
                  marginTop: "20px",
                }}
              >
                <Button
                  className={btns}
                  color="error"
                  variant="contained"
                  onClick={() => setShowForm(false)}
                >
                  Cancel
                </Button>
                {isSubmitting ? (
                  <CircularProgress />
                ) : (
                  <Button className={btns} type="submit" variant="contained">
                    Save
                  </Button>
                )}
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default ChangeinForm;
ChangeinForm.propTypes = {
  setShowForm: PropTypes.any,
};
