import React, { useContext, useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.css";

// import required modules
// import { Pagination } from "swiper";
import AttendanceCard from "../DepartmentAttendanceSection/AttendanceCard";
import {
  FetchPresentEmployeeData,
  getDashboardActivities,
} from "../../../Redux/Slice/Dashboard/DashboardSlice";
import { logout } from "../../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import SwiperCore, { Navigation } from "swiper";
import { Box, CircularProgress } from "@mui/material";

SwiperCore.use([Navigation]);

const AttendanceSwiper = () => {
  const navigate = useNavigate();
  const { setsnackBarData } = useContext(SnackBarContext);
  const [cardData, setcardData] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchPresentEmployeeData());
  }, [dispatch]);
  const { presentEmployee, IsLogin, message, status } = useSelector(
    getDashboardActivities
  );
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  useEffect(() => {
    if (presentEmployee) {
      const sortedData = [...presentEmployee]?.sort(
        (a, b) => b?.departmetn_emp_count - a?.departmetn_emp_count
      );
      setcardData(sortedData);
    }
  }, [presentEmployee]);
  console.log("", cardData);
  return (
    <>
      {status === "loading" ? (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {cardData && cardData ? (
            <Swiper
              navigation={true}
              modules={[Navigation]}
              slidesPerView={"auto"}
              // centeredSlides={false}
              spaceBetween={30}
              // grabCursor={true}
              // pagination={{
              //   clickable: true,
              // }}
              autoplay={{
                delay: 1500,
                disableOnInteraction: false,
              }}
              // modules={[Pagination]}
              className="mySwiper"
            >
              {cardData?.map((data, i) => {
                return (
                  <SwiperSlide key={i}>
                    <AttendanceCard data={data} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src="./NoData.png"
                alt="No Data Found "
                style={{ width: "32%" }}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default AttendanceSwiper;
