import PropTypes from "prop-types";
import {
  Box,
  // Button,
  // CircularProgress,
  gridClasses,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import { logout } from "../../../Redux/Slice/LogoutSlice/LogoutSlice";
import { fetchRoles, getAllRoles } from "../../../Redux/Slice/Roles/RolesSlice";
import { useRef } from "react";
import RoleDeleteModal from "../../DeleteModal/RoleDeleteModal";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { DeleteRoleAPI } from "../../../Api/Roles/DeleteRole";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "../../Button/AddNewButton";
import { TabelStyleContext } from "../../../Context/TabelStyleContext/TabelContext";
import Loader from "../../Loader/Loader";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});
function RolesTable({ setShowForm, setrolesRowData, setIsEdit }) {
  const { tabelStyle } = useContext(TabelStyleContext);
  const { header } = useStyles();
  const dispatch = useDispatch();
  const [rows, setrows] = useState([]);

  const [filterRows, setfilterRows] = useState([]);
  const [pageSize, setPageSize] = useState(15);
  const navigate = useNavigate();
  const { setsnackBarData } = useContext(SnackBarContext);
  const { IsLogin, message, Roles, status } = useSelector(getAllRoles);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [EditedData, setEditedData] = useState(null);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [editPermit, seteditpermit] = useState(false);
  const [addPermit, setaddpermit] = useState(false);
  const [deletePermit, setdeletepermit] = useState(false);
  const [loading, setloading] = useState(false);
  // console.log(crop);

  console.log(roles);
  useEffect(() => {
    let string = "Roles";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Edit") {
        seteditpermit(true);
      } else if (val?.module?.split("_")[1] === "Add") {
        setaddpermit(true);
      } else if (val?.module?.split("_")[1] === "Delete") {
        setdeletepermit(true);
      }
    });
    if (!roles || roles.length < 1) {
      setaddpermit(true);
      seteditpermit(true);
      setdeletepermit(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  console.log(editPermit);
  console.log(addPermit);
  console.log(deletePermit);

  const Name = useRef();
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  useEffect(() => {
    dispatch(fetchRoles());
  }, [dispatch]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport csvOptions={{ fileName: "Rules" }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }
  const columns = [
    { field: "sr", headerName: "Sr #", width: 100, headerClassName: header },
    {
      field: "role_name",
      headerName: "Role",
      width: 180,

      headerClassName: header,
    },
    {
      field: "action",
      headerName: "Action",
      width: 180,
      headerClassName: header,
      renderCell: (celVal) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              gap: "10px",
            }}
          >
            {editPermit ? (
              <Tooltip title="Edit">
                <IconButton
                  size={"small"}
                  onClick={() => {
                    setrolesRowData(celVal.row);
                    setIsEdit(true);
                    setShowForm(true);
                  }}
                >
                  <AppRegistrationIcon
                    sx={{ cursor: "pointer", color: "black !important" }}
                  />
                </IconButton>
              </Tooltip>
            ) : null}
            {deletePermit ? (
              <IconButton
                onClick={() => {
                  setEditedData(celVal.row);
                  setshowDeleteModal(true);
                }}
                size={"small"}
              >
                <DeleteOutlineIcon
                  sx={{ color: (theme) => theme.palette.error.main }}
                />
              </IconButton>
            ) : null}
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    let arr = [];
    if (Roles) {
      Roles?.forEach((item, idx) => {
        return arr.push({
          ...item,
          sr: idx + 1,
          id: item?.role_id,
        });
      });
    }
    setrows(arr);
    setfilterRows(arr);
  }, [Roles]);

  const onTextChange = () => {
    if (Name.current.value === "") {
      setfilterRows(rows);
    } else {
      let suggestions = [];
      let regex1 = new RegExp(Name.current.value, "i");

      suggestions = rows?.filter(
        (val) => Name.current?.value === "" || regex1.test(val?.role_name)
      );

      setfilterRows(suggestions);
    }
  };
  const handelDelete = async () => {
    console.log(EditedData);
    if (!EditedData) {
      return setsnackBarData({
        message: "please select role first",
        type: "error",
        openToast: true,
      });
    }
    setloading(true);
    const response = await DeleteRoleAPI(EditedData?.id);
    console.log(response.data);
    setsnackBarData(response?.snackBarData);
    if (response.data.IsSuccess) {
      setloading(false);
      setshowDeleteModal(false);
      dispatch(fetchRoles());
    }
    setloading(false);
  };
  useEffect(() => {
    if (status === "failed") {
      setsnackBarData({
        type: "error",
        message: "Network Error",
        openToast: true,
      });
    }
  }, [status]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          padding: "20px 10px 10px 10px",
        }}
      >
        <Box>
          {addPermit ? (
            <>
              {/* <Button
                sx={{ padding: "5px 10px" }}
                onClick={() => {
                  setShowForm(true);
                }}
                variant="contained"
                className="tabletopbutton1 text-right"
              >
                New
              </Button> */}
              <CustomButton
                Color={"primary"}
                Icon={<AddIcon />}
                onClick={() => {
                  setShowForm(true);
                }}
                Label={"Add"}
              />
            </>
          ) : null}
        </Box>
        <Box>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{ color: (theme) => theme.palette.grey.G2 }}
                  />
                </InputAdornment>
              ),
            }}
            variant="standard"
            type="text"
            autoComplete="off"
            placeholder="Role"
            // className="input_border"
            id="empName"
            inputRef={Name}
            onChange={onTextChange}
          />
        </Box>
      </Box>

      <Box
        style={{
          height: tabelStyle.height,
          width: "100%",
          padding: "10px",
        }}
      >
        {Roles?.length <= 0 && status === "loading" ? <Loader /> : null}
        <DataGrid
          rows={filterRows}
          columns={columns}
          onRowClick={() => { }}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[15, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          pagination
          components={{
            Toolbar: CustomToolbar,
          }}
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />
      </Box>
      <RoleDeleteModal
        name={`"${EditedData?.role_name}"`}
        handelDelete={handelDelete}
        showModal={showDeleteModal}
        setshowModal={setshowDeleteModal}
        loading={loading}
      />
    </>
  );
}

export default RolesTable;
RolesTable.propTypes = {
  setShowForm: PropTypes.any,
  setrolesRowData: PropTypes.any,
  setIsEdit: PropTypes.any,
};
