import PropTypes from "prop-types";
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { GetMissingPunchesReport } from "../../Api/EmployeMissingPunches/EmployeMissingPunchesApi";
import { useDispatch, useSelector } from "react-redux";
import { FetchAllActiveEmployee } from "../../Redux/Slice/Employee/EmplyeeSlice";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";
import Loader from "../Loader/Loader";
import moment from "moment";

const useStyle = makeStyles((theme) => {
  return {
    tablehead: {
      fontWeight: "bold",
      backgroundColor: "#53b7c5 !important",
      color: theme.palette.white.main,
      padding: "15px 20px",
      flex: 1,
    },
  };
});

function MissingPunchesOfEmployees({ setrowvalue, rowvalue, empDate, setEmpDate }) {
  const { tablehead } = useStyle();
  const [filterRows, setfilterRows] = useState([]);
  const [loading, setloading] = useState(false);
  const [LogDetails, setLogDetails] = useState([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const { setsnackBarData } = useContext(SnackBarContext);
  const dispatch = useDispatch();
  const Employe = useSelector((state) => state.employee.allActiveEmployees);
  const { tabelStyle } = useContext(TabelStyleContext);
  const [expandedRow, setExpandedRow] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(empDate);

  const handleExpand = (rowIndex) => {
    setExpandedRow(rowIndex === expandedRow ? null : rowIndex);
  };

  useEffect(() => {
    dispatch(FetchAllActiveEmployee());
    GetMissingPunches();

  }, [dispatch]);
  useEffect(() => {
    GetMissingPunches();
  }, [rowvalue, empDate]);
  const handleClick = () => {
    setrowvalue(selectedEmployeeId);
    setEmpDate(selectedMonth);

  };
  console.log(LogDetails);
  const GetMissingPunches = async () => {
    setloading(true);
    const response = await GetMissingPunchesReport({
      emp_id: rowvalue,
      month: empDate,
    });
    setsnackBarData(response?.snackBarData);
    setloading(false);
    if (response.data.IsSuccess) {
      setLogDetails(response?.data);
      setfilterRows(response?.data);
    }
    setloading(false);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          padding: "20px 10px 10px 10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "10px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              width: "100%",
            }}
          >
            <TextField
              size="small"
              type=""
              select
              variant="outlined"
              color="primary"
              fullWidth
              sx={{
                input: { color: "black" },
                maxWidth: "250px",
                textAlign: "left",
              }}
              value={selectedEmployeeId}
              onChange={(event) => setSelectedEmployeeId(event.target.value)}
              InputProps={{
                style: { borderRadius: 8 },
              }}
              className="for-dark-input"
            >
              {Employe?.map((type, index) => (
                <MenuItem value={type?.Emp_id} key={index}>
                  {type.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              size="small"
              type="month"
              variant="outlined"
              color="primary"
              fullWidth
              sx={{
                input: { color: "black" },
                maxWidth: "250px",
                textAlign: "left",
              }}
              value={selectedMonth}
              inputProps={{
                max: moment().format("YYYY-MM"),
              }}
              onChange={(e) => {
                console.log(e.target.value);
                setSelectedMonth(e.target.value);
              }}
            />

            <Button
              onClick={handleClick}
              type="submit"
              sx={{ padding: "5px 10px" }}
              variant="contained"
            >
              Get
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "end",
            width: "100%",
          }}
        ></Box>
      </Box>

      {loading ? (
        <>
          <Loader />
        </>
      ) : null}
      <Grid
        item
        md={12}
        sm={12}
        xs={12}
        overflow
        sx={{ height: tabelStyle.height, padding: "10px" }}
      >
        <TableContainer
          component={Paper}
          sx={{
            width: "100%",
            maxWidth: "100%",
            height: "100%",
            overflowX: "scroll",
            margin: "0px auto",
          }}
        >
          <Table size="small" stickyHeader sx={{ overflowX: "scroll" }}>
            <TableHead
              sx={{
                width: "100%",
                padding: "0px 10px",
              }}
            >
              <TableRow sx={{ padding: "0px 20px", width: "100%" }}>
                <TableCell className={tablehead}>Sr #</TableCell>
                <TableCell className={tablehead}>Employee Name</TableCell>
                <TableCell className={tablehead}>Date</TableCell>
                <TableCell className={tablehead}>In Time</TableCell>
                <TableCell className={tablehead}>Out Time</TableCell>
                <TableCell className={tablehead}>Total Hrs</TableCell>
                <TableCell className={tablehead}>Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ width: "100%", overflowX: "scroll" }}>
              {filterRows?.missingPunches?.length > 0 ? (
                filterRows?.missingPunches?.map((row, rowIndex) => (
                  <React.Fragment key={row.id}>
                    <TableRow>
                      <TableCell>{rowIndex + 1}</TableCell>
                      <TableCell>{row[0]?.emp_id?.name}</TableCell>
                      <TableCell>
                        {" "}
                        {moment(row[0].date).format("DD-MMM-YYYY")}
                      </TableCell>
                      <TableCell>
                        {moment(row[0].in_time, "HH:mm:ss").format("hh:mm A")}
                      </TableCell>
                      <TableCell>
                        {moment(row[0].out_time, "HH:mm:ss").format("hh:mm A")}
                      </TableCell>
                      <TableCell>{row[0].total_hrs}</TableCell>
                      <TableCell onClick={() => handleExpand(rowIndex)}>
                        <IconButton>
                          <KeyboardArrowDownIcon
                            style={{
                              transform: `rotate(${rowIndex === expandedRow ? "180deg" : "0deg"
                                })`,
                            }}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    {rowIndex === expandedRow && (
                      <>
                        <Typography
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            display: "flex",
                            marginTop: "6px",
                            marginLeft: "11%",
                          }}
                        >
                          Log Details
                        </Typography>
                        <TableRow>
                          <TableCell colSpan={7}>
                            <TableContainer>
                              <Table size="small" stickyHeader>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Sr#</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Note</TableCell>
                                    <TableCell>Time</TableCell>
                                    <TableCell>Year</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {row[0]?.attdetail?.map((item, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{index + 1}</TableCell>
                                      <TableCell>{item?.date}</TableCell>
                                      <TableCell>{item?.status}</TableCell>
                                      <TableCell>{item?.note}</TableCell>
                                      <TableCell>
                                        {/* {item?.time} */}
                                        {moment(item?.time, "HH:mm:ss").format("hh:mm A")}

                                      </TableCell>
                                      <TableCell>{item?.year}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <TableCell
                  colSpan={7}
                  style={{ textAlign: "center", height: "650px" }}
                >
                  No rows found
                </TableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
}

export default MissingPunchesOfEmployees;

MissingPunchesOfEmployees.propTypes = {
  setrowvalue: PropTypes.any,
  rowvalue: PropTypes.any,
  setEmpDate: PropTypes.any,
  empDate: PropTypes.any,
};
