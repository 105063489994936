import PropTypes from "prop-types";
import {
  Box,
  Button,
  CircularProgress,
  gridClasses,
  InputAdornment,
  Link,
  // Link,
  MenuItem,
  Modal,
  Paper,
  TextField,
  Tooltip,
  Typography,
  // Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchLeaveApplication,
  fetchsingleEmpLeave,
  getleaveapplicationDetails,
  // removeLeaveApplicationDetail,
} from "../../Redux/Slice/LeaveApplication/LeaveApplicationSlice";
import { makeStyles } from "@mui/styles";
import { useRef } from "react";
import moment from "moment/moment";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { ApproveLeaveAPI } from "../../Api/LeaveApplication/ApproveLeaveStatusApi";
import Cookies from "universal-cookie";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "../Button/AddNewButton";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";
import Loader from "../Loader/Loader";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});
const cookies = new Cookies();
function EmpLeaveApplicationTable({ setShowForm }) {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const { tabelStyle } = useContext(TabelStyleContext);
  const { header } = useStyles();
  const dispatch = useDispatch();
  const NameFilter = useRef();
  const LeaveType = useRef();
  const LeaveStatusFilter = useRef();
  const { setsnackBarData } = useContext(SnackBarContext);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [Leaveapplication, setLeaveapplication] = useState([]);
  const { EmpleaveApplication, status, IsLogin, message, EmpAvlAl, EmpAvlCl } =
    useSelector(getleaveapplicationDetails);
  const [leaveRowVal, setleaveRowVal] = useState({});
  const [countVal, setcountVal] = useState(null);
  const [loader, setloader] = useState(false);
  const [rows, setrows] = useState([]);
  const [filterRows, setfilterRows] = useState([]);
  const [pageSize, setPageSize] = useState(15);
  // const [setSelectedRow] = useState({});
  // const [setshowModal] = useState(false);
  const [disabled, setdisabled] = useState(false);
  const navigate = useNavigate();
  const [setmessage] = useState("");
  const [setopenToast] = useState(false);
  const [setAlertType] = useState("success");
  const [leaveApprove, setleaveApprove] = useState({
    leave_id: "",
    approval_status: "",
    approval_count: "",
    approval_start_date: "",
  });

  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [editPermit, seteditpermit] = useState(false);

  // console.log(crop);

  console.log(roles, setcountVal, setleaveRowVal);
  useEffect(() => {
    let string = "LeaveApplication";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Edit") {
        seteditpermit(true);
      }
    });
    if (!roles || roles.length < 1) {
      seteditpermit(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  console.log(editPermit);

  const user = cookies.get("user");
  useEffect(() => {
    if (cookies.get("user")?.type === "Employee") {
      // dispatch(removeLeaveApplicationDetail())

      dispatch(fetchsingleEmpLeave(cookies.get("user").Emp_id));
    } else if (cookies.get("user")?.type === "Admin") {
      // dispatch(removeLeaveApplicationDetail())
      dispatch(fetchLeaveApplication());
    }
  }, [dispatch]);

  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
    if (EmpleaveApplication) {
      setLeaveapplication(EmpleaveApplication);
    }
  }, [EmpleaveApplication, IsLogin]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport csvOptions={{ fileName: "Leave Application" }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }
  const columns = [
    { field: "id", headerName: "Sr #", headerClassName: header },
    //   {
    //     field: "empId",
    //     headerName: "Employee",
    //     width: 150,
    //     headerClassName: header,
    //   },
    {
      field: "leavetype",
      headerName: "Leave Type",
      width: 200,
      headerClassName: header,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "dateRange",
      headerName: "Start-End Date",
      width: 300,
      headerClassName: header,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Tooltip
          title={params.value || "N/A"}
          placement="top"
          arrow
        >
          <span>{params.value || "N/A"}</span>
        </Tooltip>
      ),
    },
    // {
    //   field: "enddate",
    //   headerName: "End Date",
    //   width: 150,
    //   headerClassName: header,
    //   headerAlign: "center",
    //   align: "center",
    // },

    {
      field: "reasons",
      headerName: "Reason",
      width: 200,
      headerClassName: header,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Tooltip
          title={params.value || "N/A"}
          placement="top"
          arrow
        >
          <span
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "180px",
              display: "block",
              cursor: "pointer",
            }}
          >
            {params.value || "N/A"}
          </span>
        </Tooltip>
      ),
    },

    {
      field: "Attachment",
      headerName: "Attachment",
      width: 200,
      headerClassName: header,
      headerAlign: "center",
      align: "center",
      renderCell: (celVal) => {
        return celVal.row?.Attachment ? (
          <Link
            href={celVal.row.Attachment}
            target="_blank"
          >
            <Tooltip
              title={celVal.row.Attachment}
              placement="top"
              arrow
            >
              <img
                src="/download-icon.png"
                alt="Download"
                width={18}
                height={18}
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          </Link>
        ) : (
          "N/A"
        );
      },
    },
    {
      field: "requestedCount",
      headerName: "Requested Leaves",
      width: 200,
      headerClassName: header,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "ApprovalCount",
      headerName: "Approval Leaves",
      width: 200,
      headerClassName: header,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Approved",
      headerName: "Status",
      width: 170,
      headerClassName: header,
      headerAlign: "center",
      align: "center",
      renderCell: (celVal) => {
        return (
          // <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
          //   <Box
          //     sx={{
          //       width: "20px",
          //       height: "20px",
          //       backgroundColor:
          //         celVal?.row?.Approved === "Rejected"
          //           ? "#FF0000"
          //           : celVal?.row?.Approved === "Accepted"
          //             ? "#4CAF50"
          //             : celVal?.row?.Approved === "Pending"
          //               ? "#FFC107"
          //               : null,
          //       borderRadius: "100%",
          //     }}
          //   ></Box>
          // </Box>
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Box
              sx={{
                width: "20px",
                height: "20px",
                backgroundColor:
                  celVal?.row?.Approved === "Rejected"
                    ? "#FF0000"
                    : celVal?.row?.Approved === "Accepted"
                      ? "#4CAF50"
                      : celVal?.row?.Approved === "Pending"
                        ? "#FFC107"
                        : "transparent",
                borderRadius: "100%",
              }}
            ></Box>
            <Typography variant="body2"
              sx={{
                color:
                  celVal?.row?.Approved === "Rejected"
                    ? "#FF0000"
                    : celVal?.row?.Approved === "Accepted"
                      ? "#4CAF50"
                      : celVal?.row?.Approved === "Pending"
                        ? "#FFC107"
                        : "transparent",
              }}>
              {celVal?.row?.Approved || "N/A"}
            </Typography>
          </Box>

        );
      },
    },
  ];
  const handleChangeVal = (evt) => {
    let value = evt.target.value;
    setdisabled(false);

    if (evt.target.name === "approval_count") {
      if (value > countVal) {
        value = countVal;
      }
    }
    if (evt.target.name === "approval_status" && value === "Rejected") {
      setleaveApprove({
        ...leaveApprove,
        [evt.target.name]: value,
        approval_count: 0,
      });
      setdisabled(true);
    } else {
      setdisabled(false);

      setleaveApprove({
        ...leaveApprove,
        [evt.target.name]: value,
      });
    }
  };
  const handleApproveLeave = async () => {
    if (
      leaveApprove.approval_status === "" &&
      leaveApprove.approval_count === "" &&
      leaveApprove.approval_start_date
    ) {
      setsnackBarData({
        type: "error",
        message: "please add Required Data",
        openToast: "true",
      });
      return;
    }
    setloader(true);
    if (leaveApprove?.approval_status === "Accepted") {
      const obj = {
        leave_id: leaveRowVal?.leave_id,
        approval_status: leaveApprove?.approval_status,
        approval_count: leaveApprove?.approval_count,
        approval_start_date: leaveApprove?.approval_start_date,
      };
      const response = await ApproveLeaveAPI(obj);
      setsnackBarData(response.snackBarData);
      setloader(false);
      if (response.data.IsSuccess) {
        dispatch(fetchLeaveApplication());
        setloader(false);
        handleClose();
        // leaveApprove.approval_status = "",
        // leaveApprove.approval_count = "" ,
        // leaveApprove.approval_start_date ="",
        // leaveApprove.leave_id=""
      }
      setloader(false);
    } else if (leaveApprove?.approval_status === "Rejected") {
      const obj = {
        leave_id: leaveRowVal?.leave_id,
        approval_status: leaveApprove?.approval_status,
        approval_count: 0,
        approval_start_date: null,
      };
      const response = await ApproveLeaveAPI(obj);
      setsnackBarData(response.snackBarData);
      setloader(false);
      if (response.data.IsSuccess) {
        dispatch(fetchLeaveApplication());
        setloader(false);
        handleClose();
        (leaveApprove.approval_status = ""),
          (leaveApprove.approval_count = ""),
          (leaveApprove.approval_start_date = ""),
          (leaveApprove.leave_id = "");
      }
      setloader(false);
    }
  };
  useEffect(() => {
    let arr = [];
    // if (leaveapplications) {
    console.log("Available applications", EmpAvlCl, EmpAvlAl);
    Leaveapplication &&
      Leaveapplication?.map((item, idx) => {
        return arr.push({
          ...item,
          id: idx + 1,
          empId: item?.emp_id?.name,
          dateRange:
            item?.start_date && item?.end_date
              ? `${moment(item.start_date).format("DD-MMM-YYYY")} - ${moment(
                item.end_date
              ).format("DD-MMM-YYYY")}`
              : item?.start_date
                ? moment(item.start_date).format("DD-MMM-YYYY")
                : item?.end_date
                  ? moment(item.end_date).format("DD-MMM-YYYY")
                  : "",

          reasons: item?.reason,
          leavetype: item?.leave_type,
          Attachment: item?.attachment,
          Approved: item?.approval_status,
          ApprovalCount: item?.approval_count,
          requestedCount: item?.total_count,
          available_CL: EmpAvlCl,
          available_AL: EmpAvlAl,
          leave_id: item?.leave_id,
        });
      });
    setrows(arr);
    setfilterRows(arr);
  }, [Leaveapplication]);

  useEffect(() => {
    if (
      getleaveapplicationDetails?.status === "succeeded" ||
      getleaveapplicationDetails?.status === "failed"
    ) {
      // setloading(false);
      if (getleaveapplicationDetails?.isSuccess === true) {
        setAlertType("success");
        setmessage(getleaveapplicationDetails.message);
        setopenToast(true);
      }
      if (getleaveapplicationDetails?.isSuccess === false) {
        setAlertType("error");
        setmessage(
          getleaveapplicationDetails.message || getleaveapplicationDetails.error
        );
        setopenToast(true);
      }
    }
  }, [getleaveapplicationDetails]);

  const onTextChange = () => {
    let name = NameFilter.current.value;

    let type = LeaveType.current.value;

    let status = LeaveStatusFilter.current.value;

    if (name === "" && type === "" && status === "") {
      setfilterRows(rows);
    } else {
      let suggestions = [];

      const regex = new RegExp(name, "i");
      const regex1 = new RegExp(type, "i");
      const regex2 = new RegExp(status, "i");

      suggestions = rows?.filter(
        (val) =>
          (name === "" || regex.test(val.empId)) &&
          (type === "" || regex1.test(val.leavetype)) &&
          (status === "" || regex2.test(val.Approved))
      );

      setfilterRows(suggestions);
    }
  };
  useEffect(() => {
    if (status === "failed") {
      setsnackBarData({
        type: "error",
        message: "Network Error",
        openToast: true,
      });
    }
  }, [status]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          padding: "20px 10px 10px 10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {user?.type !== "Admin" ? (
            // <Button
            //   sx={{ padding: "5px 10px" }}
            //   onClick={() => {
            //     setShowForm(true);
            //   }}
            //   variant="contained"
            //   className="tabletopbutton1 text-right"
            // >
            //   New
            // </Button>
            <CustomButton
              Color={"primary"}
              Icon={<AddIcon />}
              onClick={() => {
                setShowForm(true);
              }}
              Label={"Add"}
            />
          ) : null}
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
          }}
        >
          {user?.type !== "Employee" ? (
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      sx={{ color: (theme) => theme.palette.grey.G2 }}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{
                width: "100%",
                maxWidth: "180px",
              }}
              variant="standard"
              type="text"
              autoComplete="off"
              placeholder="Employee"
              className="input_border"
              inputRef={NameFilter}
              id=" EmployeeName"
              onChange={onTextChange}
            />
          ) : null}

          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{ color: (theme) => theme.palette.grey.G2 }}
                  />
                </InputAdornment>
              ),
            }}
            sx={{
              width: "100%",
              maxWidth: "180px",
            }}
            variant="standard"
            type="text"
            autoComplete="off"
            placeholder="Leave Type"
            className="input_border"
            id="TypeFilter"
            inputRef={LeaveType}
            onChange={onTextChange}
          />

          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{ color: (theme) => theme.palette.grey.G2 }}
                  />
                </InputAdornment>
              ),
            }}
            sx={{
              width: "100%",
              maxWidth: "180px",
            }}
            variant="standard"
            type="text"
            autoComplete="off"
            placeholder="Status"
            className="input_border"
            id="StatusFilter"
            inputRef={LeaveStatusFilter}
            onChange={onTextChange}
          />
        </Box>
      </Box>
      {status === "loading" ? (
        <>
          <Loader />
        </>
      ) : null}
      <Box sx={{ height: tabelStyle.height, padding: "10px" }}>
        <DataGrid
          rows={filterRows}
          columns={columns}
          // onRowClick={(e) => {
          //   setSelectedRow(e.row);
          //   setshowModal(true);
          // }}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[15, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          pagination
          // components={{
          //   Toolbar: CustomToolbar,
          // }}
          components={isSmallScreen ? {} : { Toolbar: CustomToolbar }}
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />
      </Box>

      <Modal
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
        }}
        open={open}
        onClose={() => {
          // setselectedEmployee(null);
          handleClose();
        }}
      >
        <Paper sx={{ width: "100%", maxWidth: "500px", padding: "10px" }}>
          {leaveApprove.approval_status === "" ? null : (
            <Typography sx={{ fontWeight: "bold" }}>
              You are {leaveApprove.approval_status} Leave Application of{" "}
              {leaveRowVal.empId}
            </Typography>
          )}

          <Typography sx={{ paddingTop: "10px" }}>Approval Status</Typography>

          <TextField
            name="approval_status"
            select
            value={leaveApprove.approval_status}
            type="text"
            size="small"
            sx={{ width: "100%" }}
            onChange={handleChangeVal}
          >
            <MenuItem value="">Select</MenuItem>
            <MenuItem value="Accepted">Accepted</MenuItem>
            <MenuItem value="Rejected">Rejected</MenuItem>
          </TextField>

          <Typography sx={{ paddingTop: "10px" }}>Approval Count</Typography>
          <TextField
            name="approval_count"
            type="number"
            value={leaveApprove.approval_count}
            sx={{ width: "100%" }}
            size="small"
            disabled={disabled}
            onChange={handleChangeVal}
          />
          <Typography sx={{ paddingTop: "10px" }}>Start Date</Typography>
          <TextField
            name="approval_start_date"
            type="date"
            disabled={disabled}
            inputProps={{
              min: moment().format("YYYY-MM-DD"),
            }}
            value={leaveApprove.approval_start_date}
            sx={{ width: "100%" }}
            size="small"
            onChange={handleChangeVal}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              paddingTop: "10px",
              gap: "10px",
            }}
          >
            <Button
              sx={{
                background: "red",
                color: "white !important",
                fontSize: "13px",
                textTransform: "none",
              }}
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
            {loader ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                sx={{
                  // background: "#3480FA",
                  color: "white !important",
                  fontSize: "13px",
                  // margin: "0px auto",
                  textTransform: "none",
                }}
                onClick={() => {
                  handleApproveLeave();
                }}
              >
                Save
              </Button>
            )}
          </Box>
        </Paper>
      </Modal>
    </>
  );
}

export default EmpLeaveApplicationTable;

EmpLeaveApplicationTable.propTypes = {
  setShowForm: PropTypes.any,
  setempData: PropTypes.any,
  setisEdited: PropTypes.any,
};
