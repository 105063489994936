import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  employee: [],
  empDepartments: [],
  Technologies: [],
  PageNum: 0,
  PageSize: 15,
  TotalCount: 0,
  Experties: [],
  allActiveEmployees: [],
  allInactiveEmployees: [],
  Department: [],
  Role: [],
  Designation: [],
  Jobtitle: [],
  ShiftRule: [],
  EmployeeTaskSummary: [],
  singleEmployee: null,
  SingleEmployeDetail: [],
  isSuccess: false,
  message: null,
  employeeStatus: "idle",
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};

export const employePopulate = createAsyncThunk(
  "employeepopulate",
  async () => {
    // try{
    const response = await axiosInstance.get(`employeepopulate`);
    return response.data;
    // }catch (error) {
    //
    // }
  }
);

export const FetchAllActiveEmployee = createAsyncThunk(
  "activeemployeedata",
  async () => {
    // try{
    const response = await axiosInstance.get(`activeemployeedata`);
    return response.data;
    // }catch (error) {
    //
    // }
  }
);

export const FetchAllInActiveEmployee = createAsyncThunk(
  "inactiveemployeedata",
  async () => {
    // try{
    const response = await axiosInstance.get(`inactiveemployeedata`);

    return response.data;
    // }catch (error) {
    //
    //   return error
    // }
  }
);

export const fetchEmployee = createAsyncThunk(
  "getemployeesfulldata?",
  async ({ page, pageSize }) => {
    // try {
    const response = await axiosInstance.get(
      `getemployeesfulldata?PageNum=${Number(page) + 1}&PageSize=${pageSize}`
    );
    return response.data;

    // } catch (error) {
    //
    // }
  }
);

export const FetchSingleEmployee = createAsyncThunk(
  "getoneemployee",
  async (id) => {
    // try {
    const response = await axiosInstance.get(`getoneemployee/${id}`);
    return response.data;

    // } catch (error) {
    //
    // }
  }
);
export const FetchSingleEmployeeForPerformance = createAsyncThunk(
  "getEmployeeInfoForPerformaceManagement",
  async (id) => {
    // try {
    const response = await axiosInstance.get(
      `getEmployeeInfoForPerformaceManagement/${id}`
    );
    return response.data;

    // } catch (error) {
    //
    // }
  }
);
export const FetchSingleEmployeDetail = createAsyncThunk(
  "Fetch/employeecard/",
  async (id) => {
    // try {

    const response = await axiosInstance.get(`employeecard/${id}`);

    return response.data;

    // } catch (error) {
    //
    // }
  }
);
export const FetchEmployeeTaskSummary = createAsyncThunk(
  "Fetch/EmployeeTaskSummary/",
  async ({ startDate, endDate }) => {

    const response = await axiosInstance.get(`/EmployeesTaskSummary?startof=${startDate}&endof=${endDate}`);

    return response.data;
  }
);

const EmployeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    EmployeeAdded: {
      reducer(state, action) {
        state.employee.push(action.payload);
      },
    },
    removeSingleEmployeeData: {
      reducer(state) {
        state.singleEmployee = null;
      },
    },
    UpdateEmployeeData: {
      reducer(state, action) {
        console.log([...state.employee]);
        let abc = [...state.employee];
        console.log(action.payload);
        state.employee = abc.filter((item) => item.Emp_id !== action.payload);
      },
    },
    EmployeeResetData: (state) => {
      state.employee = [];
      state.empDepartments = [];
      state.Technologies = [];
      state.PageNum = 0;
      state.PageSize = 15;
      state.TotalCount = 0;
      state.Experties = [];
      state.allActiveEmployees = [];
      state.allInactiveEmployees = [];
      state.Department = [];
      state.Role = [];
      state.Designation = [];
      state.Jobtitle = [];
      state.ShiftRule = [];
      state.singleEmployee = null;
      state.SingleEmployeDetail = [];
      state.isSuccess = false;
      state.message = null;
      state.employeeStatus = "idle";
      state.status = "idle";
      state.error = null;
      state.IsLogin = true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchEmployee.pending, (state) => {
        state.employeeStatus = "loading";
      })
      .addCase(fetchEmployee.fulfilled, (state, action) => {
        state.employeeStatus = "succeeded";
        state.message = action.payload?.Message;
        state.isSuccess = action.payload?.IsSuccess;
        let arr = [...state.employee];
        // let arr1=[]
        Array.prototype.splice.apply(
          arr,
          [
            action.payload?.PageSize * action.payload?.PageNum -
            action.payload?.PageSize,
            action?.payload?.Data?.length,
          ].concat(action?.payload?.Data)
        );
        //         const startIdx =
        //   action.payload?.PageSize * action.payload?.PageNum - action.payload?.PageSize;
        // const deleteCount = action?.payload?.Data.length;
        // let acv=arr.splice(startIdx, deleteCount);
        // console.log(acv);
        // console.log(arr1);
        // // Insert the new elements from action.payload?.Data at the same position
        // let op=arr.splice(startIdx, action?.payload?.Data.length, action?.payload?.Data);
        // console.log(op);
        // console.log(arr);
        state.employee = [...arr];
        state.PageNum = Number(action.payload?.PageNum);
        state.PageSize = Number(action.payload?.PageSize);
        state.TotalCount = Number(action.payload?.TotalCount);
        // state.employee = action.payload?.Data;
      })
      .addCase(fetchEmployee.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })

      .addCase(employePopulate.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload?.Message;
        state.isSuccess = action.payload?.IsSuccess;
        state.Experties = action.payload?.Com_Expertise;
        state.Technologies = action.payload?.Com_Technologies;
        state.Department = action.payload?.Com_Departments;
        state.Designation = action.payload?.Com_Designation;
        state.Jobtitle = action.payload?.Com_JobTitle;
        state.ShiftRule = action.payload?.Com_ShiftRules;
        state.Role = action.payload?.Roles;
      })
      .addCase(employePopulate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(employePopulate.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(FetchSingleEmployee.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload?.Message;
        state.isSuccess = action.payload?.IsSuccess;
        state.singleEmployee = action.payload?.Data;
      })
      .addCase(FetchSingleEmployee.pending, (state) => {
        state.status = "loading";
      })
      .addCase(FetchSingleEmployee.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(FetchSingleEmployeeForPerformance.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload?.Message;
        state.isSuccess = action.payload?.IsSuccess;
        state.singleEmployee = action.payload?.Data;
      })
      .addCase(FetchSingleEmployeeForPerformance.pending, (state) => {
        state.status = "loading";
      })
      .addCase(FetchSingleEmployeeForPerformance.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(FetchAllActiveEmployee.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload?.Message;
        state.isSuccess = action.payload?.IsSuccess;
        state.allActiveEmployees = action.payload?.Data;
      })
      .addCase(FetchAllActiveEmployee.pending, (state) => {
        state.status = "loading";
      })
      .addCase(FetchAllActiveEmployee.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })

      .addCase(FetchAllInActiveEmployee.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload?.Message;
        state.isSuccess = action.payload?.IsSuccess;
        state.allInactiveEmployees = action.payload?.Data;
      })
      .addCase(FetchAllInActiveEmployee.pending, (state) => {
        state.status = "loading";
      })
      .addCase(FetchAllInActiveEmployee.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
        // state.status = "failed";
        // state.error = action.error.message;
      })
      .addCase(FetchSingleEmployeDetail.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload?.Message;
        state.isSuccess = action.payload?.IsSuccess;
        state.SingleEmployeDetail = action.payload?.Data;
      })
      .addCase(FetchSingleEmployeDetail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(FetchSingleEmployeDetail.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      }).addCase(FetchEmployeeTaskSummary.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload?.Message;
        state.isSuccess = action.payload?.IsSuccess;
        state.EmployeeTaskSummary = action.payload;
      })
      .addCase(FetchEmployeeTaskSummary.pending, (state) => {
        state.status = "loading";
      })
      .addCase(FetchEmployeeTaskSummary.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      });
  },
});

export const getemployeeDetails = (state) => state.employee;

export const {
  EmployeeAdded,
  EmployeeResetData,
  removeSingleEmployeeData,
  UpdateEmployeeData,
} = EmployeeSlice.actions;

export default EmployeeSlice.reducer;
