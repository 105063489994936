import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import PropTypes from "prop-types";

const Card3 = ({ title, icon, data }) => {
  return (
    <>
      <Box
        sx={{
          // height: "70px",
          // width: "70px",
          marginTop: { xs: "6px", sm: "0" },
          width: { xs: '45px', sm: "70px" },

          height: { xs: '45px', sm: "70px" },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: (theme) => theme.palette.warning.light,
          borderRadius: "10px",
        }}
      >
        {icon}
      </Box>
      <Box>
        <Typography sx={{ color: (theme) => theme.palette.grey['600'] }}>
          {title}
        </Typography>
        <Typography variant="h6" sx={{
          fontWeight: 'bold', textAlign: 'left',
          fontSize: { xs: "15px", sm: "20px" },

        }}>{data}</Typography>
      </Box>
    </>
  );
};

export default Card3;

Card3.propTypes = {
  title: PropTypes.any,
  data: PropTypes.any,
  icon: PropTypes.any,
};
