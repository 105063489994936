import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  EmployeeTasksReport: [],
  EmployeeTasksReportbyPortalID: [],
  isSuccess: false,
  spentHours: 0,
  estimatedHours: 0,
  IsLogin: true,
  message: null,
  message1: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  status1: "idle",
  error: null,
};

export const fetchEmployeeTaskReport = createAsyncThunk(
  "EmployeesTaskReport",
  async ({ startDate, endDate }) => {
    const response = await axiosInstance.get(
      `EmployeesTaskReport?startof=${startDate}&endof=${endDate}`
    );

    return response.data;
  }
);
export const fetchEmployeeTaskReportbyPortalId = createAsyncThunk(
  "EmployeesTaskReportbyPortalId",
  async ({ startDate, endDate, portalId }) => {
    const response = await axiosInstance.get(
      `WeeklyTaskCalender?startof=${startDate}&endof=${endDate}&portalid=${portalId}`
    );

    return response.data;
  }
);

const PortalSummarySlice = createSlice({
  name: "EmployeesTaskReport",
  initialState,
  reducers: {
    resetData: (state) => {
      state.EmployeeTasksReport = [];
      state.isSuccess = false;
      state.IsLogin = true;
      state.message = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchEmployeeTaskReport.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchEmployeeTaskReport.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.EmployeeTasksReport = action.payload.finaldata;
      })
      .addCase(fetchEmployeeTaskReport.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(fetchEmployeeTaskReportbyPortalId.pending, (state) => {
        state.status1 = "loading";
      })
      .addCase(fetchEmployeeTaskReportbyPortalId.fulfilled, (state, action) => {
        state.status1 = "succeeded";
        state.message1 = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.spentHours = action.payload.spentHours || 0;
        state.estimatedHours = action.payload.estimatedHours || 0;
        state.EmployeeTasksReportbyPortalID = action.payload.formattedResponse || [];
      })
      .addCase(fetchEmployeeTaskReportbyPortalId.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message1 = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status1 = "failed";
        }
      });
  },
});
export const getPortalTaskSummary = (state) => state.portalSummary;
export default PortalSummarySlice.reducer;
