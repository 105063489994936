import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import PropTypes from "prop-types";

const Card5 = ({ title, icon, data, title2, data2, title3, data3 }) => {
  return (
    <>
      <Box
        sx={{
          // height: "70px",
          marginTop: { xs: "17px", sm: "0" },

          width: "70px",
          height: { xs: '45px', sm: "70px" },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: (theme) => theme.palette.warning.light,
          borderRadius: "10px",
        }}
      >
        {icon}
      </Box>
      <Box >
        <Typography sx={{
          color: (theme) => theme.palette.grey['600'],
          fontSize: { xs: "14px", sm: "16px" },

        }}>
          {title}
        </Typography>
        <Typography sx={{
          textAlign: 'center',
          fontSize: { xs: "14px", sm: "16px" },

        }}>{data}</Typography>
      </Box>
      <Divider orientation="vertical" sx={{ height: "70px" }} />
      <Box>
        <Typography sx={{
          color: (theme) => theme.palette.grey['600'],
          fontSize: { xs: "14px", sm: "16px" },

        }}>
          {title2}
        </Typography>
        <Typography sx={{ textAlign: 'center' }}>{data2}</Typography>
      </Box>
      <Box>
        <Typography sx={{
          color: (theme) => theme.palette.grey['600'],
          fontSize: { xs: "14px", sm: "16px" },

        }}>
          {title3}
        </Typography>
        <Typography sx={{ textAlign: 'center' }}>{data3}</Typography>
      </Box>
    </>
  );
};

export default Card5;

Card5.propTypes = {
  title: PropTypes.any,
  data: PropTypes.any,
  title2: PropTypes.any,
  data2: PropTypes.any,
  title3: PropTypes.any,
  data3: PropTypes.any,
  icon: PropTypes.any,
};


